import {
  NotificationsPayload,
  NotificationTypes,
  NotificationsReducerTypes,
  NotificationInterface
} from "../types/notifications.type";

export interface NotificationsStateInterface {
  notifications: NotificationInterface[]
  //unreadNotifications: NotificationInterface[]
  unreadNumber: number
}

export const emptyNotificationsState: NotificationsStateInterface = {
  notifications: [],
  //unreadNotifications: [],
  unreadNumber: 0
}

function sortNotifications(notifications: NotificationInterface[]) {
  return notifications.sort(function(a, b) {
    return b.id - a.id; //  ||  a.name.localeCompare(b.name);
  });
}

const notificationsReducer = (state: NotificationsStateInterface = emptyNotificationsState, action: NotificationsPayload) => {
  switch (action.type) {
    case NotificationsReducerTypes.GET_ALL_NOTIFICATIONS:
      const sorted = sortNotifications(action.payload);
      return {...state, notifications: sorted}
    case NotificationsReducerTypes.GET_NUMBER_OF_UNREAD:
      console.log('action.payload = ', action.payload)
      return {...state, unreadNotifications: action.payload, unreadNumber: action.payload}
    case NotificationsReducerTypes.CLEAR_NOTIFICATIONS:
      return {unreadNumber: 0, notifications: []}
    case NotificationsReducerTypes.SET_VIEWED:
      const sortedNotifications = sortNotifications(action.payload.notifications);
      return {...state, notifications: sortedNotifications, unreadNumber: action.payload?.unreadNumber}
    default:
      return state;
  }
}

export default notificationsReducer;