import {AlertDurations, AlertPayload, AlertReducerTypes, AlertStatuses} from "../types/alert.types";
import {Dispatch} from "redux";
import {AlertColorStatuses} from "../types/common.types";

export const alertActions = (status: AlertColorStatuses, message: string, duration: AlertDurations = AlertDurations.THREE_SECONDS) => {
  return (dispatch: Dispatch<AlertPayload>) => {
      const alert = {status, message}
      dispatch({type: AlertReducerTypes.OPEN, payload: alert })
      setTimeout(() => {
        dispatch({type: AlertReducerTypes.CLOSE })
      }, duration)
  }
}

export const setAlertClose = () =>{
  return (dispatch: Dispatch<AlertPayload>) => {
    dispatch({type: AlertReducerTypes.CLOSE});
  }
}