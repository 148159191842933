import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import PreOrderImages from "./components/PreOrderImages";
import PreOrderInfo from "./components/PreOrderInfo";
import {FullPreOrderStateInterface} from "../../../../store/reducers/pre-order.reducer";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import MDBox from "../../../../components/MDBox";
import CommentsBlock from "../../../../components/Сomment/CommentsBlock";
import {useNavigate, useParams} from "react-router-dom";
import FullWindowLoading from "../../../../components/FullWindowLoading";
import Footer from "examples/Footer";
import DashboardLayout from "../../../dashboard/DashboardLayout";
import { Card } from '@mui/material';

interface Props {

}

const OnePreorderPage = ({ ...rest }: Props): JSX.Element => {
  const {id} = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [preOrder, setPreOrder] = useState<FullPreOrderStateInterface | null>(null);
  const {getMyOnePreOrder, getAllCommentsForPreOrder} = useAppDispatch();
  const {preOrders: {onePreOrder}, auth: {isAuth}} = useAppSelector(state => state);

  useEffect(() => {
    if (!isAuth) navigate('/login');

    if (id) {
      getMyOnePreOrder(id)
    }
  }, []);

  useEffect(() => {
    if (onePreOrder['id'] !== 0) {
      setPreOrder({
        id: onePreOrder['id'],
        createdAt: onePreOrder['createdAt'],
        updatedAt: onePreOrder['updatedAt'],
        title: onePreOrder['title'],
        description: onePreOrder['description'],
        status: onePreOrder['status'],
        minBudget: onePreOrder['minBudget'],
        maxBudget: onePreOrder['maxBudget'],
        budgetWithTax: onePreOrder['budgetWithTax'],
        useDeliveryCompany: onePreOrder['useDeliveryCompany'],
        preOrderRelevantTill: onePreOrder['preOrderRelevantTill'],
        productionStart: onePreOrder['productionStart'],
        productionEnd: onePreOrder['productionEnd'],
        getOffersBeforeDate: onePreOrder['getOffersBeforeDate'],
        deliveryCompany: {id: onePreOrder['deliveryCompany']['id'], title: onePreOrder['deliveryCompany']['title']},
        project: {id: onePreOrder['project']['id'], title: onePreOrder['project']['title']},
        files: onePreOrder['files'],
        images: onePreOrder['images'],
        comments: onePreOrder['comments'],
        order: {id: onePreOrder['order']['id']},
        routes: onePreOrder['routes'],
        ownerUser: {
          id: onePreOrder['ownerUser']['id'],
          nickname: onePreOrder['ownerUser']['nickname'],
          avatar: onePreOrder['ownerUser']['avatar']
        },
        ownerCompany: {
          id: onePreOrder['ownerCompany']['id'],
          name: onePreOrder['ownerCompany']['name'],
          profile: onePreOrder['ownerCompany']['profile'],
          logo: onePreOrder['ownerCompany']['logo'],
          typesOfWorks: onePreOrder['ownerCompany']['typesOfWorks'],
          owner: {id: onePreOrder['ownerCompany']['owner']['id'], avatar: onePreOrder['ownerCompany']['owner']['avatar'], nickname: onePreOrder['ownerCompany']['owner']['nickname']},
          managers: onePreOrder['ownerCompany']['managers'],
        },
      })
    }
  }, [onePreOrder])

  return(
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
      { preOrder?.id !== 0 ?
        <Card >
          <MDBox p={3}>
            <Grid container spacing={3}>

              <Grid item xs={12} lg={12} md={12} sx={{ mx: "auto" }}>
                <PreOrderInfo po={preOrder}/>
              </Grid>
              <Grid item xs={12} lg={12} xl={12}>
                {
                  preOrder?.images?.length > 0 ? <PreOrderImages images={preOrder?.images} /> : <p>Нет изображений</p>
                }
              </Grid>
              <CommentsBlock getComments={getAllCommentsForPreOrder}/>
            </Grid>
          </MDBox>
        </Card>
        : <FullWindowLoading />
      }
    </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default OnePreorderPage