/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "./pages/account/profile/index";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";

// Material Dashboard 2 PRO React TS components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import Login from "./pages/login";
import HomePage from "./pages/home";
import Registration from "./pages/registration";
import DashboardAnalytics from "./pages/dashboard/analytics";
import AccountSettings from "./pages/account/settings";
import AdminModels from "./pages/admin/Models";
import AdminUsers from "./pages/admin/Users/AllUsers";
import ForgotPassword from "./pages/login/forgot-password";
import UpdatePassword from "./pages/login/update-password";
import PreOrders from "./pages/account/pre-orders";
import AdminPreOrders from "./pages/admin/PreOrders";
import AdminRoutesPage from "./pages/admin/Routes";
import OpenOrder from "./pages/openOrder";
import Blog from "./pages/blog";
import Offers from "./pages/account/offers";
import AdminStagesPage from "./pages/admin/Stages/AllStages";
import Notifications from "./pages/account/notifications";

export const routes = [
  {
    name: "Главная",
    key: "home",
    route: "/",
    component: <HomePage />,
  },
  {
    //name: "Заказы",
    key: "open orders",
    route: "/open-order/:id",
    component: <OpenOrder  />,
  },
  {
    name: "Блог",
    key: "blog",
    route: "/blog",
    component: <Blog  />,
  },
  {
    type: "collapse",
    name: "Личный кабинет",
    key: "account",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Аккаунт",
        key: "profile",
        route: "/account/profile",
        protected: true,
        component: <ProfileOverview />,
      },
      {
        name: "Настройки",
        key: "settings",
        route: "/account/settings",
        protected: true,
        component: <AccountSettings />,
      },
      {
        name: "Уведомления",
        key: "notifications",
        route: "/account/notifications",
        protected: true,
        component: <Notifications />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Проекты",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Статистика",
        key: "analytics",
        protected: true,
        route: "/dashboards/analytics",
        component: <DashboardAnalytics />,
      },
      {
        name: "Предзаказы",
        key: "pre-orders",
        protected: true,
        route: "/dashboards/pre-orders",
        component: <PreOrders />,
      },
      {
        name: "В работе",
        key: "offers",
        protected: true,
        route: "/dashboards/offers",
        component: <Offers />,
      },
      {
        name: "Мои заявки",
        key: "sales",
        protected: true,
        route: "/dashboards/sales",
        component: <Sales />,
      },
      {
        name: "Оплаты",
        key: "invoices",
        route: "/account/invoice",
        protected: true,
        component: <Invoice />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Kanban",
        key: "kanban",
        route: "/applications/kanban",
        component: <Kanban />,
      },
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: <Wizard />,
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <DataTables />,
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: <Calendar />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    invisibleIfNotAdmin: true,
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Модели",
        key: "models",
        route: "/admin/models",
        invisibleIfNotAdmin: true,
        protected: true,
        component: <AdminModels />,
      },
      {
        name: "Пользователи",
        key: "users",
        route: "/admin/users",
        invisibleIfNotAdmin: true,
        protected: true,
        component: <AdminUsers />,
      },
      {
        name: "Заявки",
        key: "preorders",
        route: "/admin/pre-orders",
        invisibleIfNotAdmin: true,
        protected: true,
        component: <AdminPreOrders />,
      },
      {
        name: "Маршруты",
        key: "routes",
        route: "/admin/routes",
        invisibleIfNotAdmin: true,
        protected: true,
        component: <AdminRoutesPage />
      },
      {
        name: "Этапы",
        key: "stages",
        route: "/admin/stages",
        invisibleIfNotAdmin: true,
        protected: true,
        component: <AdminStagesPage />
      },
    ],
  },
  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: <NewProduct />,
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: <EditProduct />,
          },
          {
            name: "Product Page",
            key: "product-page",
            route: "/ecommerce/products/product-page",
            component: <ProductPage />,
          },
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />,
          },
          {
            name: "All projects",
            key: "all projects",
            route: "/profile/all-projects",
            component: <AllProjects />,
          }
        ],
      },
      {
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Предзаказы",
            key: "pre-orders",
            route: "/account/pre-orders",
            component: <PreOrders />
          },
          {
            name: "Order List",
            key: "order-list",
            route: "/ecommerce/orders/order-list",
            component: <OrderList />,
          },
          {
            name: "Order Details",
            key: "order-details",
            route: "/ecommerce/orders/order-details",
            component: <OrderDetails />,
          },
        ],
      },
    ],
  },

  {
    type: "collapse",
    name: "Авторизация",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Вход",
        key: "login",
        route: "/authentication/login",
        component: <Login />,
        invisibleIfAuth: true,
      },
      {
        name: "Регистрация",
        key: "registration",
        route: "/authentication/registration",
        component: <Registration />,
        invisibleIfAuth: true,
      },
      {
        name: "Сброс пароля",
        key: "forgot-password",
        route: "/authentication/forgot-password",
        component: <ForgotPassword />,
        invisibleIfAuth: true,
      },
      {
        name: "Восстановление пароля",
        key: "update-password",
        route: "/update-password/:link",
        component: <UpdatePassword />,
        invisibleIfAuth: true,
      },
    ],
  },

  process.env.NODE_ENV === 'development' &&
    { type: "divider", key: "divider-1" },

  process.env.NODE_ENV === 'development' &&
    { type: "title", title: "Docs", key: "title-docs" },

  process.env.NODE_ENV === 'development' &&
    {
    type: "collapse",
    name: "Basic",
    key: "basic",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        collapse: [
          {
            name: "Overview",
            key: "overview",
            href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
          },
          {
            name: "Quick Start",
            key: "quick-start",
            href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
          },
          {
            name: "Build Tools",
            key: "build-tools",
            href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
          },
        ],
      },
      {
        name: "Foundation",
        key: "foundation",
        collapse: [
          {
            name: "Colors",
            key: "colors",
            href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
          },
          {
            name: "Grid",
            key: "grid",
            href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
          },
          {
            name: "Typography",
            key: "base-typography",
            href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
          },
          {
            name: "Borders",
            key: "borders",
            href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
          },
          {
            name: "Box Shadows",
            key: "box-shadows",
            href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
          },
          {
            name: "Functions",
            key: "functions",
            href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
          },
          {
            name: "Routing System",
            key: "routing-system",
            href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
          },
        ],
      },
    ],
  },

  process.env.NODE_ENV === 'development' &&
    {
    type: "collapse",
    name: "Components",
    key: "components",
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    collapse: [
      {
        name: "Alerts",
        key: "alerts",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
      },
      {
        name: "Avatar",
        key: "avatar",
        href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
      },
      {
        name: "Badge",
        key: "badge",
        href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
      },
      {
        name: "Badge Dot",
        key: "badge-dot",
        href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
      },
      {
        name: "Box",
        key: "box",
        href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
      },
      {
        name: "Buttons",
        key: "buttons",
        href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
      },
      {
        name: "Date Picker",
        key: "date-picker",
        href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
      },
      {
        name: "Dropzone",
        key: "dropzone",
        href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
      },
      {
        name: "Editor",
        key: "editor",
        href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
      },
      {
        name: "Input",
        key: "input",
        href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
      },
      {
        name: "Pagination",
        key: "pagination",
        href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
      },
      {
        name: "Progress",
        key: "progress",
        href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
      },
      {
        name: "Snackbar",
        key: "snackbar",
        href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
      },
      {
        name: "Social Button",
        key: "social-button",
        href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
      },
      {
        name: "Typography",
        key: "typography",
        href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
      },
    ],
  }

];

