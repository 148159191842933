import React, {createRef, useCallback, useEffect, useState} from "react";
import {useDropzone, FileWithPath, FileError, DropEvent} from "react-dropzone";
import "./index.css";
import {Button, Grid} from "@mui/material";
import {FileSize} from "../../utilities/constants";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import MDButton from "../MDButton";

const dropzoneRef = createRef();

const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
        // @ts-ignore
        dropzoneRef.current.open()
    }
};

interface PropsInterface {
    isImage: boolean,
    done: boolean,
    onDrop: any,
    removeUploadedFile: any,
    sizeLimit: FileSize,
}

const maxLength = 20;
function nameLengthValidator(file: any) {
    console.log('VALIDATOR ', JSON.stringify(file))
    if (file.path.length > maxLength) {
        return {
            code: "name-too-large",
            message: `Name is larger than ${maxLength} characters`
        };
    }

    return null
}

const DropZoneSquare = ({ done, onDrop, isImage, removeUploadedFile, sizeLimit }: PropsInterface): JSX.Element => {

    const { getRootProps, getInputProps, fileRejections, isDragActive, open, acceptedFiles } =
        useDropzone({
            maxFiles: isImage ? 7 : 3,
            maxSize: sizeLimit,
            noClick: true,
            accept: isImage ? {
                'image/jpeg': [],
                'image/jpg': [],
                'image/png': []
            } : {
                'application/pdf': [],
                'application/msword': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                'application/vnd.ms-excel': [],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                'application/txt': [],
            },
            onDrop,
            //validator: nameLengthValidator,
            disabled: done,
            //getFilesFromEvent: event => myCustomFileGetter(event),
        });

    // async function myCustomFileGetter(event: any) {
    //     const files = [];
    //     const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    //     for (var i = 0; i < fileList.length; i++) {
    //         const file = fileList.item(i);
    //         Object.defineProperty(file, 'myProp', {
    //             value: true
    //         });
    //         files.push(file);
    //     }
    //     return files;
    // }

    const files = acceptedFiles.map((file: FileWithPath) => (
        <li key={file.path} style={{fontSize: 12}}>
            {file.path} - {(file.size / 1048576).toFixed(2)} мегабайт
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }: {file: FileWithPath, errors: FileError[]}) => (
        <li key={file.path} style={{fontSize: 12}}>
            {/*{file.path} - {file.size} bytes*/}
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    return (
            <Grid container  {...getRootProps({ className: "dropzone" })} justifyItems='center' alignContent='center' textAlign='center'>
                <input className="input-zone" {...getInputProps()} />
                <Grid item alignContent='center' textAlign='center' style={{width: '100%'}}>
                    {isDragActive ? (
                        <p className="dropzone-content">
                            Теперь можно отпустить кнопку
                        </p>
                    ) : (
                        <p className="dropzone-content">
                            {
                                done ?
                                    'Загружено максимальное количество файлов'
                                    :
                                    'Перетащите сюда файлы для загрузки или нажмите кнопку'
                            }
                        </p>
                    )}
                </Grid>
                <Grid item alignContent='center' mt={2} textAlign='center' style={{width: '100%'}}>
                  <MDButton variant="gradient" color="info" onClick={openDialog}>
                    Загрузить
                  </MDButton>
                </Grid>
                <aside>
                    {
                        !done &&
                            (
                                files.length > 0 ?
                                        <>
                                            <h6>Файл принят <CheckCircleOutlineIcon color='success'/></h6>
                                            <ul>{files}</ul>
                                        </>
                                    :
                                    (fileRejectionItems.length > 0 &&
                                        <>
                                            <h6>Ошибка <ReportGmailerrorredIcon color='error'/></h6>
                                            <ul>{fileRejectionItems}</ul>
                                        </>)
                            )
                    }
                    {/*{*/}
                    {/*    files.length > 0 &&*/}
                    {/*    <>*/}
                    {/*        <h6>Файл принят <CheckCircleOutlineIcon color='success'/></h6>*/}
                    {/*        <ul>{files}</ul>*/}
                    {/*    </>*/}
                    {/*}*/}

                    {/*{*/}
                    {/*    fileRejectionItems.length > 0 &&*/}
                    {/*    <>*/}
                    {/*        <h6>Ошибка <ReportGmailerrorredIcon color='error'/></h6>*/}
                    {/*        <ul>{fileRejectionItems}</ul>*/}
                    {/*    </>*/}
                    {/*}*/}
                </aside>
            </Grid>
    );
}
export default DropZoneSquare;