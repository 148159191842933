import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {ChangeEvent, useEffect, useState} from "react";
import {DialogContent, DialogContentText, Grid, TextField} from "@mui/material";
import {useAppDispatch} from "../../hooks/useActions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface DialogWindowInterface {
  open: boolean,
  setOpen: any,
  setDialogWindowText?: any,
  dialogWindowTitle: string,
  dialogWindowDescription: string,
  handleAction?: any,
  children?: JSX.Element,
  showMainInput?: boolean,
  showAction?: boolean
}

export default function DialogWindow({
                                     open,
                                     setOpen,
                                     setDialogWindowText,
                                     dialogWindowTitle,
                                     dialogWindowDescription,
                                     handleAction,
                                     children,
                                     showMainInput = true,
                                       showAction = true}: DialogWindowInterface) {

  const handleClose = () => {
    handleAction && handleAction();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="primary"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {dialogWindowTitle}
            </Typography>
            {
              showAction &&
                <Button autoFocus color="inherit" onClick={handleClose}>
                    Сохранить и закрыть
                </Button>
            }
          </Toolbar>
        </AppBar>
        <DialogContent>
          {
            dialogWindowDescription &&
              <DialogContentText>{dialogWindowDescription}</DialogContentText>
          }
          {
            showMainInput &&
              <TextField
                  autoFocus
                  margin="dense"
                  label="Введите текст"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setDialogWindowText(event.target.value)}
              />
          }
          <Grid container >
            {children}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}