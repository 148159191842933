import React, {useEffect, useMemo, useState} from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import {Button, Tooltip} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import {PreOrderStateInterface} from "../../../../store/reducers/pre-order.reducer";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {convertFromStringToBeautyDateString} from "../../../../utilities/converters";

function PreordersTableBody() {
  const navigate = useNavigate();
  const {getMyPreOrders} = useAppDispatch();
  const {preOrders: {preOrders, order_by, skip, take, sort_by, query}} = useAppSelector(state => state);

  useEffect(() => {
    getMyPreOrders({take, query});
  }, []);

  const handleNavigate = (id: any) => {
    navigate(`/dashboard/preorders/${id}`);
  }

  return (
    <>
      {// @ts-ignore
        preOrders && preOrders?.map((po: PreOrderStateInterface) =>
        <TableRow key={po?.id}>
          <TableCell component="th" scope="row">
            {po?.id}
          </TableCell>
          <TableCell style={{ width: 140 }} align="center">
            <Typography fontSize={11}>
              {convertFromStringToBeautyDateString(po?.createdAt)}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 140 }} align="center">
            <Typography fontSize={11}>
              {po?.project?.title}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 240 }} align="center">
            <Typography fontSize={11}>
              {po?.title}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 120 }} align="center">
            <Typography fontSize={11}>
              {po?.status}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Typography fontSize={11}>
              {po?.minBudget}{" - "}{po?.maxBudget}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Typography fontSize={11}>
              {convertFromStringToBeautyDateString(po?.preOrderRelevantTill)}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Typography fontSize={11}>
              {convertFromStringToBeautyDateString(po?.getOffersBeforeDate)}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Button variant="text" onClick={() => handleNavigate(po?.id)}>
              <DehazeIcon color="primary" />
            </Button>
          </TableCell>
        </TableRow>
        )
      }
    </>
  );
}

export default PreordersTableBody;