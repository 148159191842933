import React, {useEffect} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {CompanyProfile} from "../../../../store/types/company.types";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {setCompanyProfile} from "../../../../store/actions/company.actions";

interface PropsInterface {
  isForCompany: boolean;
  handleChangeProfile?: (arg: CompanyProfile) => void;
}
function ProfileSelectorForm({isForCompany, handleChangeProfile}: PropsInterface) {
  const {user} = useAppSelector(state => state);
  const {changeUserProfile, setCompanyProfile} = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [profile, setProfile] = React.useState<CompanyProfile>(CompanyProfile.BLANK);

  useEffect(() => {
    setProfile(isForCompany ? user["ownCompany"]["profile"] : user["profile"]);
  }, []);

  const handleUserChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setLoading(true);
    setProfile(event.target.value as CompanyProfile);
    const res = changeUserProfile({newProfile: event.target.value as CompanyProfile, oldProfile: user["profile"]});
    setLoading(false);
  };
  const handleCompanyChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setProfile(event.target.value as CompanyProfile);
    handleChangeProfile(event.target.value as CompanyProfile)
    if (user["ownCompany"]["id"] === 0 || user["ownCompany"]["id"] === undefined) return;
    setLoading(true);
    const res = setCompanyProfile({companyId: user["ownCompany"]["id"], profile: event.target.value as CompanyProfile});
    setLoading(false);
  };

  return (
    <div>
      {
        loading ? <p style={{fontSize: 12}}>Сохраняем...</p> :
          <FormControl fullWidth style={{minHeight: 40}}>
            <InputLabel id="profile">Выберите роль</InputLabel>
            <Select
              labelId="profile"
              value={profile}
              name='profile'
              // native={true}
              label="Тип компании"
              size={'medium'}
              style={{height: 40}}
              onChange={(e: any) => isForCompany ? handleCompanyChange(e) : handleUserChange(e)}
            >
              <MenuItem defaultChecked disabled value={CompanyProfile.BLANK}>Пусто</MenuItem>
              <MenuItem value={CompanyProfile.CLIENT}>Заказчик</MenuItem>
              <MenuItem value={CompanyProfile.PRODUCER}>Подрядчик</MenuItem>
              <MenuItem value={CompanyProfile.PRODUCERCLIENT}>Заказчик и Подрядчик</MenuItem>
            </Select>
          </FormControl>
      }
    </div>
  );
}

export default ProfileSelectorForm;