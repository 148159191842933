import $api from "./http.axios";

export const jsonContentType = {"Content-Type": "application/json",}
export const formdataContentType = {"Content-Type": "multipart/form-data; charset=utf-8",}

export const getDeliveryCompanies = async () => {
    try {
        const res = await $api.get(`/pre-order/list-of-delivery-companies`);
        return res.data;
    } catch (e) {
        console.log(e)
    }
}

export const getUserRating = async (userId: number) => {
    try {
        const res = await $api.get(`/rating/rating-for-user/${userId}`);
        return res.data;
    } catch (e) {
        console.log(e)
    }
}

export const getCompanyRating = async (companyId: number) => {
    try {
        const res = await $api.get(`/rating/rating-for-company/${companyId}`);
        return res.data;
    } catch (e) {
        console.log(e)
    }
}