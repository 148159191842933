import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import LastPageIcon from '@mui/icons-material/LastPage';
import {Button, TableHead, Tooltip} from "@mui/material";
import DehazeIcon from '@mui/icons-material/Dehaze';
import CommentIcon from '@mui/icons-material/Comment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircleIcon from '@mui/icons-material/Circle';
import {ShortAdminPreOrderInterface} from "../../../../store/reducers/pre-order.reducer";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {PreOrderStatus} from "../../../../store/types/pre-order.types";
import {convertFromStringToBeautyDateString} from "../../../../utilities/converters";
import PaginationBlock from "../../../../components/Pagination/PaginationBlock";

interface TablePropsInterface {
  preOrders: ShortAdminPreOrderInterface[],
  setModalOpen: any,
}

export default function AdminPreOrdersTable({preOrders, setModalOpen}: TablePropsInterface) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const {getAnyOnePreOrder} = useAppDispatch();
  const {preOrders: {onePreOrder, count, take, skip, search}} = useAppSelector(state => state);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - preOrders.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const pickIdAndOpenModal = (id: number) => {
    setModalOpen(true);
    getAnyOnePreOrder(id);
  }

  const smallTextStyle = {
    fontSize: 14,
    fontWeight: 'bold'
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">

        <TableBody>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="center" style={smallTextStyle}>Дата</TableCell>
            <TableCell align="center" style={smallTextStyle}>Статус</TableCell>
            <TableCell align="center" style={smallTextStyle}>Владелец</TableCell>
            <TableCell align="center" style={smallTextStyle}>Бюджет</TableCell>
            <TableCell align="center" style={smallTextStyle}>Актуален до</TableCell>
            <TableCell align="center" style={smallTextStyle}>Сбор предложений до</TableCell>
            <TableCell align="center" style={smallTextStyle}>
              <Tooltip title="Наличие непрочитанных сообщений" placement="top-start">
                <CommentIcon color="secondary" />
              </Tooltip>
            </TableCell>
            <TableCell align="center" style={smallTextStyle}>
              <Tooltip title="Количество созданных маршрутов" placement="top-start">
                <CallSplitIcon color="secondary" />
              </Tooltip>
              </TableCell>
            <TableCell align="center" style={smallTextStyle}>Изменить</TableCell>
          </TableRow>
          {
            // @ts-ignore
            preOrders?.map(row =>
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    {convertFromStringToBeautyDateString(row.createdAt)}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    {row.status}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    <Tooltip title={row.ownerUser?.email ?? ''} placement="left-start">
                      <span>
                        {row.ownerCompany?.name?.length ? row.ownerCompany?.name : row.ownerUser?.nickname}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    {row.minBudget} - {row.maxBudget}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    {convertFromStringToBeautyDateString(row.preOrderRelevantTill)}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    {convertFromStringToBeautyDateString(row.getOffersBeforeDate)}
                  </TableCell>
                  <TableCell style={{ width: 60 }} align="center">
                    <CircleIcon color={row.comments?.some((c: any) => (c.senderRole === 'CUSTOMER' && c.viewed === false)) ? 'error' : 'success'} />
                  </TableCell>
                  <TableCell style={{ width: 60 }} align="center">
                    {row?._count?.routes}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    <Button variant="text" onClick={() => pickIdAndOpenModal(row.id)}>
                      <DehazeIcon color="primary" />
                    </Button>
                  </TableCell>
                </TableRow>
            )
          }
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
              colSpan={3}
              count={preOrders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'Кол-во записей на странице',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={PaginationBlock}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}