import React from 'react';

export enum MarginSize {
  huge = 60,
  big = 40,
  medium = 20,
  small = 10,
  empty = 0
}

export enum HeightSize {
  big = 3,
  medium = 2,
  small = 1
}

interface DividerInterface {
  marginSize?: MarginSize,
  height?: HeightSize
}
const Divider = ({marginSize = 20, height = 1}: DividerInterface) => {

  return(
    <div style={{
      height: height,
      backgroundColor: 'lightgray',
      width: "auto",
      marginBottom: marginSize,
      marginTop: marginSize
    }}/>
  )
}

export default Divider;