import {Dispatch} from "redux";
import {AuthPayload, authReducerTypes} from "../types/auth.types";
import {AlertPayload, AlertReducerTypes} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {AlertColorStatuses} from "../types/common.types";
import {setCookie, setLocalStorage} from "./auth.actions";
import {
  isDeliveryOrJuridicalEnum,
  updateUserByAdminBodyInterface,
  UserPayload,
  userReducerTypes, UsersForAdminQueryDto
} from "../types/user.types";
import {CompanyProfile} from "../types/company.types";
import {TypesOfWorksPayload, TypesOfWorksReducerTypes} from "../types/types-of-works.types";


export const getUser = () => {
  return async (dispatch: Dispatch<UserPayload | AlertPayload>) => {
    try {
      dispatch({type: userReducerTypes.CLEAR_USER});
      const res = await $api.get('/users/me', );
      dispatch({type: userReducerTypes.SET_USER_SUCCESS, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      console.log('getUser ERROR - ', JSON.stringify(err))
      dispatch({type: userReducerTypes.SET_USER_ERROR, payload: res})
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} })
      //return dispatch({type: authReducerTypes.SET_AUTH_ERROR, payload: res})
    }
  }
}

interface ChangeSocialProfileInterface {
  prevData: {
    phone: string,
    vkontakte: string,
    odnoklassniki: string,
    telegram: string,
    whatsapp: string,
    facebook: string,
    instagram: string,
    tiktok: string,
  },
  newData: {
    phone: string,
    vkontakte: string,
    odnoklassniki: string,
    telegram: string,
    whatsapp: string,
    facebook: string,
    instagram: string,
    tiktok: string,
  }
}

export const changeSocialProfile = (data: ChangeSocialProfileInterface) => {
  return async (dispatch: Dispatch<UserPayload | AlertPayload>): Promise<boolean> => {
    try {
      const res = await $api.put('/users/change-my-details', data.newData)
      dispatch({type: userReducerTypes.SET_SOCIAL_PROFILE, payload: res.data})
      return true;
    } catch (e) {
      console.log(JSON.stringify(e))
      dispatch({type: userReducerTypes.SET_SOCIAL_PROFILE, payload: data.prevData})
      return false;
    }
  }
}

interface ChangeAddress {
  isDeliveryAddress: boolean,
  prevData: {
    country: string,
    postalCode: string,
    state: string,
    city: string,
    street: string,
    houseBuilding: string,
    officeApartment: string,
  },
  newData: {
    country: string,
    postalCode: string,
    state: string,
    city: string,
    street: string,
    houseBuilding: string,
    officeApartment: string,
  }
}

export const changeUserAddress = (data: ChangeAddress) => {
  return async (dispatch: Dispatch<UserPayload | AlertPayload>) => {
    try {
      const res = await $api.put(`/address/change-user-address/${
        data.isDeliveryAddress 
          ? isDeliveryOrJuridicalEnum.DELIVERY 
          : isDeliveryOrJuridicalEnum.JURIDICAL
      }`,
        data.newData
      )
      if (data.isDeliveryAddress) {
        return dispatch({type: userReducerTypes.SET_DELIVERY_ADDRESS, payload: res.data})
      }
      return dispatch({type: userReducerTypes.SET_JURIDICAL_ADDRESS, payload: res.data})
    } catch (e) {
      console.log(JSON.stringify(e))
      if (data.isDeliveryAddress) {
        return dispatch({type: userReducerTypes.SET_DELIVERY_ADDRESS, payload: data.prevData})
      }
      return dispatch({type: userReducerTypes.SET_JURIDICAL_ADDRESS, payload: data.prevData})
    }
  }
}

interface ChangeUserBankDetails {
  prevData: {
    bankName: string,
    bankAccount: string,
    bankCorrAccount: string,
    bankBik: string
  },
  newData: {
    bankName: string,
    bankAccount: string,
    bankCorrAccount: string,
    bankBik: string
  }
}

export const changeUserBankDetails = (data: ChangeUserBankDetails) => {
  return async (dispatch: Dispatch<UserPayload | AlertPayload>) => {
    try {
      const res = await $api.put(`/bank-details/change-for-user`,
        data.newData
      )
      return dispatch({type: userReducerTypes.SET_BANK_DETAILS, payload: res.data})
    } catch (e) {
      console.log(JSON.stringify(e))
      return dispatch({type: userReducerTypes.SET_BANK_DETAILS, payload: data.prevData})
    }
  }
}

interface ChangeUserData {
  prevData: {
    fio: string,
    birthday: string,
    passportNumber: number,
    passportIssued: string,
  },
  newData: {
    fio: string,
    birthday: string,
    passportNumber: number,
    passportIssued: string,
  }
}

export const changeUserData = (data: ChangeUserData) => {
  return async (dispatch: Dispatch<UserPayload>) => {
    try {
      const res = await $api.put(`/users/change-my-details`,
        {
          fio: data.newData.fio,
          birthday: data.newData.birthday,
          passportNumber: Number(data.newData.passportNumber),
          passportIssued: data.newData.passportIssued
        }
      )
      return dispatch({type: userReducerTypes.SET_USER_DATA, payload: res.data})
    } catch (e) {
      console.log(JSON.stringify(e))
      return dispatch({type: userReducerTypes.SET_USER_DATA, payload: data.prevData})
    }
  }
}

interface ChangeUserAvatarInterface {
  oldAvatarName?: string,
  form: FormData
}

export const changeUserAvatar = (data: ChangeUserAvatarInterface) => {
  return async (dispatch: Dispatch<UserPayload | AlertPayload>) => {
    try {
      const res = await $api.put(`/users/change-avatar`, data.form)
      dispatch({type: userReducerTypes.SET_USER_AVATAR, payload: res.data.newAvatar})
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.SUCCESS, message: res.data.message} })
      setTimeout(() => {
        dispatch({type: AlertReducerTypes.CLOSE })
      }, 2000)
    } catch (err: unknown) {
      //ts-ignore
      dispatch({type: userReducerTypes.SET_USER_AVATAR, payload: data.oldAvatarName})
      const res = axiosErrorMessageHandler(err);
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} })
      setTimeout(() => {
        dispatch({type: AlertReducerTypes.CLOSE })
      }, 3000)
    }
  }
}

interface ChangeUserPasswordInterface {
  newProfile: CompanyProfile,
  oldProfile: CompanyProfile
}
export const changeUserProfile = (data: ChangeUserPasswordInterface) => {
  return async (dispatch: Dispatch<UserPayload | AlertPayload>): Promise<boolean> => {
    try {
      const res = await $api.put(
        '/users/change-my-profile',
        {
          profile: data.newProfile
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      dispatch({type: userReducerTypes.SET_USER_PROFILE, payload: res.data["profile"]})
      console.log('changeUserProfile SUCCESS - ', JSON.stringify(data))
      return true;
    } catch (e) {
      console.log('changeUserProfile ERROR - ', JSON.stringify(e))
      console.log('changeUserProfile DATA - ', JSON.stringify(data))
      dispatch({type: userReducerTypes.SET_USER_PROFILE, payload: data.oldProfile})
      return false;
    }
  }
}

export const addUserTypeOfWork = (id: number) => {
  return async (dispatch: Dispatch<UserPayload | AlertPayload>) => {
    try {
      const res = await $api.put(
        `/users/add-type-of-work/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      return dispatch({type: userReducerTypes.ADD_TYPE_OF_WORK, payload: res.data});

    } catch (err: unknown) {
      console.log(`ERROR ======== ${err}`)
      const res = axiosErrorMessageHandler(err);
    }
  }
}

export const removeUserTypeOfWork = (id: number) => {
  return async (dispatch: Dispatch<UserPayload | AlertPayload>) => {
    try {
      const res = await $api.delete(
        `/users/remove-type-of-work/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      return dispatch({type: userReducerTypes.REMOVE_TYPE_OF_WORK, payload: res.data});

    } catch (err: unknown) {
      console.log(`ERROR ======== ${err}`)
      const res = axiosErrorMessageHandler(err);
    }
  }
}

export const getUserAndCheckIfOfferExists = (stageId: number) => {
  return async (dispatch: Dispatch<UserPayload | AlertPayload>) => {
    try {
      dispatch({type: userReducerTypes.CLEAR_USER});
      const res = await $api.get(`/users/get-own-prerequisites-and-check-offer/${stageId}`, );
      dispatch({type: userReducerTypes.SET_USER_SUCCESS, payload: res.data.user});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: userReducerTypes.SET_USER_ERROR, payload: res})
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} })
      //return dispatch({type: authReducerTypes.SET_AUTH_ERROR, payload: res})
    }
  }
}

// export const getAllUsers = async (query: UsersForAdminQueryDto) => {
//     try {
//       const res = await $api.get(`/users/all?skip=${query.skip ?? 0}&take=${query.take ?? query.take ?? 20}&order_by=${query.order}`);
//       return res.data;
//     } catch (err: unknown) {
//       console.log('getAllUsers ERROR - ', JSON.stringify(err))
//     }
// }

// export const getOneUser = async (id: number) => {
//     try {
//       const res = await $api.get(`/users/one/${id}`);
//       return res.data;
//     } catch (err: unknown) {
//       console.log('getOneUser ERROR - ', JSON.stringify(err))
//     }
// }

// export const updateByAdmin = async (id: number, body: updateUserByAdminBodyInterface) => {
//     try {
//       const res = await $api.put(
//         `update-by-admin/${id}`,
//         body,
//         {headers: {'Content-Type': 'application/json'}
//       });
//       return res.data;
//     } catch (err: unknown) {
//       console.log('updateByAdmin ERROR - ', JSON.stringify(err))
//     }
// }
