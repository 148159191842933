import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  convertFromStringToBeautyDateString,
  getStageStatus
} from "../../../../../utilities/converters";
import {Button, Tooltip} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import PaginationBlock from "../../../../../components/Pagination/PaginationBlock";
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";

interface PropsInterface {
  setModalOpen: (set: boolean) => void
}

function StagesTable({setModalOpen}: PropsInterface) {
  const {getAllStages, getOneStageAdmin} = useAppDispatch();
  const {stages: {stages, query}} = useAppSelector(state => state);

  const smallTextStyle = {
    fontSize: 14,
    fontWeight: 'bold'
  }

  useEffect(() => {
    getAllStages(query ?? '');
  }, [])

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">

        <TableBody>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="center" style={smallTextStyle}>Старт пр-ва</TableCell>
            <TableCell align="center" style={smallTextStyle}>Конец пр-ва</TableCell>
            <TableCell align="center" style={smallTextStyle}>Название</TableCell>
            <TableCell align="center" style={smallTextStyle}>Статус</TableCell>
            <TableCell align="center" style={smallTextStyle}>Цена пр-ва</TableCell>
            <TableCell align="center" style={smallTextStyle}>Деталь</TableCell>
            <TableCell align="center" style={smallTextStyle}>Кол-во деталей</TableCell>
            <TableCell align="center" style={smallTextStyle}>Тип работ</TableCell>
            <TableCell align="center" style={smallTextStyle}>Кол-во отзывов</TableCell>
            <TableCell align="center" style={smallTextStyle}>Изменить</TableCell>
          </TableRow>
          {
            // @ts-ignore
            stages?.map(row =>
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell style={{ width: 140 }} align="center">
                  <Typography fontSize={11}>
                    {convertFromStringToBeautyDateString(row.productionStart)}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 140 }} align="center">
                  <Typography fontSize={11}>
                    {convertFromStringToBeautyDateString(row.productionEnd)}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 240 }} align="center">
                  <Typography fontSize={11}>
                    {row.title.slice(0, 40)}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 120 }} align="center">
                  <Typography color={getStageStatus(row.status).color} fontSize={11} fontWeight={'bold'}>
                    {getStageStatus(row.status).ruStatus}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 80 }} align="center">
                  <Typography fontSize={11}>
                    {row?.productionCost}
                  </Typography>
                </TableCell>
                <Tooltip title='SOME TEXT' placement="top-start">
                  <TableCell style={{ width: 160 }} align="center">
                    <Typography fontSize={11}>
                      {row.detail?.title}
                    </Typography>
                  </TableCell>
                </Tooltip>
                <TableCell style={{ width: 140 }} align="center">
                  {row.detail?.quantity}
                </TableCell>
                <TableCell style={{ width: 140 }} align="center">
                  <Typography fontSize={11}>
                    {row.typeOfWork?.title}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 140 }} align="center">
                  <Typography fontSize={11}>
                    {row._count?.offersFromProducers}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 80 }} align="center">
                  <Link to={`/admin/stages/${row.id}`}>
                  {/*<Button variant="text" onClick={() => pickIdAndOpenModal(row.id)}>*/}
                    <DehazeIcon color="primary" />
                  {/*</Button>*/}
                  </Link>
                </TableCell>
              </TableRow>
            )
          }

        </TableBody>
        <TableFooter>
          <TableRow>
            {/*<TablePagination*/}
            {/*  rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}*/}
            {/*  colSpan={3}*/}
            {/*  count={stages?.length}*/}
            {/*  rowsPerPage={rowsPerPage}*/}
            {/*  page={page}*/}
            {/*  SelectProps={{*/}
            {/*    inputProps: {*/}
            {/*      'aria-label': 'Кол-во записей на странице',*/}
            {/*    },*/}
            {/*    native: true,*/}
            {/*  }}*/}
            {/*  onPageChange={handleChangePage}*/}
            {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*  ActionsComponent={PaginationBlock}*/}
            {/*/>*/}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default StagesTable;