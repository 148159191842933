import {AlertPayload, AlertReducerTypes, AlertStatuses} from "../types/alert.types";
import LoadingReducer from "./loading.reducer";
import {LoadingPayload, LoadingReducerTypes} from "../types/loading";

interface LoadingState {
  status: boolean,
}

const initialState: LoadingState = {
  status: false,
}

const loadingReducer = (state: LoadingState = initialState, action: LoadingPayload) => {
  switch (action.type) {
    case LoadingReducerTypes.SET_LOADING_TRUE:
      return {...state, loading: true}
    case LoadingReducerTypes.SET_LOADING_FALSE:
      return {...state, loading: false}
    default:
      return state
  }
}

export default loadingReducer;