import {ChangeEvent, ReactNode, useEffect, useState} from "react";
import { styled } from '@mui/material/styles';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import noAvatar from "assets/img/no-avatar.svg";
import editAvatar from "assets/img/edit-avatar.jpg";
import {useStringInput, useAnyInput} from "../../../../../hooks/useInput";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {UserStateInterface} from "../../../../../store/reducers/user.reducer";
import FormField from "../../../../../layouts/pages/account/components/FormField";
import {changeUserAvatar, changeUserData} from "../../../../../store/actions/user.actions";
import MDDatePicker from "../../../../../components/MDDatePicker";
import MDButton from "../../../../../components/MDButton";
import {Box, Modal, Typography} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import {FRONT_URL} from "../../../../../utilities/http.axios";


function Header(): JSX.Element {
  const [offerToChangeAvatar, setOfferToChangeAvatar] = useState(false);

  const {user} = useAppSelector(state => state);
  const {changeUserData, changeUserAvatar} = useAppDispatch();
  const [newAvatar, setNewAvatar] = useState(null);
  const [uploadAvatarButton, setUploadAvatarButton] = useState(false);

  const [values, setValues] = useState({
    avatar: '',
    fio: '',
    birthday: '',
    passportNumber: 0,
    passportIssued: ''
  });

  const [prevData, setPrevData] = useState({
    fio: '',
    birthday: '',
    passportNumber: 0,
    passportIssued: ''
  });

  const {avatar, fio, birthday, passportNumber, passportIssued} = values;

  useEffect(() => {
    if (user) {
      setValues({
        avatar: user["avatar"],
        fio: user["fio"],
        birthday: user["birthday"],
        passportNumber: user["passportNumber"],
        passportIssued: user["passportIssued"]
      });
      setPrevData({
        fio: user["fio"],
        birthday: user["birthday"],
        passportNumber: user["passportNumber"],
        passportIssued: user["passportIssued"]
      });
    }
  }, [user]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [e.target.name]: e.target.value});
  }

  const handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewAvatar(e.target.files[0]);
    setUploadAvatarButton(true);
    console.log('AV CH')
  }

  const handleDateChange = (value: any) => {
    setValues({...values, birthday: value});
  }

  const handleSubmit = async () => {
    try {
      await changeUserData({
        prevData,
        newData: {
          fio: fio || null,
          birthday: birthday || null,
          passportNumber: passportNumber || null,
          passportIssued: passportIssued || null
        }
      })
    } catch (e) {
      console.log(`NO ${e}`)
    }
  }

  const handleAvatarSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('avatar', newAvatar);
      formData.append('oldAvatarName', avatar ? avatar : '');
      await changeUserAvatar({oldAvatarName: avatar, form: formData}); // Дублирубю старый аватар вне FormData, чтобы поставить его обратно в случае ошибки
      setUploadAvatarButton(false);
    } catch (e) {
      console.log(`NO ${e}`)
    }
  }

  return (
    <Card id="basic" style={{scrollMarginTop: 90}}>
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <input accept="image/*" style={{ display: 'none' }} id="icon-button-file" onChange={(e: ChangeEvent<HTMLInputElement>) => handleAvatarChange(e)} type="file"  />
            <label htmlFor="icon-button-file">
              <MDAvatar
                onMouseEnter={() => setOfferToChangeAvatar(true)}
                onMouseLeave={() => setOfferToChangeAvatar(false)}
                src={offerToChangeAvatar ? editAvatar : (avatar ? `${FRONT_URL}/images/avatars/300x300/${avatar}` : noAvatar)}
                alt="profile-image"
                size="xl"
                shadow="sm"
              />
            </label>
          </Grid>
          <Grid item>
            {
              uploadAvatarButton ?
                <MDButton variant="gradient" color="dark" size="small" onClick={() => handleAvatarSubmit()}>
                  Загрузить
                </MDButton>
                :
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {user['email'] || ''}
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {user['nickname'] || ''}
                  </MDTypography>
                </MDBox>
                // <MDTypography variant={'h6'} >{`ник: ${user['nickname'] || ''} - емейл: ${user['email'] || ''}`}</MDTypography>

            }
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
            <FormField
              label="ФИО"
              placeholder="Фамилия Имя Отчество"
              name={'fio'}
              value={fio || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                <DatePicker
                  label="Дата рождения"
                  value={birthday}
                  views={['year', 'month', 'day']}
                  onChange={(newValue) => {
                    setValues({...values, birthday: newValue});
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <FormField
                label="Номер паспорта"
                placeholder="..."
                name={'passportNumber'}
                value={passportNumber || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
              />
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-start" }}
              alignItems="center"
              lineHeight={1}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                <DatePicker
                  label="Дата выдачи пасспорта"
                  value={passportIssued}
                  views={['year', 'month', 'day']}
                  onChange={(newValue) => {
                    setValues({...values, passportIssued: newValue});
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </MDBox>
          </Grid>
          <MDBox mt={3} ml={3} display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
            <MDButton variant="gradient" color="dark" size="small" onClick={() => handleSubmit()}>
              Сохранить изменения
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Header;
