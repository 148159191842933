import React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

function PreordersTableHeader() {

  const smallTextStyle = {
    fontSize: 14,
    fontWeight: 'bold'
  }

  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell align="center" style={smallTextStyle}>Создан</TableCell>
      <TableCell align="center" style={smallTextStyle}>Проект</TableCell>
      <TableCell align="center" style={smallTextStyle}>Название</TableCell>
      <TableCell align="center" style={smallTextStyle}>Статус</TableCell>
      <TableCell align="center" style={smallTextStyle}>Бюджет</TableCell>
      <TableCell align="center" style={smallTextStyle}>Открыт до</TableCell>
      <TableCell align="center" style={smallTextStyle}>Собирать до</TableCell>
      <TableCell align="center" style={smallTextStyle}>Выбрать</TableCell>
    </TableRow>
  );
}

export default PreordersTableHeader;