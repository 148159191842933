import {ShortStageInterface2} from "./stage.types";
import {ShortUserInterface} from "./user.types";
import {ShortCompanyInterface} from "./company.types";
import {OrderStatus, ShortOrderInterface} from "./order.types";

export enum PaymentActionTypes {
  GET_ALL_PAYMENTS = 'GET_ALL_PAYMENTS',
  GET_ONE_PAYMENT = 'GET_ONE_PAYMENT',
  SET_PAYMENT_QUERY = 'SET_PAYMENT_QUERY',
  SET_ORDER_PAYMENT_BY = 'SET_ORDER_PAYMENT_BY',
  SET_ORDER_SORT_BY = 'SET_ORDER_SORT_BY',
}

export type PaymentPayload = GetAllPayments | GetOnePayment;

export interface GetAllPayments {
  type: PaymentActionTypes.GET_ALL_PAYMENTS,
  payload: ShortPaymentInterface[],
}

export interface GetOnePayment {
  type: PaymentActionTypes.GET_ONE_PAYMENT,
  payload: FullPaymentInterface,
}

export enum PaymentStatuses {
  ISSUED = 'ISSUED',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
}

export const paymentStatusConverter = {
  ISSUED: 'Выставлен',
  OVERDUE: 'Просрочен',
  PAID: 'Оплачен',
  CANCELED: 'Отменен',
}

export enum PaymentDirection {
  TO_MD = "TO_MD",
  FROM_MD = "FROM_MD"
}

export const paymentDirectionConverter = {
  TO_MD: 'От клиента в МД',
  FROM_MD: 'От МД клиенту',
}

export enum PaymentTypes {
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH = 'CASH',
  CARD = 'CARD',
  BONUS = 'BONUS',
}

export const paymentTypesConverter = {
  BANK_TRANSFER: 'Банковский перевод',
  CASH: 'Наличные',
  CARD: 'Карта',
  BONUS: 'Бонусы',
}

export interface ShortPaymentAdminInterface {
  id: number,
  invoiceNumber: string,
  invoiceDate: string,
  validTill: string,
  type: PaymentTypes,
  direction: PaymentDirection,
  invoiceSum: number,
  status: PaymentStatuses,
}

export interface FullPaymentAdminInterface {
  id: number,
  createdAt: string,
  updatedAt: string,
  includeTax: boolean,
  taxRate: number,
  invoiceNumber: string,
  invoiceDate: string,
  validTill: string,
  paiedOnDate: string,
  type: PaymentTypes,
  direction: PaymentDirection,
  invoiceSum: number,
  status: PaymentStatuses,
  order: {
    id: number,
    createdAt: string,
    title: string,
    status: OrderStatus,
  },
  counterpartyUser: {
    id: number,
    nickname: string,
    email: string,
  },
  counterpartyCompany: {
    id: number,
    title: string,
    logo: string,
  },
  files: {
    id: number,
    fileName: string,
    description: string,
  }[]
}

export interface ShortPaymentInterface {
  id: number,
  invoiceNumber: string,
  invoiceDate: string,
  type: PaymentTypes,
  invoiceSum: number,
  status: PaymentStatuses,
}

export interface FullPaymentInterface {
  id: number,
  includeTax: string,
  taxRate: number,
  invoiceNumber: string,
  invoiceDate: string,
  validTill: string,
  paiedOnDate: string,
  type: PaymentTypes,
  invoiceSum: number,
  status: PaymentStatuses,
  order: {
    id: number,
    status: OrderStatus,
  },
  files: {
    id: number,
    fileName: string,
    description: string,
  }
}

export interface createPaymentInterface {
  includeTax?: boolean
  taxRate?: number
  invoiceNumber?: string
  invoiceDate?: string
  validTill?: string
  paiedOnDate?: string
  type?: PaymentTypes
  direction: PaymentDirection
  invoiceSum: number
  orderId: number
  counterpartyUserId?: number
  counterpartyCompanyId?: number
}

export interface PaymentInOrderInterface {
  id: number,
  createdAt: string,
  updatedAt: string,
  invoiceNumber: string,
  invoiceDate: string,
  validTill: string,
  type: PaymentTypes,
  invoiceSum: string,
  status: PaymentStatuses,
  includeTax: boolean,
  taxRate: number,
  paiedOnDate: string,
  direction: PaymentDirection
}