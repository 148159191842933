import {
  StagePaymentType,
  StageReducerTypes,
  StagesOrderBy,
  StagesPayload,
  StagesSortBy,
  StageStatuses
} from "../types/stage.types";

export interface ShortStageInterface {
  id: number,
  number: number,
  title: string,
  status: StageStatuses,
  productionStart: string,
  productionEnd: string,
  productionCost: number,
  detail: {
    id: number,
    quantity: number,
    title: string,
    material: {
      id: number,
      title: string,
      image: string,
    },
    shape: {
      id: number,
      title: string,
      image: string,
      }
  },
  typeOfWork: {
    id: number,
    title: string,
    description: string,
    commission: number,
  },
  _count: {
    offersFromProducers: number,
  },
}

export interface FullOpenStage {
  id: number,
  title: string,
  productionStart: string,
  productionEnd: string,
  status: StageStatuses,
  description: string,
  equipment: string,
  paymentType: StagePaymentType,
  productionCost: string,
  requestCollectionEnd: string,
  requestCollectionStart: string,
  detail: {
    id: number,
    quantity: number,
    title: string,
    material: {
      id: number,
      title: string,
      image: string,
      details: string,
    },
    shape: {
      id: number,
      title: string,
      image: string,
      details: string,
    },
  },
  typeOfWork: {
    id: number,
    title: string,
    description: string,
  },
  files: [],
  images: [],
  _count: {
    offersFromProducers: number,
  },
  route: {preOrder: {ownerUser: {id: number}}},
}

export interface ShortOpenStage {
  id: number,
  title: string,
  productionStart: string,
  productionEnd: string,
  status: StageStatuses,
  equipment: string,
  productionCost: number,
  images: {
    fileName: string,
  }[],
  route: {
    preOrder: {
      ownerUser: {id: number}
    }
  },
  detail: {
    id: number,
    quantity: number,
    title: string,
    material: {
      title: string,
      image: string,
    },
    shape: {
      title: string,
      image: string,
    },
  },
  typeOfWork: {
    title: string,
  },
  _count: {
    offersFromProducers: number,
  },
}

export const emptyShortStage = {
  id: 0,
  number: 0,
  title: '',
  status: StageStatuses.PROBLEM,
  productionStart: '',
  productionEnd: '',
  productionCost: 0,
  detail: {
    id: 0,
    quantity: 0,
    title: '',
    material: {
      id: 0,
      title: '',
      image: '',
    },
    shape: {
      id: 0,
      title: '',
      image: '',
    }
  },
  typeOfWork: {
    id: 0,
    title: '',
    description: '',
    commission: 0,
  },
  _count: {
    offersFromProducers: 0,
  }
}

export const emptyFullStage: FullOpenStage = {
  id: 0,
  title: '',
  productionStart: '',
  productionEnd: '',
  status: StageStatuses.CLOSED_SUCCESS,
  description: '',
  equipment: '',
  paymentType: StagePaymentType.PARTIAL_ADVANCE,
  productionCost: '',
  requestCollectionEnd: '',
  requestCollectionStart: '',
  detail: {
    id: 0,
    quantity: 0,
    title: '',
    material: {
      id: 0,
      title: '',
      image: '',
      details: '',
    },
    shape: {
      id: 0,
      title: '',
      image: '',
      details: '',
    },
  },
  typeOfWork: {
    id: 0,
    title: '',
    description: '',
  },
  files: [],
  images: [],
  route: {preOrder: {ownerUser: {id: 0}}},
  _count: {
    offersFromProducers: 0,
  }
}

export interface OffersPromProducersForStage {
  id: number,
  title: string,
  createdAt: string,
  updatedAt: string,
  status: string,
  detail: {
    id: number,
    quantity: number,
    title: string,
    material: {
      id: number,
      title: string,
      image: string,
    },
  },
  order: {
    id: number,
  },
  producerCompany: {
    id: number,
    name: string,
    logo: string,
  },
  producerUser: {
    id: number,
    nickname: string,
    email: string,
  },
}

export interface FullStageInterface {
  id: number,
  createdAt: string,
  updatedAt: string,
  title: string,
  productionStart: string,
  productionEnd: string,
  status: StageStatuses,
  customerPrice: number,
  description: string,
  equipment: string,
  paymentType: string,
  productionCost: number,
  requestCollectionEnd: string,
  requestCollectionStart: string,
  number: number,
  companyProducer: {
    id: number,
    name: string,
    inn: string,
    contactPerson: string,
    contactPersonPhone: string,
    logo: string,
    profile: string,
    owner: {
      id: number,
      nickname: string,
      email: string
    },
    details: string,
    typesOfWorks: string,
    managers: {
      id: number,
      nickname: string,
      email: string
    }
  },
  detail: {
    id: number,
    quantity: number,
    title: string,
    material: {
      id: number,
      title: string,
      image: string,
    },
    order: {
      id: number,
    },
    shape: {
      id: number,
      title: string,
      image: string,
    },
  },
  route: {
    id: number,
    title: string,
  },
  typeOfWork: {
    id: number,
    title: string,
    description: string,
    commission: number,
  },
  userProducer: {
    id: number,
    nickname: string,
    email: string
  },
  comments: {
    id: number,
    createdAt: string,
    updatedAt: string,
    senderRole: string,
    text: string,
    sender: {
      id: number,
      nickname: string,
      email: string
    },
    received: boolean,
    viewed: boolean,
  },
  files: [],
  images: [],
  offersFromProducers: OffersPromProducersForStage[],
  payment: {
    id: number,
    createdAt: string,
    updatedAt: string,
    invoiceNumber: string,
    invoiceDate: string,
    validTill: string,
    type: string,
    invoiceSum: number,
    status: string,
    order: {
      id: number,
    },
    payerCompany: {
      id: number,
      name: string,
      logo: string,
    },
    payerUser: {
      id: number,
      nickname: string,
      email: string
    },
    recipientCompany: {
      id: number,
      name: string,
      logo: string,
    },
    recipientUser: {
      id: number,
      nickname: string,
      email: string
    },
  }
}

interface initialStateInterface {
  stages: ShortStageInterface[],
  openStages: ShortOpenStage[],
  oneOpenStage: FullOpenStage,
  oneStage: FullStageInterface | null,
  count: 0,
  skip: 0,
  take: 20,
  sort_by: StagesSortBy,
  order_by: StagesOrderBy,
  query: string,
}

const initialState: initialStateInterface = {
  stages: [],
  openStages: [],
  oneOpenStage: emptyFullStage,
  oneStage: null,
  count: 0,
  skip: 0,
  take: 20,
  sort_by: StagesSortBy.ID,
  order_by: StagesOrderBy.ASCENDING,
  query: '',
}

const stagesReducer = (state: initialStateInterface = initialState, action: StagesPayload) => {
  switch (action.type) {
    case StageReducerTypes.CREATE_STAGE:
      const newExtendedArray = [...state.stages, action.payload];
      return {...state, stages: action.payload}
    case StageReducerTypes.CHANGE_STAGE:
      return {...state, oneStage: action.payload}
    case StageReducerTypes.GET_STAGES:
      return {...state, stages: action.payload}
    case StageReducerTypes.GET_OPEN_STAGES:
      return {...state, openStages: action.payload}
    case StageReducerTypes.GET_ONE_OPEN_STAGES:
      return {...state, oneOpenStage: action.payload}
    case StageReducerTypes.GET_ONE_STAGE:
      return {...state, oneStage: action.payload}
    case StageReducerTypes.DELETE_STAGE:
      const newArray = state.stages.map(s => s.id !== action.payload.id);
      // @ts-ignore
      return {...state, stages: newArray, oneStage: null}
    case StageReducerTypes.SET_NEW_STAGE_STATUS:
      return {...state, oneStage: action.payload}
    case StageReducerTypes.SET_ORDER_BY:
      return {...state, order_by: action.payload}
    case StageReducerTypes.SET_SORT_BY:
      return {...state, sort_by: action.payload}
    case StageReducerTypes.SET_QUERY:
      return {...state, query: action.payload}
    case StageReducerTypes.SET_TAKE:
      return {...state, take: action.payload}
    case StageReducerTypes.SET_SKIP:
      return {...state, skip: action.payload}
    default:
      return state;
  }
}

export default stagesReducer;