// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import Moment from 'react-moment';
// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "../../dashboard/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "./components/table";

// Data
import {useAppDispatch, useAppSelector} from "../../../hooks/useActions";
import React, {useEffect, useState} from "react";
import {Box, Grid, Modal} from "@mui/material";
import OnePreOrderModal from "./components/modal/OnePreOrderModal";
import {convertFromStringToBeautyDateString, getPreOrderStatus} from "../../../utilities/converters";
import MDButton from "../../../components/MDButton";
import PreOrderForm from "./components/form/pre-order-form";
import {useNavigate} from "react-router-dom";
import PreordersTable from "./Table";

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90%',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "visible"
};

const scrollableModalBlockStyle = {
  height: '100%',
  margin: '0em',
  overflowY: 'auto',
}

function PreOrders(): JSX.Element {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pickedPreOrderId, setPickedPreOrderId] = useState<string | null>(null)
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(true);
  const {getMyPreOrders} = useAppDispatch();
  const {preOrders} = useAppSelector(state => state.preOrders);

  const navigate = useNavigate();

  const handlePreOrderModal = (id: string) => {
    setPickedPreOrderId(id);
    setModalOpen(true);
    setIsEditMode(false);
    setIsPreviewMode(true);
  }

  const handleNavigateToOnePreorderPage = (id: string) => {
    console.log('NAVIGATE !!!!!!!!! ------ ', id);
    navigate(`/account/preorders/${id}`);
  }

  const handleCreateModal = () => {
    setModalOpen(true);
    setIsEditMode(false);
    setIsPreviewMode(false);
  }

  const dataTableData = {
    columns: [
      { Header: "id", accessor: "id", width: "8%" },
      { Header: "создан", accessor: "createdAt", width: "10%" },
      { Header: "проект", accessor: "project", width: "10%" },
      { Header: "Название", accessor: "title", width: "25%" },
      { Header: "статус", accessor: "status", width: "10%" },
      { Header: "мин бюджет", accessor: "minBudget" },
      { Header: "макс бюджет", accessor: "maxBudget" },
      { Header: "открыт до", accessor: "preOrderRelevantTill" },
      { Header: "собирать до", accessor: "getOffersBeforeDate" },
    ],
    //@ts-ignore
    rows: preOrders ? (preOrders?.length === 0 ? [] : preOrders?.map(p => {
      return {
        id: p.id,
        createdAt: convertFromStringToBeautyDateString(p.createdAt),
        project: p.project?.title ?? '-',
        title: p.title,
        status: getPreOrderStatus(p.status),
        minBudget: p.minBudget,
        maxBudget: p.maxBudget,
        preOrderRelevantTill: convertFromStringToBeautyDateString(p.preOrderRelevantTill),
        getOffersBeforeDate: convertFromStringToBeautyDateString(p.getOffersBeforeDate),
      }
    })) : []
  };

  useEffect(() => {
    getMyPreOrders({query: ''});
  }, []);

  const handleClose = () => {
    setPickedPreOrderId(null);
    setModalOpen(false);
  }

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Card sx={modalStyle}>
            <Box sx={scrollableModalBlockStyle}>
              {
                isPreviewMode
                    ? <OnePreOrderModal
                        pickedPreOrderId={pickedPreOrderId}
                        setIsEditMode={setIsEditMode}
                        setIsPreviewMode={setIsPreviewMode}
                        setModalOpen={setModalOpen}
                    />
                    : <PreOrderForm
                        pickedPreOrderId={pickedPreOrderId}
                        isEditMode={isEditMode}
                        setModalOpen={setModalOpen}
                    />
              }
            </Box>
          </Card>
        </Modal>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item xs={6} md={4}>
                <MDTypography variant="h5" fontWeight="medium">
                  Мои размещенные заявки на производство
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Список всех размещенных зявок на производство или промышленную обработку
                </MDTypography>
              </Grid>
              <Grid item xs={6} md={4}>
                <MDButton variant="gradient" color="info" onClick={() => handleCreateModal()}>
                  Создать заявку
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>

          <PreordersTable />

        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PreOrders;
