

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import FormField from "../../../../../layouts/pages/account/components/FormField";
import {ChangeEvent, useEffect, useState} from "react";
import {useAnyInput, useStringInput} from "../../../../../hooks/useInput";
import {UserStateInterface} from "../../../../../store/reducers/user.reducer";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";

interface Props {
  //user: UserStateInterface;
  isDeliveryAddress: boolean;
}

function Address({ isDeliveryAddress}: Props): JSX.Element {
  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;


    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const [values, setValues] = useState({
    country: '',
    postalCode: '',
    state: '',
    city: '',
    street: '',
    houseBuilding: '',
    officeApartment: ''
  });

  const [prevData, setPrevData] = useState({
    country: '',
    postalCode: '',
    state: '',
    city: '',
    street: '',
    houseBuilding: '',
    officeApartment: ''
  });

  const {country, postalCode, state, city, street, houseBuilding, officeApartment} = values;
  
  const {changeUserAddress} = useAppDispatch();
  const {user} = useAppSelector(state => state);

  useEffect(() => {
    if (user) {
      setValues({
        country: 'Россия',
        postalCode: isDeliveryAddress ? (user["deliveryAddress"]?.["postalCode"] ? user["deliveryAddress"]?.["postalCode"] : '') : (user["juridicalAddress"]?.["postalCode"] ? user["juridicalAddress"]?.["postalCode"] : ''),
        state: isDeliveryAddress ? (user["deliveryAddress"]?.["state"] ? user["deliveryAddress"]?.["state"] : '') : (user["juridicalAddress"]?.["state"] ? user["juridicalAddress"]?.["state"] : ''),
        city: isDeliveryAddress ? (user["deliveryAddress"]?.["city"] ? user["deliveryAddress"]?.["city"] : '') : (user["juridicalAddress"]?.["city"] ? user["juridicalAddress"]?.["city"] : ''),
        street: isDeliveryAddress ? (user["deliveryAddress"]?.["street"] ? user["deliveryAddress"]?.["street"] : '') : (user["juridicalAddress"]?.["street"] ? user["juridicalAddress"]?.["street"] : ''),
        houseBuilding: isDeliveryAddress ? (user["deliveryAddress"]?.["houseBuilding"] ? user["deliveryAddress"]?.["houseBuilding"] : '') : (user["juridicalAddress"]?.["houseBuilding"] ? user["juridicalAddress"]?.["houseBuilding"] : ''),
        officeApartment: isDeliveryAddress ? (user["deliveryAddress"]?.["officeApartment"] ? user["deliveryAddress"]?.["officeApartment"] : '') : (user["juridicalAddress"]?.["officeApartment"] ? user["juridicalAddress"]?.["officeApartment"] : '')
      })
      setPrevData({
        country: 'Россия',
        postalCode: isDeliveryAddress ? (user["deliveryAddress"]?.["postalCode"] ? user["deliveryAddress"]?.["postalCode"] : '') : (user["juridicalAddress"]?.["postalCode"] ? user["juridicalAddress"]?.["postalCode"] : ''),
        state: isDeliveryAddress ? (user["deliveryAddress"]?.["state"] ? user["deliveryAddress"]?.["state"] : '') : (user["juridicalAddress"]?.["state"] ? user["juridicalAddress"]?.["state"] : ''),
        city: isDeliveryAddress ? (user["deliveryAddress"]?.["city"] ? user["deliveryAddress"]?.["city"] : '') : (user["juridicalAddress"]?.["city"] ? user["juridicalAddress"]?.["city"] : ''),
        street: isDeliveryAddress ? (user["deliveryAddress"]?.["street"] ? user["deliveryAddress"]?.["street"] : '') : (user["juridicalAddress"]?.["street"] ? user["juridicalAddress"]?.["street"] : ''),
        houseBuilding: isDeliveryAddress ? (user["deliveryAddress"]?.["houseBuilding"] ? user["deliveryAddress"]?.["houseBuilding"] : '') : (user["juridicalAddress"]?.["houseBuilding"] ? user["juridicalAddress"]?.["houseBuilding"] : ''),
        officeApartment: isDeliveryAddress ? (user["deliveryAddress"]?.["officeApartment"] ? user["deliveryAddress"]?.["officeApartment"] : '') : (user["juridicalAddress"]?.["officeApartment"] ? user["juridicalAddress"]?.["officeApartment"] : '')
      })
    }
  }, [user]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [e.target.name]: e.target.value});
  }

  const handleSubmit = async () => {
    try {
      await changeUserAddress({
        isDeliveryAddress,
        prevData,
        newData: values
      })
      console.log('YES')
    } catch (e) {
      console.log(`NO ${e}`)
    }
  }

  return (
    <Card id={isDeliveryAddress ? "delivery-address" : "juridical-address"} sx={{ overflow: "visible" }} style={{scrollMarginTop: 90}}>
      <MDBox p={3}>
        <MDTypography variant="h5">{isDeliveryAddress ? 'Адрес доставки' : 'Адрес регистрации'}</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Страна"
              placeholder="Россия"
              disabled={true}
              name={'country'}
              value={country}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Почтовый индекс"
              placeholder="..."
              name={'postalCode'}
              value={postalCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Область, Край, Республика"
              placeholder="..."
              name={'state'}
              value={state}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Город, населенный пункт"
              placeholder="..."
              name={'city'}
              value={city}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Улица"
              placeholder="..."
              name={'street'}
              value={street}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Дом, Здание, Строение"
              placeholder="..."
              name={'houseBuilding'}
              value={houseBuilding}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Квартира"
              placeholder="..."
              name={'officeApartment'}
              value={officeApartment}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <MDBox mt={3} ml={3} display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
            <MDButton variant="gradient" color="dark" size="small" onClick={() => handleSubmit()}>
              Сохранить изменения
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Address;
