// import {useDispatch} from "react-redux"
import {bindActionCreators} from "redux"
import ActionCreators from '../store/actions'
//
//
// export const useActions = () => {
//   const dispatch = useDispatch()
//   return bindActionCreators(ActionCreators, dispatch)
// }

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
//export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAppDispatch = () => {
  const dispatch = useDispatch<AppDispatch>()
  return bindActionCreators(ActionCreators, dispatch)
}