import { useState, useEffect, useMemo, JSXElementConstructor, Key, ReactElement } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "pages/layout/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React TS routes
import {routes} from "routes";
import Alert from "./components/Alert";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Protected from "./components/protected-route";
import Notifications from "./pages/account/notifications";
import HomePage from "./pages/home";
import OpenOrder from "./pages/openOrder";
import Blog from "./pages/blog";
import ProfileOverview from "./pages/account/profile";
import AccountSettings from "./pages/account/settings";
import DashboardAnalytics from "./pages/dashboard/analytics";
import PreOrders from "./pages/account/pre-orders";
import Offers from "./pages/account/offers";
import Sales from "./layouts/dashboards/sales";
import Invoice from "./pages/account/invoice";
import Kanban from "./layouts/applications/kanban";
import Wizard from "./layouts/applications/wizard";
import DataTables from "./layouts/applications/data-tables";
import Calendar from "./layouts/applications/calendar";
import AdminModels from "./pages/admin/Models";
import AdminUsers from "./pages/admin/Users/AllUsers";
import AdminPreOrders from "./pages/admin/PreOrders";
import AdminRoutesPage from "./pages/admin/Routes";
import AdminStagesPage from "./pages/admin/Stages/AllStages";
import NewProduct from "./layouts/ecommerce/products/new-product";
import EditProduct from "./layouts/ecommerce/products/edit-product";
import ProductPage from "./layouts/ecommerce/products/product-page";
import Timeline from "./layouts/pages/projects/timeline";
import AllProjects from "./layouts/pages/profile/all-projects";
import OrderList from "./layouts/ecommerce/orders/order-list";
import OrderDetails from "./layouts/ecommerce/orders/order-details";
import Login from "./pages/login";
import Registration from "./pages/registration";
import ForgotPassword from "./pages/login/forgot-password";
import UpdatePassword from "./pages/login/update-password";
import Post from "./pages/blog/Post/Post";
import OneUser from "./pages/admin/Users/OneUser";
import OnePreorderPage from "./pages/account/pre-orders/OnePreorderPage";
import AdminAllOrders from "pages/admin/Order/AllOrders";
import AdminOneOrder from "pages/admin/Order/OneOrder";
import OneStagePage from "./pages/admin/Stages/OneStage/Index";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes: any[]): any =>
  //   allRoutes.map(
  //     (route: {
  //       collapse: any;
  //       route: string;
  //       component: ReactElement<any, string | JSXElementConstructor<any>>;
  //       key: Key;
  //       name: string,
  //       protected?: boolean,
  //       invisibleIfAuth?: boolean,
  //       invisibleIfNotAdmin?: boolean,
  //     }) => {
  //       if (route.collapse) {
  //         return getRoutes(route.collapse);
  //       }
  //       if (route.route && route.protected) {
  //         return <Route
  //           path={route.route}
  //           element={
  //             <Protected invisibleIfNotAuth={route.protected} invisibleIfNotAdmin={route.invisibleIfNotAdmin}>
  //               {route.component}
  //             </Protected>
  //           }
  //           key={route.key}
  //         />;
  //       }
  //
  //       if (route.route && route.invisibleIfAuth) {
  //         return <Route
  //           path={route.route}
  //           element={
  //             <Protected invisibleIfAuth={route.invisibleIfAuth}>
  //               {route.component}
  //             </Protected>
  //           }
  //           key={route.key}
  //         />;
  //       }
  //
  //       if (route.route) {
  //         return <Route path={route.route} element={route.component} key={route.key} />;
  //       }
  //
  //       return null;
  //     }
  //   );

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  //  theme={darkMode ? themeDark : theme}

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>

      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Маркет-Деталь"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/*<Configurator />*/}
          {/*{configsButton}*/}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {/*{getRoutes(routes)}*/}

        // Admin
        <Route path="/admin/stages" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={true}>
              <AdminStagesPage />
            </Protected>} key="stages"/>
        <Route path="/admin/stages/:id" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={true}>
              <OneStagePage />
            </Protected>} key="stages"/>
        <Route path="/admin/routes" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={true}>
              <AdminRoutesPage />
            </Protected>} key="routes"/>
        <Route path="/admin/pre-orders" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={true}>
              <AdminPreOrders />
            </Protected>} key="pre-orders"/>
        <Route path="/admin/users" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={true}>
              <AdminUsers />
            </Protected>} key="users"/>
        <Route path="/admin/users/:id" element={
          <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={true}>
            <OneUser />
          </Protected>} key="users"/>
        <Route path="/admin/models" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={true}>
              <AdminModels />
            </Protected>} key="models"/>
        <Route path="/admin/orders" element={
          <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={true}>
            <AdminAllOrders />
          </Protected>} key="orders"/>
        <Route path="/admin/orders/:id" element={
          <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={true}>
            <AdminOneOrder />
          </Protected>} key="orders"/>

        // Dashboard
        <Route path="/dashboard/invoices" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={false}>
              <Invoice />
            </Protected>} key="invoices"/>
        <Route path="/dashboard/analytics" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={false}>
              <DashboardAnalytics />
            </Protected>} key="analytics"/>
        <Route path="/dashboard/preorders" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={false}>
              <PreOrders />
            </Protected>} key="preorders"/>
        <Route path="/dashboard/preorders/:id" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={false}>
              <OnePreorderPage />
            </Protected>} key="preorders"/>
        <Route path="/dashboard/offers" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={false}>
              <Offers />
            </Protected>} key="offers"/>
        <Route path="/dashboard/sales" element={
            <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={false}>
              <Sales />
            </Protected>} key="sales"/>
        <Route path="/dashboard/all-projects" element={
          <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={false}>
            <AllProjects />
          </Protected>} key="all-projects"/>

        // Account
        <Route path="/account/notifications"  element={
          <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={false}>
            <Notifications />
          </Protected>} key="notifications" />
        <Route path="/account/settings" element={
          <Protected invisibleIfNotAuth={true} invisibleIfNotAdmin={false}>
            <AccountSettings />
          </Protected>} key="settings"/>

        // Authorization
        <Route path="/authentication/login" element={
            <Protected invisibleIfNotAuth={false} invisibleIfNotAdmin={false}>
              <Login />
            </Protected>} key="login"/>
        <Route path="/authentication/login" element={
           <Protected invisibleIfNotAuth={false} invisibleIfNotAdmin={false}>
             <Login />
            </Protected>} key="login"/>
        <Route path="/authentication/registration"  element={
            <Protected invisibleIfNotAuth={false} invisibleIfNotAdmin={false}>
              <Registration />
            </Protected>} key="registration" />
        <Route path="/authentication/forgot-password"  element={
            <Protected invisibleIfNotAuth={false} invisibleIfNotAdmin={false}>
              <ForgotPassword />
            </Protected>} key="forgot-password" />
        <Route path="/update-password/:link"  element={
            <Protected invisibleIfNotAuth={false} invisibleIfNotAdmin={false}>
            <UpdatePassword />
            </Protected>} key="update-password" />

        // Backup pages for using during development
        <Route path="/templates/order-details" element={<OrderDetails />} key="order-details"/>
        <Route path="/templates/order-list" element={<OrderList />} key="order-list"/>
        <Route path="/templates/timeline" element={<Timeline />} key="timeline"/>
        <Route path="/templates/product-page" element={<ProductPage />} key="product-page"/>
        <Route path="/templates/edit-product" element={<EditProduct />} key="edit-product"/>
        <Route path="/templates/new-product" element={<NewProduct />} key="new-product"/>
        <Route path="/templates/kanban" element={<Kanban />} key="kanban"/>
        <Route path="/templates/wizard" element={<Wizard />} key="wizard"/>
        <Route path="/templates/data-tables" element={<DataTables />} key="data-tables"/>
        <Route path="/templates/calendar" element={<Calendar />} key="calendar"/>

        // Open routes
        <Route path="/blog" element={<Blog />} key="blog"/>
        <Route path="/blog/:slug" element={<Post />} key="post"/>
        <Route path="/open-order/:id" element={<OpenOrder />} key="open-order"/>
        <Route path="/" element={<HomePage />} key="home"/>

        <Route path="*" element={<Navigate to="/" />} />

      </Routes>
    </ThemeProvider>
  );
}
