import {TypesOfWorksPayload, TypesOfWorksReducerTypes} from "../types/types-of-works.types";
import {ProjectPayload, ProjectReducerTypes} from "../types/project.types";

export interface ProjectsInterface {
    id: number
    title: string
}

export interface OneProjectInterface {
    id: number
    title: string
    orders: []
    preOrders: []
}

export interface ProjectsStateInterface {
    projects: ProjectsInterface[],
    project: OneProjectInterface | null
}

const initialState: ProjectsStateInterface = {
    projects: [],
    project: null
};

const projectReducer = (state: ProjectsStateInterface = initialState, action: ProjectPayload) => {
    switch (action.type) {
        case ProjectReducerTypes.SET_PROJECTS_ERROR:
            return {...state};
        case ProjectReducerTypes.SET_PROJECTS_SUCCESS:
            return {...state, projects: action.payload};
        case ProjectReducerTypes.SET_ONE_PROJECT_SUCCESS:
            return {...state, project: action.payload};
        default:
            return state;
    }
}

export default projectReducer;