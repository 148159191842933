export interface MaterialInterface {
  id: number
  title: string
  image: string
}

export enum MaterialReducerTypes {
  GET_MATERIALS = 'GET_MATERIALS',
  CLEAR_MATERIALS = 'CLEAR_MATERIALS',
}

export type MaterialPayload = GetMaterials | ClearMaterials

export interface GetMaterials {
  type: MaterialReducerTypes.GET_MATERIALS,
  payload: MaterialInterface[]
}

export interface ClearMaterials {
  type: MaterialReducerTypes.CLEAR_MATERIALS
}