import React, {useEffect, useState} from 'react';
import MDBox from "../../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import DropZone from '../../../../../components/DropZone/dropzone';
import MDTypography from "../../../../../components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import MDEditor from "../../../../../components/MDEditor";
import {Checkbox, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import MDButton from "../../../../../components/MDButton";
import {getDeliveryCompanies} from "../../../../../utilities/http.requests";
import {Constants, DocumentType, FileSize} from "../../../../../utilities/constants";

interface ProjectType {
    title: string;
    id: number;
}

interface PropsInterface {
    pickedPreOrderId: string,
    isEditMode: boolean,
    setModalOpen: any,
}

interface FileInterface {
    id?: number,
    file: any,
    description?: string
}

const PreOrderForm = ({pickedPreOrderId, isEditMode, setModalOpen}: PropsInterface): JSX.Element => {
    const [preOrder, setPreOrder] = useState<PreOrderInterface>(emptyPreOrder);
    const [images, setImages] = useState<any>([]);
    const [files, setFiles] = useState<any>([]);
    const [allProjects, setAllProjects] = useState<ProjectType[]>([]);
    const [newProject, setNewProject] = useState<string>('');
    const [listOfDeliveryCompanies, setListOfDeliveryCompanies] = useState<{id: number, title: string}[] | null>([]);

    const {getMyOnePreOrder, getProjects, createProject, createPreOrder, changePreOrder} = useAppDispatch();
    const {preOrders: {onePreOrder}, projects: {projects}, auth: {user: {userId}}} = useAppSelector(state => state);

    const {title, description, minBudget, maxBudget, budgetWithTax, useDeliveryCompany,
        preOrderRelevantTill, getOffersBeforeDate, productionStart, productionEnd,
        deliveryCompany, project, } = preOrder;

    useEffect(() => {
        if (pickedPreOrderId && isEditMode) {
            getMyOnePreOrder(pickedPreOrderId)
        }
        getDeliveryCompanies()
            .then(comps => setListOfDeliveryCompanies(comps))
        getProjects();
    }, []);

    useEffect(() => {
        setAllProjects(projects)
    }, [projects]);

    useEffect(() => {
        if (isEditMode && onePreOrder['id'] !== 0) {
            setPreOrder({
                id: onePreOrder['id'],
                title: onePreOrder['title'],
                description: onePreOrder['description'],
                minBudget: onePreOrder['minBudget'],
                maxBudget: onePreOrder['maxBudget'],
                budgetWithTax: onePreOrder['budgetWithTax'],
                useDeliveryCompany: onePreOrder['useDeliveryCompany'],
                preOrderRelevantTill: new Date(onePreOrder['preOrderRelevantTill']),
                productionStart: new Date(onePreOrder['productionStart']),
                productionEnd: new Date(onePreOrder['productionEnd']),
                getOffersBeforeDate: new Date(onePreOrder['getOffersBeforeDate']),
                deliveryCompany: onePreOrder['deliveryCompany']['id'],
                project: onePreOrder['project'],
                order: {id: onePreOrder['order']['id']},
                ownerUser: {
                    id: onePreOrder['ownerUser']['id'],
                    nickname: onePreOrder['ownerUser']['nickname'],
                    avatar: onePreOrder['ownerUser']['avatar']
                },
                ownerCompany: {
                    id: onePreOrder['ownerCompany']['id'],
                    name: onePreOrder['ownerCompany']['name'],
                    profile: onePreOrder['ownerCompany']['profile'],
                    logo: onePreOrder['ownerCompany']['logo'],
                    typesOfWorks: onePreOrder['ownerCompany']['typesOfWorks'],
                    owner: {id: onePreOrder['ownerCompany']['owner']['id'], avatar: onePreOrder['ownerCompany']['owner']['avatar'], nickname: onePreOrder['ownerCompany']['owner']['nickname']},
                    managers: onePreOrder['ownerCompany']['managers'],
                },
            });
            // @ts-ignore
            if (isEditMode && onePreOrder?.images?.length > 0) {
                console.log('SET IMAGES - EDIT')
                // @ts-ignore
                const existingImages = onePreOrder?.images?.map((i: any) => {
                    return {id: i.id, src: `${Constants.backendURL}orders/${userId}/500x500/${i.fileName}`}
                });
                setImages(existingImages);
            }
            // @ts-ignore
            if (isEditMode && onePreOrder?.files?.length > 0) {
                setFiles(onePreOrder['files']);
            }
        }
    }, [onePreOrder])

    const handleCancel = () => {
        setModalOpen(false);
        setPreOrder(emptyPreOrder);
        setImages([]);
        setFiles([]);
    }

    const handleChangePreOrder = async (e: any): Promise<void> => {
        e.preventDefault();
        const res = await changePreOrder(preOrder, pickedPreOrderId);
        res && setModalOpen(false);
    }

    const handleCreateNewPreOrder = async (e: any): Promise<void> => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('minBudget', minBudget.toString());
        formData.append('maxBudget', maxBudget.toString());
        formData.append('budgetWithTax', budgetWithTax.toString());
        formData.append('useDeliveryCompany', useDeliveryCompany.toString());
        formData.append('preOrderRelevantTill', preOrderRelevantTill.toString());
        formData.append('productionStart', productionStart.toString());
        formData.append('productionEnd', productionEnd.toString());
        formData.append('getOffersBeforeDate', getOffersBeforeDate.toString());
        if (deliveryCompany) formData.append('deliveryCompany', deliveryCompany.toString());
        if (project && project.id !== 0) formData.append('projectId', project.id.toString());
        if (images?.length > 0) {
            for(let i=0; i<images.length; i++) {
                formData.append('images', images[i].raw);
            }
        }
        if (files?.length > 0) {
            for(let i=0; i<files.length; i++) {
                formData.append('files', files[i].raw, files[i].raw.convertedName);
            }
        }

        const res = await createPreOrder(formData);
        res && setModalOpen(false);
    }

    const handleCreateNewProject = async () => {
        const confirmedProject = await createProject(newProject);
        await getProjects();
        // @ts-ignore
        setPreOrder({...preOrder, project: confirmedProject});
        setNewProject('');
    }

    const handleSetProject = (project: string) => {
        const pro = allProjects?.find(p => p.title === project);
        setPreOrder({...preOrder, project: pro});
    }

    return(
        <>
            <MDBox py={3}>
                <MDBox p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12} xl={12} md={12}>
                            <MDTypography variant="h5" fontWeight="medium">
                                {
                                    pickedPreOrderId ? `Редактирование заявки ${pickedPreOrderId}` : 'Создание предзаказа'
                                }
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12} lg={12} xl={12} md={12}>
                            <MDInput
                              variant="outlined"
                              label="Название ..."
                              value={title}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPreOrder({...preOrder, title: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} xl={3} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                                <DatePicker
                                    label="Заявка актуально до:"
                                    value={preOrderRelevantTill}
                                    views={['year', 'month', 'day']}
                                    onChange={(newValue) => {
                                        setPreOrder({...preOrder, preOrderRelevantTill: newValue});
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={3} xl={3} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                                <DatePicker
                                    label="Крайний срок получения ответов:"
                                    value={getOffersBeforeDate}
                                    views={['year', 'month', 'day']}
                                    onChange={(newValue) => {
                                        setPreOrder({...preOrder, getOffersBeforeDate: newValue});
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={3} xl={3} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                                <DatePicker
                                    label="Желаемый срок старта работ:"
                                    value={productionStart}
                                    views={['year', 'month', 'day']}
                                    onChange={(newValue) => {
                                        setPreOrder({...preOrder, productionStart: newValue});
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={3} xl={3} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                                <DatePicker
                                    label="Желаемый срок окончания работ:"
                                    value={productionEnd}
                                    views={['year', 'month', 'day']}
                                    onChange={(newValue) => {
                                        setPreOrder({...preOrder, productionEnd: newValue});
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6} md={6}>
                            <MDInput
                                variant="outlined"
                                label="Минимальный бюджет..."
                                value={minBudget}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPreOrder({...preOrder, minBudget: Number(e.target.value)})}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6} md={6}>
                            <MDInput
                                variant="outlined"
                                label="Максимальный бюджет..."
                                value={maxBudget}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPreOrder({...preOrder, maxBudget: Number(e.target.value)})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel
                                control={<Checkbox
                                    value={useDeliveryCompany}
                                    checked={useDeliveryCompany}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPreOrder({...preOrder, useDeliveryCompany: event.target.checked})}
                                />}
                                label="Использовать транспортную компанию"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel
                                control={<Checkbox
                                    value={budgetWithTax}
                                    checked={budgetWithTax}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPreOrder({...preOrder, budgetWithTax: event.target.checked})}
                                />}
                                label="Цена с учетом НДС"
                            />
                        </Grid>
                        {
                            useDeliveryCompany &&
                                <Grid item xs={12} lg={5}>
                                    <InputLabel id="deliveryCompany">Выберите транспортную компанию:</InputLabel>
                                    <Select
                                        labelId="deliveryCompany"
                                        value={deliveryCompany}
                                        name='deliveryCompany'
                                        // native={true}
                                        label="Тип компании"
                                        size={'medium'}
                                        style={{height: 40, width: 120}}
                                        onChange={(e) => setPreOrder({...preOrder, deliveryCompany: Number(e.target.value)})}
                                    >
                                        <MenuItem defaultChecked disabled value={0}>Пусто</MenuItem>
                                        {
                                            listOfDeliveryCompanies.length > 0
                                                && listOfDeliveryCompanies?.map(d => <MenuItem value={d.id} key={d.id}>{d.title}</MenuItem>)
                                        }
                                    </Select>
                                </Grid>
                        }
                        <Grid item xs={12} lg={12} xl={12} md={12}>
                            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                                    Описание&nbsp;&nbsp;
                                </MDTypography>
                            </MDBox>
                            <MDEditor
                                value={description}
                                onChange={(e: string) => setPreOrder({...preOrder, description: e})}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} mt={3} mb={3}>
                            {/*<MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">*/}
                            {/*    <MDTypography component="label" variant="button" fontWeight="regular" color="text">*/}
                            {/*        {*/}
                            {/*            project && project?.title !== 'без проекта'*/}
                            {/*                && <MDTypography variant="body2" >Связан с проектом: {project.title}</MDTypography>*/}
                            {/*        }*/}
                            {/*    </MDTypography>*/}
                            {/*</MDBox>*/}
                            <Grid container>
                                <Grid item md={6} xs={12}>
                                    <MDTypography component="label" variant="button" fontWeight="regular" color="text" mr={1}>
                                        Связать с проектом
                                    </MDTypography>
                                    {
                                        (allProjects?.length > 0)
                                            ? <Select
                                                style={{height: 40}}
                                                value={project?.title}
                                                label="Проект"
                                                name={'project'}
                                                onChange={(event: SelectChangeEvent) => handleSetProject(event.target.value)}
                                                >
                                                <MenuItem key='no' value={'без проекта'}>Без проекта</MenuItem>
                                                {
                                                    allProjects?.map(p => <MenuItem key={p.id} value={p.title}>{p.title}</MenuItem>)
                                                }
                                                </Select>
                                            : <MDTypography variant="body2" verticalAlign={'middle'}>Нет проектов</MDTypography>

                                    }
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <MDTypography component="label" variant="button" fontWeight="regular" color="text" mr={1}>
                                        Создать новый проект
                                    </MDTypography>
                                    <MDInput
                                        variant="outlined"
                                        label="Введите название"
                                        value={newProject}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewProject(e.target.value)}
                                    />
                                    <MDButton
                                        size={'medium'}
                                        color="info"
                                        variant={'text'}
                                        onClick={() => handleCreateNewProject()}
                                    >
                                        Создать
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                                    Изображения - максимум 7 штук по 8 мегабайт
                                </MDTypography>
                            </MDBox>
                            <DropZone
                              documentType={DocumentType.PREORDER}
                              documentId={pickedPreOrderId}
                              isEditMode={isEditMode}
                              files={images}
                              setFiles={setImages}
                              isImage={true}
                              sizeLimit={FileSize.MB_8}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                                    Фалы - максимум 3 штуки по 5 мегабайт
                                </MDTypography>
                            </MDBox>
                            <DropZone
                              documentType={DocumentType.PREORDER}
                              documentId={pickedPreOrderId}
                              isEditMode={isEditMode}
                              files={files}
                              setFiles={setFiles}
                              isImage={false}
                              sizeLimit={FileSize.MB_5}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                isEditMode ?
                                  <MDButton variant="gradient" color="info" onClick={(e: any) => handleChangePreOrder(e)}>
                                      Сохранить изменения
                                  </MDButton>
                                  :
                                  <MDButton variant="gradient" color="info" onClick={(e: any) => handleCreateNewPreOrder(e)}>
                                      Создать предзаказ
                                  </MDButton>
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                isEditMode ?
                                  <MDButton variant="gradient" color="error" onClick={() => handleCancel()}>
                                      Отменить изменения
                                  </MDButton>
                                  :
                                  <MDButton variant="gradient" color="error" onClick={() => handleCancel()}>
                                      Отменить
                                  </MDButton>
                            }
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </>
    )
}

export default PreOrderForm;

export interface PreOrderInterface {
    id?: number,
    title: string,
    description: string,
    minBudget: number,
    maxBudget: number,
    budgetWithTax: boolean,
    useDeliveryCompany: boolean,
    preOrderRelevantTill: Date,
    productionStart: Date,
    productionEnd: Date,
    getOffersBeforeDate: Date,
    deliveryCompany: number,
    project?: ProjectType,
    order?: {id: number} | null,
    ownerUser?: {id: number, nickname: string, avatar: string} | null,
    ownerCompany?: {
        id: number,
        name: string,
        profile: string,
        logo: string,
        typesOfWorks: {id: number, title: string}[],
        owner: {id: number, avatar: string, nickname: string},
        managers: {id: number, nickname: string, avatar: string}[],
    } | null,
}

const emptyPreOrder: PreOrderInterface = {
    id: 0,
    title: '',
    description: '',
    minBudget: 0,
    maxBudget: 0,
    budgetWithTax: false,
    useDeliveryCompany: false,
    preOrderRelevantTill: new Date(),
    productionStart: new Date(),
    productionEnd: new Date(),
    getOffersBeforeDate: new Date(),
    deliveryCompany: 0,
    project: {title: 'без проекта', id: 0},
    order: null,
    ownerUser: null,
    ownerCompany: null,
}