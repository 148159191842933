import {Dispatch} from "redux";
import {AlertPayload} from "../types/alert.types";
import {RoutePayload, RoutesReducerTypes} from "../types/route.type";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {store} from "../index";
import {formdataContentType, jsonContentType} from "../../utilities/http.requests";
import {PreOrderPayload, PreOrderReducerTypes, PreOrdersOrderBy, PreOrdersSortBy} from "../types/pre-order.types";
import {CreateDetailInterface} from "../types/detail.types";

export const getRoutesAction = (query?: string) => {
  return async (dispatch: Dispatch<RoutePayload | AlertPayload>) => {
    try {
      const state = store?.getState();
      // @ts-ignore
      let baseQueryString = `?skip=${state.route.skip ?? 0}&take=${state.route.take ?? 20}&order_by=${state.route.order_by}&sort_by=${state.route.sort_by}` + (query ? query : '');
      dispatch({type: RoutesReducerTypes.CLEAR_ROUTES});
      const res = await $api.get(`/route/all${baseQueryString}`);
      dispatch({type: RoutesReducerTypes.GET_ROUTES, payload: res.data})
    } catch (err) {
      const res = axiosErrorMessageHandler(err);
      console.log('ERROR GetRoutesAction --- ', JSON.stringify(res))
    }
  }
}

export const createRouteAction = (title: string, preOrderId: number, detail: CreateDetailInterface) => {
  return async (dispatch: Dispatch<RoutePayload | AlertPayload>) => {
    try {
      const res = await $api.post(
        `/route/create`,
        {title, preOrderId, detail},
        {headers: jsonContentType}
        );
      dispatch({type: RoutesReducerTypes.CREATE_ROUTES, payload: res.data});
      return res.data.id;
    } catch (err) {
      const res = axiosErrorMessageHandler(err);
      console.log('ERROR CreateRouteAction --- ', JSON.stringify(res))
    }
  }
}

export const getOneRouteAction = (id: number) => {
  return async (dispatch: Dispatch<RoutePayload | AlertPayload>) => {
    try {
      const res = await $api.get(`/route/${id}`);
      dispatch({type: RoutesReducerTypes.GET_ONE_ROUTE, payload: res.data})
    } catch (err) {
      const res = axiosErrorMessageHandler(err);
      console.log('ERROR CreateRouteAction --- ', JSON.stringify(res))
    }
  }
}

export const setTakeForRoute = (number: number) => {
  return async (dispatch: Dispatch<RoutePayload | AlertPayload>) => {
    dispatch({type: RoutesReducerTypes.SET_TAKE, payload: number});
  }
}

export const setSkipForRoute = (number: number) => {
  return async (dispatch: Dispatch<RoutePayload | AlertPayload>) => {
    dispatch({type: RoutesReducerTypes.SET_SKIP, payload: number});
  }
}

export const setQueryForRoute = (text: string) => {
  return async (dispatch: Dispatch<RoutePayload | AlertPayload>) => {
    dispatch({type: RoutesReducerTypes.SET_QUERY, payload: text});
  }
}

export const setSortByForRoute = (text: PreOrdersSortBy) => {
  return async (dispatch: Dispatch<RoutePayload | AlertPayload>) => {
    dispatch({type: RoutesReducerTypes.SET_SORT_BY, payload: text});
  }
}

export const setOrderByForRoute = (text: PreOrdersOrderBy) => {
  return async (dispatch: Dispatch<RoutePayload>) => {
    dispatch({type: RoutesReducerTypes.SET_ORDER_BY, payload: text});
  }
}

export const getFullQueryRoute = () => {
  return async (dispatch: Dispatch<RoutePayload>) => {

  }
}