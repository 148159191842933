import {Dispatch} from "redux";
import {LoadingPayload, LoadingReducerTypes} from "../types/loading";

export const setLoadingTrue = () =>{
  return (dispatch: Dispatch<LoadingPayload>) => {
    dispatch({type: LoadingReducerTypes.SET_LOADING_TRUE});
  }
}

export const setLoadingFalse = () =>{
  return (dispatch: Dispatch<LoadingPayload>) => {
    dispatch({type: LoadingReducerTypes.SET_LOADING_FALSE});
  }
}