// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/img/auth-bg.jpg";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useStringInput} from "../../hooks/useInput";
import { useNavigate } from 'react-router-dom';
import $api from "../../utilities/http.axios";
import {ValidatePassword} from "../../utilities/validators";
import {CircularProgress, Grid} from "@mui/material";
import {useAppSelector} from "../../hooks/useActions";

function UpdatePassword(): JSX.Element {
  let navigate = useNavigate();
  let { link } = useParams();
  const password = useStringInput('');
  const [errorInput, setErrorInput] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {auth: {isAuth}} = useAppSelector(state => state);

  useEffect(() => {
    isAuth && navigate('/dashboard');
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    password.onChange(e);
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      if (!ValidatePassword(password.value)) {
        setErrorInput(true);
        setTimeout(() => {
          setErrorInput(false);
        }, 2000)
        return;
      }
      setIsLoading(true);
      const res = await $api.post(`/auth/update-password/${link}`, {newPassword: password.value});
      console.log(`SUCCESS --- ${JSON.stringify(res.data)}`);
      setTimeout(() => {
        setIsLoading(false);
        navigate('/authentication/login');
      }, 2000)
    } catch (e) {
      console.log(`ERROR --- ${e}`);
    }
  }


  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor={errorInput ? 'error' : 'info'}
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
          style={{paddingLeft: 10, paddingRight: 10}}
        >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Восстановление пароля
            </MDTypography>
            <MDTypography display="block" variant="button" color='white' my={1}>
              Пароль должен быть не менее 6 символов, содержать заглавную букву и символ
            </MDTypography>
        </MDBox>

            <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                    <MDBox mb={4}>
                      {
                        isLoading ?
                          <Grid container alignContent={'center'}>
                            <Grid item>
                              <CircularProgress color="info"/>
                            </Grid>
                          </Grid>
                          :
                          <MDInput
                            type="password"
                            label={'Новый пароль'}
                            name='password'
                            {...password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            variant="standard"
                            fullWidth

                          />
                      }
                    </MDBox>
                    <MDBox mt={6} mb={1}>
                        <MDButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            onClick={(e: React.MouseEvent<HTMLElement>) => handleSubmit(e)}
                        >
                            Отправить
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDBox>

        <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            <MDTypography
              component={Link}
              to="/authentication/login"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Назад к авторизации
            </MDTypography>
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default UpdatePassword;
