import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {convertEnumToArray, getOrderStatus} from "../../../../../utilities/converters";
import {OrderStatus} from "../../../../../store/types/order.types";
import {FormControl, MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

function OrderStatuses() {
  const {orders: {oneOrder}} = useAppSelector(state => state);
  const {updateOrderStatus} = useAppDispatch();

  const status = getOrderStatus(oneOrder?.status);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [newStatus, setNewStatus] = React.useState<OrderStatus>(OrderStatus.CREATED);

  useEffect(() => {
      setNewStatus(oneOrder?.status);
  }, [oneOrder?.status]);

  const statusesArray = convertEnumToArray(OrderStatus);

  const handleUpdateStatus = () => {
    updateOrderStatus(oneOrder?.id, newStatus);
    setEditMode(false);
  }

  const handleCancel = () => {
    setEditMode(false);
    setNewStatus(oneOrder?.status);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <MDTypography variant={"h6"}>Статус заказа:</MDTypography>
          </Grid>
          <Grid item xs={9}>
            <FormControl fullWidth>
              <Select
                id="status-select"
                value={newStatus}
                disabled={!editMode}
                onChange={(e: any) => setNewStatus(e.target.value)}
                style={{height: 40}}
              >
                {
                  statusesArray.map((status: any) => (
                    <MenuItem key={status} value={status}>{getOrderStatus(status).title}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {
          editMode
            ? <MDButton
              color={"primary"}
              onClick={handleUpdateStatus}
            >Сохранить</MDButton>
            : <MDButton
              color={"success"}
              onClick={() => setEditMode(true)}
            >Изменить</MDButton>
        }
      </Grid>
      <Grid item xs={6}>
        <MDButton
          color={"warning"}
          onClick={() => handleCancel()}
          disabled={!editMode}
        >Отменить</MDButton>
      </Grid>
    </Grid>
  );
}

export default OrderStatuses;