import React, {Suspense, useEffect} from 'react';
import {FullStageInterface} from "../../../../store/reducers/stage.reducer";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {getOneStageAdmin} from "../../../../store/actions/stage.actions";
import OffersTabs from "./OffersTabs";
import DashboardLayout from "../../../dashboard/DashboardLayout";
import MDBox from "../../../../components/MDBox";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Card } from '@mui/material';
import Grid from "@mui/material/Grid";

interface PropsInterface {

}

function OneStagePage({}: PropsInterface) {
  const {id} = useParams<{ id: string }>();
  const {getOneStageAdmin} = useAppDispatch();
  const { stages: { oneStage }} = useAppSelector(state => state);

  useEffect(() => {
    getOneStageAdmin(id);
  }, []);

  return (
    <DashboardLayout>
      <MDBox mt={4}>

        <Card >
          <Grid container spacing={2} style={{padding: 12}}>
          <Grid item xs={12} style={{marginBottom: 30}}>
            {/*//@ts-ignore*/}
            <h3>{oneStage?.title}</h3>
          </Grid>
          <Grid item xs={12}>
            {
              //@ts-ignore
              oneStage?.offersFromProducers?.length > 0
              ?
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <p style={{fontSize: 16}}>ПРЕДЛОЖЕНИЯ ПРОИЗВОДИТЕЛЕЙ:</p>
                  <Typography variant="h6" gutterBottom component="div" style={{margin: 12}}>
                    Цена для заказчика: {//@ts-ignore
                    oneStage?.customerPrice
                  } р.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  {/*//@ts-ignore*/}
                  <OffersTabs offers={oneStage?.offersFromProducers}  />
                  </Grid>
                </Grid>
              : <p style={{fontSize: 16}}>ПРЕДЛОЖЕНИЙ ЕЩЕ НЕТ</p>
            }
          </Grid>
            </Grid>
        </Card>

      </MDBox>
    </DashboardLayout>
  );
}

export default OneStagePage;