import React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

function OrdersTableHeader() {

  const smallTextStyle = {
    fontSize: 14,
    fontWeight: 'bold'
  }

  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell align="center" style={smallTextStyle}>Название</TableCell>
      <TableCell align="center" style={smallTextStyle}>Создан</TableCell>
      <TableCell align="center" style={smallTextStyle}>Статус</TableCell>
      <TableCell align="center" style={smallTextStyle}>Старт-Конец</TableCell>
      <TableCell align="center" style={smallTextStyle}>Цена для заказчика</TableCell>
      <TableCell align="center" style={smallTextStyle}>Цена для производителя</TableCell>
      <TableCell align="center" style={smallTextStyle}>Оплата</TableCell>
      <TableCell align="center" style={smallTextStyle}>Этап</TableCell>
    </TableRow>
  );
}

export default OrdersTableHeader;