import React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import {NotificationInterface, NotificationTypes} from "../../../../../store/types/notifications.type";
import {convertFromStringToBeautyDateTimeString} from "../../../../../utilities/converters";
import {useAppDispatch} from "../../../../../hooks/useActions";
import InfoIcon from '@mui/icons-material/Info';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import DescriptionIcon from '@mui/icons-material/Description';

interface Props {
  expanded: string | false;
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  notification: NotificationInterface;
  panel: string;
}

function NotificationAccordionItem({handleChange, expanded, notification, panel}: Props) {
  const {setViewed} = useAppDispatch();
  const handleSetViewed = (id: number) => {
    !notification.viewed && setViewed(id);
  }

  const getIcon = (type: NotificationTypes) => {
    console.log('NOTI TYPE --- ', type)
    switch (type) {
      case NotificationTypes.INFO:
        return <InfoIcon sx={{color: '#1976d2'}} />;
      case NotificationTypes.SUCCESS:
        return <ThumbUpIcon sx={{color: '#4caf50'}} />;
      case NotificationTypes.WARNING:
        return <NotificationImportantIcon sx={{color: '#f57c00'}} />;
      case NotificationTypes.PLAIN:
        return <DescriptionIcon sx={{color: '#d32f2f'}} />;
      default:
        return <InfoIcon sx={{color: '#1976d2'}} />;
    }
  }

  return (
    <Accordion expanded={expanded === panel} onClick={() => handleSetViewed(notification.id)} onChange={handleChange(panel)} sx={{mb: 1, backgroundColor: notification.viewed ? 'inherit' : '#FBEFF2',}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: '6%', flexShrink: 0 }}>
          {getIcon(notification.type as NotificationTypes)}
        </Typography>
        <Typography sx={{ width: '66%', flexShrink: 0 }}>
          {notification.title}
        </Typography>
        <Typography sx={{ width: '20%', flexShrink: 0, fontSize: 'small' }}>
          {notification.viewed ? 'Прочтено' : 'Новое!'}
        </Typography>
        <Typography sx={{ color: 'text.secondary', fontSize: 'small' }}>
          {convertFromStringToBeautyDateTimeString(notification.createdAt)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {notification.text}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default NotificationAccordionItem;