export enum CompanyProfile {
  BLANK = 'BLANK',
  CLIENT = 'CLIENT',
  PRODUCER = 'PRODUCER',
  PRODUCERCLIENT = 'PRODUCERCLIENT',
}

export enum CompanyReducerTypes {
  SET_COMPANY_SUCCESS = 'SET_COMPANY_SUCCESS',
  SET_COMPANY_ERROR = 'SET_COMPANY_ERROR',
  CLEAR_COMPANY = 'CLEAR_COMPANY',
  SET_COMPANY_LOGO = 'SET_COMPANY_LOGO',
  SET_COMPANY_PROFILE = 'SET_COMPANY_PROFILE',
  ADD_COMPANY_TYPE_OF_WORK = 'ADD_COMPANY_TYPE_OF_WORK',
  REMOVE_COMPANY_TYPE_OF_WORK = 'REMOVE_COMPANY_TYPE_OF_WORK',
}

export interface CompanyInterface {
  id: number,
  profile:                    CompanyProfile;
  name:                       string;
  description:                string;
  inn:                        string;
  ogrnognip:                  string;
  juridicalAddress: {
    id: number;
    country:         string;
    postalCode:      string;
    state:           string;
    city:            string;
    street:          string;
    houseBuilding:   string;
    officeApartment: string;
  },
  deliveryAddress: {
    id: number;
    country:         string;
    postalCode:      string;
    state:           string;
    city:            string;
    street:          string;
    houseBuilding:   string;
    officeApartment: string;
  },
  officialPersonForDocs:      string;
  officialPersonForDocsPhone: string;
  contactPerson:              string;
  contactPersonPhone:         string;
  bankDetails: {
    id: number;
    bankName:                   string;
    bankAccount:                string;
    bankCorrAccount:            string;
    bankBik:                    string;
  },
  logo:                       string;
  managers: {
    id: number,
    avatar: string,
    fio: string,
    nickname: string
  }[],
  typesOfWorks: {
    id: number,
    title: string,
    description: string,
  }[],
}

export interface ShortCompanyInterface {
  id: number,
  profile:                    CompanyProfile;
  name:                       string;
  description:                string;
  inn:                        string;
  ogrnognip:                  string;
  logo:                       string;
  typesOfWorks: {
    id: number,
    title: string,
    description: string,
  }[],
}

export type CompanyPayload = SuccessCompanyPayload
  | ErrorCompanyPayload
  | CompanyNoPayload
  | SetCompanyLogo
  | SetCompanyProfile
  | AddCompanyTypeOfWork
  | RemoveCompanyTypeOfWork

interface SuccessCompanyPayload {
  type: CompanyReducerTypes.SET_COMPANY_SUCCESS,
  payload: {
    id: number,
    profile:                    CompanyProfile;
    name:                       string;
    description:                string;
    inn:                        string;
    ogrnognip:                  string;
    juridicalAddress: {
      id: number;
      country:         string;
      postalCode:      string;
      state:           string;
      city:            string;
      street:          string;
      houseBuilding:   string;
      officeApartment: string;
    },
    deliveryAddress: {
      id: number;
      country:         string;
      postalCode:      string;
      state:           string;
      city:            string;
      street:          string;
      houseBuilding:   string;
      officeApartment: string;
    },
    officialPersonForDocs:      string;
    officialPersonForDocsPhone: string;
    contactPerson:              string;
    contactPersonPhone:         string;
    bankDetails: {
      id: number;
      bankName:                   string;
      bankAccount:                string;
      bankCorrAccount:            string;
      bankBik:                    string;
    },
    logo:                       string;
    managers: {
      id: number,
      avatar: string,
      fio: string,
      nickname: string
    }[],
    typesOfWorks: {
      id: number,
      title: string,
      description: string,
    }[],
  }
}

interface ErrorCompanyPayload {
  type: CompanyReducerTypes.SET_COMPANY_ERROR,
  payload: string
}

interface CompanyNoPayload {
  type: CompanyReducerTypes.CLEAR_COMPANY,
}

interface SetCompanyLogo {
  type: CompanyReducerTypes.SET_COMPANY_LOGO,
  payload: string
}

interface SetCompanyProfile {
  type: CompanyReducerTypes.SET_COMPANY_PROFILE,
  payload: CompanyProfile
}

interface AddCompanyTypeOfWork {
  type: CompanyReducerTypes.ADD_COMPANY_TYPE_OF_WORK,
  payload: {
    id: number,
    title: string,
    description: string,
  }
}

interface RemoveCompanyTypeOfWork {
  type: CompanyReducerTypes.REMOVE_COMPANY_TYPE_OF_WORK,
  payload: {
    id: number,
    title: string,
    description: string,
  }[]
}