import {Dispatch} from "redux";
import {
  OpenStagesQuery,
  StageReducerTypes,
  StagesOrderBy,
  StagesPayload,
  StagesQuery,
  StagesSortBy
} from "../types/stage.types";
import {AlertDurations, AlertPayload, AlertReducerTypes} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {AlertColorStatuses} from "../types/common.types";
import {formdataContentType} from "../../utilities/http.requests";
import {store} from "../index";
import {alertActions} from "./alert.actions";
import {RoutePayload, RoutesReducerTypes} from "../types/route.type";
import {PreOrdersOrderBy, PreOrdersSortBy} from "../types/pre-order.types";

export const createStageAction = (stage: any) => {
  return async (dispatch: Dispatch<StagesPayload | AlertPayload | RoutePayload>): Promise<boolean> => {
    try {
      const res = await $api.post(
        `stage/create`,
        stage,
        { headers: formdataContentType });
      //dispatch({type: StageReducerTypes.CREATE_STAGE, payload: res.data});
      dispatch({type: RoutesReducerTypes.UPDATE_STAGES_IN_ROUTE, payload: res.data})
      return true;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      store.dispatch(alertActions(AlertColorStatuses.ERROR, res, AlertDurations.FOUR_SECONDS))
      //dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
      return false;
    }
  }
}

export const getOpenStages = (query?: OpenStagesQuery) => {
  return async (dispatch: Dispatch<StagesPayload | AlertPayload>): Promise<boolean> => {
    try {
      const res = await $api.get(`stage/get-open-stages`);
      dispatch({type: StageReducerTypes.GET_OPEN_STAGES, payload: res.data});
      return true;
    } catch (err: unknown) {
      console.log('GET OPEN STAGES - ERROR = ', err)
      const res = axiosErrorMessageHandler(err);
      store.dispatch(alertActions(AlertColorStatuses.ERROR, res, AlertDurations.FOUR_SECONDS))
      return false;
    }
  }
}

export const getAllStages = (query?: string) => {
  return async (dispatch: Dispatch<StagesPayload | AlertPayload>): Promise<boolean> => {
    try {
      query = query ? query : '';
      const res = await $api.get(`stage/get-all-stages${query}`);
      dispatch({type: StageReducerTypes.GET_STAGES, payload: res.data});
      return true;
    } catch (err: unknown) {
      console.log('GET STAGES - ERROR = ', err)
      const res = axiosErrorMessageHandler(err);
      store.dispatch(alertActions(AlertColorStatuses.ERROR, res, AlertDurations.FOUR_SECONDS))
      return false;
    }
  }
}

export const getOneOpenStage = (id: string) => {
  return async (dispatch: Dispatch<StagesPayload | AlertPayload>): Promise<boolean> => {
    try {
      const res = await $api.get(`stage/get-one-open-stage/${id}`);
      dispatch({type: StageReducerTypes.GET_ONE_OPEN_STAGES, payload: res.data});
      return true;
    } catch (err: unknown) {
      console.log('GET OPEN STAGE - ERROR = ', err)
      const res = axiosErrorMessageHandler(err);
      store.dispatch(alertActions(AlertColorStatuses.ERROR, res, AlertDurations.FOUR_SECONDS))
      return false;
    }
  }
}

export const getOneStageAdmin = (id: string) => {
  return async (dispatch: Dispatch<StagesPayload | AlertPayload>): Promise<boolean> => {
    try {
      const res = await $api.get(`stage/get-one-stage/${id}`);
      dispatch({type: StageReducerTypes.GET_ONE_STAGE, payload: res.data});
      return true;
    } catch (err: unknown) {
      console.log('GET OPEN STAGE - ERROR = ', err)
      const res = axiosErrorMessageHandler(err);
      store.dispatch(alertActions(AlertColorStatuses.ERROR, res, AlertDurations.FOUR_SECONDS))
      return false;
    }
  }
}

export const setTakeForStage = (number: number) => {
  return async (dispatch: Dispatch<StagesPayload | AlertPayload>) => {
    dispatch({type: StageReducerTypes.SET_TAKE, payload: number});
  }
}

export const setSkipForStage = (number: number) => {
  return async (dispatch: Dispatch<StagesPayload | AlertPayload>) => {
    dispatch({type: StageReducerTypes.SET_SKIP, payload: number});
  }
}

export const setQueryForStage = (text: string) => {
  return async (dispatch: Dispatch<StagesPayload | AlertPayload>) => {
    dispatch({type: StageReducerTypes.SET_QUERY, payload: text});
  }
}

export const setSortByForStage = (text: StagesSortBy) => {
  return async (dispatch: Dispatch<StagesPayload | AlertPayload>) => {
    dispatch({type: StageReducerTypes.SET_SORT_BY, payload: text});
  }
}

export const setOrderByForStage = (text: StagesOrderBy) => {
  return async (dispatch: Dispatch<StagesPayload>) => {
    dispatch({type: StageReducerTypes.SET_ORDER_BY, payload: text});
  }
}