import React, {useEffect, useMemo, useState} from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";
import {Button, Tooltip} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import $api from "../../../../../utilities/http.axios";
import {UserForAdminInterface} from "../../../../../store/types/user.types";
import {Navigate} from "react-router-dom";

function UsersTableBody() {
  const navigate = useNavigate();

  const [users, setUsers] = useState<UserForAdminInterface[]>([]);

  const getAllUsersRequest = async (query: any) => {
    try {
      const res = await $api.get(`/users/all?skip=${query.skip ?? 0}&take=${query.take ?? query.take ?? 20}&order_by=${query.order}`);
      setUsers(res.data as UserForAdminInterface[]);

    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getAllUsersRequest({});
  }, []);

  const handleRouteToUserPage = (id: any) => {
    console.log('NAVIGATE ---- ', id);
    navigate(`/admin/users/${id}`);
  }

  return (
    <>
      {users && users?.map((user: UserForAdminInterface) =>
        <TableRow key={user?.id}>
          <TableCell component="th" scope="row">
            {user?.id}
          </TableCell>
          <TableCell style={{ width: 140 }} align="center">
            <Typography fontSize={11}>
              {user?.nickname}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 140 }} align="center">
            <Typography fontSize={11}>
              {user?.email}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 240 }} align="center">
            <Typography fontSize={11}>
              {user?.status}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 120 }} align="center">
            <Typography fontSize={11}>
              {user?.roles?.map(role => `${role.value} `)}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Typography fontSize={11}>
              {user?._count?.customerToOrders}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Typography fontSize={11}>
              {user?._count?.producerToOrders}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Link to={`${user?.id}`}>
              <DehazeIcon color="primary" />
            </Link>
          </TableCell>
        </TableRow>
        )
      }
    </>
  );
}

export default UsersTableBody;