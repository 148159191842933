import {Dispatch} from "redux";
import {AlertPayload, AlertReducerTypes} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {AlertColorStatuses} from "../types/common.types";
import {ProjectPayload, ProjectReducerTypes} from "../types/project.types";

export const getProjects = () => {
    return async (dispatch: Dispatch<ProjectPayload | AlertPayload>) => {
        try {
            const res = await $api.get(`/project/`, );
            dispatch({type: ProjectReducerTypes.SET_PROJECTS_SUCCESS, payload: res.data});
            return res.data;
        } catch (err: unknown) {
            const res = axiosErrorMessageHandler(err);
            dispatch({type: ProjectReducerTypes.SET_PROJECTS_ERROR, payload: res});
            return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
        }
    }
}

export const createProject = (title: string) => {
    return async (dispatch: Dispatch<ProjectPayload | AlertPayload>) => {
        try {
            const res = await $api.post(`/project/create`, {title: title});
            dispatch({type: ProjectReducerTypes.SET_ONE_PROJECT_SUCCESS, payload: res.data});

            return res.data;
        } catch (err: unknown) {
            const res = axiosErrorMessageHandler(err);
            dispatch({type: ProjectReducerTypes.SET_PROJECTS_ERROR, payload: res});
            return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
        }
    }
}