import {combineReducers} from "redux";
import authReducer from "./auth.reducer";
import alertReducer from "./alert.reducer";
import userReducer from "./user.reducer";
import companyReducer from "./company.reducer";
import typesOfWorksReducer from "./types-of-works.reducer";
import preOrdersReducer from "./pre-order.reducer";
import projectReducer from "./project.reducer";
import textReducer from "./text.reducer";
import routesReducer from "./route.reducer";
import stagesReducer from "./stage.reducer";
import detailReducer from "./detail.reducer";
import shapesReducer from "./shape.reducer";
import materialReducer from "./material.reducer";
import offerReducer from "./offer.reducer";
import loadingReducer from "./loading.reducer";
import orderReducer from "./order.reducer";
import notificationsReducer from "./notifications.reducer";

const rootReducer = combineReducers ({
  auth: authReducer,
  alert: alertReducer,
  user: userReducer,
  company: companyReducer,
  typesWorks: typesOfWorksReducer,
  preOrders: preOrdersReducer,
  projects: projectReducer,
  text: textReducer,
  route: routesReducer,
  stages: stagesReducer,
  details: detailReducer,
  shapes: shapesReducer,
  materials: materialReducer,
  offers: offerReducer,
  orders: orderReducer,
  loading: loadingReducer,
  notifications: notificationsReducer,
})

export type State = ReturnType<typeof rootReducer>
export default rootReducer;