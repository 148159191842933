import {TypesOfWorksPayload, TypesOfWorksReducerTypes} from "../types/types-of-works.types";

export interface TypeOfWorksInterface {
  id: number
  title: string
  description: string
  commission: number
}

export interface TypesOfWorksStateInterface {
  id: number,
  title: string,
  description: string
}

const emptyTypeOfWork = {id: 0, title: '', description: ''};

const initialState: TypesOfWorksStateInterface[] = [emptyTypeOfWork];

const typesOfWorksReducer = (state: TypesOfWorksStateInterface[] = initialState, action: TypesOfWorksPayload) => {
  switch (action.type) {
    case TypesOfWorksReducerTypes.CLEAR_TYPES_OF_WORKS:
      return [emptyTypeOfWork];
    case TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_ERROR:
      return [emptyTypeOfWork];
    case TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}

export default typesOfWorksReducer;