export enum NotificationTypes {
  INFO = "INFO",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
  PLAIN = "PLAIN"
}

export interface NotificationInterface {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  text: string;
  link?: string;
  icon?: string;
  type: NotificationTypes;
  viewed: boolean

}

export enum NotificationsReducerTypes {
  GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS',
  GET_NUMBER_OF_UNREAD = 'GET_NUMBER_OF_UNREAD',
  SET_VIEWED = 'SET_VIEWED',
  CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
}

export type NotificationsPayload = GetAllNotifications
  | GetNumberOfAllUnreadNotifications
  | SetViewed
  | ClearAllNotifications

export interface GetAllNotifications {
  type: NotificationsReducerTypes.GET_ALL_NOTIFICATIONS,
  payload: NotificationInterface[]
}

export interface ClearAllNotifications {
  type: NotificationsReducerTypes.CLEAR_NOTIFICATIONS,
}

export interface GetNumberOfAllUnreadNotifications {
  type: NotificationsReducerTypes.GET_NUMBER_OF_UNREAD,
  payload: number
}

export interface SetViewed {
  type: NotificationsReducerTypes.SET_VIEWED,
  payload: {notifications: NotificationInterface[], unreadNumber: number}
}