// react-router-dom components
import {Link, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
// Images
import bgImage from "assets/img/auth-bg.jpg";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import {useStringInput} from "../../hooks/useInput";
import {useEffect, useState} from "react";
import {registration} from "../../store/actions/auth.actions";
import {useAppDispatch, useAppSelector} from "../../hooks/useActions";
import {AlertColorStatuses, AlertContent, ErrorTitleCreator} from "../../store/types/common.types";
import MDSnackbar from "../../components/MDSnackbar";
import MySnackbar from "../../components/Snackbar";
import BasicPageLayout from "../layout/BasicPageLayout";
import {ValidateEmail, ValidatePassword} from "../../utilities/validators";

function Registration(): JSX.Element {
  const [agreed, setAgreed] = useState<boolean>(false);
  const handleSetAgreed = () => setAgreed(!agreed);

  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<AlertContent | null>(null);

  const email = useStringInput('');
  const password = useStringInput('');

  const {auth: {isAuth, error}} = useAppSelector(store => store);
  let navigate = useNavigate();

  useEffect(() => {
    isAuth && navigate('/account/settings');
  }, []);

  useEffect(() => {
    setIsAlert(!!error)
    error && setAlertContent({status: AlertColorStatuses.ERROR, message: error})
    !error && setAlertContent(null)
  }, [error]);

  const { registration } = useAppDispatch()

  const handleAlert = (isOpen: boolean, status: AlertColorStatuses, message: string) => {
    setIsAlert(isOpen);
    setAlertContent({status, message});
  }

  const handleRegistration = async () => {
    if (!agreed) {
      return handleAlert(true, AlertColorStatuses.ERROR, 'Необходимо согласиться с условиями')
    }
    if (!ValidateEmail(email.value)) {
      return handleAlert(true, AlertColorStatuses.ERROR, 'Неверный формат Емейл')
    }
    if (!ValidatePassword(password.value)) {
      return handleAlert(true, AlertColorStatuses.ERROR, 'Пароль должен содержать заглавную букву, цифру, символ и быть не менее 6 знаков')
    }
    const result = await registration(email.value, password.value)
    if (!result) {
      return handleAlert(true, AlertColorStatuses.ERROR, error)
    }
    setTimeout(() => navigate('/account/settings'), 1000)
    return handleAlert(true, AlertColorStatuses.SUCCESS, 'Успешная регистрация. Проверьте емейл для подтверждения адреса')
  }

  return (
    <BasicPageLayout image={bgImage}>
      {isAlert ?
        <MySnackbar
          color={alertContent?.status ? alertContent?.status : AlertColorStatuses.INFO}
          icon="warning"
          title={ErrorTitleCreator(alertContent?.status)}
          content={alertContent?.message ? alertContent?.message : ''}
          open={isAlert}
          onClose={() => setIsAlert(false)}
          close={() => setIsAlert(false)}
        /> : null
      }
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Регистрация
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            для новых пользователей
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput {...email} type="email" label="Емейл" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput {...password} type="password" label="Пароль" variant="standard" fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox onChange={() => handleSetAgreed()} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Согласен&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="/policy"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                с условиями
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={() => handleRegistration()}>
                Отправить
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Уже есть аккаунт?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Войти
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicPageLayout>
  );
}

export default Registration;
