import React, {ChangeEvent, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {Card, FormControl, MenuItem, Select, TextField} from "@mui/material";
import MDBox from "../../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../components/MDButton";
import {AllOrdersQueryDto, OrdersOrderBy, OrdersSortBy, OrderStatus} from "../../../../../store/types/order.types";
import {convertEnumToArray, getOrderStatus} from "../../../../../utilities/converters";

const inputStyles = {
  width: '100%',
}

interface queryMakerInterface {
  name: AllOrdersQueryDto,
  value: string | number
}

function OrdersSearchHeader() {
  const {getAllOrders, setOrderQuery, clearOrderQuery} = useAppDispatch();
  const {orders: {skip, take, order_by, sort_by, query}} = useAppSelector(state => state);

  const [status, setStatus] = useState<OrderStatus | ''>('');
  const [id, setId] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [producer, setProducer] = useState<string>('');
  const [customer, setCustomer] = useState<string>('');

  const clearQuery = () => {
    setId(0);
    setStatus('');
    setProducer('');
    setCustomer('');
    setSearch('');
  }

  const statusFromEnum = convertEnumToArray(OrderStatus);

  function createQueryFromStates() {
    let rawQuery = ``

    search.length && (rawQuery = rawQuery.concat(`&search=${encodeURI(search)}`));
    status && (rawQuery = rawQuery.concat(`&status=${status}`));
    (id !== 0) && (rawQuery = rawQuery.concat(`&id=${id}`));
    producer.length && (rawQuery = rawQuery.concat(`&producer=${producer}`));
    customer.length && (rawQuery = rawQuery.concat(`&customer=${customer}`));

    console.log('raw Query --- ', rawQuery)
    console.log('search.length --- ', search.length)

    return rawQuery;
  }

  const handleSearch = async () => {
    const rawQuery = createQueryFromStates();

    setOrderQuery(rawQuery);

    console.log('raw Query --- ', rawQuery)
    console.log('QUERY --- ', query)

    let readyQuery = `?take=${take}&skip=${skip}&order_by=${order_by}&sort_by=${sort_by}`
    rawQuery?.length && (readyQuery = readyQuery.concat(rawQuery));

    getAllOrders(readyQuery)
  }

  useEffect(() => {
    handleSearch();
  }, [])

  return (
    <>
      <Card id="basic" style={{scrollMarginTop: 90}}>
        <MDBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Поиск по названию маршрута или предзаказа"
                name={"search"}
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="ID заказа"
                name="id"
                value={id}
                onChange={(e: ChangeEvent<HTMLInputElement>) => (typeof Number(e.target.value) === 'number' && setId(Number(e.target.value)))}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Название заказчика"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Название подрядчика"
                value={producer}
                onChange={(e) => setProducer(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl fullWidth>
                <Select
                  value={status}
                  name='status'
                  label="Статус"
                  size={'medium'}
                  style={{height: 40, width: '100%'}}
                  //@ts-ignore
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem defaultChecked value={''}>Пусто</MenuItem>
                  {
                    statusFromEnum.map((item: any) => (
                      <MenuItem value={item} key={item}>{getOrderStatus(item).title}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" mt={2} mb={2}>
            <Grid item xs={12} md={4} textAlign="center">
              <MDButton variant={'contained'} color={'info'} size={'small'} onClick={() => handleSearch()}>
                Поиск
              </MDButton>
            </Grid>
            <Grid item xs={12} md={4} textAlign="center">
              <MDButton variant={'outlined'} color={'info'} size={'small'} onClick={() => clearQuery()}>
                Сбросить
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </>
  )
}

export default OrdersSearchHeader;