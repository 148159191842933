import {FullStageInterface, ShortStageInterface} from "../reducers/stage.reducer";
import {TypeOfWorksInterface} from "../reducers/types-of-works.reducer";
import {SmallRoutesInterface} from "../reducers/route.reducer";
import {ShortDetailInterface} from "./detail.types";
import {ShortUserInterface} from "./user.types";
import {ShortPaymentInterface} from "./payment.types";

export enum StageStatuses {
  CREATED = 'CREATED',
  CONVERTED_TO_ORDER = 'CONVERTED_TO_ORDER',
  PROBLEM = 'PROBLEM',
  CLOSED_SUCCESS = 'CLOSED_SUCCESS',
  CLOSED_CANCELED_BY_MANAGER = 'CLOSED_CANCELED_BY_MANAGER',
  CLOSED_CANCELED_BY_CUSTOMER = 'CLOSED_CANCELED_BY_CUSTOMER',
  CLOSED_CANCELED_BY_PRODUCER = 'CLOSED_CANCELED_BY_PRODUCER',
}

export interface ShortStageInterface2 {
  id: number,
  createdAt: string,
  updatedAt: string,
  title: string,
  productionStart: string,
  productionEnd: string,
  status: StageStatuses,
  description: string,
  paymentType: StagePaymentType,
  productionCost?: number,
  requestCollectionEnd: string,
  requestCollectionStart: string,
  number: number,
  detail: ShortDetailInterface,
  route: SmallRoutesInterface
  typeOfWork: TypeOfWorksInterface,
  userProducer: ShortUserInterface,
  payment: ShortPaymentInterface,
}

export enum StagesSortBy {
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ID = 'id',
}

export enum OpenStagesSortBy {
  ID = 'id',
  PRODUCTION_START = 'productionStart',
}

export enum StagesOrderBy {
  DESCENDING = 'desc',
  ASCENDING = 'asc',
}

export class StagesQuery {
  take: number
  skip: number
  order_by?: StagesOrderBy
  sort_by?: StagesSortBy
  search?: string
  status?: StageStatuses
  id?: number
  route?: string
  companyProducer?: string
  detail?: string
  typeOfWork?: string
  userProducer?: string

  constructor(query: QueryForStagesInterface) {
    this.take = 10
    this.skip = 0
    this.order_by = StagesOrderBy.DESCENDING
    this.sort_by = StagesSortBy.CREATED_AT
    this.search = query.search
    this.status = query.status
    this.id = query.id
    this.route = query.route
    this.companyProducer = query.ownerCompany
    this.detail = query.detail
    this.typeOfWork = query.typeOfWork
    this.userProducer = query.ownerUser
  }

  getFullQuery(): string {
    let query = `?take=${this.take}&skip=${this.skip}&order_by=${this.order_by}&sort_by=${this.sort_by}`
    if (this.search) query += `&search=${this.search}`
    if (this.status) query += `&status=${this.status}`
    if (this.id) query += `&id=${this.id}`
    if (this.route) query += `&route=${this.route}`
    if (this.companyProducer) query += `&companyProducer=${this.companyProducer}`
    if (this.detail) query += `&detail=${this.detail}`
    if (this.typeOfWork) query += `&typeOfWork=${this.typeOfWork}`
    if (this.userProducer) query += `&userProducer=${this.userProducer}`
    return query
  }
}

export class OpenStagesQuery {
  take: number
  skip: number
  order_by?: StagesOrderBy
  sort_by?: OpenStagesSortBy
  search?: string
}

export interface QueryForStagesInterface {
  search?: string
  status?: StageStatuses
  id?: number
  ownerUser?: string
  ownerCompany?: string
  route?: string
  detail?: string
  typeOfWork?: string
}

export interface QueryForOpenStagesInterface {
  search?: string
  status?: StageStatuses
}

export enum StageReducerTypes {
  CREATE_STAGE = 'CREATE_STAGE',
  GET_STAGES = 'GET_STAGES',
  GET_OPEN_STAGES = 'GET_OPEN_STAGES',
  GET_ONE_STAGE = 'GET_ONE_STAGE',
  CHANGE_STAGE = 'CHANGE_STAGE',
  DELETE_STAGE = 'DELETE_STAGE',
  SET_SKIP = 'SET_SKIP',
  SET_TAKE = 'SET_TAKE',
  SET_QUERY = 'SET_QUERY',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ORDER_BY = 'SET_ORDER_BY',
  GET_FULL_QUERY = 'GET_FULL_QUERY',
  SET_NEW_STAGE_STATUS = 'SET_NEW_STAGE_STATUS',
  GET_ONE_OPEN_STAGES = 'GET_ONE_OPEN_STAGES',
}

export type StagesPayload = CreateStagePayload
  | GetStagesPayload
  | GetOneStagePayload
  | ChangeStagePayload
  | DeleteStagePayload
  | SetStagesSkip
  | SetStagesTake
  | SetStagesQuery
  | SetStagesSortBy
  | SetStagesOrderBy
  | GetFullQuery
  | SetNewStageStatus
  | GetOpenStagesPayload
  | GetOneOpenStagePayload

export interface CreateStagePayload {
  type: StageReducerTypes.CREATE_STAGE,
  payload: ShortStageInterface,
}

export interface GetStagesPayload {
  type: StageReducerTypes.GET_STAGES,
  payload: ShortStageInterface[],
}

export interface GetOpenStagesPayload {
  type: StageReducerTypes.GET_OPEN_STAGES,
  payload: ShortStageInterface[],
}

export interface GetOneStagePayload {
  type: StageReducerTypes.GET_ONE_STAGE,
  payload: FullStageInterface,
}

export interface ChangeStagePayload {
  type: StageReducerTypes.CHANGE_STAGE,
  payload: ShortStageInterface,
}

export interface DeleteStagePayload {
  type: StageReducerTypes.DELETE_STAGE,
  payload: ShortStageInterface,
}

export interface SetStagesSkip {
  type: StageReducerTypes.SET_SKIP,
  payload: number,
}

export interface SetStagesTake {
  type: StageReducerTypes.SET_TAKE,
  payload: number,
}

export interface SetStagesQuery {
  type: StageReducerTypes.SET_QUERY,
  payload: string,
}

export interface SetStagesSortBy {
  type: StageReducerTypes.SET_SORT_BY,
  payload: StagesSortBy,
}

export interface SetStagesOrderBy {
  type: StageReducerTypes.SET_ORDER_BY,
  payload: StagesOrderBy,
}

export interface GetFullQuery {
  type: StageReducerTypes.GET_FULL_QUERY,
}

export interface SetNewStageStatus {
  type: StageReducerTypes.SET_NEW_STAGE_STATUS,
  payload: StageStatuses
}

export interface GetOneOpenStagePayload {
  type: StageReducerTypes.GET_ONE_OPEN_STAGES,
  payload: FullStageInterface,
}

export enum StagePaymentType {
  PARTIAL_ADVANCE = 'PARTIAL_ADVANCE',
  PREPAYMENT = 'PREPAYMENT',
  POSTPAID = 'POSTPAID',
}

export interface CreateStageInterface {
  title: string
  description: string
  productionStart: string
  productionEnd: string
  customerPrice: number
  detailId: number
  paymentType: StagePaymentType
  productionCost: number
  requestCollectionEnd: string
  requestCollectionStart: string
  typeOfWorkId: number
  number: number
  routeId: number
  files: []
  images: []
}
