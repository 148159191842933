import React from 'react';
import {FullOpenStage} from "../../store/reducers/stage.reducer";
import MDTypography from "../../components/MDTypography";
import {
  convertFromStringToBeautyDateString,
  createImagePath, cutFileNameFromPath,
  getStagePaymentStatus,
  getStageStatus
} from "../../utilities/converters";
import {CircularProgress, Grid, Modal} from "@mui/material";
import {FolderTypesEnum, ImageSizesEnum} from "../../utilities/constants";
import MuiLink from "@mui/material/Link";
import MDButton from "../../components/MDButton";
import {useAppDispatch, useAppSelector} from "../../hooks/useActions";
import {AlertColorStatuses} from "../../store/types/common.types";
import {AlertDurations} from "../../store/types/alert.types";
import {CreateOfferInterface} from "../../store/types/offer.type";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FullWindowLoading from "../../components/FullWindowLoading";
import MDInput from "../../components/MDInput";
import {getUserAndCheckIfOfferExists} from "../../store/actions/user.actions";

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "visible",
  borderRadius: 2,
};

enum ButtonTypesEnum {
  BLANK = 'BLANK',
  CREATE_AS_USER = 'CREATE_AS_USER',
  CREATE_AS_COMPANY = 'CREATE_AS_COMPANY',
  CHOICE_AS_USER_OR_COMPANY = 'CHOICE_AS_USER_OR_COMPANY',
}

interface PropsInterface {
  stage: FullOpenStage
}

function OpenOrderData({stage}: PropsInterface) {
  const {auth: {isAuth}, user, alert, loading} = useAppSelector(state => state);
  const {alertActions, createOffer, getUserAndCheckIfOfferExists, setLoadingTrue, setLoadingFalse} = useAppDispatch();

  const [open, setOpen] = React.useState<boolean>(false);
  const [isCompany, setIsCompany] = React.useState<boolean>(false);
  const [offerTitle, setOfferTitle] = React.useState<string>('');
  const [conterPrice, setConterPrice] = React.useState<string>('');
  const [buttonType, setButtonType] = React.useState<ButtonTypesEnum>(ButtonTypesEnum.BLANK);

  const handleCreateOfferClick = async () => {
    if (!isAuth) {
      return alertActions(AlertColorStatuses.WARNING, 'Для заявки необходимо авторизоваться', AlertDurations.THREE_SECONDS)
    }

    setLoadingTrue();
    const res = await getUserAndCheckIfOfferExists(stage?.id);
    // @ts-ignore
    const userPrerequisites = res.user;
    setLoadingFalse();

    // Если пользователь уже откликнулся на эту заявку, то не даем ему откликаться еще раз
    // @ts-ignore
    if (res?.offers?.id) {
      return alertActions(AlertColorStatuses.ERROR, 'Вы уже откликнулись на данную заявку', AlertDurations.FIVE_SECONDS)
    }

    // Если у пользователя нет компании, и у него нет типов работ, которые совпадают с типом работ заявки или у пользователя есть компания, но типы работ не совпадают нигде
    // @ts-ignore
    if ((!userPrerequisites?.ownCompany || userPrerequisites?.ownCompany?.id === 0) && !userPrerequisites?.typesOfWorks.some((typeOfWork) => typeOfWork.id === stage?.typeOfWork.id)
      // @ts-ignore
      || (userPrerequisites?.ownCompany?.id !== 0 && !userPrerequisites?.typesOfWorks.some((typeOfWork) => typeOfWork.id === stage?.typeOfWork.id) && !userPrerequisites?.ownCompany?.typesOfWorks.some((typeOfWork) => typeOfWork.id === stage?.typeOfWork.id))) {
      return alertActions(AlertColorStatuses.WARNING, `Для отклика на заявку у вас должен быть соответствующий заявке тип выполняемых работ: ${stage.typeOfWork.title}`, AlertDurations.FIVE_SECONDS)
    }

    // Создаем через пользователя - Если у пользователя нет компании, но у него есть типы работ, которые совпадают с типом работ заявки
    //@ts-ignore
    if ((userPrerequisites?.ownCompany?.id === 0 || !userPrerequisites?.ownCompany?.typesOfWorks?.some(typeOfWork => typeOfWork.id === stage?.typeOfWork.id)) && userPrerequisites?.typesOfWorks.some((typeOfWork) => typeOfWork.id === stage?.typeOfWork.id)) {
      setButtonType(ButtonTypesEnum.CREATE_AS_USER);
      console.log('create as user');
      return setOpen(true);
    }

    // Создаем через компанию - Если у пользователя есть компания, но у него самого нет типов работ, которые совпадают с типом работ заявки, а у его компании есть
    // @ts-ignore
    if (userPrerequisites?.ownCompany?.id !== 0 && !userPrerequisites?.typesOfWorks.some((typeOfWork) => typeOfWork.id === stage?.typeOfWork.id) && userPrerequisites?.ownCompany?.typesOfWorks.some((typeOfWork) => typeOfWork.id === stage?.typeOfWork.id)) {
      setButtonType(ButtonTypesEnum.CREATE_AS_COMPANY);
      return setOpen(true);
    }

    // Даем возможность выбрать от кого подать заявку - Если у пользователя есть компания, и тип работ совпадает с профилем его и с его компаниино
    // @ts-ignore
    if (userPrerequisites?.ownCompany?.id !== 0 && userPrerequisites?.typesOfWorks.some((typeOfWork) => typeOfWork.id === stage?.typeOfWork.id) && userPrerequisites?.ownCompany?.typesOfWorks.some((typeOfWork) => typeOfWork.id === stage?.typeOfWork.id)) {
      setButtonType(ButtonTypesEnum.CHOICE_AS_USER_OR_COMPANY);
      return setOpen(true);
    }

    return alertActions(AlertColorStatuses.ERROR, `Неопределенная ошибка`, AlertDurations.FIVE_SECONDS)
  }
  const handleClose = () => {
    setOpen(false);
  }
  async function handleCreateOffer(isCompanyModal: boolean) {

    const newOffer: CreateOfferInterface = {
      title: offerTitle,
      detailId: stage.detail.id,
      stageId: stage.id,
      isCompany: isCompanyModal,
    }

    //@ts-ignore
    isCompanyModal ? newOffer['producerCompanyId'] = user?.ownCompany?.id : newOffer['producerUserId'] = user.id;
    conterPrice && (newOffer.conterPrice = Number(conterPrice));

    const res: any = await createOffer(newOffer);
    if (res?.error) return alertActions(AlertColorStatuses.ERROR, res?.message, AlertDurations.FIVE_SECONDS);

    alertActions(AlertColorStatuses.SUCCESS, res?.message, AlertDurations.THREE_SECONDS);
    return handleClose();
  }

  function handleConterPriceChange(e: React.ChangeEvent<HTMLInputElement>) {
      const value = e.target.value.replace(/\D/g, "");
      setConterPrice(value);
  }

  return (
    <div>
      {
        loading.status && <FullWindowLoading size={80}/>
      }
      <Grid container >
        <Grid item xs={12}>
          <MDTypography variant="body2" component="h2" color="inherit" fontWeight="bold">
            Заказ: №{stage?.id}
          </MDTypography>
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Grid container spacing={2} direction="row" justifyContent="space-evenly" alignItems="center" mt={4} mb={4}>
              <Grid item md={12} textAlign={'center'}>
                <Typography variant={'h6'}>Можете добавить комментрий - максимум 200 символов ({
                  offerTitle.length
                })</Typography>
                <MDInput
                  value={offerTitle}
                  fullWidth
                  minRows={2}
                  maxRows={4}
                  multiline
                  onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => {
                    offerTitle.length < 200
                      ? setOfferTitle(e.target.value)
                      : (
                        e.target.value.length < 200 ? setOfferTitle(e.target.value) : alertActions(AlertColorStatuses.WARNING, 'Максимальное количество символов 200', AlertDurations.THREE_SECONDS)
                      )
                  }}
                  placeholder={'Комментрий к заявке'}
                />
              </Grid>
              <Grid item md={12} textAlign={'center'}>
                <Typography variant={'h6'}>Можете предложить свою цену (руб.)</Typography>
                <MDInput
                  value={conterPrice}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConterPriceChange(e)}
                  placeholder={'Ваша цена'}
                />
              </Grid>
            </Grid>

            {
              buttonType === ButtonTypesEnum.CHOICE_AS_USER_OR_COMPANY &&
                <Grid container spacing={2} direction="row" justifyContent="space-evenly" alignItems="center" mb={4}>
              <Grid item md={12} textAlign={'center'}>
                <Typography variant={'h6'}>Выберите от кого подать заявку</Typography>
              </Grid>
              <Grid item >
                <MDButton
                  variant={'contained'}
                  color={isCompany ? 'secondary' : 'success'}
                  disabled={isCompany}
                  onClick={() => handleCreateOffer(false)}
                >
                  Подать заявку от частного лица
                </MDButton>
              </Grid>
              <Grid item md={1} justifyContent={'center'} justifyItems={'center'}>
                <Switch checked={isCompany} color={'info'} onChange={() => setIsCompany(!isCompany)}/>
              </Grid>
              <Grid item >
                <MDButton
                  variant={'contained'}
                  color={!isCompany ? 'secondary' : 'success'}
                  disabled={!isCompany}
                  onClick={() => handleCreateOffer(true)}
                >
                  Подать заявку от Компании
                </MDButton>
              </Grid>
            </Grid>
            }

            {
              buttonType === ButtonTypesEnum.CREATE_AS_COMPANY &&
                <Grid container spacing={2} direction="row" justifyContent="space-evenly" alignItems="center" mb={4}>
                  <Grid item md={12} textAlign={'center'}>
                    <Typography variant={'h6'}>Вы можете подать заявку от вашей компании</Typography>
                  </Grid>
                  <Grid item >
                    <MDButton
                      variant={'contained'}
                      color={'success'}
                      onClick={() => handleCreateOffer(true)}
                    >
                      Подать заявку от Компании
                    </MDButton>
                  </Grid>
                </Grid>
            }

            {
              buttonType === ButtonTypesEnum.CREATE_AS_USER &&
                <Grid container spacing={2} direction="row" justifyContent="space-evenly" alignItems="center" mb={4}>
                  <Grid item md={12} textAlign={'center'}>
                    <Typography variant={'h6'}>Вы можете подать заявку как частное лицо</Typography>
                  </Grid>
                  <Grid item >
                    <MDButton
                      variant={'contained'}
                      color={'success'}
                      onClick={() => handleCreateOffer(false)}
                    >
                      Подать заявку
                    </MDButton>
                  </Grid>
                </Grid>
            }

          </Box>
        </Modal>

        <Grid item xs={12} mb={2}>
          <MDTypography
            variant="heading6"
            component="h2"
            color="inherit"
            fontWeight="regular"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {stage?.title}
          </MDTypography>
        </Grid>

        <div style={{height: 1, backgroundColor: 'lightgray', width: '100%'}} />

        <Grid item xs={12} mt={2} mb={2}>
          <Grid container flex="auto" justifyContent="space-around">
            <Grid item xs={6} md={4}>
              <MDTypography variant="overline" component="h4" color="inherit" fontWeight="regular">
                Статус: <strong>{getStageStatus(stage?.status).ruStatus}</strong>
              </MDTypography>
            </Grid>
            <Grid item xs={6} md={4}>
              <MDTypography variant="overline" component="h4" color="inherit" fontWeight="regular">
                Тип оплаты: <strong>{getStagePaymentStatus(stage?.paymentType)}</strong>
              </MDTypography>
            </Grid>
            <Grid item xs={6} md={4}>
              <MDTypography variant="overline" component="h4" color="inherit" fontWeight="regular">
                Тип работы: <strong>{stage?.typeOfWork?.title}</strong>
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>

        <div style={{height: 1, backgroundColor: 'lightgray', width: '100%'}} />

        <Grid item xs={12} md={12} mt={4} mb={4}>
          <MDTypography variant="body2" component="p" color="inherit" fontWeight="regular">
          <span style={{fontSize: 12}}>Описание:</span><br/>{stage.description}
          </MDTypography>
        </Grid>

        <div style={{height: 1, backgroundColor: 'lightgray', width: '100%'}} />

        <Grid item xs={12} mt={2} mb={2}>
          <Grid container flex="auto" justifyContent="space-around">
            <Grid item xs={6} mt={1}>
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                Старт производства: <strong>{convertFromStringToBeautyDateString(stage.productionStart)}</strong>
              </MDTypography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                Конец производства: <strong>{convertFromStringToBeautyDateString(stage.productionEnd)}</strong>
              </MDTypography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                Дата сборки: <strong>{convertFromStringToBeautyDateString(stage.requestCollectionStart)}</strong>
              </MDTypography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                Дата завершения сборки: <strong>{convertFromStringToBeautyDateString(stage.requestCollectionEnd)}</strong>
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>

        <div style={{height: 1, backgroundColor: 'lightgray', width: '100%'}} />

        <Grid item xs={12} mt={2} mb={2}>
          <Grid container flex="auto" justifyContent="space-around">
            <Grid item xs={6} mt={1}>
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                Материал детали: <strong>{stage.detail.material.title}</strong>
              </MDTypography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                Деталь: <strong>{stage.detail.title}</strong>
              </MDTypography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                форма: <strong>{stage.detail.shape.title}</strong>
              </MDTypography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                кол-во: <strong>{stage.detail.quantity}</strong>
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>

        <div style={{height: 1, backgroundColor: 'lightgray', width: '100%'}} />

        <Grid item xs={12} mt={2} mb={2}>
          <Grid container flex="auto" justifyContent="space-around">
            <Grid item xs={12} >
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                {stage?.images?.length > 0 ? 'Прикрепленные изображения' : 'Нет прикрепленных изображений'}
              </MDTypography>
            </Grid>
              {
                stage?.images?.length > 0 && stage.images.map((image: {id: number, fileName: string}, index: number) => (
                  <Grid item xs={6} md={3} lg={2} key={index}>
                    <img
                      style={{width: '100%', height: '100%'}}
                      src={createImagePath(FolderTypesEnum.ORDER, stage?.route?.preOrder?.ownerUser?.id, ImageSizesEnum.X500, image?.fileName)}
                      alt="Маркет-Деталь"
                    />
                  </Grid>
                ))
              }
          </Grid>
        </Grid>

        <div style={{height: 1, backgroundColor: 'lightgray', width: '100%'}} />

        <Grid item xs={12} mt={2}>
          <Grid container flex="auto" justifyContent="space-around">
            <Grid item xs={12} >
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                {stage?.files?.length > 0 ? 'Прикрепленные файлы' : 'Нет прикрепленных файлов'}
              </MDTypography>
            </Grid>
            {
              stage?.files?.length > 0 && stage.files.map((file: {id: number, fileName: string}, index: number) => (
                <Grid item xs={6} md={3} lg={2} key={index}>
                  <MuiLink href={`https://localhost:5045/${file.fileName}`}>
                    <MDTypography variant="caption" component="p" color="inherit" fontWeight="regular">
                      {cutFileNameFromPath(file.fileName)}
                    </MDTypography>
                  </MuiLink>
                </Grid>
              ))
            }
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} mb={2}>
          <Grid container flex="auto" justifyContent="center">
            <Grid item xs={12} mt={1}>
              <MDTypography variant="overline" component="p" color="inherit" fontWeight="regular">
                Стоимость производства: <strong>{stage?.productionCost} руб.</strong>
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Grid item xs={12} mt={8}>
            <Grid container={true} justifyContent="center">
              <MDButton
                color="primary"
                onClick={() => handleCreateOfferClick()}
              >
                Подать заявку
              </MDButton>
            </Grid>
          </Grid>
        </Grid>


      </Grid>
    </div>
  );
}

export default OpenOrderData;