import {OneProjectInterface, ProjectsInterface} from "../reducers/project.reducer";

export enum ProjectReducerTypes {
    SET_PROJECTS_SUCCESS = 'SET_PROJECTS_SUCCESS',
    SET_ONE_PROJECT_SUCCESS = 'SET_ONE_PROJECT_SUCCESS',
    SET_PROJECTS_ERROR = 'SET_PROJECTS_ERROR',

}

export type ProjectPayload = SuccessProjectsPayload
    | SuccessOneProjectPayload
    | ErrorProjectsPayload


interface SuccessProjectsPayload {
    type: ProjectReducerTypes.SET_PROJECTS_SUCCESS,
    payload: ProjectsInterface[]
}

interface SuccessOneProjectPayload {
    type: ProjectReducerTypes.SET_ONE_PROJECT_SUCCESS,
    payload: OneProjectInterface
}

interface ErrorProjectsPayload {
    type: ProjectReducerTypes.SET_PROJECTS_ERROR,
}
