import { Navigate } from "react-router-dom";
import {TypographyProps} from "@mui/material";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/useActions";
import FullWindowLoading from "./FullWindowLoading";

interface Props extends TypographyProps {
  invisibleIfAuth?: boolean;
  invisibleIfNotAuth?: boolean,
  invisibleIfNotAdmin?: boolean,
  children: JSX.Element;
}

const Protected = ({invisibleIfAuth, invisibleIfNotAuth, invisibleIfNotAdmin, children }: Props) => {
  const {auth: {isAuth, user}} = useAppSelector(state => state)

  const route = () => {
    if (!isAuth && invisibleIfNotAuth) {
      return <Navigate to="/" replace />;
    }

    if (isAuth && invisibleIfAuth) {
      return <Navigate to="/pages/profile/profile-overview" replace />;
    }

    if (isAuth && invisibleIfNotAdmin && (user?.roles && !user?.roles.includes('ADMIN', 'MANAGER'))) {
      return <Navigate to="/pages/profile/profile-overview" replace />;
    }
  }

  useEffect(() => {
    // setIsLoading(true);
    // mainRules().then(() => setIsLoading(false));
    route();
  }, []);

  return children;
};
export default Protected;