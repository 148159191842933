import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../dashboard/DashboardLayout";
import MDBox from "../../../components/MDBox";
import {Box, Grid, Modal} from "@mui/material";
import Card from "@mui/material/Card";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import DataTable from "./table/index";
import Footer from "../../../examples/Footer";
import {useAppDispatch, useAppSelector} from "../../../hooks/useActions";
import {convertFromStringToBeautyDateString, getOfferStatus} from "../../../utilities/converters";


function MyOffers() {

  const {offers, skip, take, sort_by, order_by} = useAppSelector(state => state.offers);
  const {getMyOffers} = useAppDispatch();

  useEffect(() => {
    getMyOffers({query: ''});
  }, []);

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item xs={12} md={12}>
                <MDTypography variant="h5" fontWeight="medium">
                  Мои отклики на заявки
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Список всех размещенных мной откликов на заказы на промышленную обработку
                </MDTypography>
              </Grid>
              {/*<Grid item xs={6} md={4}>*/}
              {/*  <MDButton variant="gradient" color="info" onClick={() => handleCreateModal()}>*/}
              {/*    Создать предзаказ*/}
              {/*  </MDButton>*/}
              {/*</Grid>*/}
            </Grid>
          </MDBox>

          <DataTable />

        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MyOffers;