// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "../../../dashboard/DashboardLayout";
import Footer from "examples/Footer";
import UsersTable from "./Table";

function AdminUsers(): JSX.Element {
  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="button" color="text">
                Управление доступом пользователей: статус, роли, права
              </MDTypography>
            </MDBox>
            {/*<DataTable table={tableHeadersData} canSearch />*/}

            <UsersTable />

          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdminUsers;
