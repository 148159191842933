export enum TypesOfWorksReducerTypes {
  SET_TYPES_OF_WORKS_SUCCESS = 'SET_TYPES_OF_WORKS_SUCCESS',
  SET_TYPES_OF_WORKS_ERROR = 'SET_TYPES_OF_WORKS_ERROR',
  CLEAR_TYPES_OF_WORKS = 'CLEAR_TYPES_OF_WORKS',
}

export type TypesOfWorksPayload = SuccessTypesOfWorksPayload
  | ErrorTypesOfWorksPayload
  | ClearTypesOfWorksPayload


interface SuccessTypesOfWorksPayload {
  type: TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_SUCCESS,
  payload: {
    id: number,
    title: string,
    description: string,
  }[]
}

interface ErrorTypesOfWorksPayload {
  type: TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_ERROR,
}

interface ClearTypesOfWorksPayload {
  type: TypesOfWorksReducerTypes.CLEAR_TYPES_OF_WORKS,
}

export interface ChangeTypesOfWorksInterface {
  addType?: number
  removeType?: number
}

