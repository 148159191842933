import React from 'react';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { Table } from '@mui/material';
import UsersTableFooter from "./UsersTableFooter";
import UsersTableHeader from "./UsersTableHeader";
import UsersTableBody from "./UsersTableBody";

function UsersTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>

          <UsersTableHeader />

          <UsersTableBody />

        </TableBody>

        <UsersTableFooter />

      </Table>
    </TableContainer>
  );
}

export default UsersTable;