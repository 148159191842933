import {createPaymentInterface, PaymentActionTypes, PaymentPayload} from "../types/payment.types";
import {Dispatch} from "redux";
import {AlertPayload} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {OrderActionTypes, OrderPayload} from "../types/order.types";

export const createPayment = (data: createPaymentInterface) => {
  return async (dispatch: Dispatch<PaymentPayload | AlertPayload | OrderPayload>) => {
    try {
      const res = await $api.post('/payment/create', data);
      dispatch({type: PaymentActionTypes.GET_ONE_PAYMENT, payload: res.data})
      return dispatch({type: OrderActionTypes.SET_ORDER_PAYMENT, payload: res.data});
    } catch (err: unknown) {
      return axiosErrorMessageHandler(err);
    }
  }
}

