import { ReactNode } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {Grid} from "@mui/material";

const data: {title: string, description: string}[] = [
  {
    title: "Размещение заказов",
    description: "Заказчикам предоставляется простой и понятный интерфейс для размещения своих заказов с подробным описанием требований и условий выполнения.",
  },
  {
    title: "Сервис",
    description: "МаркетДеталь  предлагает инновационный сервис по разбивке заказов на простые операции: после размещения заказа, приложение автоматически разбивает его на простые и понятные операции, которые могут быть выполнены отдельными исполнителями. Это упрощает процесс выполнения заказа и делает его более прозрачным для всех сторон. Операции от различных заказчиков группируются в заказ, который размещается на предприятиях, специализирующихся на выполнении именно этой операции и предлагающих минимальную цену и сжатые сроки изготовления при высоком качестве."
  },
  {
    title: "Удобство",
    description: "Размещение операций на доске объявлений: каждая простая операция из разбитого заказа размещается на внутренней доске объявлений приложения, где исполнители могут выбрать и взять на выполнение интересующую их операцию или несколько операций."
  },
  {
    title: "Оплата",
    description: "Безопасная система оплаты: МаркетДеталь предлагает безопасную систему оплаты, которая гарантирует, что исполнители получат оплату за выполненную работу вовремя и без проблем."
  },
  {
    title: "Репутация",
    description: "Рейтинг и отзывы: пользователи могут оставлять отзывы и оценивать работу друг друга, что помогает создать надежный и проверенный пул исполнителей и заказчиков."
  },
  {
    title: "Уведомления",
    description: "В МаркетДеталь предусмотрена система уведомлений, которая позволяет заказчикам быть в курсе актуальных изменений в выполнении операций своего заказа."
  },
  {
    title: "Специализация",
    description: "Исполнители специализирующихся на выполнении отдельных видов технологических операций получают возможность получать ранее недоступные заказы. "
  }
]

function Index(): JSX.Element {

  const getOneItem = (item: {title: string, description: string}, index: number) => (
    <Grid sx={{ mt: 4 }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={0.5}
        pb={1}
        px={3}
        lineHeight={1}
      >
        <MDBox color="text" display="flex" alignItems="center">
          {/*<Icon color="inherit" sx={{ m: 0.5 }}>*/}
          {/*  place*/}
          {/*</Icon>*/}
          <MDTypography variant="button" fontWeight="light" color="text" sx={{fontWeight: 'bold'}} >
            {index + 1} {" - "} {item.title}
          </MDTypography>
        </MDBox>
      </MDBox>

      <Divider />

      <MDBox textAlign="center" pt={3} px={3}>
        {/*<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>*/}
        {/*  {item.title}*/}
        {/*</MDTypography>*/}
        <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          {item.description}
        </MDTypography>
      </MDBox>

    </Grid>
  )

  return (
    <MDBox mt={12}>
      <Divider />
      <MDTypography variant="body2" align="center" color="text" sx={{mt: 4, mb: 8}}>
        МаркетДеталь фактически работает как «Виртуальный  супер-завод» объединяющий под своей оболочкой различные производственные предприятия выстроенные в производственную цепочку.  Заказчик получает сервис под ключ, все технические и административные вопросы берет на себя МаркетДеталь.
      </MDTypography>
      {data.map((oneItem, index) => getOneItem(oneItem, index))}
    </MDBox>
  );
}

export default Index;
