import moment from 'moment';
import 'moment/locale/ru';
import {Constants, FolderTypesEnum, ImageSizesEnum} from "./constants";
import {PreOrderStatus} from "../store/types/pre-order.types";
import {RouteStatus} from "../store/types/route.type";
import {StagePaymentType, StageStatuses} from "../store/types/stage.types";
import {StageBoxColors, StageStatusColors} from "../pages/admin/Routes/ArrowBox";
import {OfferStatuses} from "../store/types/offer.type";
import {OrderStatus} from "../store/types/order.types";

moment.locale('ru');

export const convertFromDateToString = (date: Date): string => {
    return moment(date).format("DD MMM YYYY");
}

export const convertFromStringToBeautyDateString = (stringDate: string): string => {
    const date = new Date(stringDate);
    return moment(date).format("DD MMM YYYY");
}

export const countDifferenceBetweenDates = (lastDate: string, firstDate: string): number => {
    const date1 = firstDate ? new Date(firstDate) : new Date();
    const date2 = new Date(lastDate);
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

export const convertFromStringToBeautyDateTimeString = (stringDate: string): string => {
    const date = new Date(stringDate);
    return moment(date).format("DD MMM YY, hh:mm:ss ");
}

export const createImagePath = (folderType: FolderTypesEnum, userId: number, size: ImageSizesEnum, fileName: string) => {
    return `${Constants.backendURL}${folderType}/${userId}/${size}/${fileName}`;
}

export const getRouteStatus = (text: RouteStatus) => {
    switch (text) {
        case RouteStatus.CREATED:
            return 'СОЗДАН';
        case RouteStatus.ACTIVE:
            return 'В РАБОТЕ';
        case RouteStatus.ARCHIVE:
            return 'АРХИВ';
        case RouteStatus.DECLINE:
            return 'ОТМЕНЕН';
        default:
            return text;
    }
}

export const getRouteStatusColors = (text: RouteStatus) => {
    switch (text) {
        case RouteStatus.CREATED:
            return 'info';
        case RouteStatus.ACTIVE:
            return 'success';
        case RouteStatus.ARCHIVE:
            return 'warning';
        case RouteStatus.DECLINE:
            return 'error';
        default:
            return text;
    }
}

export const getPreOrderStatus = (status: PreOrderStatus) => {
    console.log('STATUS CONVERTER --- ', status)
    switch (status) {
        case PreOrderStatus.ACCEPTED:
            return 'ПРИНЯТ';
        case PreOrderStatus.ORDERED:
            return 'РАЗМЕЩЕН';
        case PreOrderStatus.CREATED:
            return 'СОЗДАН';
        case PreOrderStatus.DECLINED:
            return 'ОТМЕНЕН';
        case PreOrderStatus.PROCESSING:
            return 'В РАБОТЕ';
        case PreOrderStatus.RECLARIFICATION:
            return 'УТОЧНЯЕТСЯ';
        case PreOrderStatus.ARCHIVED:
            return 'АРХИВ';
        default:
            return status;
    }
}

export const getOfferStatus = (status: OfferStatuses) => {
    switch (status) {
        case OfferStatuses.CREATED:
            return 'СОЗДАН';
        case OfferStatuses.ACCEPTED:
            return 'ПРИНЯТ';
        case OfferStatuses.PROCESSING:
            return 'В РАБОТЕ';
        case OfferStatuses.EDITED:
            return 'ИЗМЕНЕН';
        case OfferStatuses.ARCHIVED:
            return 'АРХИВ';
    }
}

export const getStageStatus = (status: StageStatuses): {color: StageStatusColors, ruStatus: string} => {
    switch (status) {
        case StageStatuses.CREATED:
            return { color: StageStatusColors.BLUE, ruStatus: 'СОЗДАН'};
        case StageStatuses.CONVERTED_TO_ORDER:
            return { color: StageStatusColors.PURPLE, ruStatus: 'ПЕРЕДАН В ЗАКАЗ'};
        case StageStatuses.CLOSED_SUCCESS:
            return { color: StageStatusColors.GREEN, ruStatus: 'УСПЕШНО ЗАКРЫТ'};
        case StageStatuses.CLOSED_CANCELED_BY_CUSTOMER:
            return { color: StageStatusColors.GREY, ruStatus: 'ЗАКРЫТ ЗАКАЗЧИКОМ'};
        case StageStatuses.CLOSED_CANCELED_BY_MANAGER:
            return { color: StageStatusColors.GREY, ruStatus: 'ЗАКРЫТ МЕНЕДЖЕРОМ'};
        case StageStatuses.CLOSED_CANCELED_BY_PRODUCER:
            return { color: StageStatusColors.GREY, ruStatus: 'ЗАКРЫТ ПОДРЯДЧИКОМ'};
        case StageStatuses.PROBLEM:
            return { color: StageStatusColors.RED, ruStatus: 'НАЙДЕНА ПРОБЛЕМА'};
        default:
            return status;
    }
}

export const getStagePaymentStatus = (paymentType: StagePaymentType): string => {
    switch (paymentType) {
        case StagePaymentType.PARTIAL_ADVANCE:
            return 'Частичная предоплата';
        case StagePaymentType.PREPAYMENT:
            return 'Полная предоплата';
        case StagePaymentType.POSTPAID:
            return 'Постоплата';
        default: return 'Не указан';
    }
}

export const getPreOrderStatusColor = (status: PreOrderStatus) => {
    switch (status) {
        case PreOrderStatus.CREATED:
            return 'info';
        case PreOrderStatus.RECLARIFICATION:
            return 'warning';
        case PreOrderStatus.ACCEPTED:
            return 'success';
        case PreOrderStatus.DECLINED:
            return 'error';
        case PreOrderStatus.PROCESSING:
            return 'info';
        case PreOrderStatus.ORDERED:
            return 'success';
        case PreOrderStatus.ARCHIVED:
            return 'grey';
        default:
            return 'grey';
    }
}

export const getOrderStatus = (status: OrderStatus): {title: string, color: string} => {

    const data = {
        CREATED: {title: 'СОЗДАН', color: 'lightblue'},
        NEEDS_PAYMENT_FROM_CUSTOMER: {title: 'ЖДЕМ ОПЛАТУ ОТ ЗАКАЗЧИКА', color: 'orange'},
        NEEDS_TO_PAY_TO_PRODUCER: {title: 'НЕОБХОДИМО ОПЛАТИТЬ ПОДРЯДЧИКУ', color: 'orange'},
        PRODUCTION: {title: 'В ПРОИЗВОДСТВЕ', color: 'darkgreen'},
        PAUSED: {title: 'НА ПАУЗЕ', color: 'blue'},
        PROBLEM: {title: 'ПРОБЛЕМА', color: 'red'},
        COMPLETE: {title: 'УСПЕШНО ЗАВЕРШЕН', color: 'green'},
        ARCHIVE: {title: 'АРХИВ', color: 'grey'},
        CANCELED_BY_CUSTOMER: {title: 'ОТМЕНЕН ЗАКАЗЧИКОМ', color: 'red'},
        CANCELED_BY_PRODUCER: {title: 'ОТМЕНЕН ПОДРЯДЧИКОМ', color: 'red'},
        CANCELED_BY_MANAGER: {title: 'ОТМЕНЕН МЕНЕДЖЕРОМ', color: 'red'},
    }

    return data[status] ?? {title: 'НЕ ОПРЕДЕЛЕН', color: 'error'};
}

export const convertCyrillicToLatin = (text: string) => {
    const matrixRus = ['а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ы', 'э', 'ю', 'я', 'ь', 'ъ', ' ']
    const matrixEng = ['a', 'b', 'v', 'g', 'd', 'e', 'yo', 'zh', 'z', 'i', 'yi', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f', 'h', 'ts', 'ch', 'sh', 'sch', 'y', 'ye', 'yu', 'ya', '', '', '_']
    let array = [];
    const lowerCase = text.toLowerCase();
    for (let i = 0; i < lowerCase.length; i++) {
        const index = matrixRus.indexOf(lowerCase[i]);
        (index !== -1) ? array.push(matrixEng[index]) : array.push(lowerCase[i]);
    }
    return array.join('')
}

export const cutFileNameFromPath = (path: string): string => {
    const array = path.split("/");
    return array[array.length - 1];
}

export const convertEnumToArray = (someEnum: any) => {
    let newArray = [];
    // @ts-ignore
    for(let key: any in someEnum){
        newArray.push(someEnum[key]);
    }
    return newArray;
}