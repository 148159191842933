import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import FormField from "../../../../../layouts/pages/account/components/FormField";
import React, {ChangeEvent, useEffect, useState} from "react";
import MDButton from "../../../../../components/MDButton";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {CompanyProfile} from "../../../../../store/types/company.types";
import {Box, Chip, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Stack} from "@mui/material";
import MDAvatar from "../../../../../components/MDAvatar";
import editAvatar from "../../../../../assets/img/edit-avatar.jpg";
import noAvatar from "../../../../../assets/img/no-avatar.svg";
import {changeCompanyLogo} from "../../../../../store/actions/company.actions";
import {TypesOfWorksStateInterface} from "../../../../../store/reducers/types-of-works.reducer";
import AddIcon from '@mui/icons-material/Add';
import {alertActions} from "../../../../../store/actions/alert.actions";
import {AlertColorStatuses} from "../../../../../store/types/common.types";
import {AlertDurations} from "../../../../../store/types/alert.types";
import ProfileSelectorForm from "../../../components/ProfileSelectorForm/ProfileSelectorForm";
import Card from "@mui/material/Card";
import TypesOfWorks from "../../../components/TypesOfWorks/TypesOfWorks";
import ListOfPickedTypesOfWorks from "../../../components/TypesOfWorks/ListOfPickedTypesOfWorks";

const scrollableModalBlockStyle = {
  height: '100%',
  margin: '0em',
  overflowY: 'auto',
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90%',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "visible"
};

interface Props {
  setEditCompanyInfo?: (newState: boolean) => void,
  setCreateCompany?: (newState: boolean) => void,
  isCompany: boolean
}

function CompanyForm({setEditCompanyInfo, setCreateCompany, isCompany}: Props): JSX.Element {

  const {company, typesWorks, user: {ownCompany}} = useAppSelector(state => state);
  const {getCompany, alertActions, createCompany, changeCompany, changeCompanyLogo, getTypesOfWorks, changeTypesOfWorks} = useAppDispatch();

  const [typesOfWorksModal, setTypesOfWorksModal] = React.useState(false);
  const [offerToChangeLogo, setOfferToChangeLogo] = useState(false);
  const [uploadLogoButton, setUploadLogoButton] = useState(false);
  const [newLogo, setNewLogo] = useState(null);

  const [values, setValues] = useState({
    profile: CompanyProfile.BLANK,
    name: '',
    description: '',
    inn: '',
    ogrnognip: '',
    juridicalAddressId: 0,
    juridicalAddressCountry: 'Россия',
    juridicalAddressPostalCode: '',
    juridicalAddressState: '',
    juridicalAddressCity: '',
    juridicalAddressStreet: '',
    juridicalAddressHouseBuilding: '',
    juridicalAddressOfficeApartment: '',
    deliveryAddressId: 0,
    deliveryAddressCountry: 'Россия',
    deliveryAddressPostalCode: '',
    deliveryAddressState: '',
    deliveryAddressCity: '',
    deliveryAddressStreet: '',
    deliveryAddressHouseBuilding: '',
    deliveryAddressOfficeApartment: '',
    officialPersonForDocs: '',
    officialPersonForDocsPhone: '',
    contactPerson: '',
    contactPersonPhone: '',
    bankDetailsId: 0,
    bankName: '',
    bankAccount: '',
    bankCorrAccount: '',
    bankBik: '',
    logo: '',
    managers: [],
    typesOfWorks: [],
  });

  const checkAndFillCompany = async (needToFetchCompanyData: boolean) => {
    let comp;
    let types;
    if (needToFetchCompanyData) {
      comp = await getCompany();
      types = await getTypesOfWorks();
    }
    if (company?.id !== 0) {
      setValues({
        ...values,
        profile: company?.profile,
        name: company?.name,
        description: company?.description,
        inn: company?.inn,
        ogrnognip: company?.ogrnognip,
        juridicalAddressId: company.juridicalAddress?.id,
        //juridicalAddressCountry: company?.juridicalAddress?.country,
        juridicalAddressPostalCode: company?.juridicalAddress?.postalCode,
        juridicalAddressState: company?.juridicalAddress?.state,
        juridicalAddressCity: company?.juridicalAddress?.city,
        juridicalAddressStreet: company?.juridicalAddress?.street,
        juridicalAddressHouseBuilding: company?.juridicalAddress?.houseBuilding,
        juridicalAddressOfficeApartment: company?.juridicalAddress?.officeApartment,
        deliveryAddressId: company.deliveryAddress?.id,
        //deliveryAddressCountry: company?.deliveryAddress?.country,
        deliveryAddressPostalCode: company?.deliveryAddress?.postalCode,
        deliveryAddressState: company?.deliveryAddress?.state,
        deliveryAddressCity: company?.deliveryAddress?.city,
        deliveryAddressStreet: company?.deliveryAddress?.street,
        deliveryAddressHouseBuilding: company?.deliveryAddress?.houseBuilding,
        deliveryAddressOfficeApartment: company?.deliveryAddress?.officeApartment,
        officialPersonForDocs: company?.officialPersonForDocs,
        officialPersonForDocsPhone: company?.officialPersonForDocsPhone,
        contactPerson: company?.contactPerson,
        contactPersonPhone: company?.contactPersonPhone,
        bankDetailsId: company.bankDetails?.id,
        bankName: company?.bankDetails?.bankName,
        bankAccount: company?.bankDetails?.bankAccount,
        bankCorrAccount: company?.bankDetails?.bankCorrAccount,
        bankBik: company?.bankDetails?.bankBik,
        logo: company?.logo,
        managers: company?.managers,
        typesOfWorks: company.typesOfWorks!,
      })
    }
    return {comp, types}
  }

  useEffect(() => {
    checkAndFillCompany(true)
  }, [])

  useEffect(() => {
    checkAndFillCompany(false);
  }, [company]);

  const handleTypesOfWorksModalOpen = () => {
    (typesWorks.length < 2) && getTypesOfWorks();
    setTypesOfWorksModal(true);
  }

  const {
    profile,
    name,
    description,
    inn,
    ogrnognip,
    juridicalAddressId,
    juridicalAddressCountry,
    juridicalAddressPostalCode,
    juridicalAddressState,
    juridicalAddressCity,
    juridicalAddressStreet,
    juridicalAddressHouseBuilding,
    juridicalAddressOfficeApartment,
    deliveryAddressId,
    deliveryAddressCountry,
    deliveryAddressPostalCode,
    deliveryAddressState,
    deliveryAddressCity,
    deliveryAddressStreet,
    deliveryAddressHouseBuilding,
    deliveryAddressOfficeApartment,
    officialPersonForDocs,
    officialPersonForDocsPhone,
    contactPerson,
    contactPersonPhone,
    bankDetailsId,
    bankName,
    bankAccount,
    bankCorrAccount,
    bankBik,
    logo,
    managers,
    typesOfWorks,
  } = values;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [e.target.name]: e.target.value});
  }

  const handleChangeProfile = (value: CompanyProfile) => {
    setValues({...values, profile: value});
  }

  const handleAddTypesOfWorksWhenNoCompanyYet = ({value, label}: {value: number, label: string}) => {
    setValues({...values, typesOfWorks: [...values.typesOfWorks, {id: value, title: label}]});
  }

  const handleRemoveTypesOfWorksWhenNoCompanyYet = (id: number) => {
    setValues({...values, typesOfWorks: values.typesOfWorks.filter((item) => item.id !== id)});
  }

  const handleSubmitCreate = () => {
    if ((profile === CompanyProfile.PRODUCER || profile === CompanyProfile.PRODUCERCLIENT) && typesOfWorks.length > 0) {
      return createCompany({
        ...values,
        juridicalAddress: {
          country: juridicalAddressCountry,
          postalCode: juridicalAddressPostalCode,
          state: juridicalAddressState,
          city: juridicalAddressCity,
          street: juridicalAddressStreet,
          houseBuilding: juridicalAddressHouseBuilding,
          officeApartment: juridicalAddressOfficeApartment,
        },
        deliveryAddress: {
          country: deliveryAddressCountry,
          postalCode: deliveryAddressPostalCode,
          state: deliveryAddressState,
          city: deliveryAddressCity,
          street: deliveryAddressStreet,
          houseBuilding: deliveryAddressHouseBuilding,
          officeApartment: deliveryAddressOfficeApartment,
        },
        bankDetails: {
          bankName,
          bankAccount,
          bankCorrAccount,
          bankBik,
        }
      });
    }
    document.getElementById('picked-types-of-works').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    return alertActions(
      AlertColorStatuses.WARNING,
      'Подрядчику необходимо указать как минимум один тип выполняемых работ',
      AlertDurations.THREE_SECONDS
    );
  }

  const handleSubmitChanges = () => {
    if (company?.id) {
      changeCompany({
        prevData: {
          id: company.id,
          profile: company.profile,
          name: company.name,
          description: company.description,
          inn: company.inn,
          ogrnognip: company.ogrnognip,
          juridicalAddress: {
            id: company.juridicalAddress?.id,
            country: company.juridicalAddress?.country,
            postalCode: company.juridicalAddress?.postalCode,
            state: company.juridicalAddress?.state,
            city: company.juridicalAddress?.city,
            street: company.juridicalAddress?.street,
            houseBuilding: company.juridicalAddress?.houseBuilding,
            officeApartment: company.juridicalAddress?.officeApartment,
          },
          deliveryAddress: {
            id: company.deliveryAddress?.id,
            country: company.deliveryAddress?.country,
            postalCode: company.deliveryAddress?.postalCode,
            state: company.deliveryAddress?.state,
            city: company.deliveryAddress?.city,
            street: company.deliveryAddress?.street,
            houseBuilding: company.deliveryAddress?.houseBuilding,
            officeApartment: company.deliveryAddress?.officeApartment,
          },
          officialPersonForDocs: company.officialPersonForDocs,
          officialPersonForDocsPhone: company.officialPersonForDocsPhone,
          contactPerson: company.contactPerson,
          contactPersonPhone: company.contactPersonPhone,
          bankDetails: {
            id: company.bankDetails?.id,
            bankName: company.bankDetails?.bankName,
            bankAccount: company.bankDetails?.bankAccount,
            bankCorrAccount: company.bankDetails?.bankCorrAccount,
            bankBik: company.bankDetails?.bankBik,
          },
          logo: company.logo,
          managers: company.managers,
          typesOfWorks: company.typesOfWorks,
        },
        newData: {
          profile: values.profile,
          name: values.name,
          description: values.description,
          inn: values.inn,
          ogrnognip: values.ogrnognip,
          juridicalAddress: {
            id: company.juridicalAddress?.id,
            country: values.juridicalAddressCountry,
            postalCode: values.juridicalAddressPostalCode,
            state: values.juridicalAddressState,
            city: values.juridicalAddressCity,
            street: values.juridicalAddressStreet,
            houseBuilding: values.juridicalAddressHouseBuilding,
            officeApartment: values.juridicalAddressOfficeApartment,
          },
          deliveryAddress: {
            id: company.deliveryAddress?.id,
            country: values.deliveryAddressCountry,
            postalCode: values.deliveryAddressPostalCode,
            state: values.deliveryAddressState,
            city: values.deliveryAddressCity,
            street: values.deliveryAddressStreet,
            houseBuilding: values.deliveryAddressHouseBuilding,
            officeApartment: values.deliveryAddressOfficeApartment,
          },
          officialPersonForDocs: values.officialPersonForDocs,
          officialPersonForDocsPhone: values.officialPersonForDocsPhone,
          contactPerson: values.contactPerson,
          contactPersonPhone: values.contactPersonPhone,
          bankDetails: {
            id: company.bankDetails?.id,
            bankName: values.bankName,
            bankAccount: values.bankAccount,
            bankCorrAccount: values.bankCorrAccount,
            bankBik: values.bankBik,
          },
          logo: values.logo,
          typesOfWorks: values.typesOfWorks,
        }
      })
    }
  }

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewLogo(e.target.files[0]);
    setUploadLogoButton(true);
    console.log('LOGO CHANGE')
  }

  const submitLogoChange = async () => {
    try {
      const formData = new FormData();
      formData.append('logo', newLogo);
      formData.append('oldNameLogo', logo);
      await changeCompanyLogo({oldNameLogo: logo, form: formData});
      setUploadLogoButton(false);
    } catch (e) {
      console.log(`NO ${e}`)
    }
  }

  return (
    <>
      <Modal
        open={typesOfWorksModal}
        onClose={() => setTypesOfWorksModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={modalStyle}>
          <Box sx={scrollableModalBlockStyle}>
            <TypesOfWorks
              isForCompany={true}
              isForNewCompany={company?.id === 0}
              handleAddTypesOfWorksWhenNoCompanyYet={handleAddTypesOfWorksWhenNoCompanyYet}
            />
            <ListOfPickedTypesOfWorks
              list={company?.typesOfWorks}
              listForNotCreatedCompany={values.typesOfWorks}
              isForNewCompany={company?.id === 0}
              isForCompany={true}
              handleRemoveTypesOfWorksWhenNoCompanyYet={handleRemoveTypesOfWorksWhenNoCompanyYet}
            />
          </Box>
        </Card>
      </Modal>

      <MDBox p={3}>
        <MDTypography variant="h5">Информация о компании</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        {/*Общая информация*/}
        <Grid container spacing={3}>
          <Grid item>
            <input accept="image/*" style={{ display: 'none' }} id="logo-change" onChange={(e: ChangeEvent<HTMLInputElement>) => handleLogoChange(e)} type="file"  />
            <label htmlFor="logo-change">
              <MDAvatar
                onMouseEnter={() => setOfferToChangeLogo(true)}
                onMouseLeave={() => setOfferToChangeLogo(false)}
                src={offerToChangeLogo ? editAvatar : (logo ? `http://localhost:5045/images/logos/300x300/${logo}` : noAvatar)}
                alt="profile-image"
                size="xl"
                shadow="sm"
              />
            </label>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {
              uploadLogoButton ?
                <MDButton variant="gradient" color="dark" size="small" onClick={() => submitLogoChange()}>
                  Загрузить
                </MDButton>
                :
                <FormField
                  label="Название"
                  placeholder="..."
                  name={'name'}
                  value={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                />
            }
          </Grid>

          <Grid item md={12} marginTop={2} id='picked-types-of-works'>
            <ProfileSelectorForm isForCompany={true} handleChangeProfile={handleChangeProfile}/>
          </Grid>

          {
            (profile === CompanyProfile.PRODUCER || profile === CompanyProfile.PRODUCERCLIENT) &&
              <Grid item xs={12} sm={12} md={12} marginTop={2} marginBottom={2} >
                  <Grid container marginTop={2}>
                      <Grid item xs={12} md={6}>
                          <MDTypography variant="h6" pt={1}>Выберите выполняемые типы работ:</MDTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <MDButton variant={'contained'} onClick={() => handleTypesOfWorksModalOpen()}>Выбрать типы работ</MDButton>
                      </Grid>
                  </Grid>
              </Grid>
          }
          <Grid item xs={12} sm={12} md={12}>
            <FormField
              label="Описание"
              multiline rows={3}
              placeholder="..."
              name={'description'}
              value={description}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="ИНН"
              placeholder="..."
              name={'inn'}
              value={inn}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="ОГРНИП"
              placeholder="..."
              name={'ogrnognip'}
              value={ogrnognip}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="ФИО для документов"
              placeholder="..."
              name={'officialPersonForDocs'}
              value={officialPersonForDocs}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Телефон для документов"
              placeholder="..."
              name={'officialPersonForDocsPhone'}
              value={officialPersonForDocsPhone}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Контактное лицо"
              placeholder="..."
              name={'contactPerson'}
              value={contactPerson}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Телефон контактного лица"
              placeholder="..."
              name={'contactPersonPhone'}
              value={contactPersonPhone}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
        </Grid>
        {/*Юридический адрес*/}
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} sm={12} md={6}>
            <MDTypography variant={'h5'}>Юридический адрес</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Страна"
              disabled
              placeholder="..."
              name={'juridicalAddressCountry'}
              value={juridicalAddressCountry}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Индекс"
              placeholder="..."
              name={'juridicalAddressPostalCode'}
              value={juridicalAddressPostalCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Край, Область, Республика"
              placeholder="..."
              name={'juridicalAddressState'}
              value={juridicalAddressState}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Город, Населенный пункт"
              placeholder="..."
              name={'juridicalAddressCity'}
              value={juridicalAddressCity}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Улица"
              placeholder="..."
              name={'juridicalAddressStreet'}
              value={juridicalAddressStreet}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Дом, строение"
              placeholder="..."
              name={'juridicalAddressHouseBuilding'}
              value={juridicalAddressHouseBuilding}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Квартира, оффис"
              placeholder="..."
              name={'juridicalAddressOfficeApartment'}
              value={juridicalAddressOfficeApartment}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
        </Grid>
        {/*Адрес доставки*/}
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} sm={12} md={6}>
            <MDTypography variant={'h5'}>Адрес доставки</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Страна"
              disabled
              placeholder="..."
              name={'deliveryAddressCountry'}
              value={deliveryAddressCountry}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Индекс"
              placeholder="..."
              name={'deliveryAddressPostalCode'}
              value={deliveryAddressPostalCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Край, Область, Республика"
              placeholder="..."
              name={'deliveryAddressState'}
              value={deliveryAddressState}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Город, Населенный пункт"
              placeholder="..."
              name={'deliveryAddressCity'}
              value={deliveryAddressCity}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Улица"
              placeholder="..."
              name={'deliveryAddressStreet'}
              value={deliveryAddressStreet}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Дом, строение"
              placeholder="..."
              name={'deliveryAddressHouseBuilding'}
              value={deliveryAddressHouseBuilding}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Квартира, оффис"
              placeholder="..."
              name={'deliveryAddressOfficeApartment'}
              value={deliveryAddressOfficeApartment}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
        </Grid>
        {/*Банковские реквизиты*/}
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} sm={12} md={6}>
            <MDTypography variant={'h5'}>Банковские реквизиты</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Название банка"
              placeholder="..."
              name={'bankName'}
              value={bankName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Счет"
              placeholder="..."
              name={'bankAccount'}
              value={bankAccount}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="Корреспондентский счет"
              placeholder="..."
              name={'bankCorrAccount'}
              value={bankCorrAccount}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormField
              label="БИК"
              placeholder="..."
              name={'bankBik'}
              value={bankBik}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
        </Grid>
        {/*Кнопки*/}
        <Grid container spacing={3} mt={6}>
          <MDBox mt={3} ml={3} display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
            {
              isCompany
                ? (
                  <MDButton variant="gradient" color="dark" size="small" onClick={() => setEditCompanyInfo(false)}>
                    Отменить редактирование
                  </MDButton>
                )
                : (
                  <MDButton variant="gradient" color="dark" size="small" onClick={() => setCreateCompany(false)}>
                    Отменить создание
                  </MDButton>
                )
            }
          </MDBox>
          <MDBox mt={3} ml={3} display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
            {
              isCompany
                ? (
                  <MDButton variant="gradient" color="dark" size="small" onClick={() => handleSubmitChanges()}>
                    Сохранить изменения
                  </MDButton>
                )
                : (
                  <MDButton variant="gradient" color="dark" size="small" onClick={() => handleSubmitCreate()}>
                    Создать компанию
                  </MDButton>
                )
            }
          </MDBox>
        </Grid>
      </MDBox>
    </>
  )
}

export default CompanyForm;