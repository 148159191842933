import {CreateDetailInterface, DetailReducerPayloads, DetailReducerTypes} from "../types/detail.types";
import {Dispatch} from "redux";
import $api from "../../utilities/http.axios";
import {jsonContentType} from "../../utilities/http.requests";

export const getDetails = () => {
  return async (dispatch: Dispatch<DetailReducerPayloads>) => {
    try {
      const res = await $api.get('/detail/all', )
      dispatch({type: DetailReducerTypes.GET_DETAILS, payload: res.data})
      return res.data;
    } catch (e) {
      console.log('ERROR - ', e)
    }
  }
}

export const createDetail = (detail: any) => {
  return async (dispatch: Dispatch<DetailReducerPayloads>) => {
    try {
      const res = await $api.post(
        '/detail/create',
        detail,
        {
        headers: jsonContentType
      })
      dispatch({type: DetailReducerTypes.CREATE_DETAIL, payload: res.data})
      return res.data;
    } catch (e) {
      console.log('ERROR - ', e)
    }
  }
}

export const createDetailWithRouteAction = (detail: CreateDetailInterface) => {
  return (dispatch: Dispatch<DetailReducerPayloads>) => {
    return dispatch({type: DetailReducerTypes.CREATE_DETAILS_WITH_ROUTE, payload: detail});
  }
}
