export enum TextsReducerTypes {
  CLEAR_DIALOG_TEXT = 'CLEAR_DIALOG_TEXT',
  SET_DIALOG_TEXT = 'SET_DIALOG_TEXT',
}

export type TextPayload = ClearDialogText
  | SetDialogText


export interface ClearDialogText {
  type: TextsReducerTypes.CLEAR_DIALOG_TEXT,
}

export interface SetDialogText {
  type: TextsReducerTypes.SET_DIALOG_TEXT,
  payload: string
}