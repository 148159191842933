import React, {ReactNode} from 'react';
import {Box, Card, Modal} from "@mui/material";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../dashboard/DashboardLayout";
import MDBox from "../../../components/MDBox";
import FaqTabs from "./components/FaqTabs";
import BlogPosts from './components/BlogPosts';


const scrollableModalBlockStyle = {
  height: '100%',
  margin: '0em',
  overflowY: 'auto',
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90%',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "visible"
};

interface Props {
  // tabValue: number;
  // tabHandler: (...arg: any) => void;
  children?: ReactNode;
}

const AdminModels = ({ children }: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card sx={modalStyle}>
            <Box sx={scrollableModalBlockStyle}>
              MODAL
            </Box>
          </Card>
        </Modal>
        <div>
          <Grid container spacing={3} style={{padding: 12}}>
            <Grid item xs={12} lg={12} md={12}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    Управление Описаниями
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <FaqTabs />
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <BlogPosts />
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default AdminModels;