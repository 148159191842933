import {Dispatch} from "redux";
import {AlertPayload, AlertReducerTypes} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {AlertColorStatuses} from "../types/common.types";
import {
  ChangeStatusInterface,
  PreOrderPayload,
  PreOrderReducerTypes,
  PreOrdersOrderBy,
  PreOrdersQuery,
  PreOrdersSortBy
} from "../types/pre-order.types";
import {FullPreOrderStateInterface} from "../reducers/pre-order.reducer";
import {formdataContentType, jsonContentType} from "../../utilities/http.requests";
import {FileOrImage, DocumentType} from "../../utilities/constants";
import {CreatingCommentInterface} from "../types/comment.types.";
import {store} from "../index";


//Получить все предзаказы ДЛЯ АДМИНА
export const getPreOrders = (query?: string) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      const state = store?.getState();
      // @ts-ignore
      let baseQueryString = `?skip=${state.preOrders.skip ?? 0}&take=${state.preOrders.take ?? 20}&order_by=${state.preOrders.order_by}&sort_by=${state.preOrders.sort_by}` + (query ? query : '');
      console.log('QUERY - -  - - - ', query)
      dispatch({type: PreOrderReducerTypes.CLEAR_PRE_ORDERS});
      const res = await $api.get(`/pre-order/all${baseQueryString}`, );
      dispatch({type: PreOrderReducerTypes.SET_ADMINS_PRE_ORDERS_SUCCESS, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const createPreOrder = (preOrder: any) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      dispatch({type: PreOrderReducerTypes.CLEAR_SELECTED_PRE_ORDER});
      const res = await $api.post(
          `/pre-order/create`,
          preOrder,
          {
            headers: formdataContentType
          }
        );
      dispatch({type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CREATE, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('createPreOrder - ERROR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const createCommentForPreOrder = (comment: CreatingCommentInterface) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      const res = await $api.post(
        `/comment/create-for-preorder`,
        comment,
        {
          headers: jsonContentType
        }
      );
      dispatch({type: PreOrderReducerTypes.INSERT_CREATED_COMMENT, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('Create Comment For PreOrder - ERROR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const changePreOrder = (preOrder: any, id: string) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      dispatch({type: PreOrderReducerTypes.CLEAR_SELECTED_PRE_ORDER});
      const res = await $api.put(
        `/pre-order/change/${id}`,
        preOrder,
        { headers: jsonContentType }
      );
      dispatch({type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CREATE, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('createPreOrder - ERROR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const setCommentsViewedByManagerPreOrder = (id: number) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      const res = await $api.put(
        `/pre-order/set-viewed`,
        {preOrderId: id},
        {
          headers: jsonContentType
        }
      );
      dispatch({type: PreOrderReducerTypes.UPDATE_ALL_COMMENTS, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('setCommentsViewedByManagerPreOrder - ERROR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const getAllCommentsForPreOrder = (id: number) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      const res = await $api.get(
        `/comment/all-for-preorder/${id}`,
        {
          headers: jsonContentType
        }
      );
      dispatch({type: PreOrderReducerTypes.GET_ALL_COMMENTS, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('getAllCommentsForPreOrder - ERROR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.GET_ALL_COMMENTS, payload: []});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const uploadImageForPreOrder = (formData: FormData, id: string) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      const res = await $api.put(
        `/files/upload-image-for-preorder/${id}`,
        formData,
        { headers: formdataContentType }
      );
      dispatch({type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CHANGE, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('uploadImageForPreOrder - ERROR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const uploadFileForPreOrder = (formData: FormData, id: string) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      const res = await $api.put(
        `/files/upload-file-for-preorder/${id}`,
        formData,
        { headers: formdataContentType }
      );
      dispatch({type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CHANGE, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('uploadFileForPreOrder - ERROR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const deleteImageOrFileForPreOrder = (type: FileOrImage, document: DocumentType, fileName: string, fileId: number, id: string) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      const res = await $api.put(
        `/files/delete-file/${id}`,
        {type, document, fileName, fileId},
        { headers: jsonContentType }
      );
      dispatch({type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CHANGE, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('deleteImageOrFileForPreOrder - ERROR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const getAnyOnePreOrder = (id: number) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      dispatch({type: PreOrderReducerTypes.CLEAR_SELECTED_PRE_ORDER});
      const res = await $api.get(`/pre-order/any/${id}`);
      dispatch({type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

// Получить свои предзаказы - ДЛЯ ПОЛЬЗОВАТЕЛЯ
export const getMyPreOrders = ({query, take}: {query?: string, take?: number}) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      const state = store?.getState();
      // @ts-ignore
      let baseQueryString = `?skip=${state.preOrders.skip ?? 0}&take=${take ?? state.preOrders.take ?? 20}&order_by=${state.preOrders.order_by}&sort_by=${state.preOrders.sort_by}` + (query ? query : '');

      dispatch({type: PreOrderReducerTypes.CLEAR_PRE_ORDERS});
      const res = await $api.get(`/pre-order/own-preorders${baseQueryString}`, );
      dispatch({type: PreOrderReducerTypes.SET_MANAGERS_PRE_ORDERS_SUCCESS, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('getMyPreOrders - ERROR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const getManagersPreOrders = () => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      dispatch({type: PreOrderReducerTypes.CLEAR_PRE_ORDERS});
      const res = await $api.get(`/pre-order/managers-preorders`, );
      dispatch({type: PreOrderReducerTypes.SET_MANAGERS_PRE_ORDERS_SUCCESS, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const getMyOnePreOrder = (id: string) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>): Promise<FullPreOrderStateInterface> => {
    try {
      dispatch({type: PreOrderReducerTypes.CLEAR_SELECTED_PRE_ORDER});
      const res = await $api.get(`/pre-order/own-pre-order/${id}`);
      dispatch({type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const setNewStatus = ({newStatus, id}: ChangeStatusInterface) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    try {
      const res = await $api.put(
        `/pre-order/change-status`,
        {newStatus, id},
        { headers: jsonContentType }
        );
      dispatch({type: PreOrderReducerTypes.SET_NEW_STATUS, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR, payload: res});
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const setTakeForPreOrder = (number: number) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    dispatch({type: PreOrderReducerTypes.SET_TAKE, payload: number});
  }
}

export const setSkipForPreOrder = (number: number) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    dispatch({type: PreOrderReducerTypes.SET_SKIP, payload: number});
  }
}

export const setQueryForPreOrder = (text: string) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    dispatch({type: PreOrderReducerTypes.SET_QUERY, payload: text});
  }
}

export const setSortByForPreOrder = (text: PreOrdersSortBy) => {
  return async (dispatch: Dispatch<PreOrderPayload | AlertPayload>) => {
    dispatch({type: PreOrderReducerTypes.SET_SORT_BY, payload: text});
  }
}

export const setOrderByForPreOrder = (text: PreOrdersOrderBy) => {
  return async (dispatch: Dispatch<PreOrderPayload>) => {
    dispatch({type: PreOrderReducerTypes.SET_ORDER_BY, payload: text});
  }
}

export const getFullQuery = () => {
  return async (dispatch: Dispatch<PreOrderPayload>) => {

  }
}

