import {AuthPayload, authReducerTypes} from "../types/auth.types";

interface AuthState {
  isAuth: boolean,
  accessToken: null | string,
  error: null | string,
  user: null | {
    userId: number,
    roles: string[],
    status: string,
    nickname: string | null,
    avatar: string | null
  },
  wasForceTokenRefresh: boolean
}

const initialState: AuthState = {
  isAuth: false,
  accessToken: null,
  error: null,
  user: null,
  wasForceTokenRefresh: false
}

const authReducer = (state: AuthState = initialState, action: AuthPayload) => {
  switch (action.type) {
    case authReducerTypes.CLEAR_AUTH:
      return {...state, isAuth: false, error: null, accessToken: null, user: null}
    case authReducerTypes.SET_AUTH_SUCCESS:
      return {...state, isAuth: true, error: null as null, accessToken: action.payload.accessToken, user: action.payload.user }
    case authReducerTypes.SET_AUTH_ERROR:
      return {...state, isAuth: false, accessToken: null, user: null, error: action.payload}
    case authReducerTypes.WAS_FORCIBLY_REFRESHED_TOKEN:
      return {...state, wasForceTokenRefresh: true}
    case authReducerTypes.WAS_NOT_FORCIBLY_REFRESHED_TOKEN:
      return {...state, wasForceTokenRefresh: false}
    default:
      return state
  }
}

export default authReducer;