export interface AxiosErrorServerResponse {
  response: AxiosServerResponse
}

export interface AxiosServerResponse {
  data: ErrorMessage
  status: number
  headers?: any
}

interface ErrorMessage {
  statusCode: number;
  error: string;
  message: string[]
}

// color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
export enum AlertColorStatuses {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  DARK = 'dark',
  LIGHT = 'light',
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export const ErrorTitleCreator = (status: AlertColorStatuses): string => {
  switch (status) {
    case AlertColorStatuses.SUCCESS:
      return 'УСПЕШНО'
    case AlertColorStatuses.ERROR:
      return 'ОШИБКА'
    case AlertColorStatuses.WARNING:
      return 'ВНИМАНИЕ'
    case AlertColorStatuses.INFO:
      return 'КСТАТИ'
    case AlertColorStatuses.DARK:
      return 'ИНФОРМАЦИЯ'
    case AlertColorStatuses.LIGHT:
      return 'НЕ ЗАБУДЬТЕ'
    case AlertColorStatuses.PRIMARY:
      return 'ИНФОРМАЦИЯ'
    case AlertColorStatuses.SECONDARY:
      return 'ДОПОЛНИТЕЛЬНО'
  }
}

export interface AlertContent {
  status: AlertColorStatuses,
  message: string
}

export enum Countries {
  RUSSIA = 'Россия',
  BELARUS = 'Беларусь',
  KAZAKHSTAN = 'Казахстан',
}

export interface InvitedCompany {
  id: number,
  company: {
    logo: string,
    name: string
  }
}