// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {SyntheticEvent, useEffect, useState} from "react";
import NotificationAccordionItem from "./NotificationAccordionItem";
import {useAppSelector} from "../../../../../hooks/useActions";
import {NotificationInterface} from "../../../../../store/types/notifications.type";


interface Props {

}

function NotificationsList({}: Props): JSX.Element {
  const [expanded, setExpanded] = useState<string | false>(false);
  const {notifications} = useAppSelector(state => state)

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  return (
    <Card id="social-profile" sx={{ overflow: "visible", pt: 4 }} style={{scrollMarginTop: 90}}>
      {/*<MDBox p={3}>*/}
      {/*  <MDTypography variant="h5">Уведомления</MDTypography>*/}
      {/*</MDBox>*/}
      <MDBox pb={3} px={3}>
        {
            //@ts-ignore
          notifications?.notifications?.length ? notifications?.notifications?.map((notification: NotificationInterface, index: number) => <NotificationAccordionItem panel={`panel${index}`} notification={notification} expanded={expanded} handleChange={handleChange} key={index} />)
            : <MDTypography variant="h6">Уведомлений нет</MDTypography>
        }
      </MDBox>
    </Card>
  );
}

export default NotificationsList;
