import React, {ChangeEvent, useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {Card, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import MDBox from "../../../../components/MDBox";
import {useNumberInput, useStringInput} from "../../../../hooks/useInput";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {PreOrderStatus, QueryForInterface} from "../../../../store/types/pre-order.types";
import {getDeliveryCompanies} from "../../../../utilities/http.requests";

const inputStyles = {
  width: '100%',
}

const TableHeader = () => {
  const {
    getPreOrders,
    setQueryForPreOrder,
    setTakeForPreOrder,
    setSkipForPreOrder,
    setSortByForPreOrder,
    setOrderByForPreOrder
  } = useAppDispatch();

  const [listOfDeliveryCompanies, setListOfDeliveryCompanies] = useState<{id: number, title: string}[] | null>([]);
  const [status, setStatus] = useState<string>('');
  const [deliveryCompany, setDeliveryCompany] = useState<number>(0);
  const [id, setId] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [ownerUser, setOwnerUser] = useState<string>('');
  const [ownerCompany, setOwnerCompany] = useState<string>('');

  useEffect(() => {
    getDeliveryCompanies()
      .then(comps => setListOfDeliveryCompanies(comps))
  }, []);

  const clearQuery = () => {
    setId(0)
    setSearch('')
    setStatus('')
    setDeliveryCompany(0)
    setOwnerUser('')
    setOwnerCompany('')
  }

  const handleSearch = async () => {
    const queryMaker = () => {
      let rawQuery = '';
      if (search?.length > 0) {
        rawQuery = rawQuery + `&search=${search}`;
      }
      if (id > 0) {
        rawQuery = rawQuery + `&id=${id}`;
      }
      if (status in PreOrderStatus) {
        rawQuery = rawQuery + `&status=${status}`;
      }
      if (deliveryCompany > 0) {
        rawQuery = rawQuery + `&deliveryCompany=${deliveryCompany}`;
      }
      if (ownerCompany?.length > 0) {
        rawQuery = rawQuery + `&ownerCompany=${ownerCompany}`;
      }
      if (ownerUser?.length > 0) {
        rawQuery = rawQuery + `&ownerUser=${ownerUser}`;
      }
      return rawQuery;
    }

    const queryString = queryMaker();

    console.log('queryString -- ', queryString)

    setQueryForPreOrder(queryString);
    getPreOrders(queryString)
  }

  return (
    <>
      <Card id="basic" style={{scrollMarginTop: 90}}>
        <MDBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Поиск по названию или описанию"
                name={"search"}
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="ID предзаказа"
                name="id"
                value={id}
                onChange={(e: ChangeEvent<HTMLInputElement>) => (typeof e.target.value === 'number' && setId(e.target.value))}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Никнейм пользователя"
                value={ownerUser}
                onChange={(e) => setOwnerUser(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Назание компании"
                value={ownerCompany}
                onChange={(e) => setOwnerCompany(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {/*<InputLabel id="deliveryCompany">Выберите транспортную компанию:</InputLabel>*/}
              <Select
                labelId="deliveryCompany"
                value={deliveryCompany}
                name='deliveryCompany'
                // native={true}
                label="Тип компании"
                size={'medium'}
                style={{height: 40, width: 120}}
                onChange={(e) => (typeof e.target.value === 'number' && setDeliveryCompany(e.target.value))}
              >
                <MenuItem defaultChecked value={0}>Пусто</MenuItem>
                {
                  listOfDeliveryCompanies?.length > 0
                  && listOfDeliveryCompanies?.map(d => <MenuItem value={d.id} key={d.id}>{d.title}</MenuItem>)
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                labelId="status"
                value={status}
                name='status'
                label="Статус"
                size={'medium'}
                style={{height: 40, width: 120}}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem defaultChecked value={''}>Пусто</MenuItem>
                <MenuItem value={PreOrderStatus.CREATED} >СОЗДАН</MenuItem>
                <MenuItem value={PreOrderStatus.RECLARIFICATION} >УТОЧНЯЕТСЯ</MenuItem>
                <MenuItem value={PreOrderStatus.DECLINED} >ОТМЕНЕН</MenuItem>
                <MenuItem value={PreOrderStatus.ACCEPTED} >ПРИНЯТ</MenuItem>
                <MenuItem value={PreOrderStatus.PROCESSING} >В РАБОТЕ</MenuItem>
                <MenuItem value={PreOrderStatus.ORDERED} >РАЗМЕЩЕН</MenuItem>
                <MenuItem value={PreOrderStatus.ARCHIVED} >АРХИВ</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" mt={2} mb={2}>
            <Grid item xs={12} md={4} textAlign="center">
              <MDButton variant={'contained'} color={'info'} size={'small'} onClick={() => handleSearch()}>
                Поиск
              </MDButton>
            </Grid>
            <Grid item xs={12} md={4} textAlign="center">
              <MDButton variant={'outlined'} color={'info'} size={'small'} onClick={() => clearQuery()}>
                Сбросить
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </>
  )
}

export default TableHeader;