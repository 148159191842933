import {Dispatch} from "redux";
import {UserPayload, userReducerTypes} from "../types/user.types";
import {AlertPayload, AlertReducerTypes} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {AlertColorStatuses} from "../types/common.types";
import {CompanyPayload, CompanyProfile, CompanyReducerTypes} from "../types/company.types";

export const getCompany = () => {
  return async (dispatch: Dispatch<CompanyPayload | AlertPayload>) => {
    try {
      dispatch({type: CompanyReducerTypes.CLEAR_COMPANY})
      const res = await $api.get('/company/my-company', )
      dispatch({type: CompanyReducerTypes.SET_COMPANY_SUCCESS, payload: res.data})
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: CompanyReducerTypes.SET_COMPANY_ERROR, payload: res})
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} })
      //return dispatch({type: authReducerTypes.SET_AUTH_ERROR, payload: res})
    }
  }
}

interface CreateCompanyInterface {
  profile?:                    CompanyProfile;
  name?:                       string;
  description?:                string;
  inn?:                        string;
  ogrnognip?:                  string;
  juridicalAddress?: {
    country?:         string;
    postalCode?:      string;
    state?:           string;
    city?:            string;
    street?:          string;
    houseBuilding?:   string;
    officeApartment?: string;
  },
  deliveryAddress?: {
    country?:         string;
    postalCode?:      string;
    state?:           string;
    city?:            string;
    street?:          string;
    houseBuilding?:   string;
    officeApartment?: string;
  },
  officialPersonForDocs?:      string;
  officialPersonForDocsPhone?: string;
  contactPerson?:              string;
  contactPersonPhone?:         string;
  bankDetails?: {
    bankName?:                   string;
    bankAccount?:                string;
    bankCorrAccount?:            string;
    bankBik?:                    string;
  },
  logo?:                       string;
  typesOfWorks?: {
    id: number,
    title: string,
    description: string,
  }[];
}

export const createCompany = (data: CreateCompanyInterface) => {
  return async (dispatch: Dispatch<CompanyPayload | UserPayload | AlertPayload>) => {
    try {
      dispatch({type: CompanyReducerTypes.CLEAR_COMPANY})
      const res = await $api.post('/company/create', data)
      dispatch({type: CompanyReducerTypes.SET_COMPANY_SUCCESS, payload: res.data})
      dispatch({type: userReducerTypes.SET_COMPANY_DATA, payload: {
          id: res.data.id || 0,
          profile: res.data.profile || '',
          name: res.data.name || '',
          description: res.data.description || '',
          inn: res.data.inn || '',
          logo: res.data.logo || '',
          typesOfWorks: res.data.typesOfWorks || [],
        }})
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: CompanyReducerTypes.SET_COMPANY_ERROR, payload: res})
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} })
      //return dispatch({type: authReducerTypes.SET_AUTH_ERROR, payload: res})
    }
  }
}

interface ChangeCompanyInterface {
  prevData: {
    id: number,
    profile:                    CompanyProfile;
    name:                       string;
    description:                string;
    inn:                        string;
    ogrnognip:                  string;
    juridicalAddress: {
      id: number;
      country:         string;
      postalCode:      string;
      state:           string;
      city:            string;
      street:          string;
      houseBuilding:   string;
      officeApartment: string;
    },
    deliveryAddress: {
      id: number;
      country:         string;
      postalCode:      string;
      state:           string;
      city:            string;
      street:          string;
      houseBuilding:   string;
      officeApartment: string;
    },
    officialPersonForDocs:      string;
    officialPersonForDocsPhone: string;
    contactPerson:              string;
    contactPersonPhone:         string;
    bankDetails: {
      id: number;
      bankName:                   string;
      bankAccount:                string;
      bankCorrAccount:            string;
      bankBik:                    string;
    },
    logo:                       string;
    managers: {
      id: number,
      avatar: string,
      fio: string,
      nickname: string
    }[],
    typesOfWorks: {
      id: number,
      title: string,
      description: string,
    }[];
  },
  newData: {
    profile?:                    CompanyProfile;
    name?:                       string;
    description?:                string;
    inn?:                        string;
    ogrnognip?:                  string;
    juridicalAddress?: {
      id?: number;
      country?:         string;
      postalCode?:      string;
      state?:           string;
      city?:            string;
      street?:          string;
      houseBuilding?:   string;
      officeApartment?: string;
    },
    deliveryAddress?: {
      id?: number;
      country?:         string;
      postalCode?:      string;
      state?:           string;
      city?:            string;
      street?:          string;
      houseBuilding?:   string;
      officeApartment?: string;
    },
    officialPersonForDocs?:      string;
    officialPersonForDocsPhone?: string;
    contactPerson?:              string;
    contactPersonPhone?:         string;
    bankDetails?: {
      id?: number;
      bankName?:                   string;
      bankAccount?:                string;
      bankCorrAccount?:            string;
      bankBik?:                    string;
    },
    logo?:                       string;
    typesOfWorks: {
      id: number,
      title: string,
      description: string,
    }[];
  },
}

export const changeCompany = (data: ChangeCompanyInterface) => {
  return async (dispatch: Dispatch<CompanyPayload | UserPayload | AlertPayload>) => {
    try {
      dispatch({type: CompanyReducerTypes.CLEAR_COMPANY})
      const res = await $api.put('/company/change', data.newData)
      dispatch({type: CompanyReducerTypes.SET_COMPANY_SUCCESS, payload: res.data})
      dispatch({type: userReducerTypes.SET_COMPANY_DATA, payload: {
          id: res.data.id || 0,
          profile: res.data.profile || '',
          name: res.data.name || '',
          description: res.data.description || '',
          inn: res.data.inn || '',
          logo: res.data.logo || '',
          typesOfWorks: res.data.typesOfWorks || [],
        }})
    } catch (err: unknown) {
      console.log(err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: CompanyReducerTypes.SET_COMPANY_ERROR, payload: res})
      dispatch({type: CompanyReducerTypes.SET_COMPANY_SUCCESS, payload: data.prevData})
      dispatch({type: userReducerTypes.SET_COMPANY_DATA, payload: {
          id: data.prevData.id || 0,
          profile: data.prevData.profile || '',
          name: data.prevData.name || '',
          description: data.prevData.description || '',
          inn: data.prevData.inn || '',
          logo: data.prevData.logo || '',
          typesOfWorks: data.prevData.typesOfWorks || [],
        }})
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} })
      //return dispatch({type: authReducerTypes.SET_AUTH_ERROR, payload: res})
    }
  }
}

interface ChangeCompanyLogoInterface {
  oldNameLogo: string,
  form: FormData
}

export const changeCompanyLogo = (data: ChangeCompanyLogoInterface) => {
  return async (dispatch: Dispatch<CompanyPayload | UserPayload | AlertPayload>) => {
    try {
      const res = await $api.put('/company/change-logo', data.form)
      dispatch({type: CompanyReducerTypes.SET_COMPANY_LOGO, payload: res.data.newLogo})
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.SUCCESS, message: res.data.message} })
      setTimeout(() => {
        dispatch({type: AlertReducerTypes.CLOSE })
      }, 2000)
    } catch (err) {
      //ts-ignore
      dispatch({type: CompanyReducerTypes.SET_COMPANY_LOGO, payload: data.oldNameLogo})
      const res = axiosErrorMessageHandler(err);
      dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} })
      setTimeout(() => {
        dispatch({type: AlertReducerTypes.CLOSE })
      }, 3000)
    }
  }
}

export const getManagers = () => {
  return async () => {
    try {
      const res = await $api.get('/company/get-managers')
      if (res.data.managers) {
        return res.data.managers
      }
      return []
    } catch (e) {
      console.log(`ERROR RECEIVING MANAGERS ${e}`)
    }
  }
}

export const getInvitedToBeManagers = () => {
  return async () => {
    try {
      const res = await $api.get('/company/get-invited-managers')
      if (res.data.managersInvites) {
        return res.data.managersInvites
      }
      return []
    } catch (e) {
      console.log(`ERROR RECEIVING INVITED MANAGERS ${e}`)
    }
  }
}

export const deleteInvitation = (id: number) => {
  return async () => {
    try {
      const res = await $api.delete(`/company/delete-invitation/${id}`)
      return res.data
    } catch (e) {
      console.log(`ERROR DELETING INVITATION ${e}`)
      return {status: 'error', message: e}
    }
  }
}

export const createInvitationForManager = (email: string) => {
  return async () => {
    try {
      const res = await $api.post('/company/create-invitation',
        {email},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      return res.data
    } catch (e) {
      console.log(`ERROR CREATING INVITATION ${e}`)
      return {status: 'error', message: e}
    }
  }
}

export const acceptInvitation = (id: number) => {
  return async () => {
    try {
      const res = await $api.post(`/company/accept-invitation/${id}`)
      return res.data
    } catch (e) {
      console.log(`ERROR ACCEPTING INVITATION ${e}`)
      return {status: 'error', message: e}
    }
  }
}

export const declineInvitation = (id: number) => {
  return async () => {
    try {
      const res = await $api.post(`/company/decline-invitation/${id}`)
      return res.data
    } catch (e) {
      console.log(`ERROR DECLINING INVITATION ${e}`)
      return {status: 'error', message: e}
    }
  }
}

export const deleteManager = (id: number) => {
  return async () => {
    try {
      const res = await $api.delete(`/company/delete-manager/${id}`)
      return res.data
    } catch (e) {
      console.log(`ERROR DELETING INVITATION ${e}`)
      return {status: 'error', message: e}
    }
  }
}

interface SetCompanyProfileInterface {
  companyId: number,
  profile: CompanyProfile
}
export const setCompanyProfile = (data: SetCompanyProfileInterface) => {
  return async (dispatch: Dispatch<CompanyPayload | AlertPayload>) => {
    try {
      const res = await $api.put(
        '/company/set-profile',
        {
          companyId: data.companyId,
          profile: data.profile
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch({type: CompanyReducerTypes.SET_COMPANY_PROFILE, payload: res.data})
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      console.log(res, err)
    }
  }
}

interface AddCompanyTypeOfWorkInterface {
  typeOfWorkId: number,
  companyId: number,
}
export const addCompanyTypeOfWork = (data: AddCompanyTypeOfWorkInterface) => {
  return async (dispatch: Dispatch<CompanyPayload | AlertPayload>) => {
    try {
      const res = await $api.put(
        '/company/add-type-of-work',
        {
          companyId: data.companyId,
          typeOfWorkId: data.typeOfWorkId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch({type: CompanyReducerTypes.ADD_COMPANY_TYPE_OF_WORK, payload: res.data})
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      console.log(res, err)
    }
  }
}

export const removeCompanyTypeOfWork = (data: AddCompanyTypeOfWorkInterface) => {
  return async (dispatch: Dispatch<CompanyPayload | AlertPayload>) => {
    try {
      const res = await $api.put(
        '/company/remove-type-of-work',
        {
          companyId: data.companyId,
          typeOfWorkId: data.typeOfWorkId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch({type: CompanyReducerTypes.REMOVE_COMPANY_TYPE_OF_WORK, payload: res.data})
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      console.log(res, err)
    }
  }
}