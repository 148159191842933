import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import InfoBlock from "./InfoBlock";
import CommentsBlock from "../../../../components/Сomment/CommentsBlock";

const PreviewBlock = () => {
  const {preOrders: {onePreOrder, count, take, skip, search}} = useAppSelector(state => state);
  const {getAllCommentsForPreOrder} = useAppDispatch();

  return(
    <>
      <InfoBlock po={onePreOrder}/>
      <CommentsBlock getComments={getAllCommentsForPreOrder} />
    </>
  )
}

export  default PreviewBlock;