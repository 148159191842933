// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Footer(): JSX.Element {
  return (
    <MDBox component="footer" py={6}>
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={8}>
          <MDBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
            <MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <MDTypography
                component="a"
                href="#"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                Главная
              </MDTypography>
            </MDBox>
            <MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <MDTypography
                component="a"
                href="#"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                О нас
              </MDTypography>
            </MDBox>
            <MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <MDTypography
                component="a"
                href="#"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                Блог
              </MDTypography>
            </MDBox>
            <MDBox mr={{ xs: 0, lg: 3, xl: 6 }}>
              <MDTypography
                component="a"
                href="#"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                Авторизация
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={8}>
          <MDBox display="flex" justifyContent="center" mt={1} mb={3}>
            <MDBox mr={3} color="secondary">
              <TwitterIcon fontSize="small" />
            </MDBox>
            <MDBox mr={3} color="secondary">
              <InstagramIcon fontSize="small" />
            </MDBox>
            <MDBox mr={3} color="secondary">
              <LinkedInIcon fontSize="small" />
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          <MDTypography variant="body2" color="secondary">
            Copyright &copy; 2023
            {/*<a href="https://arthur-developer.com" target="_blank">Arthur Developer</a>*/}
          </MDTypography>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Footer;
