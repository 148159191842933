import React from 'react';
import {useAppSelector} from "../../../../../hooks/useActions";
import MDBadge from "../../../../../components/MDBadge";
import Icon from "@mui/material/Icon";

function NumberOfUnreadBadge(iconsStyle: any) {
  const {notifications: {unreadNumber}} = useAppSelector(state => state);

  return (
    <MDBadge badgeContent={unreadNumber} color="error" size="xs" circular>
      <Icon sx={iconsStyle}>notifications</Icon>
    </MDBadge>
  );
}

export default NumberOfUnreadBadge;