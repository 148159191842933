import React, {ChangeEvent, useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {Card, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import MDBox from "../../../../components/MDBox";
import {useNumberInput, useStringInput} from "../../../../hooks/useInput";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {PreOrderStatus, QueryForInterface} from "../../../../store/types/pre-order.types";
import {getDeliveryCompanies} from "../../../../utilities/http.requests";
import {RouteStatus} from "../../../../store/types/route.type";
import {getPreOrders} from "../../../../store/actions/pre-order.actions";

const inputStyles = {
  width: '100%',
}

const TableHeaderRoutes = () => {
  const {
    getRoutesAction,
    setQueryForRoute,
    setTakeForRoute,
    setSkipForRoute,
    setSortByForRoute,
    setOrderByForRoute
  } = useAppDispatch();

  const [status, setStatus] = useState<string>('');
  const [id, setId] = useState<number>(0);
  const [preOrderId, setPreOrderId] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [ownerUser, setOwnerUser] = useState<string>('');
  const [ownerCompany, setOwnerCompany] = useState<string>('');

  const clearQuery = () => {
    setId(0);
    setSearch('');
    setStatus('');
    setPreOrderId(0);
    setOwnerUser('');
    setOwnerCompany('');
  }

  const handleSearch = async () => {
    const queryMaker = () => {
      let rawQuery = '';
      if (search.length > 0) {
        rawQuery = rawQuery + `&search=${search}`;
      }
      if (id > 0) {
        rawQuery = rawQuery + `&id=${id}`;
      }
      if (status in PreOrderStatus) {
        rawQuery = rawQuery + `&status=${status}`;
      }
      if (preOrderId > 0) {
        rawQuery = rawQuery + `&preOrderId=${preOrderId}`;
      }
      if (ownerCompany.length > 0) {
        rawQuery = rawQuery + `&ownerCompany=${ownerCompany}`;
      }
      if (ownerUser.length > 0) {
        rawQuery = rawQuery + `&ownerUser=${ownerUser}`;
      }
      return rawQuery;
    }

    const queryString = queryMaker();

    console.log('queryString -- ', queryString)

    setQueryForRoute(queryString);
    getPreOrders(queryString)
  }

  return (
    <>
      <Card id="basic" style={{scrollMarginTop: 90}}>
        <MDBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Поиск по названию маршрута или предзаказа"
                name={"search"}
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="ID маршрута"
                name="id"
                value={id}
                onChange={(e: ChangeEvent<HTMLInputElement>) => (typeof e.target.value === 'number' && setId(e.target.value))}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="ID предзаказа"
                name="id"
                value={preOrderId}
                onChange={(e: ChangeEvent<HTMLInputElement>) => (typeof e.target.value === 'number' && setPreOrderId(e.target.value))}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Никнейм пользователя"
                value={ownerUser}
                onChange={(e) => setOwnerUser(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Назание компании"
                value={ownerCompany}
                onChange={(e) => setOwnerCompany(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                value={status}
                name='status'
                label="Статус"
                size={'medium'}
                style={{height: 40, width: 140}}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem defaultChecked value={''}>Пусто</MenuItem>
                <MenuItem value={RouteStatus.CREATED} >СОЗДАН</MenuItem>
                <MenuItem value={RouteStatus.ACTIVE} >В РАБОТЕ</MenuItem>
                <MenuItem value={RouteStatus.DECLINE} >В АРХИВЕ</MenuItem>
                <MenuItem value={RouteStatus.ARCHIVE} >ПРИНЯТ</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" mt={2} mb={2}>
            <Grid item xs={12} md={4} textAlign="center">
              <MDButton variant={'contained'} color={'info'} size={'small'} onClick={() => handleSearch()}>
                Поиск
              </MDButton>
            </Grid>
            <Grid item xs={12} md={4} textAlign="center">
              <MDButton variant={'outlined'} color={'info'} size={'small'} onClick={() => clearQuery()}>
                Сбросить
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </>
  )
}

export default TableHeaderRoutes;