import {TextPayload, TextsReducerTypes} from "../types/text.types";

interface TextsStateInterface {
  dialogText: string,
}

const initialState: TextsStateInterface = {
  dialogText: '',
}

const textReducer = (state: TextsStateInterface = initialState, action: TextPayload) => {
  switch (action.type) {
    case TextsReducerTypes.CLEAR_DIALOG_TEXT:
      return {...state, dialogText: ''};
    case TextsReducerTypes.SET_DIALOG_TEXT:
      return {...state, dialogText: action.payload};
    default:
      return state;
  }
}

export default textReducer;