import React, {useEffect, useState} from 'react';
import {StagePaymentType} from "../../../../store/types/stage.types";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {Button, Grid, InputLabel, MenuItem, TextField} from "@mui/material";
import MDInput from "../../../../components/MDInput";
import MDTypography from "../../../../components/MDTypography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import ImagesAndFilesBlock from "./ImagesAndFilesBlock";
import DropZone from "../../../../components/DropZone/dropzone";
import {FileSize} from "../../../../utilities/constants";
import MDButton from "../../../../components/MDButton";
import Select from 'react-select'

interface PropsInterface {
  isEditMode: boolean,
  stageNumber: number,
  setOpenDialog: any,
}

export interface ImageAndFileInterface {
  id: number
  fileName: string
  checked?: boolean
}

interface ValuesInterface {
  title: string
  description: string
  productionStart: string
  productionEnd: string
  customerPrice: number
  productionCost: number
  requestCollectionEnd: string
  requestCollectionStart: string
  paymentType: StagePaymentType
  typeOfWorkId: number
  routeId: number
  files: any[]
  images: any[]
}

const emptyValues = {
  title: '',
  description: '',
  productionStart: '',
  productionEnd: '',
  customerPrice: 0,
  productionCost: 0,
  requestCollectionEnd: '',
  requestCollectionStart: '',
  paymentType: StagePaymentType.PREPAYMENT,
  typeOfWorkId: 0,
  routeId: 0,
  // @ts-ignore
  files: [], images: [],
}

const labelStyle = {marginBottom: 6}

const StageForm = ({isEditMode = false, stageNumber, setOpenDialog,}: PropsInterface): JSX.Element => {
  const {getTypesOfWorksForAdmin, createStageAction} = useAppDispatch();
  const {typesWorks, route: {oneRoute}} = useAppSelector(state => state);
  const [values, setValues] = useState<ValuesInterface>(emptyValues);
  const {title, description, productionStart, productionEnd, customerPrice, typeOfWorkId,
    productionCost, requestCollectionEnd, requestCollectionStart, paymentType} = values;

  const [newImages, setNewImages] = useState([]);
  const [newFiles, setNewFiles] = useState([]);

  const [existingFiles, setExistingFiles] = useState<ImageAndFileInterface[]>([]);
  const [existingImages, setExistingImages] = useState<ImageAndFileInterface[]>([]);

  const paymentTypesForSelect = [
    {value: StagePaymentType.PREPAYMENT, label: 'Предоплата'},
    {value: StagePaymentType.POSTPAID, label: 'Постоплата'},
    {value: StagePaymentType.PARTIAL_ADVANCE, label: 'Частичная предоплата'},
  ]
  interface SelectOption {
    value: number
    label: string
  }
  const [typesOfWorksForSelect, setTypesOfWorksForSelect] = useState<SelectOption[]>([]);

  const createNewObjectFromExisting = (existingArray: any[]) => {
    return existingArray?.map(i => ({
      id: i.id,
      fileName: i.fileName,
      checked: true,
    }))
  }

  const prepareTypesOfWorksOptions = async () => {
    console.log('prepareTypesOfWorksOptions - 1')

      await getTypesOfWorksForAdmin();

    setTimeout(() => {
      console.log('prepareTypesOfWorksOptions - 2', JSON.stringify(typesWorks))
    }, 500)
    const res = typesWorks.map(t => ({value: t.id, label: t.title}));
    console.log('prepareTypesOfWorksOptions - 3', JSON.stringify(res))
    setTypesOfWorksForSelect(res);
  }

  useEffect(() => {
    const res = typesWorks.map(t => ({value: t.id, label: t.title}));
    console.log('prepareTypesOfWorksOptions', JSON.stringify(res))
    setTypesOfWorksForSelect(res);
  }, [typesWorks.length])

  useEffect(() => {
    getTypesOfWorksForAdmin();
  }, [])

  useEffect(() => {
    // const files = createNewObjectFromExisting(oneRoute["preOrder"]['files']);
    // const images = createNewObjectFromExisting(oneRoute["preOrder"]['images']);
    // console.log('CREATED ARRAY OF files', JSON.stringify(files));
    // console.log('CREATED ARRAY OF images', JSON.stringify(images));
    setExistingFiles(oneRoute["preOrder"]['files']);
    setExistingImages(oneRoute["preOrder"]['images']);
  }, [oneRoute])

  const handleCreateStage = async () => {
    let formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('productionStart', productionStart);
    formData.append('productionEnd', productionEnd);
    formData.append('customerPrice', customerPrice.toString());
    formData.append('paymentType', paymentType);
    formData.append('productionCost', productionCost.toString());
    formData.append('requestCollectionStart', requestCollectionStart);
    formData.append('requestCollectionEnd', requestCollectionEnd);
    formData.append('typeOfWorkId', typeOfWorkId.toString());
    //@ts-ignore
    formData.append('detailId', oneRoute?.detail.id);
    //@ts-ignore
    formData.append('routeId', oneRoute?.id);
    formData.append('number', stageNumber.toString());

    //@ts-ignore
    if (oneRoute?.preOrder?.ownerCompany?.id) {
      //@ts-ignore
      formData.append('ownerCompanyId', oneRoute?.preOrder.ownerCompany.id);
    //@ts-ignore
    } else if (oneRoute?.preOrder.ownerUser.id) {
      //@ts-ignore
      formData.append('ownerId', oneRoute?.preOrder.ownerUser.id);
    }

    if (existingFiles.length > 0) {
      existingFiles.map(f => {
        console.log('EEEEEEEE FFFFFFFF - ', JSON.stringify(f));
        f.checked && formData.append('files', JSON.stringify(f.id))
      });
    }
    if (existingImages.length > 0) {
      existingImages.map(i => {
        console.log('EEEEEEEE IIIIIIII - ', JSON.stringify(i));
        i.checked && formData.append('images', JSON.stringify(i.id))
      })
    }

    if (newImages?.length > 0) {
      for(let i=0; i<newImages.length; i++) {
        formData.append('newImages', newImages[i].raw);
      }
    }
    if (newFiles?.length > 0) {
      for(let i=0; i<newFiles.length; i++) {
        formData.append('newFiles', newFiles[i].raw);
      }
    }
    const res = await createStageAction(formData);
    if (res) return setOpenDialog(false);

  }

  return(
    <Grid container m={1} justifyContent={'space-evenly'} spacing={3}>
      <Grid item xs={12} lg={8} xl={8} md={8} sm={6}>
        <InputLabel id="title" style={labelStyle}>Название этапа:</InputLabel>
        <MDInput
          //labelId="title"
          variant="outlined"
          value={title}
          style={{height: 40, width: '100%'}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValues(prev => ({...prev, title: e.target.value}))}/>
      </Grid>
      <Grid item xs={12} lg={2} xl={2} md={2} sm={3}>
        <InputLabel id="customerPrice" style={labelStyle}>Цена для заказчика:</InputLabel>
        <MDInput
          //labelId="customerPrice"
          variant="outlined"
          value={customerPrice}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => (
            (e.target.value.match(/^[0-9]+$/)) && setValues(prev => ({...prev, customerPrice: Number(e.target.value)}))
          )}/>
      </Grid>
      <Grid item xs={12} lg={2} xl={2} md={2} sm={3}>
        <InputLabel id="productionCost" style={labelStyle}>Стоимость производства:</InputLabel>
        <MDInput
          variant="outlined"
          //labelId="productionCost"
          value={productionCost}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => (
            (e.target.value.match(/^[0-9]+$/)) && setValues(prev => ({...prev, productionCost: Number(e.target.value)}))
          )}/>
      </Grid>
      <Grid item xs={12} lg={6} xl={6} md={6} sm={6}>
        <InputLabel id="typesWorks" style={labelStyle}>Тип работы:</InputLabel>
        <Select
          //labelId="typesWorks"
          options={typesOfWorksForSelect}
          name='typesWorks'
          // size={'medium'}
          // style={{height: 40, width: '100%'}}
          onChange={(e: {value: number, label: string}) => setValues(prev => ({...prev, typeOfWorkId: e.value}))}
        />
        {/*  <MenuItem defaultChecked disabled value={0}>Выбрать</MenuItem>*/}
        {/*  {*/}
        {/*    typesWorks && (typesWorks?.length > 0 && typesWorks?.map( t => <MenuItem value={t.id} key={t.id}>{t.title}</MenuItem>))*/}
        {/*  }*/}
        {/*</Select>*/}
      </Grid>
      <Grid item xs={12} lg={6} xl={6} md={6} sm={6}>
        <InputLabel id="paymentType" style={labelStyle}>Тип оплаты:</InputLabel>
        <Select
          //labelId="paymentType"
          options={paymentTypesForSelect}
          name='paymentType'
          // size={'medium'}
          // style={{height: 40, width: '100%'}}
          onChange={(e: {value: StagePaymentType, label: string}) => setValues(prev => ({...prev, paymentType: e.value}))}
        />
        {/*  <MenuItem defaultChecked value={StagePaymentType.PREPAYMENT}>Предоплата</MenuItem>*/}
        {/*  <MenuItem defaultChecked value={StagePaymentType.PARTIAL_ADVANCE}>Частичная оплата</MenuItem>*/}
        {/*  <MenuItem defaultChecked value={StagePaymentType.POSTPAID}>Постоплата</MenuItem>*/}
        {/*</Select>*/}
      </Grid>
      <Grid item xs={12} lg={3} xl={3} md={6}>
        <InputLabel id="productionStart" style={labelStyle}>Начало производства:</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
          <DatePicker
            value={productionStart}
            views={['year', 'month', 'day']}
            onChange={(newValue) => {
              setValues(prev => ({...prev, productionStart: newValue}));
            }}
            renderInput={(params) => <TextField {...params} />}
           />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={3} xl={3} md={6}>
        <InputLabel id="productionStart" style={labelStyle}>Завершение производства:</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
          <DatePicker
            value={productionEnd}
            views={['year', 'month', 'day']}
            onChange={(newValue) => {
              setValues(prev => ({...prev, productionEnd: newValue}));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={3} xl={3} md={6}>
        <InputLabel id="productionStart" style={labelStyle}>Начало подачи заявок:</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
          <DatePicker
            value={requestCollectionStart}
            views={['year', 'month', 'day']}
            onChange={(newValue) => {
              setValues(prev => ({...prev, requestCollectionStart: newValue}));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={3} xl={3} md={6}>
        <InputLabel id="productionStart" style={labelStyle}>Окончание подачи заявок:</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
          <DatePicker
            value={requestCollectionEnd}
            views={['year', 'month', 'day']}
            onChange={(newValue) => {
              setValues(prev => ({...prev, requestCollectionEnd: newValue}));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
        <InputLabel id="description" style={labelStyle}>Описание этапа:</InputLabel>
        <TextField
          variant="outlined"
          type='text'
          value={description}
          fullWidth
          multiline={true}
          rows="3"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValues(prev => ({...prev, description: e.target.value}))}
        />
      </Grid>
      <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
        <ImagesAndFilesBlock
          existingFiles={existingFiles}
          existingImages={existingImages}
          setExistingFiles={setExistingFiles}
          setExistingImages={setExistingImages}
          ownerId={oneRoute['preOrder']['ownerUser']['id']}
        />
      </Grid>
      <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
        <MDTypography variant="h6" mt={4} mb={2}>
          Добавить изображения
        </MDTypography>
        <DropZone  files={newImages} setFiles={setNewImages} isEditMode={false} isImage={true} sizeLimit={FileSize.MB_5} />
      </Grid>
      <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
        <MDTypography variant="h6" mt={4} mb={2}>
          Добавить файлы
        </MDTypography>
        <DropZone  files={newFiles} setFiles={setNewFiles} isEditMode={false} isImage={false} sizeLimit={FileSize.MB_8} />
      </Grid>
      <Grid container justifyContent={'right'} mt={4}>
        <Grid item md={3} p={2}>
          <MDButton variant="gradient" color="info" fullWidth onClick={() => handleCreateStage()}>
            Сохранить
          </MDButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StageForm;