import React, {ChangeEvent, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {PreOrderStatus} from "../../../../../store/types/pre-order.types";
import {getPreOrders} from "../../../../../store/actions/pre-order.actions";
import {Card, MenuItem, Select, TextField} from "@mui/material";
import MDBox from "../../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import {RouteStatus} from "../../../../../store/types/route.type";
import MDButton from "../../../../../components/MDButton";
import {StagesOrderBy, StagesQuery, StagesSortBy, StageStatuses} from "../../../../../store/types/stage.types";

const inputStyles = {
  width: '100%',
}

enum queryMakerNamesEnum {
  search,
  status,
  id,
  route,
  companyProducer,
  detail,
  typeOfWork,
  userProducer,
}

interface queryMakerInterface {
  name: queryMakerNamesEnum,
  value: string | number
}

function StagesSearchHeader() {
  const {setQueryForStage, setSortByForStage, setOrderByForStage, getAllStages} = useAppDispatch();
  const {stages: {skip, take, order_by, sort_by, query}} = useAppSelector(state => state);

  const [status, setStatus] = useState<StageStatuses | ''>('');
  const [id, setId] = useState<number>(0);
  const [route, setRoute] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [userProducer, setUserProducer] = useState<number>(0);
  const [companyProducer, setCompanyProducer] = useState<number>(0);
  const [detail, setDetail] = useState<number>(0);
  const [typeOfWork, setTypeOfWork] = useState<number>(0);

  const clearQuery = () => {
    setId(0);
    setSearch('');
    setStatus('');
    setRoute(0);
    setUserProducer(0);
    setCompanyProducer(0);
    setDetail(0);
    setTypeOfWork(0);
    getAllStages();
  }

  // const saveQueryDataHandler = ({name, value}: queryMakerInterface) => {
  //   let rawQuery = ``
  //
  //   function setALlStates() {
  //     search.length && rawQuery.concat(`&search=${search}`);
  //     status.length && rawQuery.concat(`&status=${status}`);
  //     (id !== 0) && rawQuery.concat(`&id=${id}`);
  //     (route !== 0) && rawQuery.concat(`&route=${route}`);
  //     (companyProducer !== 0) && rawQuery.concat(`&companyProducer=${companyProducer}`);
  //     (detail !== 0) && rawQuery.concat(`&detail=${detail}`);
  //     (typeOfWork !== 0) && rawQuery.concat(`&typeOfWork=${typeOfWork}`);
  //     (userProducer !== 0) && rawQuery.concat(`&userProducer=${userProducer}`);
  //
  //     console.log('RAW QUERY --- ', rawQuery)
  //
  //     return setQueryForStage(rawQuery);
  //   }
  //
  //   switch (name) {
  //     case queryMakerNamesEnum.search:
  //       rawQuery = rawQuery + `&search=${value}`;
  //       setSearch(value as string);
  //       return setALlStates();
  //     case queryMakerNamesEnum.status:
  //       rawQuery = rawQuery + `&status=${value}`;
  //       setStatus(value as StageStatuses);
  //       return setALlStates();
  //     case queryMakerNamesEnum.id:
  //       rawQuery = rawQuery + `&id=${value}`;
  //       setId(value as number);
  //       return setALlStates();
  //     case queryMakerNamesEnum.route:
  //       rawQuery = rawQuery + `&route=${value}`;
  //       setRoute(value as number);
  //       return setALlStates();
  //     case queryMakerNamesEnum.companyProducer:
  //       rawQuery = rawQuery + `&companyProducer=${value}`;
  //       setCompanyProducer(value as number);
  //       return setALlStates();
  //     case queryMakerNamesEnum.detail:
  //       rawQuery = rawQuery + `&detail=${value}`;
  //       setDetail(value as number);
  //       return setALlStates();
  //     case queryMakerNamesEnum.typeOfWork:
  //       rawQuery = rawQuery + `&typeOfWork=${value}`;
  //       setTypeOfWork(value as number);
  //       return setALlStates();
  //     case queryMakerNamesEnum.userProducer:
  //       rawQuery = rawQuery + `&userProducer=${value}`;
  //       setUserProducer(value as number);
  //       return setALlStates();
  //   }
  //
  // }

  function createQueryFromStates() {
    let rawQuery = ``

    search.length && (rawQuery = rawQuery.concat(`&search=${encodeURI(search)}`));
    (status !== '') && (rawQuery = rawQuery.concat(`&status=${status}`));
    (id !== 0) && (rawQuery = rawQuery.concat(`&id=${id}`));
    (route !== 0) && (rawQuery = rawQuery.concat(`&route=${route}`));
    (companyProducer !== 0) && (rawQuery = rawQuery.concat(`&companyProducer=${companyProducer}`));
    (detail !== 0) && (rawQuery = rawQuery.concat(`&detail=${detail}`));
    (typeOfWork !== 0) && (rawQuery = rawQuery.concat(`&typeOfWork=${typeOfWork}`));
    (userProducer !== 0) && (rawQuery = rawQuery.concat(`&userProducer=${userProducer}`));

    console.log('raw Query --- ', rawQuery)
    console.log('search.length --- ', search.length)

    return rawQuery;
  }

  const handleSearch = async () => {
    const rawQuery = createQueryFromStates();

    setQueryForStage(rawQuery);

    console.log('raw Query --- ', rawQuery)
    console.log('QUERY --- ', query)

    let readyQuery = `?take=${take}&skip=${skip}&order_by=${order_by}&sort_by=${sort_by}`
    rawQuery?.length && (readyQuery = readyQuery.concat(rawQuery));

    getAllStages(readyQuery)
  }

  return (
    <>
      <Card id="basic" style={{scrollMarginTop: 90}}>
        <MDBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Поиск по названию маршрута или предзаказа"
                name={"search"}
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="ID этапа"
                name="id"
                value={id}
                onChange={(e: ChangeEvent<HTMLInputElement>) => (typeof Number(e.target.value) === 'number' && setId(Number(e.target.value)))}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="ID маршрута"
                name="id"
                value={route}
                onChange={(e: ChangeEvent<HTMLInputElement>) => (typeof e.target.value === 'number' && setRoute(e.target.value))}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="ID пользователя - производителя"
                value={userProducer}
                onChange={(e) => (typeof e.target.value === 'number' && setUserProducer(e.target.value))}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="ID компании - производителя"
                value={companyProducer}
                onChange={(e) => (typeof e.target.value === 'number' && setCompanyProducer(e.target.value))}
                style={inputStyles}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                value={status}
                name='status'
                label="Статус"
                size={'medium'}
                style={{height: 40, width: 140}}
                //@ts-ignore
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem defaultChecked value={''}>Пусто</MenuItem>
                <MenuItem value={StageStatuses.CREATED} >СОЗДАН</MenuItem>
                <MenuItem value={StageStatuses.CLOSED_CANCELED_BY_CUSTOMER} >ОТМЕНЕН ЗАКАЗЧИКОМ</MenuItem>
                <MenuItem value={StageStatuses.CLOSED_CANCELED_BY_MANAGER} >ОТМЕНЕН МЕНЕДЖЕРОМ</MenuItem>
                <MenuItem value={StageStatuses.CLOSED_CANCELED_BY_PRODUCER} >ОТМЕНЕН ПРОРИЗВОДИТЕЛЕМ</MenuItem>
                <MenuItem value={StageStatuses.CONVERTED_TO_ORDER} >ПЕРЕДАН В ЗАКАЗ</MenuItem>
                <MenuItem value={StageStatuses.CLOSED_SUCCESS} >ВЫПОЛНЕН И ЗАКРЫТ</MenuItem>
                <MenuItem value={StageStatuses.PROBLEM} >ПРОБЛЕМА</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" mt={2} mb={2}>
            <Grid item xs={12} md={4} textAlign="center">
              <MDButton variant={'contained'} color={'info'} size={'small'} onClick={() => handleSearch()}>
                Поиск
              </MDButton>
            </Grid>
            <Grid item xs={12} md={4} textAlign="center">
              <MDButton variant={'outlined'} color={'info'} size={'small'} onClick={() => clearQuery()}>
                Сбросить
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </>
  )
}

export default StagesSearchHeader;