import React from 'react';
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import {ShortStageInterface} from "../../../../store/reducers/stage.reducer";
import MDBadge from "../../../../components/MDBadge";
import {
  convertFromStringToBeautyDateString,
  getStageStatus
} from "../../../../utilities/converters";
import ModeIcon from '@mui/icons-material/Mode';
import {IconButton} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";

export enum StageBoxColors {
  GREY = '#D5D7D5',
  GREEN = '#AADAD3',
  RED = '#F9B5B4',
  BLUE = '#BCE0F0',
  YELLOW = '#FFEB99',
}

export enum StageStatusColors {
  GREY = '#B6B9B6',
  GREEN = '#7FC7BC',
  RED = '#F47D7B',
  BLUE = '#8BCAE5',
  YELLOW = '#FFDE5C',
  PURPLE = '#9F9FD1',
  MILITARY = '#B9C9A6',
  OCEAN = '#66C2E1',
}

interface PropsInterface {
  stage: ShortStageInterface,
  boxColor?: StageBoxColors,
  isActive?: boolean,
  openToEdit?: any,
}

const ArrowBox = ({ stage, boxColor = StageBoxColors.GREY, isActive = false, openToEdit }: PropsInterface): JSX.Element => {
  const navigate = useNavigate();
  const routeArrowBoxStyle = {
    //minHeight: 200,
    height: 300,
    minWidth: 300,
    backgroundColor: boxColor,
    display: 'inline-block',
    clipPath: isActive ? 'polygon(0% 0%, 96% 0, 100% 50%, 96% 100%, 0% 100%)' : 'polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%)'
  }

  const handleNavigate = () => {
    navigate(`admin/stages/${stage?.id}`)
  }

  const {color, ruStatus} = getStageStatus(stage.status);

  return(
    <Grid item xs={12} md={3} lg={2} m={1} p={2} pr={4} style={routeArrowBoxStyle}>
      <MDBox >
        <MDTypography variant="caption" fontWeight="light">
          № этапа:{' '}<strong>{stage.number}</strong>
        </MDTypography>
        <Grid container>
          <Grid item xs={6}>
            <MDBox mt={1} mb={1}>
              <MDBadge variant="contained" color={color} badgeContent={ruStatus} container/>
            </MDBox>
          </Grid>
          <Grid item xs={6} pt={1}>
            <Link to={`/admin/stages/${stage?.id}`}>
            {/*<IconButton aria-label="delete" color="info" size="small" onClick={() => handleNavigate()}>*/}
              <ModeIcon />
            {/*</IconButton>*/}
            </Link>
          </Grid>
        </Grid>
        <MDTypography variant="body2" fontWeight="medium" textTransform="capitalize">
          {stage.title}
        </MDTypography>
        <Grid container>
          <Grid item xs={12}>
            <MDTypography variant="caption" fontWeight="light" textTransform="capitalize">
              {stage.detail.title}{' - '}{stage.detail.quantity}шт.
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="caption" fontWeight="light" textTransform="capitalize">
              {stage.detail.material.title}{' - '}{stage.detail.shape.title}
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <MDTypography variant="caption" fontWeight="light">
              начало:{' '}<strong>{convertFromStringToBeautyDateString(stage.productionStart)}</strong>
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="caption" fontWeight="light">
              конец:{' '}<strong>{convertFromStringToBeautyDateString(stage.productionEnd)}</strong>
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </Grid>
  )
}

export default ArrowBox;