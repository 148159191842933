import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import Navbar from "../../../layout/Navbar/DefaultNavbar/index";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

// Images
import bgImage from "assets/img/inst3-bg.jpg";

// Declaring props types for Header
interface Props {
  children: ReactNode;
}

function Header({children }: Props): JSX.Element {
  return (
    <>
      <MDBox
        position="relative"
        minHeight="50vh"
        height="50vh"
        borderRadius="xl"
        m={2}
        pt={2}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { black } }) =>
            `${linearGradient(rgba(black.main, 0.25), rgba(black.main, 0.25))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
      </MDBox>
      <Grid container sx={{ px: 6, my: 8 }}>
        <Grid item xs={12}>
          <Card sx={{ mt: -16 }}>
            <MDBox minWidth={{ xs: "22rem", md: "25rem" }} mx="auto" mt={6} >
              <Grid item xs={12} lg={12} mb={5}>
                <MDBox mb={1}>
                  <MDTypography variant="h2" color="inherit" fontWeight="bold">
                    Заказы на промышленную обработку
                  </MDTypography>
                </MDBox>
                <MDBox mb={2} textAlign={'center'}>
                  <MDTypography variant="body2" color="inherit" fontWeight="light" >
                    Заказывайте и выполняйте заказы по промышленной обработке.
                  </MDTypography>
                </MDBox>
              </Grid>
            </MDBox>
            {children}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
