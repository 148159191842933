import {AlertColorStatuses} from "./common.types";

export enum AlertReducerTypes {
  OPEN= 'OPEN',
  CLOSE = 'CLOSE',
}

export enum AlertDurations {
  FIVE_SECONDS = 5000,
  FOUR_SECONDS = 4000,
  THREE_SECONDS = 3000,
  TWO_SECONDS = 2000
}

export enum AlertStatuses {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

export type AlertPayload = AlertOpenPayload | AlertClosePayload

interface AlertOpenPayload {
  type: AlertReducerTypes.OPEN,
  payload: {
    status: AlertColorStatuses,
    message: string
  }
}

interface AlertClosePayload {
  type: AlertReducerTypes.CLOSE
}

