import {MaterialInterface, MaterialPayload, MaterialReducerTypes} from "../types/material.types";

export interface MaterialStateInterface {
  allMaterials: MaterialInterface[]
}

export const emptyMaterialState: MaterialStateInterface = {
  allMaterials: []
}

const materialReducer = (state: MaterialStateInterface = emptyMaterialState, action: MaterialPayload) => {
  switch (action.type) {
    case MaterialReducerTypes.CLEAR_MATERIALS:
      return {...state, allMaterials: []}
    case MaterialReducerTypes.GET_MATERIALS:
      return {...state, allMaterials: action.payload}
    default:
      return state;
  }
}

export default materialReducer;