// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "../components/BaseLayout";
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import SocialProfileInfo from "./components/SocialProfileInfo";
import ChangePassword from "./components/Address";
import Authentication from "./components/Authentication";
import Accounts from "./components/Compamy";
import Notifications from "./components/Invites";
import DashboardLayout from "../../dashboard/DashboardLayout";
import {useEffect, useState} from "react";
import {getUser} from "../../../store/actions/user.actions";
import {useAppDispatch, useAppSelector} from "../../../hooks/useActions";
import DeliveryAddress from "./components/Address";
import Address from "./components/Address";
import {UserStateInterface} from "../../../store/reducers/user.reducer";
import BankDetails from "./components/BankDetails";
import Company from "./components/Compamy";
import Invites from "./components/Invites";
import ProfileAndTypesOfWorks from "./components/ProfileAndTypesOfWorks";

function AccountSettings(): JSX.Element {
  const {getUser} = useAppDispatch();
  const {user} = useAppSelector(state => state)

  const [isInvited, setIsInvited] = useState(false);

  useEffect(() => {
    if (!user || (user && user['id'] === 0)) {
      getUser();
    }
  }, [])

  useEffect(() => {
    //@ts-ignore
    if (user["invitedFromCompanies"].length > 0) {
      setIsInvited(true)
    } else {
      setIsInvited(false)
    }
  }, [user["invitedFromCompanies"]]);

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header />
                </Grid>
                <Grid item xs={12}>
                  <ProfileAndTypesOfWorks />
                </Grid>
                {
                  isInvited && <Grid item xs={12}>
                      <Invites list={user["invitedFromCompanies"]} />
                    </Grid>
                }
                <Grid item xs={12}>
                  <SocialProfileInfo user={user} />
                </Grid>
                <Grid item xs={12}>
                  <Address isDeliveryAddress={true} />
                </Grid>
                <Grid item xs={12}>
                  <Address isDeliveryAddress={false} />
                </Grid>
                <Grid item xs={12}>
                  <BankDetails />
                </Grid>
                <Grid item xs={12}>
                  <Company />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AccountSettings;
