// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/img/auth-bg.jpg";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useStringInput} from "../../hooks/useInput";
import {ValidateEmail} from "../../utilities/validators";
import { useNavigate } from 'react-router-dom';
import $api from "../../utilities/http.axios";
import {CircularProgress, Grid} from "@mui/material";
import {useAppSelector} from "../../hooks/useActions";

function ForgotPassword(): JSX.Element {
  let navigate = useNavigate();
  const email = useStringInput('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {auth: {isAuth}} = useAppSelector(state => state);

  useEffect(() => {
    isAuth && navigate('/dashboard');
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    email.onChange(e);
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (ValidateEmail(email.value)) {
      try {
        setIsLoading(true);
        const res = await $api.post(`/auth/reset-password`, {email: email.value})
        setIsLoading(false);
        if (!res.data.error) {
          setIsSuccess(true);
          setTimeout(() => {
            navigate('/');
          }, 2000)
        }
      } catch (e) {
        setIsLoading(false);
        console.log(`WHAT IS ---- ${e}`)
        setIsError(true);
      }
    }
  }


  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor={isSuccess ? 'success' : (isError ? 'warning' : 'info')}
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
          style={{paddingLeft: 10, paddingRight: 10}}
        >
          {
            isSuccess ?
              <MDTypography fontWeight="medium" variant="h6" color="white" my={1}>
                Ссылка для восстановления пароля отправлена на ваш Емейл
              </MDTypography>
              :
              (
                isError ?
                  <MDTypography fontWeight="medium" variant="h6" color="white" my={1}>
                    Ошибка. Возможно, такой емейл не зарегистрирован.
                  </MDTypography>
                :
                  <>
                  <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Сброс пароля
                  </MDTypography>
                  <MDTypography display="block" variant="button" color="white" my={1}>
                  Укажите емейл для получения ссылки для восстановления пароля
                  </MDTypography>
                  </>
              )
          }
        </MDBox>
        {
          (!isError && !isSuccess) &&
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={4}>
                          {
                            isLoading ?
                              <Grid container alignContent={'center'}>
                                <Grid item >
                                  <CircularProgress color="info" />
                                </Grid>
                              </Grid>
                              :
                              <MDInput
                                type="email"
                                label="Email"
                                name='email'
                                {...email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                                variant="standard"
                                fullWidth
                              />
                          }
                        </MDBox>
                        <MDBox mt={6} mb={1}>
                            <MDButton
                                variant="gradient"
                                color="info"
                                fullWidth
                                onClick={(e: React.MouseEvent<HTMLElement>) => handleSubmit(e)}
                            >
                                Сбросить
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                <MDTypography
                                    component={Link}
                                    to="/authentication/login"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Назад к авторизации
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>

        }
      </Card>
    </CoverLayout>
  );
}

export default ForgotPassword;
