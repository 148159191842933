import React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

function UsersTableHeader() {

  const smallTextStyle = {
    fontSize: 14,
    fontWeight: 'bold'
  }

  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell align="center" style={smallTextStyle}>Ник</TableCell>
      <TableCell align="center" style={smallTextStyle}>Емейл</TableCell>
      <TableCell align="center" style={smallTextStyle}>Статус</TableCell>
      <TableCell align="center" style={smallTextStyle}>Роли</TableCell>
      <TableCell align="center" style={smallTextStyle}>Как заказчик</TableCell>
      <TableCell align="center" style={smallTextStyle}>Как подрядчик</TableCell>
      <TableCell align="center" style={smallTextStyle}>Подробнее</TableCell>
    </TableRow>
  );
}

export default UsersTableHeader;