import {Dispatch} from "redux";
import {ProjectReducerTypes} from "../types/project.types";
import {AlertPayload, AlertReducerTypes} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {AlertColorStatuses} from "../types/common.types";
import {CreateOfferInterface, OfferPayload, OfferReducerTypes} from "../types/offer.type";
import {store} from "../index";

export const createOffer = (newOffer: CreateOfferInterface) => {
  return async (dispatch: Dispatch<OfferPayload | AlertPayload>) => {
    try {
      const res = await $api.post(`/offer/create`, newOffer);
      dispatch({type: OfferReducerTypes.CREATE_OFFER, payload: res.data});
      return {error: res.data.error, message: res.data.error ? res.data.message : 'Ваша заявка успешно размещена'};
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
      return {error: true, message: axiosErrorMessageHandler(err)};
    }
  }
}

export const getMyOffers = ({query, take}: {query?: string, take?: number}) => {
  return async (dispatch: Dispatch<OfferPayload | AlertPayload>) => {
    try {
      const state = store?.getState();
      // @ts-ignore
      let baseQueryString = `?skip=${state.offers.skip ?? 0}&take=${take ?? state.offers.take ?? 20}&order_by=${state.offers.order_by}&sort_by=${state.offers.sort_by}` + (query ? query : '');


      const res = await $api.get(`/offer/get-my-offers${baseQueryString}`);
      dispatch({type: OfferReducerTypes.GET_MY_OFFERS, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      return axiosErrorMessageHandler(err);
    }
  }
}

export const getMyOneOffer = (id: number) => {
  return async (dispatch: Dispatch<OfferPayload | AlertPayload>) => {
    try {
      dispatch({type: OfferReducerTypes.CLEAR_ONE_OFFER});
      const res = await $api.get(`/offer/get-my-one-offer/${id}`);
      dispatch({type: OfferReducerTypes.GET_ONE_OFFER, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const getOffersAdmin = ({take, skip, sort_by, order_by }: {take: number, skip: number, sort_by: string, order_by: 'des' | 'asc' }) => {
  return async (dispatch: Dispatch<OfferPayload | AlertPayload>) => {
    try {
      const res = await $api.get(`/offer/get-all-offers-admin&skip=${skip}&take=${take}&sort_by=${sort_by}&order_by=${order_by}`);
      dispatch({type: OfferReducerTypes.GET_OFFERS_ADMIN, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}