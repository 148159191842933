import React, {useEffect} from 'react';
import {updateUserByAdminBodyInterface, UserRolesFull} from "../../../../../store/types/user.types";
import {UserRoles} from "../../../../../utilities/constants";
import {Button, Chip, FormControl, Grid, InputLabel, MenuItem, Stack} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface PropsInterface {
  updateByAdmin: (id: number, body: updateUserByAdminBodyInterface) => void;
  id: number;
  roles: UserRolesFull[];
}

function ChangeRole({id, updateByAdmin, roles}: PropsInterface) {

  const [rolesToAdd, setRolesToAdd] = React.useState<UserRoles[]>([]);
  const [newRole, setNewRole] = React.useState<UserRoles | null>(null);

  function convertEnumToArray(userRoles: any){
    let all = [];
    // @ts-ignore
    for(let key: any in userRoles){
      all.push(userRoles[key]);
    }
    return all;
  }

  useEffect(() => {
    const rolesEnumConvertedToArray = convertEnumToArray(UserRoles);
    const userRoles = roles.map((role: UserRolesFull) => role.value);
    const rolesToAddArray = rolesEnumConvertedToArray.filter((role: UserRoles) => {
      return !userRoles.includes(role)
    });
    setRolesToAdd(rolesToAddArray);
  }, []);

  const handleAddRole = (roleToAdd: UserRoles) => {
    updateByAdmin(id, {addRole: roleToAdd})
  }

  const handleRemoveRole = (roleToRemove: UserRoles) => {
    updateByAdmin(id, {removeRole: roleToRemove})
  }

  return (
    <Grid container spacing={3} p={2}>

      <h4>Управление ролями</h4>

      <Grid item xs={12} mt={4} mb={4}>
        <p style={{fontSize: 14}}>Имеющиеся у пользователя роли:</p>

        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} flexWrap={"wrap"}>
              {
                roles ? roles?.map((role: UserRolesFull, index: number) => (
                  <Chip
                    key={index}
                    label={role.value}
                    style={{margin: '2px'}}
                    onDelete={() => handleRemoveRole(role.value)}
                  />
                )) : <p>Нет ролей</p>
              }
            </Stack>
          </Grid>
        </Grid>

      </Grid>

      <Grid item xs={12}>
        <p style={{fontSize: 14}}>Выберите роль для добавления пользователю:</p>
        <FormControl fullWidth>

          <Grid container>
            <Grid item xs={9} pr={2}>
              <Select
                sx={{height: '40px', width: '100%'}}
                value={newRole ?? ''}
                onChange={(e: SelectChangeEvent) => setNewRole(e.target.value as UserRoles)}
              >
                {
                  rolesToAdd && rolesToAdd?.map((role: UserRoles, index: number) => (

                    <MenuItem value={role} key={index}>{role}</MenuItem>
                  ))
                }
              </Select>
            </Grid>
            <Grid item xs={3} pr={2}>
              <Button
                variant="contained"
                sx={{color: 'white'}}
                color={'success'}
                onClick={() => handleAddRole(newRole)}
                disabled={!newRole}
              >
                Добавить
              </Button>
            </Grid>
          </Grid>

        </FormControl>

      </Grid>

    </Grid>
  );
}

export default ChangeRole;