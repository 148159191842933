import {ShapeInterface, ShapePayload, ShapeReducerTypes} from "../types/shape.types";

export interface ShapeStateInterface {
  allShapes: ShapeInterface[]
}

const emptyShapesState: ShapeStateInterface = {
  allShapes: []
}

const shapesReducer = (state: ShapeStateInterface = emptyShapesState, action: ShapePayload) => {
  switch (action.type) {
    case ShapeReducerTypes.GET_SHAPES:
      return {...state, allShapes: action.payload}
    case ShapeReducerTypes.CLEAR_SHAPES:
      return {...state, allShapes: []}
    default:
      return state;
  }
}

export default shapesReducer;
