import React, {useEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";
import MDTypography from "../MDTypography";
import {useAppDispatch, useAppSelector} from "../../hooks/useActions";
import {convertFromStringToBeautyDateTimeString} from "../../utilities/converters";
import {CommentInterface, CommentSenderRoles} from "../../store/types/comment.types.";
import CommentInput from "./CommentInput";
import Divider, {MarginSize} from "../Divider";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Tooltip} from "@mui/material";


const rootDivStyle = {
  marginTop: 30,
  marginLeft: 24,
  padding: 20,
  borderRadius: 8,
  backgroundColor: '#F8F6F6F9',
  minHeight: 300,
  maxHeight: 700,
  overflow: 'auto'
}

const commentLeftDivStyle = {
  marginTop: 10,
  marginLeft: 0,
  padding: 20,
  borderRadius: 8,
  backgroundColor: '#D7FACEBF',
  minHeight: 100,
  maxWidth: '80%',
}

const commentRightDivStyle = {
  marginTop: 10,
  marginLeft: '20%',
  padding: 20,
  borderRadius: 8,
  backgroundColor: '#C9F0F6BF',
  minHeight: 100,
  maxWidth: '80%',
}

const commentTextStyle = {
  fontSize: 18,
}

interface PropsInterface {
  getComments: (id: number) => void;
}

const CommentsBlock = ({getComments}: PropsInterface) => {
  const {preOrders: {onePreOrder: {id, comments}}, auth: {user}} = useAppSelector(state => state);
  const {setCommentsViewedByManagerPreOrder, getAllCommentsForPreOrder} = useAppDispatch();
  const ref = useRef(null);
  const [sent, setSent] = useState(false);
  const [updatedId, setUpdatedId] = useState<number | null>(null);

  const isCustomerCommentViewedByManager = useIsInViewport(ref);

  let timer: any;

  useEffect(() => {
    timer = setInterval(() => {
      (id && id !== 0) && getComments(id); //getAllCommentsForPreOrder(id);
    }, 120000);
    return () => clearInterval(timer);
  }, [id]);

  // useEffect(() => {
  //   return () => clearInterval(timer);
  // }, []);


  useEffect(() => {
    if (isCustomerCommentViewedByManager
      && !sent
      && (user.roles.some((r: any) => (r === 'ADMIN' || r === 'MANAGER')))
      // @ts-ignore
      && comments.some((c: any) => (c.senderRole === 'CUSTOMER' && c.viewed === false))
    ) {
      console.log('TRUE')
      setCommentsViewedByManagerPreOrder(id);
      setSent(true);
    }
  }, [isCustomerCommentViewedByManager])

  const managerComment = (comment: CommentInterface) => (
    <Grid item xs={12} lg={12} md={12} sx={{mx: "auto"}} style={commentLeftDivStyle} textAlign={'start'}
          key={comment.id}>
      <MDTypography variant="body1" fontWeight="medium">Менеджер:</MDTypography>
      <MDTypography variant="p" fontWeight="medium" fontSize={12}>
        {convertFromStringToBeautyDateTimeString(comment.createdAt)}
        {/*<VisibilityIcon color={comment.viewed ? 'primary' : 'disabled'} fontSize={'small'} />*/}
      </MDTypography>
      <br/>
      <MDTypography variant="p" fontWeight="light" style={commentTextStyle}>{comment.text}</MDTypography>
    </Grid>
  )

  const customerComment = (comment: CommentInterface) => (
    <Grid item xs={12} lg={12} md={12} sx={{mx: "auto"}} style={commentRightDivStyle} textAlign={'end'}
          key={comment.id}>
      <MDTypography variant="body1" fontWeight="medium">{comment.sender.nickname}:</MDTypography>
      <MDTypography variant="p" fontWeight="medium" fontSize={12}>
        {convertFromStringToBeautyDateTimeString(comment.createdAt)}
        <Tooltip title={comment.viewed ? 'Просмотрено администратором' : 'Еще не просмотрено администратором'} placement="top-start">
          <VisibilityIcon color={comment.viewed ? 'primary' : 'disabled'} fontSize={'small'}/>
        </Tooltip>
      </MDTypography>
      <br/>
      <MDTypography variant="p" fontWeight="light" style={commentTextStyle}>{comment.text}</MDTypography>
    </Grid>
  )


  return (
    <div style={{width: '100%'}}>
      <Grid container justifyContent={'center'} textAlign={'center'} mt={6} mb={1}>
        <MDTypography variant="h5" fontWeight="medium" tex="true">Комментарии к предзаказу:</MDTypography>
      </Grid>
      <Grid sx={{mx: "auto"}} style={rootDivStyle}>
        <Grid container justifyContent={'space-between'}>
          {
            // @ts-ignore
            comments ? comments?.sort(function (a, b) {
                return a.id - b.id
              }).map((c: any) => {
                if (c.senderRole === CommentSenderRoles.CUSTOMER) {
                  return customerComment(c);
                }
                if (c.senderRole === CommentSenderRoles.MANAGER) {
                  return managerComment(c);
                }
              })
              :
              <MDTypography variant="p" fontWeight="medium" tex>Нет комментариев</MDTypography>
          }
        </Grid>
        <div ref={ref}/>
        <Divider marginSize={MarginSize.small}/>
        <CommentInput/>
      </Grid>
    </div>
  )
}

export default CommentsBlock;

function useIsInViewport(ref: any) {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}