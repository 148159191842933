// react-routers components
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import noAvatar from "../../../../../assets/img/no-avatar.svg";
import FormField from "../../../../../layouts/pages/account/components/FormField";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {ValidateEmail} from "../../../../../utilities/validators";
import {CircularProgress, Snackbar} from "@mui/material";
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {deleteInvitation} from "../../../../../store/actions/company.actions";
import {AlertDurations} from "../../../../../store/types/alert.types";
import {AlertColorStatuses} from "../../../../../store/types/common.types";

interface ManagersInterface {
  avatar: string;
  fio: string;
  nickname: string,
  id: number
}

interface ListInvitedInterface {
  invitedManager: {
    id: number,
    avatar: string,
    nickname: string,
    fio: string
  }
}

// Declaring props types for ProfilesList
interface Props {
  shadow?: boolean;

  [key: string]: any;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ManagersList({shadow}: Props): JSX.Element {

  const {
    createInvitationForManager,
    getManagers,
    getInvitedToBeManagers,
    deleteInvitation,
    deleteManager,
    alertActions
  } = useAppDispatch();

  const {user} = useAppSelector(state => state);

  const [managersLoading, setManagersLoading] = useState(false);
  const [invitedLoading, setInvitedLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [managers, setManagers] = useState<ManagersInterface[]>([])
  const [invitedToBeManagers, setInvitedToBeManagers] = useState<ListInvitedInterface[]>([])

  const getListOfManagers = async () => {
    try {
      setManagersLoading(true);
      const manags = await getManagers();
      // @ts-ignore
      setManagers(manags);
    } catch (e) {
      console.log(`ERROR ${JSON.stringify(e)}`);
    }
  }

  const getListOfInvited = async () => {
    try {
      setInvitedLoading(true);
      const invites = await getInvitedToBeManagers();
      // @ts-ignore
      setInvitedToBeManagers(invites);
    } catch (e) {
      console.log(`ERROR ${JSON.stringify(e)}`);
    }
  }

  useEffect(() => {
    getListOfManagers()
      .then(r => setManagersLoading(false))
      .then(r => getListOfInvited())
      .then(r => setInvitedLoading(false));
  }, []);

  const handleInvite = async () => {
    if (user["email"] && (user["email"] === email)) {
      return setErrorText('Вы не можете пригласить самого себя');
    }
    const isEmail = ValidateEmail(email);
    if (email && isEmail) {
      setIsError(false);
      setErrorText('');
      setEmail('');
      alertActions(AlertColorStatuses.SUCCESS, 'Приглашение отправлено', AlertDurations.TWO_SECONDS)
      await createInvitationForManager(email);
      getListOfInvited().then(r => setInvitedLoading(false));
    }
    setIsError(true);
    setErrorText('Введите емейл');
    alertActions(AlertColorStatuses.WARNING, 'Некорретный формат Емейл', AlertDurations.TWO_SECONDS)
  }

  const handleRemoveManager = async (id: number) => {
    await deleteManager(id);
    getListOfManagers().then(r => {
      setManagersLoading(false);
      alertActions(AlertColorStatuses.SUCCESS, 'Менеджер удален', AlertDurations.TWO_SECONDS);
    });

  }

  const handleRemoveInvitation = async (id: number) => {
    await deleteInvitation(id);
    getListOfInvited().then(r => {
      setInvitedLoading(false);
      alertActions(AlertColorStatuses.SUCCESS, 'Приглашение удалено', AlertDurations.TWO_SECONDS);
    });
  }

  const renderProfiles = managers.map(({avatar, fio, nickname, id}) => (
    <MDBox key={id} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
        <MDAvatar src={
          avatar ? `http://localhost:5045/images/avatars/300x300/${avatar}` : noAvatar
        }
                  alt="something here"
                  shadow="md"
        />
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {nickname}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {fio}
        </MDTypography>
      </MDBox>
      <MDBox ml="auto">
        <MDButton variant="text" color="error" onClick={() => handleRemoveManager(id)}>
          Удалить
        </MDButton>
      </MDBox>
    </MDBox>
  ));

  const renderInvitedProfiles = invitedToBeManagers.map(({invitedManager: {avatar, fio, nickname, id}}) => (
    <MDBox key={id} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
        <MDAvatar src={
          avatar ? `http://localhost:5045/images/avatars/300x300/${avatar}` : noAvatar
        }
                  alt="something here"
                  shadow="md"
        />
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {nickname}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {fio || ''}
        </MDTypography>
      </MDBox>
      <MDBox ml="auto">
        <MDButton variant="text" color="warning" onClick={() => handleRemoveInvitation(id)}>
          Отменить
        </MDButton>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{height: "100%", boxShadow: !shadow && "none"}}>
      <MDBox p={2}>
        <MDTypography variant="caption" color="text">
          Подключенные менеджеры
        </MDTypography>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {
            managersLoading ?
              <CircularProgress color="secondary" />
              : (
                managers.length > 0 ? renderProfiles : <h6>Нет менеджеров</h6>
              )
          }
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <MDTypography variant="caption" color="text">
          Приглашенные менеджеры
        </MDTypography>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {
            invitedLoading ?
              <CircularProgress color="secondary" />
              : (
              invitedToBeManagers.length > 0 ? renderInvitedProfiles : <h6>Нет приглашений</h6>
              )
          }
        </MDBox>
      </MDBox>
      <MDBox pt={2} px={2}>
        <MDTypography variant="caption" color="text">
          Пригласить пользователя стать менеджером
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <FormField
          inputProps={{type: 'email'}}
          placeholder="Емейл пользователя"
          name={'email'}
          error={isError}
          label={errorText}
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />
        <MDButton variant="text" color="info" onClick={() => handleInvite()}>
          Отправить приглашение
        </MDButton>
      </MDBox>
    </Card>
  );
}

// Declaring defualt props for ProfilesList
ManagersList.defaultProps = {
  shadow: true,
};

export default ManagersList;
