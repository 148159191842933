import {FullRouteInterface, ShortRoutesInterface, SmallRoutesInterface} from "../reducers/route.reducer";
import {PreOrderReducerTypes, PreOrdersOrderBy, PreOrdersSortBy, PreOrderStatus} from "./pre-order.types";
import {ShortStageInterface} from "../reducers/stage.reducer";


export enum RoutesReducerTypes {
  CLEAR_ROUTES = 'CLEAR_ROUTES',
  CREATE_ROUTES = 'CREATE_ROUTES',
  GET_ROUTES = 'GET_ROUTES',
  GET_ONE_ROUTE = 'GET_ONE_ROUTE',
  CHANGE_ROUTE = 'CHANGE_ROUTE',
  UPDATE_STAGES_IN_ROUTE = 'UPDATE_STAGES_IN_ROUTE',
  SET_SKIP = 'SET_SKIP',
  SET_TAKE = 'SET_TAKE',
  SET_QUERY = 'SET_QUERY',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ORDER_BY = 'SET_ORDER_BY',
  GET_FULL_QUERY = 'GET_FULL_QUERY',
  SET_NEW_ROUTE_STATUS = 'SET_NEW_ROUTE_STATUS',
}

export type RoutePayload = CreateRoutePayload
  | ClearRoutes
  | GetRoutesPayload
  | GetOneRoutePayload
  | ChangeRoutePayload
  | SetRouteSkip
  | SetRouteTake
  | SetRouteQuery
  | SetRouteSortBy
  | SetRouteOrderBy
  | GetFullQuery
  | SetNewRouteStatus
  | AddNewStageToRoute


export interface ClearRoutes {
  type: RoutesReducerTypes.CLEAR_ROUTES,
}

export interface CreateRoutePayload {
  type: RoutesReducerTypes.CREATE_ROUTES,
  payload: FullRouteInterface
}

export interface GetOneRoutePayload {
  type: RoutesReducerTypes.GET_ONE_ROUTE,
  payload: FullRouteInterface
}

export interface ChangeRoutePayload {
  type: RoutesReducerTypes.CHANGE_ROUTE,
  payload: FullRouteInterface
}

export interface GetRoutesPayload {
  type: RoutesReducerTypes.GET_ROUTES,
  payload: [ {_count: number}, ShortRoutesInterface[]]
}

interface SetRouteSkip {
  type: RoutesReducerTypes.SET_SKIP,
  payload: number
}

interface SetRouteTake {
  type: RoutesReducerTypes.SET_TAKE,
  payload: number
}

interface SetRouteQuery {
  type: RoutesReducerTypes.SET_QUERY,
  payload: string
}

interface SetRouteSortBy {
  type: RoutesReducerTypes.SET_SORT_BY,
  payload: PreOrdersSortBy
}

interface SetRouteOrderBy {
  type: RoutesReducerTypes.SET_ORDER_BY,
  payload: PreOrdersOrderBy
}

interface GetFullQuery {
  type: RoutesReducerTypes.GET_FULL_QUERY,
}

interface SetNewRouteStatus {
  type: RoutesReducerTypes.SET_NEW_ROUTE_STATUS,
  payload: {status: PreOrderStatus}
}

interface AddNewStageToRoute {
  type: RoutesReducerTypes.UPDATE_STAGES_IN_ROUTE,
  payload: ShortStageInterface[]
}

export enum RoutesSortBy {
  TITLE = 'title',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ID = 'id',
}

export enum RoutesOrderBy {
  DESCENDING = 'desc',
  ASCENDING = 'asc',
}

export class RoutesQuery {
  take: number
  skip: number
  order_by?: RoutesOrderBy
  sort_by?: RoutesSortBy
  search?: string
  routeStatus?: RouteStatus
  id?: number
  preOrderId?: number
  ownerUser?: string
  ownerCompany?: string
}

export interface RouteQueryForInterface {
  search?: string
  status?: RouteStatus
  id?: number
  preOrderId?: number
  ownerUser?: string
  ownerCompany?: string
}

export enum RouteStatus {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  DECLINE = 'DECLINE',
  ARCHIVE = 'ARCHIVE',
}