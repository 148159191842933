import {useState, ChangeEvent} from "react";

export const useStringInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  return {value, onChange}
}

export const useNumberInput = (initialValue: number) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    typeof e.target.value === "number" && setValue(e.target.value)
  }

  return {value, onChange}
}

export const useBooleanInput = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);

  const onChange = () => {
    setValue(!value)
  }

  return {value, onChange}
}

export const useAnyInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  return {value, onChange}
}