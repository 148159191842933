import React from 'react';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { Table } from '@mui/material';
import PreordersTableFooter from "./PreordersTableFooter";
import PreordersTableHeader from "./PreordersTableHeader";
import PreordersTableBody from "./PreordersTableBody";

function PreordersTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>

          <PreordersTableHeader />

          <PreordersTableBody />

        </TableBody>

        <PreordersTableFooter />

      </Table>
    </TableContainer>
  );
}

export default PreordersTable;