import React from "react";
import {FileWithPath} from "react-dropzone";
import {Badge, Card, Grid, Stack} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {Constants} from "../../utilities/constants";

interface PropsInterface {
    removeUploadedFile: any,
    images: FileWithPath[],
    isImage: boolean
}

const ImagePreview = ({ images, removeUploadedFile, isImage}: PropsInterface): JSX.Element => {

    return (
        <Grid container direction={'row'} className="file-list">{images.map((image: any, key: any) => {
            return(
                <Grid item md={2} className="file-item" key={key}>
                    <Badge badgeContent={<CloseIcon/>} color="primary" onClick={() => removeUploadedFile(image.id)}>
                        <Grid container direction={'column'} className="thumbInner" >
                            <Grid item>
                                <img
                                  alt={`img - ${image.id}`}
                                  src={isImage ? image.src : `${Constants.assetsFrontendURL}images/doc-icon.png`}
                                  className="file-img"
                                />
                            </Grid>
                            {
                                !isImage &&
                                  <Grid item>
                                      <p style={{fontSize: 9}}>{image.fileName}</p>
                                  </Grid>
                            }
                        </Grid>
                    </Badge>
                </Grid>
            )
        })}</Grid>
    );
};
export default ImagePreview;