// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import Grid from "@mui/material/Grid";
import {
  convertFromStringToBeautyDateString,
  getRouteStatus,
  getRouteStatusColors
} from "../../../../utilities/converters";
import Divider, {MarginSize} from "../../../../components/Divider";
import {PreOrderStatus} from "../../../../store/types/pre-order.types";
import MDButton from "../../../../components/MDButton";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {CommentSenderRoles} from "../../../../store/types/comment.types.";
import DialogWindow from "../../../../components/DialogWindow";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import PopUpDialogWindow from "../../../../components/PopUpDialogWindow";
import {FullRouteInterface} from "../../../../store/reducers/route.reducer";
import ArrowBox from "../ArrowBox";
import {StageStatuses} from "../../../../store/types/stage.types";
import StageForm from "./StageForm";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import StagesLineBlock from "./StagesLineBlock";

enum buttonChoice {
  DECLINED = 'DECLINED',
  RECLARIFICATION = 'RECLARIFICATION',
  EMPTY = ''
}

interface PropsInterface {
  route: FullRouteInterface,
  // setIsEditMode: any,
  // setIsPreviewMode: any,
}

const helpTextStyle = {fontSize: 13, fontWeight: "lighter"}

function PreviewRoute({route }: PropsInterface): JSX.Element {
  let navigate = useNavigate();

  const [openPopDialog, setOpenPopDialog] = useState<boolean>(false);
  const [popDialogTitle, setPopDialogTitle] = useState<string>('');
  const [popDialogText, setPopDialogText] = useState<string>('');

  const [stageNumber, setStageNumber] = useState(0);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogWindowTitle, setDialogWindowTitle] = useState<string>('');
  const [dialogWindowDescription, setDialogWindowDescription] = useState<string>('');

  const {setNewStatus, createCommentForPreOrder, createRouteAction, getTypesOfWorksForAdmin} = useAppDispatch();
  const {route: {oneRoute}} = useAppSelector(state => state);

  const handleOpenDialog = (newStageNumber?: number) => {
    console.log('NEW STAGE NUMBER ---- ', newStageNumber)
    newStageNumber && setStageNumber(newStageNumber);
    setOpenDialog(true);
    setDialogWindowTitle('Создание нового этапа в Маршруте');
    setDialogWindowDescription('');
  }

  const handleChangeStageStatus = (newStatus: StageStatuses) => {
    setOpenPopDialog(true);
    setPopDialogTitle('Укажите новый статус для данного Этапа');
  }

  const createStage = () => {
    if (route?.stages?.length > 0) {
      //route?.stages.map(s => s.)
    }
  }

  return (
    <MDBox>
      <DialogWindow
        open={openDialog}
        setOpen={setOpenDialog}
        dialogWindowTitle={dialogWindowTitle}
        dialogWindowDescription={dialogWindowDescription}
        showAction={false}
        showMainInput={false}
        children={<StageForm isEditMode={false} stageNumber={stageNumber} setOpenDialog={setOpenDialog} />}
      />
      {/*<PopUpDialogWindow*/}
      {/*  open={openPopDialog}*/}
      {/*  setOpen={setOpenPopDialog}*/}
      {/*  title={popDialogTitle}*/}
      {/*  answer={popDialogText}*/}
      {/*  setAnswer={setPopDialogText}*/}
      {/*  onAction={}*/}
      {/*/>*/}
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          Маршрут №{route?.id}
        </MDTypography>
      </MDBox>

      <MDBox mt={1} mb={1}>
        <MDBadge variant="contained" color={getRouteStatusColors(route?.status)} badgeContent={getRouteStatus(route?.status)} container/>
      </MDBox>

      <MDBox mt={1} mb={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {route?.title}
        </MDTypography>
      </MDBox>

      <MDBox mt={1} mb={1}>
        <MDTypography variant="h6" fontWeight="medium">
          <span style={helpTextStyle}>Создан:</span> {convertFromStringToBeautyDateString(route?.createdAt)}
          {' - '}
          <span style={helpTextStyle}>Последние изменения:</span> {convertFromStringToBeautyDateString(route?.updatedAt)}
        </MDTypography>
      </MDBox>

      <Divider marginSize={MarginSize.big} />

      <MDBox mt={5} mb={5}>
        <MDTypography variant="h6" fontWeight="medium">
          Сроки
        </MDTypography>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={12} lg={12}>
            <MDTypography variant="h6" fontWeight='light'>
              Заявка актуальна до: {convertFromStringToBeautyDateString(route?.preOrder?.preOrderRelevantTill) ?? 'Не указано'}, <br/>Собирать предложения подрядчиков до: {convertFromStringToBeautyDateString(route?.preOrder?.getOffersBeforeDate) ?? 'Не указано'}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>

      <Divider marginSize={MarginSize.big} />

      <MDBox mt={5} mb={5}>
        <MDTypography variant="h6" fontWeight="medium">
          Деталь
        </MDTypography>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={12} lg={12}>
            <MDTypography variant="h6" fontWeight='light'>
              {
                route?.detail
                  ? `${route?.detail?.title}, кол-во: ${route?.detail?.quantity}, ${route?.detail?.shape.title} - ${route?.detail?.material.title}`
                  : 'Деталь не создана'

              }
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>

      <Divider marginSize={MarginSize.big} />

      <MDBox mt={5} mb={5}>
        <Grid container justifyContent={'space-evenly'}>
          {
            route?.stages?.length > 0
            ? <StagesLineBlock stages={route?.stages} handleOpenDialog={handleOpenDialog}/>//route?.stages?.map(s => <ArrowBox stage={s} key={s.id} />)
            : <Grid item md={12}>
                <MDTypography variant="h6" fontWeight="medium" mb={4}>
                  Этапы еще не созданы
                </MDTypography>
                <MDButton variant="gradient" color="info" onClick={() => handleOpenDialog(1)} style={{marginLeft: 55}}>
                  <AddCircleOutlineIcon style={{marginRight: 8}}/> новый этап
                </MDButton>
              </Grid>
          }
        </Grid>
      </MDBox>

      <Divider marginSize={MarginSize.big} />

      {/*<Grid container>*/}
      {/*  <Grid item lg={3} md={4} xs={12}>*/}
      {/*    <MDBox mt={1} mb={1} >*/}
      {/*      <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle" mb={2}>*/}
      {/*        {route?.preOrder .files?.length > 0 ? 'Прикрепленные файлы:' : 'Нет прикрепленных файлов'}*/}
      {/*      </MDTypography>*/}
      {/*      <DownloadableFiles files={po?.files} gridSize={6} />*/}
      {/*    </MDBox>*/}
      {/*  </Grid>*/}
      {/*  <Grid item lg={9} md={8} xs={12}>*/}
      {/*    <ImagesForAdmin />*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}

    </MDBox>
  );
}

export default PreviewRoute;
