export interface ShortDetailInterface {
  id: number,
  quantity: number,
  title: string,
  material: {
      id: number,
      title: string,
      image: string,
  },
  shape: {
      id: number,
      title: string,
      image: string,
  }
}

export interface CreateDetailInterface {
  preOrderId?: number
  shapeId: number
  materialId: number
  quantity: number
  title: string
}

export enum DetailReducerTypes {
  CREATE_DETAIL = 'CREATE_DETAIL',
  CHANGE_DETAIL = 'CHANGE_DETAIL',
  GET_DETAILS = 'GET_DETAILS',
  GET_ONE_DETAIL = 'GET_ONE_DETAIL',
  CREATE_DETAILS_WITH_ROUTE = 'CREATE_DETAILS_WITH_ROUTE',
}

export type DetailReducerPayloads = CreateDetailPayload
  | ChangeDetailPayload
  | GetDetailsPayload
  | GetOneDetailPayload
  | CreateDetailWithRouteArrayPayload

export interface CreateDetailPayload {
  type: DetailReducerTypes.CREATE_DETAIL,
  payload: ShortDetailInterface
}

export interface ChangeDetailPayload {
  type: DetailReducerTypes.CHANGE_DETAIL,
  payload: ShortDetailInterface
}

export interface GetDetailsPayload {
  type: DetailReducerTypes.GET_DETAILS,
  payload: ShortDetailInterface[]
}

export interface GetOneDetailPayload {
  type: DetailReducerTypes.GET_ONE_DETAIL,
  payload: ShortDetailInterface
}

export interface CreateDetailWithRouteArrayPayload {
  type: DetailReducerTypes.CREATE_DETAILS_WITH_ROUTE,
  payload: CreateDetailInterface
}
