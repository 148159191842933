import React from 'react';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";

function OrdersTableFooter() {
  const {getAllOrders, setOrderTake, setOrderSkip, setOrderQuery, setSortOrderBy, setOrderOrderBy} = useAppDispatch();
  const {orders: {skip, take, order_by, sort_by}} = useAppSelector(state => state);

  return (
    <TableFooter>
      <TableRow>
        {/*<TablePagination*/}
        {/*  rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}*/}
        {/*  colSpan={3}*/}
        {/*  count={stages?.length}*/}
        {/*  rowsPerPage={rowsPerPage}*/}
        {/*  page={page}*/}
        {/*  SelectProps={{*/}
        {/*    inputProps: {*/}
        {/*      'aria-label': 'Кол-во записей на странице',*/}
        {/*    },*/}
        {/*    native: true,*/}
        {/*  }}*/}
        {/*  onPageChange={handleChangePage}*/}
        {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
        {/*  ActionsComponent={PaginationBlock}*/}
        {/*/>*/}
      </TableRow>
    </TableFooter>
  );
}

export default OrdersTableFooter;