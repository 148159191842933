import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {OffersPromProducersForStage} from "../../../../store/reducers/stage.reducer";
import {useEffect} from "react";
import {
  convertFromStringToBeautyDateString,
  convertFromStringToBeautyDateTimeString, getOfferStatus
} from "../../../../utilities/converters";
import Grid from "@mui/material/Grid";
import Rating from "./Rating";
import {OfferStatuses} from "../../../../store/types/offer.type";
import {Button, TextField} from "@mui/material";
import {createOrder} from "../../../../store/actions/order.actions";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {CreateOrderInterface} from "../../../../store/types/order.types";
import {StageStatuses} from "../../../../store/types/stage.types";

const pStyle = {
  margin: 12,
  fontSize: 14,
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface PropsInterface {
  offers: OffersPromProducersForStage[]
}

export default function OffersTabs({offers}: PropsInterface) {
  const [value, setValue] = React.useState(0);
  const [offersTabsArrayForTabs, setOffersTabsArrayForTabs] = React.useState<any[]>([]);
  const [offersPanelsArrayForTabs, setOffersPanelsArrayForTabs] = React.useState<any[]>([]);
  const [price, setPrice] = React.useState<any>(0);
  const [priceInputOpen, setPriceInputOpen] = React.useState<boolean>(false);

  const {createOrder} = useAppDispatch();
  const {stages: {oneStage}} = useAppSelector(state => state);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCreateOrder = async (e: React.MouseEvent<HTMLButtonElement>, id: number, isCompany: boolean) => {
    e.preventDefault();

    if (price <= 0) {
      return;
    }

    let data: CreateOrderInterface = {
      totalOrderPrice: Number(price),
      //@ts-ignore
      stageId: oneStage?.id,
    };

    isCompany ? data.producerCompanyId = id : data.producerUserId = id;

    createOrder(data);

    //handleClose();
  }

  const createOffersArrayForTabs = () => {
    const tabsArray: any[] = [];
    const panelsArray: any[] = [];

    offers?.map((offer, index) => {

      tabsArray.push(<Tab key={index} label={``} {...a11yProps(index)} />);

      panelsArray.push(
        <TabPanel key={index} value={value} index={index} >
          {index}
        </TabPanel>
      );

    });

    setOffersTabsArrayForTabs(tabsArray);
    setOffersPanelsArrayForTabs(panelsArray);

  }

  useEffect(() => {
    createOffersArrayForTabs();
  }, [offers])

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto' }}
    >
      <Grid container spacing={1} justifyContent={'stretch'}>
        <Grid item md={2} xs={12}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs"
            sx={{ borderRight: 1, borderColor: 'divider', width: '100%', height: 'auto', padding: 1 }}
          >
            {
              offers?.map((offer, index) => {
                return (
                  <Tab key={index} label={`${offer.id}: ${convertFromStringToBeautyDateTimeString(offer.createdAt)}`} {...a11yProps(index)} style={{height: 60, fontSize: 12}}/>
                )
              })
            }
          </Tabs>
        </Grid>
          <Grid item md={10} xs={12}>
            {
              offers?.map((offer, index) => {
                return (
                  <TabPanel key={index} value={value} index={index} >
                    <div>
                      <p style={pStyle}>ID: <span>{offer?.id}</span></p>
                      <p style={pStyle}>Название: {offer?.title}</p>
                      <p style={pStyle}>Создано: {convertFromStringToBeautyDateTimeString(offer?.createdAt)}</p>
                      <p style={pStyle}>Изменено: {convertFromStringToBeautyDateTimeString(offer?.updatedAt)}</p>
                      <p style={pStyle}>Статус: {getOfferStatus(offer?.status as OfferStatuses)}</p>
                      <p style={pStyle}>Деталь: {offer?.detail?.title} -- {offer?.detail?.material?.title} -- {offer?.detail?.quantity} шт.</p>
                      {
                        offer?.producerCompany ?
                          <p style={pStyle}>Предложение от компании: {offer?.producerCompany?.name}</p>
                          :
                          <p style={pStyle}>Предложение от пользователя: {offer?.producerUser?.nickname} - {offer?.producerUser?.email}</p>
                      }
                    </div>
                    <Rating id={offer?.producerCompany?.id ?? offer?.producerUser?.id} isCompany={!!offer?.producerCompany?.id}/>

                    {
                      //@ts-ignore
                      oneStage?.status !== StageStatuses.CREATED ?
                        <Grid container mt={3} mb={3} pl={3}>
                          <Grid item xs={12}>
                            <Typography fontSize={16} color={'red'}>
                              ЗАКАЗ УЖЕ СОЗДАН. ПРЕДЛОЖЕНИЯ НЕ ПРИНИМАЮТСЯ
                            </Typography>
                          </Grid>
                        </Grid>
                        :
                        (
                          priceInputOpen ?
                          <Grid container mt={3} mb={3}>
                              <Grid item xs={4}>
                                <TextField
                                  id="outlined-basic"
                                  label="Цена"
                                  variant="outlined"
                                  value={price}
                                  onChange={(e) => setPrice(e.target.value.replace(/\D/g, ""))}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  variant="contained"
                                  style={{color: 'white', backgroundColor: 'red'}}
                                  onClick={(e) => handleCreateOrder(e, offer?.producerCompany?.id ?? offer?.producerUser?.id, !!offer?.producerCompany?.id)}
                                >
                                  Создать заказ
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  variant="contained"
                                  style={{color: 'white', backgroundColor: 'blue'}}
                                  onClick={(e) => setPriceInputOpen(false)}
                                >
                                  Отменить
                                </Button>
                              </Grid>
                          </Grid>
                          :
                          <Grid container mt={3} mb={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{margin: 12, color: 'white'}}
                              onClick={() => setPriceInputOpen(true)}
                            >
                              Установить цену и выбрать данного исполнителя
                            </Button>
                          </Grid>
                        )
                    }
                  </TabPanel>
                )
              })
            }
          </Grid>
      </Grid>
    </Box>
  );
}