import React from 'react';
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Grid from "@mui/material/Grid";
import DefaultProjectCard from "../../examples/Cards/ProjectCards/DefaultProjectCard";
import homeDecor1 from "../../assets/images/home-decor-1.jpg";
import team1 from "../../assets/images/team-1.jpg";
import team2 from "../../assets/images/team-2.jpg";
import team3 from "../../assets/images/team-3.jpg";
import team4 from "../../assets/images/team-4.jpg";
import homeDecor2 from "../../assets/images/home-decor-2.jpg";
import homeDecor3 from "../../assets/images/home-decor-3.jpg";
import homeDecor4 from "../../assets/images/home-decor-4.jpeg";
import OnePostCard from "./OnePostCard";
import bgImage from "../../assets/img/auth-bg.jpg";
import BasicPageLayout from "../layout/BasicPageLayout";
import HomePageLayout from "../layout/HomePageLayout";
import Container from "@mui/material/Container";

function Blog() {
  return (
    <HomePageLayout background={'default'} menuTransparent={false}>
      <Container style={{paddingTop: 90}}>

        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Блог
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Полезные статьи
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6}>
          <Grid item xs={12} md={6} xl={3} >
            <OnePostCard title={'Название поста какое-то'} image={'https://picsum.photos/300/200'} label={''} description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.1'} authors={[{name: 'Vasya', image: team1}]} action={{type: 'internal', route: '/blog/some-post', color: 'primary', label: 'подробнее'}}  />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <OnePostCard title={'Название поста какое-то'} image={'https://picsum.photos/300/300'} label={''} description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.1'} authors={[{name: 'Vasya', image: team1}]} action={{type: 'internal', route: '/blog/some-post2', color: 'primary', label: 'подробнее'}}  />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <OnePostCard title={'Название поста какое-то'} image={'https://picsum.photos/200/300'} label={''} description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.1'} authors={[{name: 'Vasya', image: team1}]} action={{type: 'internal', route: '/blog/some-post3', color: 'primary', label: 'подробнее'}}  />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <OnePostCard title={'Название поста какое-то'} image={'https://picsum.photos/200/300'} label={''} description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.1'} authors={[{name: 'Vasya', image: team1}]} action={{type: 'internal', route: '/blog/some-post4', color: 'primary', label: 'подробнее'}}  />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <OnePostCard title={'Название поста какое-то'} image={'https://picsum.photos/200/300'} label={''} description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.1'} authors={[{name: 'Vasya', image: team1}]} action={{type: 'internal', route: '/blog/some-post5', color: 'primary', label: 'подробнее'}}  />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <OnePostCard title={'Название поста какое-то'} image={'https://picsum.photos/200/300'} label={''} description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.1'} authors={[{name: 'Vasya', image: team1}]} action={{type: 'internal', route: 'some', color: 'primary', label: 'подробнее'}}  />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <OnePostCard title={'Название поста какое-то'} image={'https://picsum.photos/200/300'} label={''} description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.1'} authors={[{name: 'Vasya', image: team1}]} action={{type: 'internal', route: 'some', color: 'primary', label: 'подробнее'}}  />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <OnePostCard title={'Название поста какое-то'} image={'https://picsum.photos/200/300'} label={''} description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.1'} authors={[{name: 'Vasya', image: team1}]} action={{type: 'internal', route: 'some', color: 'primary', label: 'подробнее'}}  />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <OnePostCard title={'Название поста какое-то'} image={'https://picsum.photos/200/300'} label={''} description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.1'} authors={[{name: 'Vasya', image: team1}]} action={{type: 'internal', route: 'some', color: 'primary', label: 'подробнее'}}  />
          </Grid>
        </Grid>
        </MDBox>

      </Container>
    </HomePageLayout>
  );
}

export default Blog;