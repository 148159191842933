import {useEffect, useState} from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoAsana from "assets/images/small-logos/logo-asana.svg";

// Material Dashboard 2 PRO React TS components
import { useMaterialUIController } from "context";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {Grid, MenuItem} from "@mui/material";
import CompanyForm from "./CompanyForm";
import ManagersList from "./ManagersList";
import managersMockData from "./data";
import editAvatar from "../../../../../assets/img/edit-avatar.jpg";
import noAvatar from "../../../../../assets/img/no-avatar.svg";
import ModalWindow from "../../../../../components/Modal";
import {CompanyProfile} from "../../../../../store/types/company.types";
import {FRONT_URL} from "../../../../../utilities/http.axios";

function Company(): JSX.Element {
  const [controller] = useMaterialUIController();
  const [isCompany, setIsCompany] = useState(false);
  const [createCompany, setCreateCompany] = useState(false);
  const [editCompanyInfo, setEditCompanyInfo] = useState(false);
  const [managersList, setManagersList] = useState([]);
  const [invitedManagersList, setInvitedManagersList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {user} = useAppSelector(state => state);
  const {getManagers, getInvitedToBeManagers} = useAppDispatch();

  useEffect(() => {
    JSON.stringify(`IN GET MANAGERS USE EFFECT - ${user}`);
    if (user["ownCompany"]["id"] !== 0) {
      const managers = getManagers();
      // @ts-ignore
      setManagersList(managers);
      const invited = getInvitedToBeManagers();
      // @ts-ignore
      setInvitedManagersList(invited);
    }
  }, []);


  useEffect(() => {
    if (user) {
      setIsCompany(user["ownCompany"]["id"] !== 0)
    }
  }, [user])

  const NoCompany = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <MDTypography variant="h5">У вас пока нет компании, но вы можете ее создать.</MDTypography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MDButton variant="gradient" color="dark" size="small" onClick={() => setCreateCompany(true)}>
          Создать компанию
        </MDButton>
      </Grid>
    </Grid>
  )

  const ShortCompanyInfo = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3} md={2} lg={2}>
        <MDAvatar
          src={user["ownCompany"]["logo"] ? `${FRONT_URL}/images/logos/300x300/${user["ownCompany"]["logo"]}` : noAvatar}
          alt="profile-image"
          size="xl"
          shadow="sm"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <MDTypography variant="p">{user["ownCompany"]["name"]}</MDTypography>
      </Grid>
      <Grid item xs={12} sm={3} md={4} lg={3}>
        <MDTypography variant="p">
          {user["ownCompany"]["profile"] === CompanyProfile.CLIENT ? 'Заказчик' : (
            user["ownCompany"]["profile"] === CompanyProfile.PRODUCER ? 'Подрядчик' : (
              user["ownCompany"]["profile"] === CompanyProfile.PRODUCERCLIENT ? 'Заказчик и Подрядчик' : 'Не указан'
            )
          )
          }
        </MDTypography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <MDButton variant="gradient" color="dark" size="small" onClick={() => setEditCompanyInfo(true)}>
          Редактировать информацию
        </MDButton>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <MDButton variant="gradient" color="info" size="small" onClick={() => setModalIsOpen(true)}>
          Управление доступом
        </MDButton>
      </Grid>
    </Grid>
  )

  return (
    <Card id="company" style={{scrollMarginTop: 90}}>
      <ModalWindow title='Доступ к управлению вашей компанией' isOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
        <ManagersList managers={managersMockData} invitedToBeManagers={invitedManagersList} />
      </ModalWindow>
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Ваша Компания</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Вы можете работать как физическое или юридическое лицо. Для работы как юридическое лицо желательно создать компанию.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        {!isCompany
          ? (
            createCompany
              ? <CompanyForm setCreateCompany={setCreateCompany} isCompany={isCompany} />
              : <NoCompany />
          )
          : (
            editCompanyInfo
              ? <CompanyForm setEditCompanyInfo={setEditCompanyInfo} isCompany={isCompany} />
              : <ShortCompanyInfo />
          )
        }
      </MDBox>
    </Card>
  );
}

export default Company;
