import {
  FullPreOrderStateInterface,
  PreOrderStateInterface,
  ShortAdminPreOrderInterface
} from "../reducers/pre-order.reducer";
import {CommentInterface} from "./comment.types.";

export enum PreOrderStatus {
  CREATED = 'CREATED',
  RECLARIFICATION = 'RECLARIFICATION',
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  PROCESSING = 'PROCESSING',
  ORDERED = 'ORDERED',
  ARCHIVED = 'ARCHIVED'
}

export enum PreOrderReducerTypes {
  SET_PRE_ORDERS_SUCCESS = 'SET_PRE_ORDERS_SUCCESS',
  SET_MANAGERS_PRE_ORDERS_SUCCESS = 'SET_MANAGERS_PRE_ORDERS_SUCCESS',
  SET_ADMINS_PRE_ORDERS_SUCCESS = 'SET_ADMINS_PRE_ORDERS_SUCCESS',
  SET_PRE_ORDERS_ERROR = 'SET_PRE_ORDERS_ERROR',
  CLEAR_PRE_ORDERS = 'CLEAR_PRE_ORDERS',
  CLEAR_SELECTED_PRE_ORDER = 'CLEAR_SELECTED_PRE_ORDER',
  SET_SELECTED_PRE_ORDER = 'SET_SELECTED_PRE_ORDER',
  SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CREATE = 'SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CREATE',
  SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CHANGE = 'SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CHANGE',
  INSERT_CREATED_COMMENT = 'INSERT_CREATED_COMMENT',
  UPDATE_ALL_COMMENTS = 'UPDATE_ALL_COMMENTS',
  GET_ALL_COMMENTS = 'GET_ALL_COMMENTS',
  SET_SKIP = 'SET_SKIP',
  SET_TAKE = 'SET_TAKE',
  SET_QUERY = 'SET_QUERY',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ORDER_BY = 'SET_ORDER_BY',
  GET_FULL_QUERY = 'GET_FULL_QUERY',
  SET_NEW_STATUS = 'SET_NEW_STATUS',
}

export type PreOrderPayload = SuccessPreOrderPayload
  | ErrorPreOrderPayload
  | PreOrderNoPayload
  | OnePreOrderPayload
  | OnePreOrderNoPayload
  | SuccessManagersPreOrderPayload
  | SuccessAdminsPreOrderPayload
  | OneSavedPreOrderPayload
  | OneChangedPreOrderPayload
  | InsertCreatedComment
  | GetAllComments
  | UpdateAllComments
  | SetPreOrderSkip
  | SetPreOrderTake
  | SetPreOrderQuery
  | SetPreOrderSortBy
  | SetPreOrderOrderBy
  | GetFullQuery
  | SetNewStatus

interface SuccessPreOrderPayload {
  type: PreOrderReducerTypes.SET_PRE_ORDERS_SUCCESS,
  payload: [
    PreOrderStateInterface[],
    {_all: number}
  ]
}

interface SuccessManagersPreOrderPayload {
  type: PreOrderReducerTypes.SET_MANAGERS_PRE_ORDERS_SUCCESS,
  payload: PreOrderStateInterface[]
}

interface SuccessAdminsPreOrderPayload {
  type: PreOrderReducerTypes.SET_ADMINS_PRE_ORDERS_SUCCESS,
  payload: [ShortAdminPreOrderInterface[], {_all: number}]
}

interface OnePreOrderPayload {
  type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER,
  payload: FullPreOrderStateInterface
}

interface OneSavedPreOrderPayload {
  type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CREATE,
  payload: FullPreOrderStateInterface
}

interface OneChangedPreOrderPayload {
  type: PreOrderReducerTypes.SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CHANGE,
  payload: FullPreOrderStateInterface
}

interface ErrorPreOrderPayload {
  type: PreOrderReducerTypes.SET_PRE_ORDERS_ERROR,
  payload: string
}

interface PreOrderNoPayload {
  type: PreOrderReducerTypes.CLEAR_PRE_ORDERS,
}

interface OnePreOrderNoPayload {
  type: PreOrderReducerTypes.CLEAR_SELECTED_PRE_ORDER,
}

interface InsertCreatedComment {
  type: PreOrderReducerTypes.INSERT_CREATED_COMMENT,
  payload: CommentInterface
}

interface GetAllComments {
  type: PreOrderReducerTypes.GET_ALL_COMMENTS,
  payload: CommentInterface[]
}

interface UpdateAllComments {
  type: PreOrderReducerTypes.UPDATE_ALL_COMMENTS,
  payload: {comments: CommentInterface[]}
}

interface SetPreOrderSkip {
  type: PreOrderReducerTypes.SET_SKIP,
  payload: number
}

interface SetPreOrderTake {
  type: PreOrderReducerTypes.SET_TAKE,
  payload: number
}

interface SetPreOrderQuery {
  type: PreOrderReducerTypes.SET_QUERY,
  payload: string
}

interface SetPreOrderSortBy {
  type: PreOrderReducerTypes.SET_SORT_BY,
  payload: PreOrdersSortBy
}

interface SetPreOrderOrderBy {
  type: PreOrderReducerTypes.SET_ORDER_BY,
  payload: PreOrdersOrderBy
}

interface GetFullQuery {
  type: PreOrderReducerTypes.GET_FULL_QUERY,
}

interface SetNewStatus {
  type: PreOrderReducerTypes.SET_NEW_STATUS,
  payload: {status: PreOrderStatus}
}



export enum PreOrdersSortBy {
  PRICE = 'price',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ID = 'id',
}

export enum PreOrdersOrderBy {
  DESCENDING = 'desc',
  ASCENDING = 'asc',
}

export class PreOrdersQuery {
  take: number
  skip: number
  order_by?: PreOrdersOrderBy
  sort_by?: PreOrdersSortBy
  search?: string
  status?: PreOrderStatus
  id?: number
  ownerUser?: string
  ownerCompany?: string
  deliveryCompany?: number
}

export interface QueryForInterface {
  search?: string
  status?: PreOrderStatus
  id?: number
  ownerUser?: string
  ownerCompany?: string
  deliveryCompany?: number
}

export interface ChangeStatusInterface {
  newStatus: PreOrderStatus,
  id: number
}