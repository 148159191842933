import {CreateOfferInterface, OfferPayload, OfferReducerTypes} from "../types/offer.type";
import {Dispatch} from "redux";
import {AlertPayload} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {NotificationsPayload, NotificationsReducerTypes} from "../types/notifications.type";

export const getAllNotifications = () => {
  return async (dispatch: Dispatch<NotificationsPayload | AlertPayload>) => {
    try {
      const res = await $api.get(`/notification/get-all`);
      return dispatch({type: NotificationsReducerTypes.GET_ALL_NOTIFICATIONS, payload: res.data});
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
      return {error: true, message: axiosErrorMessageHandler(err)};
    }
  }
}

// export const getUnreadNotifications = () => {
//   return async (dispatch: Dispatch<NotificationsPayload | AlertPayload>) => {
//     try {
//       const res = await $api.get(`/notification/get-unread`);
//       return dispatch({type: NotificationsReducerTypes.GET_UNREAD_NOTIFICATIONS, payload: res.data});
//     } catch (err: unknown) {
//       console.log('errrr = ', JSON.stringify(err))
//       return {error: true, message: axiosErrorMessageHandler(err)};
//     }
//   }
// }

export const getNumberOfUnreadNotifications = () => {
  return async (dispatch: Dispatch<NotificationsPayload | AlertPayload>) => {
    try {
      const res = await $api.get(`/notification/number-of-unread`);
      return dispatch({type: NotificationsReducerTypes.GET_NUMBER_OF_UNREAD, payload: res.data});
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
      return {error: true, message: axiosErrorMessageHandler(err)};
    }
  }
}

export const setViewed = (id: number) => {
  return async (dispatch: Dispatch<NotificationsPayload | AlertPayload>) => {
    try {
      const res = await $api.get(`/notification/set-viewed/${id}`);
      return dispatch({type: NotificationsReducerTypes.SET_VIEWED, payload: res.data});
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
      return {error: true, message: axiosErrorMessageHandler(err)};
    }
  }
}