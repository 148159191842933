import React from 'react';
import {TypesOfWorksStateInterface} from "../../../../store/reducers/types-of-works.reducer";
import {Chip, Grid} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import MDTypography from "../../../../components/MDTypography";
import {removeCompanyTypeOfWork} from "../../../../store/actions/company.actions";

interface PropsInterface {
  list: TypesOfWorksStateInterface[],
  listForNotCreatedCompany?: TypesOfWorksStateInterface[],
  isForCompany?: boolean,
  isForNewCompany?: boolean,
  handleRemoveTypesOfWorksWhenNoCompanyYet?: (id: number) => void
}

function ListOfPickedTypesOfWorks({list, listForNotCreatedCompany, isForCompany=false, isForNewCompany, handleRemoveTypesOfWorksWhenNoCompanyYet}: PropsInterface) {
  const {removeUserTypeOfWork, removeCompanyTypeOfWork} = useAppDispatch();
  const {user: {ownCompany}, typesWorks} = useAppSelector(state => state);

  const handleRemove = (id: number) => {
    if (isForCompany) {
      if (isForNewCompany) return handleRemoveTypesOfWorksWhenNoCompanyYet(id);
      removeCompanyTypeOfWork({companyId: ownCompany["id"], typeOfWorkId: id});
    } else {
      removeUserTypeOfWork(id);
    }
  }

  return (
    <Grid container>
      <Grid item marginTop={3} marginBottom={1} md={12}>
        <MDTypography variant="h6">Ваши типы работ:</MDTypography>
      </Grid>
      {
        isForNewCompany
          ? listForNotCreatedCompany?.map((typeOfWork) => (
            <Grid item key={typeOfWork.id} mt={1} marginRight={1}>
              <Chip
                label={typeOfWork.title}
                onDelete={() => handleRemove(typeOfWork.id)}
                style={{borderRadius: 8}}
              />
            </Grid>
          ))
          : list?.map((typeOfWork) => (
            <Grid item key={typeOfWork.id} mt={1} marginRight={1}>
              <Chip
                label={typeOfWork.title}
                onDelete={() => handleRemove(typeOfWork.id)}
                style={{borderRadius: 8}}
              />
            </Grid>
          ))
      }
    </Grid>
  );
}

export default ListOfPickedTypesOfWorks;