import {AuthPayload, authReducerTypes} from "../types/auth.types";
import {Dispatch} from "redux";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {AlertColorStatuses, AxiosErrorServerResponse} from "../types/common.types";
import {AlertDurations, AlertPayload, AlertReducerTypes} from "../types/alert.types";
import {store} from '../index';
import {alertActions} from "./alert.actions";
import {UserPayload, userReducerTypes} from "../types/user.types";
import {NotificationsPayload, NotificationsReducerTypes} from "../types/notifications.type";
import notificationsReducer from "../reducers/notifications.reducer";

export const login = (email: string, password: string) => {
  return async (dispatch: Dispatch<AuthPayload | AlertPayload>) => {
    try {
      dispatch({type: authReducerTypes.CLEAR_AUTH})
      const res = await $api.post('/auth/login', {email, password})
      setLocalStorage('token', res.data.accessToken)
      setCookie('refreshToken', res.data.refreshToken, 30)
      dispatch({type: authReducerTypes.SET_AUTH_SUCCESS, payload: res.data})
      return true;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: authReducerTypes.SET_AUTH_ERROR, payload: res})
      store.dispatch(alertActions(AlertColorStatuses.ERROR, `Неудачная авторизация. ${res}`, AlertDurations.THREE_SECONDS));
      return false;
    }
  }
}

export const registration = (email: string, password: string) => {
  return async (dispatch: Dispatch<AuthPayload | AlertPayload>) => {
    try {
      dispatch({type: authReducerTypes.CLEAR_AUTH})
      const res = await $api.post('/auth/registration', {email, password})
      setLocalStorage('token', res.data.accessToken)
      setCookie('refreshToken', res.data.refreshToken, 30)
      dispatch({type: authReducerTypes.SET_AUTH_SUCCESS, payload: res.data})
      return true;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      console.log('registration - action - PROCESSED ', res)
      dispatch({type: authReducerTypes.SET_AUTH_ERROR, payload: res})
      store.dispatch(alertActions(AlertColorStatuses.ERROR, `Неудачная регистрация. ${res}`, AlertDurations.THREE_SECONDS));
      return false;
    }
  }
}

export const logout = () => {
  return async (dispatch: Dispatch<AuthPayload | UserPayload>) => {
    try {
      dispatch({type: authReducerTypes.CLEAR_AUTH})
      dispatch({type: userReducerTypes.CLEAR_USER})
      removeLocalStorage('token')
      const res = await $api.post('/auth/logout')
      console.log(JSON.stringify(res.data.message))
    } catch (e) {
      dispatch({type: authReducerTypes.CLEAR_AUTH})
      dispatch({type: userReducerTypes.CLEAR_USER})
      console.log('logout - action', e.toString())
    }
  }
}

export const clearAuth = () => {
  return async (dispatch: Dispatch<AuthPayload | NotificationsPayload | UserPayload>) => {
    dispatch({type: authReducerTypes.CLEAR_AUTH})
    dispatch({type: NotificationsReducerTypes.CLEAR_NOTIFICATIONS})
    dispatch({type: userReducerTypes.CLEAR_USER})
    removeLocalStorage('token')
  }
}

export const setWasForciblyRefreshed = () => {
  return async (dispatch: Dispatch<AuthPayload>) => {
    dispatch({type: authReducerTypes.WAS_FORCIBLY_REFRESHED_TOKEN})
  }
}

export const setWasNotForciblyRefreshed = () => {
  return async (dispatch: Dispatch<AuthPayload>) => {
    dispatch({type: authReducerTypes.WAS_NOT_FORCIBLY_REFRESHED_TOKEN})
  }
}

export const checkAuth = () => {
  return async (dispatch: Dispatch<AuthPayload>) => {
    try {
      //dispatch({type: authReducerTypes.CLEAR_AUTH})
      const res = await $api.get(`/auth/refresh`, {withCredentials: true})
      console.log(`RESPONSE CHECK AUTH SUCCESS --- ${res.data}`)
      setLocalStorage('token', res.data.accessToken)
      //setCookie('refreshToken', res.data.refreshToken, 30)
      dispatch({type: authReducerTypes.SET_AUTH_SUCCESS, payload: res.data})
      return true;
    } catch (err: unknown) {
      console.log(`RESPONSE CHECK AUTH ERROR --- ${JSON.stringify(err)}`)
      removeLocalStorage('token')
      const res = axiosErrorMessageHandler(err);
      dispatch({type: authReducerTypes.SET_AUTH_ERROR, payload: res})
      return false;
    }
  }
}



// export const emailConfirmation = (id) => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       const res = await $api.get(`/auth/activate/${id}`, {headers: {"content-type":"application/json"}})
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'success', message: `Емейл подтвержден. Авторизуйтесь и используйте все функции Quorer`} })
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: authReducerTypes.SET_AUTH_FALSE})
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: 'Ошибка при подтверждении емейла'} })
//       console.log('emailConfirmation - action', e.message)
//     }
//   }
// }

// export const noAccess = () => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       dispatch({type: authReducerTypes.FETCH_DEAUTH})
//       await $api.post('/auth/logout')
//       removeLocalStorage('token')
//       dispatch({type: authReducerTypes.SET_AUTH_FALSE})
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'warning', message: `Необходимо авторизоваться`} })
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: authReducerTypes.SET_AUTH_FALSE})
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось авторизаваться`} })
//     }
//   }
// }

// export const getAllUserInfo = () => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       const res = await $api.get(`${API_URL}/users/account-info` )
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       return res.data
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось получить данные аккаунта.`} })
//     }
//   }
// }
//
// export const setLoadingTrue = () => {
//   return {type: authReducerTypes.SET_LOADING_TRUE}
// }
//
// export const setLoadingFalse = () => {
//   return {type: authReducerTypes.SET_LOADING_FALSE}
// }
//
// export const setError = (data) => {
//   return {type: authReducerTypes.SET_ERROR, payload: data}
// }
//
// export const clearError = () => {
//   return {type: authReducerTypes.CLEAR_ERROR}
// }
//
// export const changeRoleValueAction = ({id, value, description}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       const res = await $api.put(`${API_URL}/roles/${id}`, {value, description} )
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'success', message: `Название роли изменено`} })
//       return res.data
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось изменить название роли.`} })
//     }
//   }
// }
//
// export const changeStatusValueAction = ({id, value, description}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       const res = await $api.put(`${API_URL}/statuses/${id}`, {value, description} )
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'success', message: `Название статуса изменено`} })
//       return res.data
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось изменить название статуса.`} })
//     }
//   }
// }
//
// export const getUserUpdated = () => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.GET_USER})
//       const res = await $api.get('/users/get-user-updated')
//       if (res.data.error) {
//         dispatch({type: authReducerTypes.SET_AUTH_FALSE})
//         return dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: res.data.message} })
//       }
//       dispatch({type: authReducerTypes.GET_USER_SUCCESS, payload: res.data})
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Что-то не срослось. Попробуйте ещё раз. ${e}`} })
//       console.log('getUserUpdated', e.message)
//     }
//   }
// }
//
// export const setRoleToUserAction = ({userId, value}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       const res = await $api.post(`${API_URL}/users/role`, {userId, value} )
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'success', message: `Пользователю установлен новая роль`} })
//       return res.data
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось установить роль`} })
//     }
//   }
// }
//
// export const setStatusToUserAction = ({userId, value}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       const res = await $api.post(`${API_URL}/users/status`, {userId, value} )
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'success', message: `Пользователю установлен новый статус`} })
//       return res.data
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось установить статус`} })
//     }
//   }
// }
//
// export const getStatusesAction = () => {
//   return async (dispatch) => {
//     try {
//       const res = await $api.get(`${API_URL}/statuses`)
//       return res.data
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось получить статусы`} })
//     }
//   }
// }
//
// export const getRolesAction = () => {
//   return async (dispatch) => {
//     try {
//       const res = await $api.get(`${API_URL}/roles`)
//       return res.data
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось получить роли`} })
//     }
//   }
// }
//
// export const setReadyToRefreshFalse = () => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       dispatch({type: authReducerTypes.SET_READY_TO_REFRESH_FALSE})
//       setTimeout(() => {
//         dispatch({type: authReducerTypes.SET_READY_TO_REFRESH_TRUE})
//         dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       }, 3000)
//     } catch (e) {
//       console.log(e)
//     }
//   }
// }
//
// export const createStatusAction = ({value, description}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       const res = await $api.post(`${API_URL}/statuses`, {value, description} )
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'success', message: `Создан новый статус`} })
//       return res.data
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось создать статус`} })
//     }
//   }
// }
//
// export const createRoleAction = ({value, description}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({type: authReducerTypes.SET_LOADING_TRUE})
//       const res = await $api.post(`${API_URL}/roles`, {value, description} )
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'success', message: `Создана новая роль`} })
//       return res.data
//     } catch (e) {
//       dispatch({type: authReducerTypes.SET_LOADING_FALSE})
//       dispatch({type: authReducerTypes.SET_ERROR, payload: e})
//       dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Не удалось создать роль`} })
//     }
//   }
// }
//
// // export const loginWithGoogle = (tokenId) => {
// //     return async (dispatch) => {
// //         try {
// //             dispatch({type: authReducerTypes.FETCH_AUTH, payload: ''})
// //             const res = await $api.post('/auth/login-with-google-token', {token: tokenId})
// //             dispatch({type: authReducerTypes.FETCH_AUTH_SUCCESS, payload: res.data})
// //             dispatch({type: authReducerTypes.SET_AUTH_TRUE})
// //             setLocalStorage('token', res.data.accessToken)
// //             dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'success', message: `Мы соскучились ;)`} })
// //         } catch (e) {
// //             dispatch({type: authReducerTypes.SET_ERROR, payload: e})
// //             dispatch({type: authReducerTypes.SET_AUTH_FALSE})
// //             dispatch({type: authReducerTypes.SET_LOADING_FALSE})
// //             dispatch({type: alertReducerTypes.OPEN_ALERT, payload: {status: 'error', message: `Что-то не срослось. Попробуйте ещё раз.`} })
// //             console.log('login - action', e.message)
// //         }
// //     }
// // }
//
// // export const setReadyToRefreshTrue = () => {
// //     return async (dispatch) => {
// //         try {
// //             return dispatch({type: authReducerTypes.SET_READY_TO_REFRESH_TRUE})
// //         } catch (e) {
// //             console.log(e)
// //         }
// //     }
// // }
//
// //------ OLD STUFFF---------------------------
// //
// // export const fetchDeAuth = () => {
// //     return async (dispatch) => {
// //         try {
// //             dispatch({type: "FETCH_AUTH", payload: ''})
// //             dispatch({type: "OPEN_ALERT", payload: {status: 'success', message: `Успешная деавторизация`} })
// //             setTimeout(() => {
// //                 dispatch({type: "CLOSE_ALERT", payload: null })
// //             }, 3000)
// //         } catch (e) {
// //             dispatch({type: "OPEN_ALERT", payload: {status: 'error', message: `Ошибка: недействительный токен. Необходимо получить новый в Телеграм`} })
// //             setTimeout(() => {
// //                 dispatch({type: "CLOSE_ALERT", payload: null })
// //             }, 3000)
// //             dispatch({type: "FETCH_AUTH_ERROR", payload: e})
// //         }
// //     }
// // }
// //
// // //-------------- АВТОРИЗАУЕМСЯ ЧЕРЕЗ ТОКЕН ИЗ ТЕЛЕГРАМ ---------------------
// // export const loginWithToken = async (token) => {
// //     try {
// //         console.log('loginW T ', token)
// //         const res = await axios.get(`http://localhost:8000/api/auth/login-with-token/${token}`)
// //         //setLocalStorage('user', res.data.user)
// //         //setCookie('token', res.data.token)
// //         return res.data.token.toString()
// //     } catch (e) {
// //         console.log(e)
// //         return e.toString()
// //     }
// // }

//-------------- РАБОТА С КУКИ В БРАУЗЕРЕ ---------------------
export const setCookie = (key: string, value: string, days?: number) => {
  try {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = key + "=" + (value || "")  + expires + "; path=/";
  } catch (e) {
    console.log(`Error setting Cookie ${e}`)
  }
}

export const getCookie = (key: string) => {
  try {
    let nameEQ = key + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  } catch (e) {
    console.log(`Error getting Cookie ${e}`)
  }
}

// //-------------- РАБОТА С LOCAL-STORAGE В БРАУЗЕРЕ ---------------
export const setLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.log(`Error setting LocalStorage ${e}`)
  }
}

export const getLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    console.log(`Error getting LocalStorage ${e}`)
  }
}

export const removeLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    console.log(`Error removing LocalStorage ${e}`)
  }
}

// //--------------- АВТОРИЗАЦИЯ - СОХРАНЕНИЕ ДАННЫХ ПОЛЬЗОВАтЕЛЯ в КУКИ и LOCAL-STORAGE --------
// export const authenticate = (res, next) => {
//     setCookie('token', res.data.token)
//     setLocalStorage('user', res.data.user)
//     next()
// }
//
// //------------ ПОЛУЧЕНИЕ ДАННЫХ ПОЛЬЗОВАТЕЛЯ из БРАУЗЕРА ------------------
// export const isAuth = () => {
//     if (browser) {
//         const cookieChecked = getCookie('token')
//         if (cookieChecked) {
//             try {
//                 const user = localStorage.getItem('user')
//                 if (user) {
//                     return JSON.parse(user)
//                 }
//                 return false
//             } catch (e) {
//                 return false
//             }
//         }
//         //logout()
//         return false
//     }
// }
//
// //---------- ОБРАБОТКА ДЕАВТОРИЗАЦИИ -----------------------
// export const logout = (next) => {
//     removeCookie('token')
//     removeLocalStorage('user')
//     next()
//     // return fetch(`${process.env.BACKEND}/auth/logout`, {
//     //     method: 'GET'
//     // })
//     //     .then(response => {
//     //         console.log('Успешная деавторизация')
//     //     })
//     //     .catch(err => console.log(err))
// }