import * as AuthActionCreators from './auth.actions';
import * as AlertActionCreators from './alert.actions';
import * as UserActionCreators from './user.actions';
import * as CompanyActionCreators from './company.actions';
import * as TypesOfWorksCreators from './types-of-works.actions';
import * as PreOrdersCreators from './pre-order.actions';
import * as ProjectsCreators from './project.actions';
import * as TextCreators from './text.actions';
import * as RoutesCreators from './route.actions';
import * as DetailsCreators from './detail.actions';
import * as StagesCreators from './stage.actions';
import * as ShapesCreators from './shape.actions';
import * as MaterialCreators from './material.actions';
import * as OfferCreators from './offer.action';
import * as LoadingCreators from './loading.actions';
import * as OrderCreators from './order.actions';
import * as NotificationsCreators from './notifications.actions';
import * as PaymentCreators from './payment.actions';

export default {
  ...AuthActionCreators,
  ...AlertActionCreators,
  ...UserActionCreators,
  ...CompanyActionCreators,
  ...TypesOfWorksCreators,
  ...PreOrdersCreators,
  ...ProjectsCreators,
  ...TextCreators,
  ...RoutesCreators,
  ...DetailsCreators,
  ...StagesCreators,
  ...ShapesCreators,
  ...MaterialCreators,
  ...OfferCreators,
  ...LoadingCreators,
  ...OrderCreators,
  ...NotificationsCreators,
  ...PaymentCreators,
}