// react-router components
// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {ShortOpenStage} from "../../../../store/reducers/stage.reducer";
import {
  convertFromStringToBeautyDateString,
  countDifferenceBetweenDates,
  createImagePath
} from "../../../../utilities/converters";
import {FolderTypesEnum, ImageSizesEnum} from "../../../../utilities/constants";

// Declaring props types for SimpleBlogCard
interface Props {
  stage: ShortOpenStage
}

function PreOrderCard(props: Props): JSX.Element {
  return (
    <Card>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
        <MDBox
          component="img"
          src={props?.stage?.detail?.material?.image ?? (props?.stage?.images[0]?.fileName ? createImagePath(FolderTypesEnum.ORDER, props?.stage?.route?.preOrder?.ownerUser?.id, ImageSizesEnum.X200, props?.stage?.images[0]?.fileName) : '/assets/images/gear-tools.png')}
          alt={props?.stage?.title}
          borderRadius="lg"
          shadow="md"
          width="280"
          height="140"
          position="relative"
          zIndex={1}
          style={{
            height: 140,
            width: "100%",
            objectFit: "contain",
            filter: "opacity(0.7)",
          }}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          overflow={"hidden"}
          top="3%"
          sx={{
            // backgroundImage: '/assets/images/gear-tools.png',
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "100%",
          }}
        />
      </MDBox>
      <MDBox p={3}>
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            height: 48,
          }}
        >
          {props?.stage?.title}
        </MDTypography>
        <MDBox mt={2} mb={1} height={30}>
          <MDTypography
            variant="body2"
            component="p"
            color="text"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            <small>Деталь:</small>{" "}{props?.stage?.detail?.title ?? 'no detail'}{' - '}{props?.stage?.detail?.quantity ?? 0 }{" шт."}
          </MDTypography>
          <MDTypography
            display="inline"
            variant="body1"
            textTransform="capitalize"
            fontWeight="normal"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {props?.stage?.productionCost} <small style={{textTransform: 'lowercase', fontSize: 10}}>руб.</small>
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1} height={22}>
          <MDTypography variant="body2" component="p" color="text" style={{fontSize: 12}}
                        sx={{
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}>
            <span style={{fontSize: 10}}>Тип работ:</span>{' '}
            <strong>{props?.stage?.typeOfWork?.title}</strong>
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDTypography variant="body2" component="p" color="text" style={{fontSize: 12}}>
            <span style={{fontSize: 10}}>Кол-во дней на произ-во:</span>{' '}
            <strong>{countDifferenceBetweenDates(props?.stage?.productionEnd, props?.stage?.productionStart)}</strong>
          </MDTypography>
        </MDBox>
        <MDBox mt={1} mb={3}>
          <MDTypography variant="body2" component="p" color="text" style={{fontSize: 12}}>
            <span style={{fontSize: 10}}>Старт произ-ва:</span>{' '}
            <strong>{convertFromStringToBeautyDateString(props?.stage?.productionStart) ?? ''}
            {/*{' - '}*/}
            {/*  {convertFromStringToBeautyDateString(props?.stage?.productionEnd) ?? ''}*/}
            </strong>
          </MDTypography>
        </MDBox>
          <MuiLink href={`/open-order/${props?.stage?.id}`} rel="noreferrer">
            <MDButton color="primary">Подробнее</MDButton>
          </MuiLink>
      </MDBox>
    </Card>
  );
}

export default PreOrderCard;
