import React from 'react';
import {useAppSelector} from "../../../../../hooks/useActions";
import { Grid, Table, TableBody } from '@mui/material';
import {OrderStatus, ShortOrderForAdmin} from "../../../../../store/types/order.types";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import {convertFromStringToBeautyDateString, getOrderStatus} from "../../../../../utilities/converters";
import {Link} from "react-router-dom";
import DehazeIcon from "@mui/icons-material/Dehaze";
import {
  paymentDirectionConverter,
  PaymentInOrderInterface,
  paymentStatusConverter, paymentTypesConverter
} from "../../../../../store/types/payment.types";

const smallTextStyle = {
  fontSize: 14,
  fontWeight: 'bold'
}

function PaymentsList() {
  const {orders: {oneOrder}} = useAppSelector(state => state);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableBody>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell align="center" style={smallTextStyle}>Статус</TableCell>
          <TableCell align="center" style={smallTextStyle}>Создан</TableCell>
          <TableCell align="center" style={smallTextStyle}>Сумма счет</TableCell>
          <TableCell align="center" style={smallTextStyle}>Номер счета</TableCell>
          <TableCell align="center" style={smallTextStyle}>Тип счета</TableCell>
          <TableCell align="center" style={smallTextStyle}>Получатель/Отправитель</TableCell>
          <TableCell align="center" style={smallTextStyle}>Актуален до</TableCell>
          <TableCell align="center" style={smallTextStyle}>Дата выставления</TableCell>
          <TableCell align="center" style={smallTextStyle}>Подробнее</TableCell>
        </TableRow>
        {oneOrder?.payments?.length ? oneOrder?.payments?.map((payment: PaymentInOrderInterface) =>
          <TableRow key={payment?.id}>
            <TableCell style={{ width: 20 }} component="th" scope="row">
              {payment?.id}
            </TableCell>
            <TableCell style={{ width: 240 }} align="center">
              <Typography fontSize={11}>
                {paymentStatusConverter[payment?.status]}
              </Typography>
            </TableCell>
            <TableCell style={{ width: 120 }} align="center">
              <Typography fontSize={11}>
                {convertFromStringToBeautyDateString(payment?.createdAt)}
              </Typography>
            </TableCell>
            <TableCell style={{ width: 120 }} align="center">
              <Typography fontSize={11}>
                {payment.invoiceSum}
              </Typography>
            </TableCell>
            <TableCell style={{ width: 80 }} align="center">
              <Typography fontSize={11}>
                {payment.invoiceNumber}
              </Typography>
            </TableCell>
            <TableCell style={{ width: 80 }} align="center">
              <Typography fontSize={11}>
                {paymentTypesConverter[payment.type]}
              </Typography>
            </TableCell>
            <TableCell style={{ width: 80 }} align="center">
              <Typography fontSize={11}>
                {paymentDirectionConverter[payment.direction]}
              </Typography>
            </TableCell>
            <TableCell style={{ width: 80 }} align="center">
              <Typography fontSize={11}>
                {convertFromStringToBeautyDateString(payment.validTill)}
              </Typography>
            </TableCell>
            <TableCell style={{ width: 80 }} align="center">
              <Typography fontSize={11}>
                {convertFromStringToBeautyDateString(payment.invoiceDate)}
              </Typography>
            </TableCell>
            <TableCell style={{ width: 80 }} align="center">
              <Link to={`${payment?.id}`}>
                <DehazeIcon color="primary" />
              </Link>
            </TableCell>
          </TableRow>
        )
        : <TableRow>
            <TableCell style={{ width: "100%" }} component="th" scope="row">
              Пока нет платежей
            </TableCell>
          </TableRow>
        }
        </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}

export default PaymentsList;