import React, {useEffect, useState} from 'react';
import {Checkbox, Grid} from "@mui/material";
import {createImagePath} from "../../../../utilities/converters";
import {Constants, FolderTypesEnum, ImageSizesEnum} from "../../../../utilities/constants";
import MDTypography from "../../../../components/MDTypography";
import {ImageAndFileInterface} from "./StageForm";

interface PropsInterface {
  existingImages: ImageAndFileInterface[]
  existingFiles: ImageAndFileInterface[]
  setExistingFiles: any
  setExistingImages: any
  ownerId: number
}

const label = {inputProps: {'aria-label': 'Checkbox demo'}};

const ImagesAndFilesBlock = ({existingImages, existingFiles, setExistingFiles, setExistingImages, ownerId}: PropsInterface) => {
  const [initialChange, setInitialChange] = useState<boolean>(false);

  useEffect(() => {
    if (!initialChange) {
      // дополняем дополнительным атрибутом "checked" для отбора - что из списка
      // существующих файлов надо прикрепить к создаваемому Этапу
      if (existingFiles?.length > 0) {
        //const extendedFiles = existingFiles.map(f => ({...f, checked: true}))
        setExistingFiles((prev: {id: number, fileName: string}[]) => prev.map(p => ({...p, checked: true})));
        setInitialChange(true);
      }
      if (existingImages?.length > 0) {
        //const extendedImages = existingImages.map(f => ({...f, checked: true}))
        setExistingImages((prev: {id: number, fileName: string}[]) => prev.map(p => ({...p, checked: true})));
        setInitialChange(true);
      }
    }

  }, [existingFiles, existingImages])

  const addToFiles = (id: number) => {
    const updatedFiles: ImageAndFileInterface[] = existingFiles.map(f => {
      if (f.id === id) return {...f, checked: true}
      return f;
    })
    setExistingFiles(updatedFiles);
  }

  const removeFromFiles = (id: number) => {
    const updatedFiles: ImageAndFileInterface[] = existingFiles.map(f => {
      if (f.id === id) return {...f, checked: false}
      return f;
    })
    setExistingFiles(updatedFiles);
  }

  const addToImages = (id: number) => {
    const updatedImages: ImageAndFileInterface[] = existingImages.map(i => {
      if (i.id === id) return {...i, checked: true}
      return i;
    })
    setExistingImages(updatedImages);
  }

  const removeFromImages = (id: number) => {
    const updatedImages: ImageAndFileInterface[] = existingImages.map(i => {
      if (i.id === id) return {...i, checked: false}
      return i;
    })
    setExistingImages(updatedImages);
  }

  const selectImageBlock = (id: number, fileName: string, checked: boolean = true) => {
    return (
      <Grid item md={3} key={id}>
        <Grid container>
          <Grid item md={10}>
            <img
              src={createImagePath(FolderTypesEnum.ORDER, ownerId, ImageSizesEnum.X800, fileName)}
              style={{width: '100%', height: 'auto'}}
              alt='изображение'
            />
          </Grid>
          <Grid item md={2}>
            <Checkbox {...label} checked={checked} onChange={() => {
              checked ? removeFromImages(id) : addToImages(id);
            }}/>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const selectFileBlock = (id: number, fileName: string, checked: boolean = true) => {
    return (
      <Grid item md={3}  key={id}>
        <Grid container>
          <Grid item md={3}>
            <a href={`${Constants.rawBackendURL}images/files/${ownerId}/${fileName}`} download target={"_blank"}>
              <img
                alt={`img - ${fileName}`}
                src={`${Constants.assetsFrontendURL}images/doc-icon.png`}
                className="file-img"
                style={{width: '100%'}}
              />
            </a>
          </Grid>
          <Grid item md={9}>
                <p style={{fontSize: 9, marginBottom: 8}}>{fileName}</p>
                <Checkbox {...label} checked={checked} onChange={() => {
                  checked ? removeFromFiles(id) : addToFiles(id);
                }}/>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {
        existingImages.length > 0 &&
          <>
              <MDTypography variant="h6" mt={4} mb={2}>
                  Прикрепляемые изображения
              </MDTypography>
              <Grid container>
                {existingImages?.map(i => selectImageBlock(i.id, i.fileName, i.checked))}
              </Grid>
          </>
      }
      {
        existingFiles.length > 0 &&
        <>
            <MDTypography variant="h6" mt={4} mb={2}>
                Прикрепляемые файлы
            </MDTypography>
            <Grid container>
              {existingFiles?.map(f => selectFileBlock(f.id, f.fileName, f.checked))}
            </Grid>
        </>
      }
    </>
  )
}

export default ImagesAndFilesBlock;