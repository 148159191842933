import { Grid } from '@mui/material';
import React from 'react';
import MDTypography from "../../../../components/MDTypography";

function FaqTabs() {
  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12}>
        <MDTypography variant="h3" fontWeight="bold">
            FAQ TEXTS
        </MDTypography>
      </Grid>
    </Grid>
  );
}

export default FaqTabs;