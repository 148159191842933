import React from 'react';
import Grid from "@mui/material/Grid";
import MDTypography from "../../../../components/MDTypography";

function BlogPosts() {
  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12}>
        <MDTypography variant="h3" fontWeight="bold">
          BLOG POSTS
        </MDTypography>
      </Grid>
    </Grid>
  );
}

export default BlogPosts;