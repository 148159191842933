import {
  PaymentDirection,
  PaymentInOrderInterface,
  PaymentStatuses,
  PaymentTypes,
  ShortPaymentInterface
} from "./payment.types";

export enum OrderStatus {
  CREATED = "CREATED",
  NEEDS_PAYMENT_FROM_CUSTOMER = "NEEDS_PAYMENT_FROM_CUSTOMER",
  NEEDS_TO_PAY_TO_PRODUCER = "NEEDS_TO_PAY_TO_PRODUCER",
  PRODUCTION = "PRODUCTION",
  PAUSED = "PAUSED",
  PROBLEM = "PROBLEM",
  COMPLETE = "COMPLETE",
  ARCHIVE = "ARCHIVE",
  CANCELED_BY_CUSTOMER = "CANCELED_BY_CUSTOMER",
  CANCELED_BY_PRODUCER = "CANCELED_BY_PRODUCER",
  CANCELED_BY_MANAGER = "CANCELED_BY_MANAGER",
}

export type ShortOrderForAdmin = {
  id: number,
  title: string,
  createdAt: string,
  updatedAt: string,
  productionStart: string,
  productionEnd: string,
  priceForCustomer: number,
  alreadyPaiedToCustomer: number,
  priceToPayToProducer: number,
  alreadyPaiedToProducer: number,
  status: string,
  payments: {
    id: number,
    direction: string,
    invoiceSum: number,
    status: string,
  },
  stage: {
    id: string,
    title: string,
    status: string,
  },
  _count: {
    payments: number
  }
}

export interface CreateOrderInterface {
  totalOrderPrice?: number
  producerUserId?: number
  producerCompanyId?: number
  stageId: number
}

export interface ShortOrderInterface {
  id: number,
  title: string,
  createdAt: string,
  updatedAt: string,
  productionStart: string,
  productionEnd: string,
  totalOrderPrice: number,
  status: OrderStatus,
  stage: {
    id: number,
      title: string,
      detail: {
      id: number,
        quantity: number,
        title: string,
        material: {
        id: number,
          title: string
      },
      shape: {
        id: number,
          title: string
      }
    }
  },
  payment: PaymentInOrderInterface[]
}

export interface FullOrderInterface {
  id: number,
  title: string,
  createdAt: string,
  updatedAt: string,
  productionStart: string,
  productionEnd: string,
  priceForCustomer: number,
  alreadyPaiedToCustomer: number,
  priceToPayToProducer: number,
  alreadyPaiedToProducer: number,
  status: OrderStatus,
  customerUser: {
    id: number,
    nickname: string,
    email: string,
  } | null,
  customerCompany: {
    id: number,
    name: string,
    logo: string,
    owner: {
      id: number,
      nickname: string,
      email: string,
    },
    managers: {
      id: number,
      nickname: string,
      email: string,
    }
  } | null,
  producerUser: {
    id: number,
    nickname: string,
    email: string,
  } | null,
  producerCompany: {
    id: number,
    name: string,
    logo: string,
    owner: {
      id: number,
      nickname: string,
      email: string,
    },
    managers: {
      id: number,
      nickname: string,
      email: string,
    }
  } | null,
  stage: {
    id: number,
    title: string,
    typeOfWork: {
      id: number,
      title: string,
      commission: number,
    },
    route: {
      id: number,
      title: string,
      createdAt: string,
      preOrder: {
        id: number,
        title: string,
        createdAt: string,
      }
    },
    detail: {
      id: number,
      quantity: number,
      title: string,
      material: {
        id: number,
        title: string
      },
      shape: {
        id: number,
        title: string
      }
    }
  },
  payments: {
    id: number,
    createdAt: string,
    updatedAt: string,
    invoiceNumber: string,
    invoiceDate: string,
    validTill: string,
    type: PaymentTypes,
    invoiceSum: string,
    status: PaymentStatuses,
    includeTax: boolean,
    taxRate: number,
    paiedOnDate: string,
    direction: PaymentDirection
  }[]
}

export enum OrderActionTypes {
  GET_MY_ORDERS = 'GET_MY_ORDERS',
  GET_ALL_ORDERS = 'GET_ALL_ORDERS',
  GET_ONE_ORDER = 'GET_ONE_ORDER',
  CREATE_ORDER = 'CREATE_ORDER',
  UPDATE_ORDER = 'UPDATE_ORDER',
  UPDATE_STATUS = 'UPDATE_STATUS',
  DELETE_ORDER = 'DELETE_ORDER',
  CLEAR_ONE_ORDER = 'CLEAR_ONE_ORDER',
  CLEAR_ORDERS = 'CLEAR_ORDERS',
  SET_ORDER_SKIP = 'SET_ORDER_SKIP',
  SET_ORDER_TAKE = 'SET_ORDER_TAKE',
  SET_ORDER_QUERY = 'SET_ORDER_QUERY',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ORDER_BY = 'SET_ORDER_BY',
  CLEAR_QUERY = 'CLEAR_QUERY',
  SET_ORDER_PAYMENT = 'SET_ORDER_PAYMENT',
}

export type OrderPayload = GetMyOrders
  | GetOneOrder
  | CreateOrder
  | ClearOneOrder
  | ClearOrders
  | SetOrderSkip
  | SetOrderTake
  | GetAllOrders
  | UpdateOrder
  | UpdateOrderStatus
  | SetOrderQuery
  | SortOrdersBy
  | OrderOrderBy
  | ClearQuery
  | SetOrderPayments;

export interface GetMyOrders {
  type: OrderActionTypes.GET_MY_ORDERS,
  payload: ShortOrderInterface[],
}

export interface GetAllOrders {
  type: OrderActionTypes.GET_ALL_ORDERS,
  payload: ShortOrderForAdmin[],
}

export interface GetOneOrder {
  type: OrderActionTypes.GET_ONE_ORDER,
  payload: FullOrderInterface,
}

export interface CreateOrder {
  type: OrderActionTypes.CREATE_ORDER,
  payload: FullOrderInterface,
}

export interface ClearOneOrder {
  type: OrderActionTypes.CLEAR_ONE_ORDER,
}

export interface UpdateOrder {
  type: OrderActionTypes.UPDATE_ORDER,
  payload: FullOrderInterface,
}

export interface ClearQuery {
  type: OrderActionTypes.CLEAR_QUERY,
}

export interface SortOrdersBy {
  type: OrderActionTypes.SET_SORT_BY,
  payload: OrdersSortBy,
}

export interface OrderOrderBy {
  type: OrderActionTypes.SET_ORDER_BY,
  payload: OrdersOrderBy,
}

export interface UpdateOrderStatus {
  type: OrderActionTypes.UPDATE_STATUS,
  payload: FullOrderInterface,
}

export interface ClearOrders {
  type: OrderActionTypes.CLEAR_ORDERS,
}

export interface SetOrderSkip {
  type: OrderActionTypes.SET_ORDER_SKIP,
  payload: number,
}

export interface SetOrderTake {
  type: OrderActionTypes.SET_ORDER_TAKE,
  payload: number,
}

export interface SetOrderQuery {
  type: OrderActionTypes.SET_ORDER_QUERY,
  payload: string,
}

export interface SetOrderPayments {
  type: OrderActionTypes.SET_ORDER_PAYMENT,
  payload: ShortPaymentInterface,
}

export enum OrdersSortBy {
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ID = 'id',
}

export enum OrdersOrderBy {
  DESCENDING = 'desc',
  ASCENDING = 'asc',
}

export type AllOrdersQueryDto = {
  take: string
  skip: string
  order_by?: OrdersOrderBy
  sort_by?: OrdersSortBy
  search?: string
  status?: OrderStatus
  id?: string
  producer?: string
  customer?: string
}

export interface CreateOrderDto {
  includeTax?: boolean
  taxRate?: number
  invoiceNumber?: string
  invoiceDate?: string
  validTill?: string
  paiedOnDate?: string
  type?: PaymentTypes
  direction: PaymentDirection
  invoiceSum: number
  orderId: number
  counterpartyUserId?: number
  counterpartyCompanyId?: number
}