

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import FormField from "../../../../../layouts/pages/account/components/FormField";
import {ChangeEvent, useEffect, useState} from "react";
import {useAnyInput, useStringInput} from "../../../../../hooks/useInput";
import {UserStateInterface} from "../../../../../store/reducers/user.reducer";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {changeUserBankDetails} from "../../../../../store/actions/user.actions";

function BankDetails(): JSX.Element {

  const [values, setValues] = useState({
    bankName: '',
    bankAccount: '',
    bankCorrAccount: '',
    bankBik: '',
});

  const [prevData, setPrevData] = useState({
    bankName: '',
    bankAccount: '',
    bankCorrAccount: '',
    bankBik: '',
  });

  const {bankName, bankAccount, bankCorrAccount, bankBik} = values;

  const {changeUserBankDetails} = useAppDispatch();
  const {user} = useAppSelector(state => state);

  useEffect(() => {
    if (user && user["bankDetails"]) {
      setValues({
        bankName: user["bankDetails"]["bankName"] || '',
        bankAccount: user["bankDetails"]["bankAccount"] || '',
        bankCorrAccount: user["bankDetails"]["bankCorrAccount"] || '',
        bankBik: user["bankDetails"]["bankBik"] || '',
      })
      setPrevData({
        bankName: user["bankDetails"]["bankName"] || '',
        bankAccount: user["bankDetails"]["bankAccount"] || '',
        bankCorrAccount: user["bankDetails"]["bankCorrAccount"] || '',
        bankBik: user["bankDetails"]["bankBik"] || '',
      })
    }
  }, [user]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setValues({...values, [e.target.name]: e.target.value});
  }

  const handleSubmit = async () => {
    try {
      await changeUserBankDetails({
        prevData,
        newData: values
      })
      console.log('YES')
    } catch (e) {
      console.log(`NO ${e}`)
    }

  }

  return (
    <Card id="bank-details" sx={{ overflow: "visible" }} style={{scrollMarginTop: 90}}>
      <MDBox p={3}>
        <MDTypography variant="h5">Банковские реквизиты</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Банк"
              placeholder="..."
              name={'bankName'}
              value={bankName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Счет"
              placeholder="..."
              name={'bankAccount'}
              value={bankAccount}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Корреспондентский счет"
              placeholder="..."
              name={'bankCorrAccount'}
              value={bankCorrAccount}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="БИК банка"
              placeholder="..."
              name={'bankBik'}
              value={bankBik}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <MDBox mt={3} ml={3} display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
            <MDButton variant="gradient" color="dark" size="small" onClick={() => handleSubmit()}>
              Сохранить изменения
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BankDetails;
