import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {FullPreOrderStateInterface} from "../../../../../store/reducers/pre-order.reducer";
import MDBox from "../../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import PreOrderImages from "./PreOrderImages";
import PreOrderInfo from "./PreOrderInfo";
import CommentsBlock from "../../../../../components/Сomment/CommentsBlock";


interface Props {
    pickedPreOrderId?: string | null,
    setIsEditMode: any,
    setIsPreviewMode: any,
    setModalOpen: any,
}

const OnePreOrderModal = ({ pickedPreOrderId, setIsEditMode, setIsPreviewMode, setModalOpen, ...rest }: Props): JSX.Element => {
  const [preOrder, setPreOrder] = useState<FullPreOrderStateInterface | null>(null);
  const {getMyOnePreOrder, getAllCommentsForPreOrder} = useAppDispatch();
  const {preOrders: {onePreOrder}} = useAppSelector(state => state);

  useEffect(() => {
      if (pickedPreOrderId) {
          getMyOnePreOrder(pickedPreOrderId)
      }
  }, []);

  useEffect(() => {
      if (onePreOrder['id'] !== 0) {
            setPreOrder({
              id: onePreOrder['id'],
              createdAt: onePreOrder['createdAt'],
              updatedAt: onePreOrder['updatedAt'],
              title: onePreOrder['title'],
              description: onePreOrder['description'],
              status: onePreOrder['status'],
              minBudget: onePreOrder['minBudget'],
              maxBudget: onePreOrder['maxBudget'],
              budgetWithTax: onePreOrder['budgetWithTax'],
              useDeliveryCompany: onePreOrder['useDeliveryCompany'],
              preOrderRelevantTill: onePreOrder['preOrderRelevantTill'],
              productionStart: onePreOrder['productionStart'],
              productionEnd: onePreOrder['productionEnd'],
              getOffersBeforeDate: onePreOrder['getOffersBeforeDate'],
              deliveryCompany: {id: onePreOrder['deliveryCompany']['id'], title: onePreOrder['deliveryCompany']['title']},
              project: {id: onePreOrder['project']['id'], title: onePreOrder['project']['title']},
              files: onePreOrder['files'],
              images: onePreOrder['images'],
              comments: onePreOrder['comments'],
              order: {id: onePreOrder['order']['id']},
              routes: onePreOrder['routes'],
              ownerUser: {
                  id: onePreOrder['ownerUser']['id'],
                  nickname: onePreOrder['ownerUser']['nickname'],
                  avatar: onePreOrder['ownerUser']['avatar']
              },
              ownerCompany: {
                  id: onePreOrder['ownerCompany']['id'],
                  name: onePreOrder['ownerCompany']['name'],
                  profile: onePreOrder['ownerCompany']['profile'],
                  logo: onePreOrder['ownerCompany']['logo'],
                  typesOfWorks: onePreOrder['ownerCompany']['typesOfWorks'],
                  owner: {id: onePreOrder['ownerCompany']['owner']['id'], avatar: onePreOrder['ownerCompany']['owner']['avatar'], nickname: onePreOrder['ownerCompany']['owner']['nickname']},
                  managers: onePreOrder['ownerCompany']['managers'],
              },
          })
      }
  }, [onePreOrder])

    return(
    <>
            <MDBox py={3}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                            {/*<MDTypography variant="h5" fontWeight="medium">*/}
                            {/*    Product Details*/}
                            {/*</MDTypography>*/}
                            <Grid item xs={12} lg={12} md={12} sx={{ mx: "auto" }}>
                                <PreOrderInfo po={preOrder} setIsEditMode={setIsEditMode} setIsPreviewMode={setIsPreviewMode}/>
                            </Grid>
                            <Grid item xs={12} lg={12} xl={12}>
                              {
                                preOrder?.images?.length > 0 ? <PreOrderImages images={preOrder?.images} /> : <p>Нет изображений</p>
                              }
                            </Grid>
                            <CommentsBlock getComments={getAllCommentsForPreOrder}/>
                        </Grid>
                    </MDBox>
            </MDBox>
    </>
  )
}

export default OnePreOrderModal