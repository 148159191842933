import {
  PreOrderPayload,
  PreOrderReducerTypes, PreOrdersOrderBy,
  PreOrdersSortBy,
  PreOrderStatus,
  QueryForInterface
} from "../types/pre-order.types";
import {CommentInterface, CommentSenderRoles} from "../types/comment.types.";
import {getPreOrderStatus} from "../../utilities/converters";

const emptyPreOrder: FullPreOrderStateInterface = {
      id: 0,
      title: '',
      createdAt: new Date().toString(),
      updatedAt: new Date().toString(),
      description: '',
      status: PreOrderStatus.ARCHIVED,
      minBudget: 0,
      maxBudget: 0,
      preOrderRelevantTill: new Date().toString(),
      getOffersBeforeDate: new Date().toString(),
      budgetWithTax: false,
      useDeliveryCompany: false,
      productionStart: new Date().toString(),
      productionEnd: new Date().toString(),
      deliveryCompany: {id: 0, title: ''},
      project: {id: 0, title: ''},
      files: [],
      images: [],
      comments: [],
      order: {id: 0},
      routes: [],
      ownerUser: {id: 0, nickname: '', avatar: ''},
      ownerCompany: {
        id: 0, name: '', profile: '', logo: '',
        owner: {id: 0, nickname: '', avatar: ''}, typesOfWorks: [{id: 0, title: ''}],
        managers: [{id: 0, nickname: '', avatar: ''}]
      },
    }

const emptyShortPreOrder: PreOrderStateInterface = {
    id: 0,
    title: '',
    createdAt: new Date().toString(),
    project: null,
    status: PreOrderStatus.ARCHIVED,
    minBudget: 0,
    maxBudget: 0,
    preOrderRelevantTill: new Date().toString(),
    getOffersBeforeDate: new Date().toString(),
}

export interface PreOrderStateInterface {
  id: number,
  createdAt: string,
  project: {id: number, title: string},
  title: string,
  status: PreOrderStatus,
  minBudget: number,
  maxBudget: number,
  preOrderRelevantTill: string,
  getOffersBeforeDate: string,
}

export interface ShortAdminPreOrderInterface {
  id: number,
  createdAt: string,
  status: PreOrderStatus,
  minBudget: number,
  maxBudget: number,
  preOrderRelevantTill: string,
  getOffersBeforeDate: string,
  ownerUser: {nickname: string, email: string},
  ownerCompany: {name: string, juridicalAddress: {city: string}} | null,
  comments: {senderRole: CommentSenderRoles, viewed: boolean}[],
  _count: {routes: number, comments: number}
}

export interface FullPreOrderStateInterface {
  id?: number,
  title: string,
  createdAt?: string,
  updatedAt?: string,
  description: string,
  status?: PreOrderStatus,
  minBudget: number,
  maxBudget: number,
  budgetWithTax: boolean,
  useDeliveryCompany: boolean,
  preOrderRelevantTill: string,
  productionStart: string,
  productionEnd: string,
  getOffersBeforeDate: string,
  deliveryCompany?: {id: number, title: string} | null,
  project?: {id: number, title: string},
  files: {id: number, fileName: string, description: string}[],
  images: {id: number, fileName: string, description: string}[],
  comments?: CommentInterface[],
  order?: {id: number} | null,
  routes?: {stages: {select: {id: number,}}}[] | null,
  ownerUser?: {id: number, nickname: string, avatar: string} | null,
  ownerCompany?: {
    id: number,
    name: string,
    profile: string,
    logo: string,
    typesOfWorks: {id: number, title: string}[],
    owner: {id: number, avatar: string, nickname: string},
    managers: {id: number, nickname: string, avatar: string}[],
  } | null,
}

export interface preOrderInitialStateInterface {
  preOrders: PreOrderStateInterface[]
  count: number,
  onePreOrder: FullPreOrderStateInterface,
  skip: number,
  take: number,
  sort_by: PreOrdersSortBy,
  order_by: PreOrdersOrderBy,
  query: QueryForInterface | null,
}

const initialState: preOrderInitialStateInterface = {
  preOrders: [emptyShortPreOrder],
  count: 0,
  onePreOrder: emptyPreOrder,
  skip: 0,
  take: 20,
  sort_by: PreOrdersSortBy.ID,
  order_by: PreOrdersOrderBy.DESCENDING,
  query: null,
};

const preOrdersReducer = (state: preOrderInitialStateInterface = initialState, action: PreOrderPayload) => {
  switch (action.type) {
    case PreOrderReducerTypes.CLEAR_PRE_ORDERS:
      return {...state, preOrders: [], count: 0, onePreOrder: emptyPreOrder};
    case PreOrderReducerTypes.CLEAR_SELECTED_PRE_ORDER:
      return {...state, onePreOrder: emptyPreOrder};
    case PreOrderReducerTypes.SET_PRE_ORDERS_ERROR:
      return state;
    case PreOrderReducerTypes.SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CHANGE:
      const changedPreOrder = {
        id: action.payload.id,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
        title: action.payload.title,
        description: action.payload.description,
        status: action.payload.status,
        minBudget: action.payload.minBudget,
        maxBudget: action.payload.maxBudget,
        preOrderRelevantTill: action.payload.preOrderRelevantTill,
        getOffersBeforeDate: action.payload.getOffersBeforeDate,
        budgetWithTax: action.payload.budgetWithTax,
        useDeliveryCompany: action.payload.useDeliveryCompany,
        productionStart: action.payload.productionStart,
        productionEnd: action.payload.productionEnd,
        deliveryCompany: {id: action.payload.deliveryCompany?.id, title: action.payload.deliveryCompany?.title},
        project: {id: action.payload.project?.id, title: action.payload.project?.title},
        files: action.payload.files,
        images: action.payload.images,
        comments: action.payload.comments,
        order: {id: action.payload.order?.id},
        routes: action.payload.routes ?? [],
        ownerUser: {id: action.payload.ownerUser?.id, nickname: action.payload.ownerUser?.nickname, avatar: action.payload.ownerUser?.avatar},
        ownerCompany: {
          id: action.payload.ownerCompany?.id,
          name: action.payload.ownerCompany?.name,
          profile: action.payload.ownerCompany?.profile,
          logo: action.payload.ownerCompany?.logo,
          typesOfWorks: action.payload.ownerCompany?.typesOfWorks,
          owner: {id: action.payload.ownerCompany?.owner.id, avatar: action.payload.ownerCompany?.owner.avatar, nickname: action.payload.ownerCompany?.owner.nickname},
          managers: action.payload.ownerCompany?.managers,
        },
      }
      const indexOfChanged = state.preOrders.findIndex(obj => obj.id === action.payload.id);
      return {...state, onePreOrder: changedPreOrder, preOrders: [
          ...state.preOrders.slice(0, indexOfChanged),
          changedPreOrder,
          ...state.preOrders.slice(indexOfChanged + 1)
        ]};
    case PreOrderReducerTypes.SET_SELECTED_PRE_ORDER_AFTER_SUCCESS_CREATE:
      const createdPreOrder = {
        id: action.payload.id,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
        title: action.payload.title,
        description: action.payload.description,
        status: action.payload.status,
        minBudget: action.payload.minBudget,
        maxBudget: action.payload.maxBudget,
        preOrderRelevantTill: action.payload.preOrderRelevantTill,
        getOffersBeforeDate: action.payload.getOffersBeforeDate,
        budgetWithTax: action.payload.budgetWithTax,
        useDeliveryCompany: action.payload.useDeliveryCompany,
        productionStart: action.payload.productionStart,
        productionEnd: action.payload.productionEnd,
        deliveryCompany: {id: action.payload.deliveryCompany?.id, title: action.payload.deliveryCompany?.title},
        project: {id: action.payload.project?.id, title: action.payload.project?.title},
        files: action.payload.files,
        images: action.payload.images,
        comments: action.payload.comments,
        order: {id: action.payload.order?.id},
        routes: action.payload.routes ?? [],
        ownerUser: {id: action.payload.ownerUser?.id, nickname: action.payload.ownerUser?.nickname, avatar: action.payload.ownerUser?.avatar},
        ownerCompany: {
          id: action.payload.ownerCompany?.id,
          name: action.payload.ownerCompany?.name,
          profile: action.payload.ownerCompany?.profile,
          logo: action.payload.ownerCompany?.logo,
          typesOfWorks: action.payload.ownerCompany?.typesOfWorks,
          owner: {id: action.payload.ownerCompany?.owner.id, avatar: action.payload.ownerCompany?.owner.avatar, nickname: action.payload.ownerCompany?.owner.nickname},
          managers: action.payload.ownerCompany?.managers,
        },
      }
      const indexOfCreated = state.preOrders.findIndex(obj => obj.id === action.payload.id);
      return {...state, onePreOrder: createdPreOrder, preOrders: [
          ...state.preOrders.slice(0, indexOfCreated),
          createdPreOrder,
          ...state.preOrders.slice(indexOfCreated + 1)
        ]};
    case PreOrderReducerTypes.SET_SELECTED_PRE_ORDER:
        const newPreOrder = {
            id: action.payload.id,
            createdAt: action.payload.createdAt,
            updatedAt: action.payload.updatedAt,
            title: action.payload.title,
            description: action.payload.description,
            status: action.payload.status,
            minBudget: action.payload.minBudget,
            maxBudget: action.payload.maxBudget,
            preOrderRelevantTill: action.payload.preOrderRelevantTill,
            getOffersBeforeDate: action.payload.getOffersBeforeDate,
            budgetWithTax: action.payload.budgetWithTax,
            useDeliveryCompany: action.payload.useDeliveryCompany,
            productionStart: action.payload.productionStart,
            productionEnd: action.payload.productionEnd,
            deliveryCompany: {id: action.payload.deliveryCompany?.id, title: action.payload.deliveryCompany?.title},
            project: {id: action.payload.project?.id, title: action.payload.project?.title},
            files: action.payload.files,
            images: action.payload.images,
            comments: action.payload.comments,
            order: {id: action.payload.order?.id},
            routes: action.payload.routes ?? [],
            ownerUser: {id: action.payload.ownerUser?.id, nickname: action.payload.ownerUser?.nickname, avatar: action.payload.ownerUser?.avatar},
            ownerCompany: {
                id: action.payload.ownerCompany?.id,
                name: action.payload.ownerCompany?.name,
                profile: action.payload.ownerCompany?.profile,
                logo: action.payload.ownerCompany?.logo,
                typesOfWorks: action.payload.ownerCompany?.typesOfWorks,
                owner: {id: action.payload.ownerCompany?.owner.id, avatar: action.payload.ownerCompany?.owner.avatar, nickname: action.payload.ownerCompany?.owner.nickname},
                managers: action.payload.ownerCompany?.managers,
            },
        }
      return {...state, onePreOrder: newPreOrder};
    case PreOrderReducerTypes.SET_ADMINS_PRE_ORDERS_SUCCESS:
      const adminsArray = !action.payload ? [] : action.payload[0].map(p => {
        return {
          id: p.id,
          createdAt: p.createdAt,
          status: getPreOrderStatus(p.status),
          minBudget: p.minBudget,
          maxBudget: p.maxBudget,
          preOrderRelevantTill: p.preOrderRelevantTill,
          getOffersBeforeDate: p.getOffersBeforeDate,
          ownerUser: {nickname: p.ownerUser.nickname, email: p.ownerUser.email},
          ownerCompany: {name: p.ownerCompany?.name ?? '', address: {city: p.ownerCompany?.juridicalAddress?.city ?? ''}},
          comments: p.comments?.map((c: any) => ({viewed: c.viewed, senderRole: c.senderRole})),
          _count: {routes: p._count.routes, comments: p._count.comments}
        }
      });
      return {...state, preOrders: adminsArray}
    case PreOrderReducerTypes.SET_MANAGERS_PRE_ORDERS_SUCCESS:
      const managersArray = !action.payload ? [] : action.payload.map(p => {
        return {
          id: p.id,
          createdAt: p.createdAt,
          project: p.project,
          title: p.title.slice(0, 20),
          status: getPreOrderStatus(p.status),
          minBudget: p.minBudget,
          maxBudget: p.maxBudget,
          preOrderRelevantTill: p.preOrderRelevantTill,
          getOffersBeforeDate: p.getOffersBeforeDate,
        }
      })
      return {...state, preOrders: managersArray}
    case PreOrderReducerTypes.SET_PRE_ORDERS_SUCCESS:
      const array = (action.payload[0] && action.payload[0].length > 0) ? action.payload[0].map(p => {
        return {
          id: p.id,
          createdAt: p.createdAt,
          project: p.project,
          title: p.title.slice(0, 20),
          status: p.status,
          minBudget: p.minBudget,
          maxBudget: p.maxBudget,
          preOrderRelevantTill: p.preOrderRelevantTill,
          getOffersBeforeDate: p.getOffersBeforeDate,
        }
      }) : [emptyShortPreOrder,]
      return {...state, count: action.payload[1], preOrders: array };
    case PreOrderReducerTypes.SET_NEW_STATUS:
      return {...state, onePreOrder: {...state.onePreOrder, status: action.payload.status}};
    case PreOrderReducerTypes.INSERT_CREATED_COMMENT:
      return {...state, onePreOrder: {...state.onePreOrder, comments: [...state.onePreOrder.comments, action.payload]}}
    case PreOrderReducerTypes.GET_ALL_COMMENTS:
      return {...state, onePreOrder: {...state.onePreOrder, comments: action.payload}}
    case PreOrderReducerTypes.UPDATE_ALL_COMMENTS:
      return {...state, onePreOrder: {...state.onePreOrder, comments: action.payload.comments}}
    case PreOrderReducerTypes.SET_QUERY:
      return {...state, query: action.payload};
    case PreOrderReducerTypes.SET_SKIP:
      return {...state, skip: action.payload};
    case PreOrderReducerTypes.SET_TAKE:
      return {...state, take: action.payload};
    default:
      return state;
  }
}

export default preOrdersReducer;