// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import {FullPreOrderStateInterface} from "../../../../../store/reducers/pre-order.reducer";
import {PreOrderStatus} from "../../../../../store/types/pre-order.types";
import {
  convertFromStringToBeautyDateString,
  getPreOrderStatusColor,
  getPreOrderStatus
} from "../../../../../utilities/converters";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../components/MDButton";
import Divider, {MarginSize} from '../../../../../components/Divider'
import DownloadableFiles from "../../../../../components/DownloadableFiles";

interface PropsInterface {
    po: FullPreOrderStateInterface,
}

const helpTextStyle = {fontSize: 13, fontWeight: "lighter"}

function PreOrderInfo({po,}: PropsInterface): JSX.Element {
    // const handleEdit = () => {
    //     setIsPreviewMode(false);
    //     setIsEditMode(true);
    // }

    return (
        <MDBox>
            <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                    Предзаказ №{po?.id}
                </MDTypography>
            </MDBox>

            <MDBox mt={1} mb={1}>
                <MDBadge variant="contained" color={getPreOrderStatusColor(po?.status)} badgeContent={getPreOrderStatus(po?.status)} container/>
            </MDBox>

          {
            po?.project?.id &&
            <MDBox mt={1} mb={1}>
              <MDBadge variant="contained" color='dark' badgeContent={po?.project?.title} container/>
            </MDBox>
          }
          <MDBox mt={1} mb={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {po?.title}
            </MDTypography>
          </MDBox>

            <MDBox mt={1} mb={1}>
                <MDTypography variant="h6" fontWeight="medium">
                    <span style={helpTextStyle}>Создан:</span> {convertFromStringToBeautyDateString(po?.createdAt)}
                    {' - '}
                    <span style={helpTextStyle}>Последние изменения:</span> {convertFromStringToBeautyDateString(po?.updatedAt)}
                </MDTypography>
            </MDBox>

            <Divider marginSize={MarginSize.big} />

            <MDBox mt={1} mb={1}>
                <MDTypography variant="h6" fontWeight="medium">
                    <span style={helpTextStyle}>Минимальный бюджет:</span> {po?.minBudget}р.
                    {' - '}
                    <span style={helpTextStyle}>Максимальный бюджет:</span> {po?.maxBudget}р.
                </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1}>
              <MDBadge variant="contained" color='info' badgeContent={po?.budgetWithTax ? 'Цена с учетом налогов' : 'Цена без НДС'} container/>
            </MDBox>

            <Divider marginSize={MarginSize.big} />

            <MDBox mt={1} mb={1}>
                <MDTypography variant="h6" fontWeight="medium">
                    <span style={helpTextStyle}>Желательная дата начала производства:</span> {convertFromStringToBeautyDateString(po?.productionStart)}
                    {' - '}
                    <span style={helpTextStyle}>Желательная дата окончания производства:</span> {convertFromStringToBeautyDateString(po?.productionEnd)}
                </MDTypography>
            </MDBox>

            <MDBox mt={1} mb={1}>
                <MDTypography variant="h6" fontWeight="medium">
                    <span style={helpTextStyle}>Актуален до:</span> {convertFromStringToBeautyDateString(po?.preOrderRelevantTill) ?? 'Не указано'}
                    {' - '}
                    <span style={helpTextStyle}>Собирать предложения подрядчиков до:</span> {convertFromStringToBeautyDateString(po?.getOffersBeforeDate) ?? 'Не указано'}
                </MDTypography>
            </MDBox>

            <Divider marginSize={MarginSize.big} />

            <MDBox mt={1} mb={1}>
                <MDTypography variant="h6" fontWeight="medium">
                    {
                      (po?.useDeliveryCompany && po?.deliveryCompany?.title) ?
                        <>
                          <span style={helpTextStyle}>Использовать транспортную компанию - </span> {po?.deliveryCompany?.title}
                        </>
                          :
                          <>
                            Не использовать транспортную компанию
                          </>
                    }
                </MDTypography>
            </MDBox>

            <Divider marginSize={MarginSize.big} />

            <MDBox mt={3} mb={1} ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    Описание
                </MDTypography>
            </MDBox>

            <MDBox component="h5" m={0} pl={4} mb={2} color="text" fontSize="1.25rem" lineHeight={1}>
              <div dangerouslySetInnerHTML={{ __html: po?.description}} />

                {/*<MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle" dangerouslySetInnerHTML={{ __html: po?.description}}>*/}
                {/*    {po?.description}*/}
                {/*</MDTypography>*/}
            </MDBox>

            <Divider marginSize={MarginSize.big} />

            <MDBox mt={1} mb={1}>
                <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle" mb={2}>
                    {po?.files?.length > 0 ? 'Прикрепленные файлы:' : 'Нет прикрепленных файлов'}
                </MDTypography>
                <DownloadableFiles files={po?.files} />
            </MDBox>

            {
                (po?.status === PreOrderStatus.ORDERED
                    || po?.status === PreOrderStatus.RECLARIFICATION
                    || po?.status === PreOrderStatus.CREATED
                    || po?.status === PreOrderStatus.ACCEPTED) &&
                <MDBox mt={5} mb={5}>
                    <Grid container>
                        <Grid item xs={12} md={4} lg={4}>
                            <MDButton variant="gradient" color="warning" fullWidth>
                                Отказаться от предзаказа
                            </MDButton>
                        </Grid>
                        {
                            (po?.status === PreOrderStatus.RECLARIFICATION
                            || po?.status === PreOrderStatus.CREATED) &&
                            <Grid item ml={5} xs={12} md={4} lg={4}>
                                <MDButton variant="gradient" color="info" fullWidth >
                                    Редактировать
                                </MDButton>
                            </Grid>
                        }
                    </Grid>
                </MDBox>
            }
        </MDBox>
    );
}

export default PreOrderInfo;
