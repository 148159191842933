import React from 'react';
import {Badge, Grid} from "@mui/material";
import {Constants} from "../../utilities/constants";
import {useAppSelector} from "../../hooks/useActions";
import {cutFileNameFromPath} from "../../utilities/converters";

enum GridSize {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  SIX = 6,
}
interface DownloadableFilesInterface {
  files: any[],
  gridSize?: GridSize
}

const DownloadableFiles = ({files, gridSize = 2}: DownloadableFilesInterface) => {
  const {user} = useAppSelector(state => state.auth)

  return (
    <Grid container direction={'row'} className="file-list">
      {files?.length ? files?.map((file: any, key: any) => {
        return (
          <Grid item md={gridSize} className="file-item" key={key}>
            <Grid container direction={'column'} className="thumbInner">
              <Grid item md={12}>
                <a href={`${Constants.rawBackendURL}${file.fileName}`} download target={"_blank"}>
                  <img
                    alt={`img - ${file.fileName}`}
                    src={`${Constants.assetsFrontendURL}images/doc-icon.png`}
                    className="file-img"
                  />
                </a>
              </Grid>
              <Grid item md={12}>
                <p style={{fontSize: 9}}>{cutFileNameFromPath(file.fileName)}</p>
              </Grid>
            </Grid>
          </Grid>
        )
      })
        :
        <p>Нет прикрепленных файлов</p>
      }
    </Grid>
  )
}

export default DownloadableFiles;