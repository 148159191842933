import {ReactNode, useEffect, useState} from "react";

// react-router-dom components
import {useLocation, useNavigate} from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import {setLayout, useMaterialUIController} from "context";
import DashboardNavbar from "../../layout/Navbar/DashboardNavbar/index";
import MDAlert from "../../../components/MDAlert";
import {useAppSelector} from "../../../hooks/useActions";
import {AlertStatuses} from "../../../store/types/alert.types";

function DashboardLayout({ children }: { children: ReactNode }): JSX.Element {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  const {alert, auth: {isAuth}} = useAppSelector(state => state);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (alert) {
      setOpenAlert(alert["isOpen"])
    }
  }, [alert]);

  useEffect(() => {
    !isAuth && navigate('/login');
  }, [isAuth]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >

      <DashboardNavbar />

      <MDBox style={{display: openAlert ? '' : 'none', position: "fixed", top: 30, zIndex: 9000}}>
        <MDAlert color={alert["status"]} dismissible>{alert["message"]}</MDAlert>
      </MDBox>

      {children}

    </MDBox>
  );
}

export default DashboardLayout;
