import {
  OfferInterface,
  OfferPayload,
  OfferReducerTypes,
  OffersOrderBy,
  OffersSortBy,
  ShortOfferInterface
} from "../types/offer.type";

export interface OffersStateInterface {
  offers: ShortOfferInterface[],
  oneOffer: OfferInterface | null
  take: number,
  skip: number,
  order_by: OffersOrderBy,
  sort_by: OffersSortBy,
};

const initialState: OffersStateInterface = {
  offers: [],
  oneOffer: null,
  take: 20,
  skip: 0,
  order_by: OffersOrderBy.DESCENDING,
  sort_by: OffersSortBy.CREATED_AT,
};

const offersReducer = (state: OffersStateInterface = initialState, action: OfferPayload) => {
  switch (action.type) {
    case(OfferReducerTypes.CREATE_OFFER):
      return {...state, oneOffer: action.payload}
    case(OfferReducerTypes.GET_OFFERS_FOR_STAGE):
      return {...state, offers: action.payload}
    case(OfferReducerTypes.GET_ONE_OFFER):
      return {...state, oneOffer: action.payload}
    case(OfferReducerTypes.GET_MY_OFFERS):
      return {...state, offers: action.payload}
    case(OfferReducerTypes.GET_OFFERS_ADMIN):
      return {...state, offers: action.payload}
    case(OfferReducerTypes.SET_TAKE_FOR_OFFERS):
      return {...state, take: action.payload}
    case(OfferReducerTypes.SET_SKIP_FOR_OFFERS):
      return {...state, skip: action.payload}
    case(OfferReducerTypes.SET_ORDER_BY_FOR_OFFERS):
      return {...state, order_by: action.payload}
    case(OfferReducerTypes.SET_SORT_BY_FOR_OFFERS):
      return {...state, sort_by: action.payload}
    case (OfferReducerTypes.CLEAR_ONE_OFFER):
      return {...state, oneOffer: null}
    default:
      return state;
  }
}

export default offersReducer;