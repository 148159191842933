import Grid from "@mui/material/Grid";
import MDInput from "../../../../components/MDInput";
import MDBox from "../../../../components/MDBox";
import {useAppDispatch, useAppSelector,} from "../../../../hooks/useActions";
import { useEffect, useState} from "react";
import { InputLabel, MenuItem} from "@mui/material";
import Divider, {MarginSize} from "../../../../components/Divider";
import {createDetailWithRouteAction} from "../../../../store/actions/detail.actions";
import Select from 'react-select';

interface PropsInterface {
  setIsDetailComplete: any
}

const OneDetailCreateForm = ({setIsDetailComplete}: PropsInterface) => {
  const {createDetailWithRouteAction} = useAppDispatch();
  const {materials: {allMaterials}, shapes: {allShapes}} = useAppSelector(state => state);

  const [title, setTitle] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(0);
  const [materialId, setMaterialId] = useState<number>(0);
  const [shapeId, setShapeId] = useState<number>(0);

  const [materialsForSelect, setMaterialsForSelect] = useState<{value: number, label: string}[]>([]);
  const [shapesForSelect, setShapesForSelect] = useState<{value: number, label: string}[]>([]);

  const {getAllShapes, getAllMaterials} = useAppDispatch();

  useEffect(() => {
    getAllShapes();
    getAllMaterials();
  }, [])

  useEffect(() => {
    const materialsForSelectOptions = allMaterials.map((material) => {
      return {value: material.id, label: material.title}
    });
    setMaterialsForSelect(materialsForSelectOptions);
  }, [allMaterials.length])

  useEffect(() => {
    const shapesForSelectOptions = allShapes.map((shape) => {
      return {value: shape.id, label: shape.title}
    });
    setShapesForSelect(shapesForSelectOptions);
  }, [allShapes.length])

  const checkAndSave = () => {
    console.log(title, quantity, shapeId, materialId)
    if (title.length > 2 && quantity !== 0 && shapeId !== 0 && materialId !== 0) {
      createDetailWithRouteAction({title, quantity, materialId, shapeId});
      setIsDetailComplete(true);
    }
  }

  useEffect(() => {
    console.log('use EFFECT: ', title, quantity, shapeId, materialId)
  }, [title, quantity, shapeId, materialId])

  return(
    <>
      <Grid container py={0} px={6} justifyContent={'center'}>
      <Divider marginSize={MarginSize.medium} />
      <MDBox py={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={10} xl={10} md={10} sm={10}>
            <InputLabel id="shapeId">Название детали:</InputLabel>
            <MDInput
              style={{width: '100%'}}
              variant="outlined"
              //label="Название детали"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
              onBlur={() => checkAndSave()}
            />
          </Grid>
          <Grid item xs={12} lg={2} xl={2} md={2} sm={2}>
            <InputLabel id="shapeId">Количество:</InputLabel>
            <MDInput
              variant="outlined"
              // label="Количество"
              value={quantity}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => (
                (e.target.value.match(/^[0-9]+$/)) && setQuantity(Number(e.target.value))
              )}
              onBlur={() => checkAndSave()}
            />
          </Grid>
          <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
            <InputLabel id="materialId">Выберите материал:</InputLabel>
            <Select
              placeholder="Выбрать"
              options={materialsForSelect}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // borderColor: state.isFocused ? 'grey' : 'red',
                  fontSize: 12,
                }),
              }}
              onChange={(e) => setMaterialId(Number(e.value))}
            />
          </Grid>
          <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
            <InputLabel id="shapeId">Выберите форму:</InputLabel>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // borderColor: state.isFocused ? 'grey' : 'red',
                  fontSize: '12px',
                }),
              }}
              options={shapesForSelect}
              onChange={(e) => setShapeId(Number(e.value))}
            />
          </Grid>
        </Grid>
      </MDBox>
      </Grid>
    </>
  )
}

export default OneDetailCreateForm;