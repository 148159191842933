import React from 'react';
import Grid from "@mui/material/Grid";
import {FormControl, MenuItem, Select} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {createPaymentInterface, PaymentDirection, PaymentTypes} from "../../../../../store/types/payment.types";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";

// includeTax?: boolean,
// taxRate?: number,
// invoiceNumber?: string,
// invoiceDate?: string,
// validTill?: string,
// paiedOnDate?: string,
// type?: PaymentTypes,
// direction: PaymentDirection,
// invoiceSum: number,
// orderId: number,
// counterpartyUserId?: number,
// counterpartyCompanyId?: number,

function CreatePaymentModal() {

  const {orders: {oneOrder}} = useAppSelector(state => state);
  const {createPayment} = useAppDispatch();

  const [payment, setPayment] = React.useState<createPaymentInterface>({
    includeTax: null,
    taxRate: null,
    invoiceNumber: null,
    invoiceDate: null,
    validTill: null,
    paiedOnDate: null,
    type: null,
    direction: PaymentDirection.FROM_MD,
    invoiceSum: 0,
    orderId: oneOrder?.id,
    counterpartyUserId: null,
    counterpartyCompanyId: null,
  });
  const handleCreatePayment = () => {
    const newPayment = {} as createPaymentInterface;
      Object.entries(payment).forEach(([key, value]) => {
      if (value !== null) {
        (key === 'invoiceSum') && (value = Number(value));
        // @ts-ignore
        newPayment[key] = value;
      }
    });
    createPayment(newPayment);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Select value={payment.direction}
                  style={{height: 40}}
                  onChange={(e) => setPayment({...payment, direction: e.target.value as PaymentDirection})}>
            <MenuItem value={PaymentDirection.FROM_MD}>Оплата от МД клиенту</MenuItem>
            <MenuItem value={PaymentDirection.TO_MD}>Оплата от клиента в МД</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <MDInput label={'Сумма оплаты'} value={payment.invoiceSum}
                       onChange={(e: any) => setPayment({...payment, invoiceSum: e.target.value})}/>
            </FormControl>
          </Grid>
        <Grid item xs={6}>
          <MDButton onClick={handleCreatePayment}>Создать оплату</MDButton>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)
  ;
}

export default CreatePaymentModal;