import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

// Authentication pages components
import Footer from "layouts/authentication/components/Footer";
import Navbar from "./Navbar/DefaultNavbar";
import MySnackbar from "../../components/Snackbar";
import {useAppDispatch, useAppSelector} from "../../hooks/useActions";

// Declaring props types for BasicLayout
interface Props {
  image: string;
  children: ReactNode;
}

function BasicPageLayout({ image, children }: Props): JSX.Element {
  const {auth: {isAuth, error}, alert: { isOpen, status, message}} = useAppSelector(state => state)
  const { login, setAlertClose } = useAppDispatch()

  return (
    <PageLayout>
      {isOpen ?
        <MySnackbar
          // @ts-ignore
          color={status}
          icon="warning"
          // @ts-ignore
          title={status}
          // @ts-ignore
          content={message ?? ''}
          // @ts-ignore
          open={isOpen}
          onClose={() => setAlertClose()}
          close={() => setAlertClose()}
        /> : null
      }
      <Navbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "https://market-detal.ru",
          label: "Home",
          color: "info",
        }}
        transparent
        light
      />
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
                              functions: { linearGradient, rgba },
                              palette: { gradients },
                            }: Theme) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <Footer light />
    </PageLayout>
  );
}

export default BasicPageLayout;
