export const Constants = {
    backendURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5045/images/' : 'https://marketdetal.ru/images/',
    rawBackendURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5045/' : 'https://marketdetal.ru/',
    apiBackendURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5045/api/' : 'https://marketdetal.ru/api/',
    frontendURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://marketdetal.ru/',
    assetsFrontendURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/assets/' : 'https://marketdetal.ru/assets/',
}

export enum FolderTypesEnum {
    ORDER = 'orders',
    AVATAR = 'avatars',
    LOGO = 'logos',
}

export enum ImageSizesEnum {
    X100 = '100x100',
    X200 = '200x200',
    X300 = '300x300',
    X400 = '400x400',
    X500 = '500x500',
    X600 = '600x600',
    X800 = '800x800',
}

export enum FileSize {
    MB_10 = 10485760,
    MB_8 = 8388608,
    MB_5 = 5242880,
    MB_4 = 4194304,
    MB_3 = 3145728,
    MB_2 = 2097152,
    MB_1 = 1048576
}

export enum FileOrImage {
    FILE = 'FILE',
    IMAGE = 'IMAGE'
}

export enum DocumentType {
    ORDER = 'ORDER',
    PREORDER = 'PREORDER',
    STAGE = 'STAGE'
}

export enum UserRoles {
    USER = 'USER',
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    AUTHOR = 'AUTHOR',
    COMPANYOWNER = 'COMPANYOWNER',
    COMPANYMANAGER = 'COMPANYMANAGER',
}

export enum UserStatuses {
    CREATED = 'CREATED',
    CONFIRMED = 'CONFIRMED', // email is confirmed
    VERIFIED = 'VERIFIED', //
    TRUSTED = 'TRUSTED', // has at least one sale or buy
    BANNED = 'BANNED',
}

export const myColors = {
    activeBackground: '#B5D2F8',
    topBarMenuScrollDown: "#DDE0E3",
}