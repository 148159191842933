import {
  CreateDetailInterface,
  DetailReducerPayloads,
  DetailReducerTypes,
  ShortDetailInterface
} from "../types/detail.types";

export interface DetailStateInterface {
  details: ShortDetailInterface[],
  oneDetail: ShortDetailInterface | null,
  detailToCreateWithRoute: CreateDetailInterface | null
}

const DetailInitialState: DetailStateInterface = {
  details: [],
  oneDetail: null,
  detailToCreateWithRoute: null,
}

const detailReducer = (state: DetailStateInterface = DetailInitialState, action: DetailReducerPayloads) => {
  switch (action.type) {
    case DetailReducerTypes.CREATE_DETAIL:
      const newArray = state.details.push(action.payload);
      return {...state, details: newArray, oneDetail: action.payload}
    case DetailReducerTypes.CHANGE_DETAIL:
      const changedInArray = state.details.map(d => {
        if (d.id === action.payload.id) {
          return action.payload;
        }
      })
      return {...state, details: changedInArray, oneDetail: action.payload}
    case DetailReducerTypes.GET_DETAILS:
      return {...state, details: action.payload, oneDetail: null}
    case DetailReducerTypes.GET_ONE_DETAIL:
      return {...state, oneDetail: action.payload}
    case DetailReducerTypes.CREATE_DETAILS_WITH_ROUTE:
      return {...state, detailToCreateWithRoute: action.payload}
    default:
      return state;
  }
}

export default detailReducer;