import {ShortDetailInterface} from "./detail.types";
import {ShortCompanyInterface} from "./company.types";
import {ShortStageInterface2} from "./stage.types";
import {ShortUserInterface} from "./user.types";
import {ShortOrderInterface} from "./order.types";
import {MaterialInterface} from "./material.types";

export interface ShortOfferInterface {
  id: number,
  title: string,
  createdAt: string,
  updatedAt: string,
  status: OfferStatuses,
  producerCompany: ShortCompanyInterface | null,
  producerUser: ShortUserInterface | null,
  stage: ShortStageInterface2,
}

export interface OfferInterface {
  id: number,
  title: string,
  createdAt: string,
  updatedAt: string,
  status: OfferStatuses,
  detail: ShortDetailInterface,
  order: ShortOrderInterface,
  producerCompany: ShortCompanyInterface,
  producerUser: ShortUserInterface,
  stage: ShortStageInterface2,
}

export interface CreateOfferInterface {
  title?: string,
  orderId?: number,
  detailId: number,
  stageId: number,
  producerCompanyId?: number,
  producerUserId?: number
  isCompany: boolean,
  conterPrice?: number,
}

export enum OfferStatuses {
  CREATED = 'CREATED',
  EDITED = 'EDITED',
  PROCESSING = 'PROCESSING',
  ACCEPTED = 'ACCEPTED',
  ARCHIVED  = 'ARCHIVED',
}

export enum OfferReducerTypes {
  GET_OFFERS_FOR_STAGE = 'GET_OFFERS_FOR_STAGE',
  GET_ONE_OFFER = 'GET_ONE_OFFER',
  CLEAR_ONE_OFFER = 'CLEAR_ONE_OFFER',
  CREATE_OFFER = 'CREATE_OFFER',
  GET_MY_OFFERS = 'GET_MY_OFFERS',
  GET_OFFERS_ADMIN = 'GET_OFFERS_ADMIN',
  SET_TAKE_FOR_OFFERS = 'SET_TAKE_FOR_OFFERS',
  SET_SKIP_FOR_OFFERS = 'SET_SKIP_FOR_OFFERS',
  SET_ORDER_BY_FOR_OFFERS = 'SET_ORDER_BY_FOR_OFFERS',
  SET_SORT_BY_FOR_OFFERS = 'SET_SORT_BY_FOR_OFFERS',
}

export type OfferPayload = GetOffersForStage
  | GetOneOffer | CreateOffer | GetMyOffers | GetOffersAdmin
  | SetTakeForOffers | SetSkipForOffers | SetOrderByForOffers | SetSortByForOffers
  | ClearOneOffer

export enum OffersSortBy {
  STATUS = 'STATUS',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ID = 'id',
}

export enum OffersOrderBy {
  DESCENDING = 'desc',
  ASCENDING = 'asc',
}

export interface GetOffersForStage {
  type: OfferReducerTypes.GET_OFFERS_FOR_STAGE,
  payload: ShortOfferInterface[]
}

export interface GetOneOffer {
  type: OfferReducerTypes.GET_ONE_OFFER,
  payload: OfferInterface
}

export interface CreateOffer {
  type: OfferReducerTypes.CREATE_OFFER,
  payload: OfferInterface
}

export interface GetMyOffers {
  type: OfferReducerTypes.GET_MY_OFFERS,
  payload: ShortOfferInterface[]
}

export interface GetOffersAdmin {
  type: OfferReducerTypes.GET_OFFERS_ADMIN,
  payload: ShortOfferInterface[]
}

export interface SetTakeForOffers {
  type: OfferReducerTypes.SET_TAKE_FOR_OFFERS,
  payload: number
}

export interface SetSkipForOffers {
  type: OfferReducerTypes.SET_SKIP_FOR_OFFERS,
  payload: number
}

export interface SetOrderByForOffers {
  type: OfferReducerTypes.SET_ORDER_BY_FOR_OFFERS,
  payload: OffersOrderBy
}

export interface SetSortByForOffers {
  type: OfferReducerTypes.SET_SORT_BY_FOR_OFFERS,
  payload: OffersSortBy
}

export interface ClearOneOffer {
  type: OfferReducerTypes.CLEAR_ONE_OFFER,
}