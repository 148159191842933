import {CompanyPayload, CompanyProfile, CompanyReducerTypes} from "../types/company.types";

export interface CompanyStateInterface {
  error: string,
  id: number,
  profile:                    CompanyProfile;
  name:                       string;
  description:                string;
  inn:                        string;
  ogrnognip:                  string;
  juridicalAddress: {
    id: number;
    country:         string;
    postalCode:      string;
    state:           string;
    city:            string;
    street:          string;
    houseBuilding:   string;
    officeApartment: string;
  },
  deliveryAddress: {
    id: number;
    country:         string;
    postalCode:      string;
    state:           string;
    city:            string;
    street:          string;
    houseBuilding:   string;
    officeApartment: string;
  },
  officialPersonForDocs:      string;
  officialPersonForDocsPhone: string;
  contactPerson:              string;
  contactPersonPhone:         string;
  bankDetails: {
    id: number;
    bankName:                   string;
    bankAccount:                string;
    bankCorrAccount:            string;
    bankBik:                    string;
  },
  logo:                       string;
  managers: {
    id: number,
    avatar: string,
    fio: string,
    nickname: string
  }[];
  typesOfWorks: {
    id: number,
    title: string,
    description: string,
  }[];
}

const initialState: CompanyStateInterface = {
  error: '',
  id: 0,
  profile: CompanyProfile.BLANK,
  name: '',
  description: '',
  inn: '',
  ogrnognip: '',
  juridicalAddress: {
    id: 0,
    country: '',
    postalCode: '',
    state: '',
    city: '',
    street: '',
    houseBuilding: '',
    officeApartment: '',
  },
  deliveryAddress: {
    id: 0,
    country: '',
    postalCode: '',
    state: '',
    city: '',
    street: '',
    houseBuilding: '',
    officeApartment: '',
  },
  officialPersonForDocs: '',
  officialPersonForDocsPhone: '',
  contactPerson: '',
  contactPersonPhone: '',
  bankDetails: {
    id: 0,
    bankName: '',
    bankAccount: '',
    bankCorrAccount: '',
    bankBik: '',
  },
  logo: '',
  managers: [],
  typesOfWorks: []
}

const companyReducer = (state: CompanyStateInterface = initialState, action: CompanyPayload) => {
  switch (action.type) {
    case CompanyReducerTypes.CLEAR_COMPANY:
      return {
        error: '',
        id: 0,
        profile: CompanyProfile.BLANK,
        name: '',
        description: '',
        inn: '',
        ogrnognip: '',
        juridicalAddress: {
          id: 0,
          country: '',
          postalCode: '',
          state: '',
          city: '',
          street: '',
          houseBuilding: '',
          officeApartment: '',
        },
        deliveryAddress: {
          id: 0,
          country: '',
          postalCode: '',
          state: '',
          city: '',
          street: '',
          houseBuilding: '',
          officeApartment: '',
        },
        officialPersonForDocs: '',
        officialPersonForDocsPhone: '',
        contactPerson: '',
        contactPersonPhone: '',
        bankDetails: {
          id: 0,
          bankName: '',
          bankAccount: '',
          bankCorrAccount: '',
          bankBik: '',
        },
        logo: '',
        managers: [],
        typesOfWorks: []
      }
    case CompanyReducerTypes.SET_COMPANY_ERROR:
      return {...state, error: action.payload}
    case CompanyReducerTypes.SET_COMPANY_SUCCESS:
      return {
        error: '',
        id: action.payload.id || 0,
        profile: action.payload.profile || CompanyProfile.BLANK,
        name: action.payload.name || '',
        description: action.payload.description || '',
        inn: action.payload.inn || '',
        ogrnognip: action.payload.ogrnognip || '',
        juridicalAddress: {
          id: action.payload.juridicalAddress?.id || 0,
          country: action.payload.juridicalAddress?.country || '',
          postalCode: action.payload.juridicalAddress?.postalCode || '',
          state: action.payload.juridicalAddress?.state || '',
          city: action.payload.juridicalAddress?.city || '',
          street: action.payload.juridicalAddress?.street || '',
          houseBuilding: action.payload.juridicalAddress?.houseBuilding || '',
          officeApartment: action.payload.juridicalAddress?.officeApartment || '',
        },
        deliveryAddress: {
          id: action.payload.deliveryAddress?.id || 0,
          country: action.payload.deliveryAddress?.country || '',
          postalCode: action.payload.deliveryAddress?.postalCode || '',
          state: action.payload.deliveryAddress?.state || '',
          city: action.payload.deliveryAddress?.city || '',
          street: action.payload.deliveryAddress?.street || '',
          houseBuilding: action.payload.deliveryAddress?.houseBuilding || '',
          officeApartment: action.payload.deliveryAddress?.officeApartment || '',
        },
        officialPersonForDocs: action.payload.officialPersonForDocs || '',
        officialPersonForDocsPhone: action.payload.officialPersonForDocsPhone || '',
        contactPerson: action.payload.contactPerson || '',
        contactPersonPhone: action.payload.contactPersonPhone || '',
        bankDetails: {
          id: action.payload.bankDetails?.id || 0,
          bankName: action.payload.bankDetails?.bankName || '',
          bankAccount: action.payload.bankDetails?.bankAccount || '',
          bankCorrAccount: action.payload.bankDetails?.bankCorrAccount || '',
          bankBik: action.payload.bankDetails?.bankBik || '',
        },
        logo: action.payload.logo || '',
        managers: action.payload.managers,
        typesOfWorks: action.payload.typesOfWorks,
      }
    case CompanyReducerTypes.SET_COMPANY_LOGO:
      return {...state, logo: action.payload}
    case CompanyReducerTypes.SET_COMPANY_PROFILE:
      return {...state, profile: action.payload}
    case CompanyReducerTypes.ADD_COMPANY_TYPE_OF_WORK:
      //@ts-ignore
      const isInArray = state.typesOfWorks?.find(item => item.id === action.payload.id)
      if (isInArray) return state;
      return {...state, typesOfWorks: [...state.typesOfWorks as {id: number, title: string, description: string}[], action.payload]}
    case CompanyReducerTypes.REMOVE_COMPANY_TYPE_OF_WORK:
      return {...state, typesOfWorks: action.payload}
    default:
      return {...state};
  }
}

export default companyReducer;