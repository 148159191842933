import React, {useEffect} from 'react';
import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import {Box, Grid, Modal} from "@mui/material";
import {CompanyProfile} from "../../../../../store/types/company.types";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import ProfileSelectorForm from "../../../components/ProfileSelectorForm/ProfileSelectorForm";
import MDButton from "../../../../../components/MDButton";
import TypesOfWorks from "../../../components/TypesOfWorks/TypesOfWorks";
import ListOfPickedTypesOfWorks from "../../../components/TypesOfWorks/ListOfPickedTypesOfWorks";

const scrollableModalBlockStyle = {
  height: '100%',
  margin: '0em',
  overflowY: 'auto',
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90%',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "visible"
};

function ProfileAndTypesOfWorks() {
  const {user, typesWorks} = useAppSelector(state => state);
  const {getTypesOfWorks} = useAppDispatch();
  const [typesOfWorksModal, setTypesOfWorksModal] = React.useState(false);

  const handleTypesOfWorksModalClose = () => {
    setTypesOfWorksModal(false);
  }

  const handleTypesOfWorksModalOpen = () => {
    (typesWorks.length < 2) && getTypesOfWorks();
    setTypesOfWorksModal(true);
  }

  return (
    <Card id="notifications">

      <Modal
        open={typesOfWorksModal}
        onClose={handleTypesOfWorksModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={modalStyle}>
          <Box sx={scrollableModalBlockStyle}>
            <TypesOfWorks isForCompany={false}/>
            <ListOfPickedTypesOfWorks list={user["typesOfWorks"]}/>
          </Box>
        </Card>
      </Modal>

      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Ваша специализация</MDTypography>
        </MDBox>
        <Grid container marginTop={2}>
          <Grid item xs={12} md={6}>
            <ProfileSelectorForm isForCompany={false}/>
          </Grid>
          <Grid item xs={12} md={6}>
            {
              (user["profile"] === CompanyProfile.PRODUCERCLIENT || user["profile"] === CompanyProfile.PRODUCER) &&
                <MDButton variant={'contained'} onClick={() => handleTypesOfWorksModalOpen()}>Типы работ</MDButton>
            }
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default ProfileAndTypesOfWorks;