import {InvitedCompany} from "./common.types";
import {CompanyProfile} from "./company.types";
import {UserRoles, UserStatuses} from "../../utilities/constants";

export interface ShortUserInterface {
  id: number,
  email: string,
  fio: string,
  nickname: string,
  avatar: string,
  phone: string
}
export enum userReducerTypes {
  SET_USER_SUCCESS = 'SET_USER_SUCCESS',
  SET_USER_ERROR = 'SET_USER_ERROR',
  CLEAR_USER = 'CLEAR_USER',
  SET_JURIDICAL_ADDRESS = 'SET_JURIDICAL_ADDRESS',
  SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS',
  SET_BANK_DETAILS = 'SET_BANK_DETAILS',
  SET_USER_DATA = 'SET_USER_DATA',
  SET_USER_AVATAR = 'SET_USER_AVATAR',
  SET_SOCIAL_PROFILE = 'SET_SOCIAL_PROFILE',
  SET_COMPANY_DATA = 'SET_COMPANY_DATA',
  SET_USER_PROFILE = 'SET_USER_PROFILE',
  ADD_TYPE_OF_WORK = 'ADD_TYPE_OF_WORK',
  REMOVE_TYPE_OF_WORK = 'REMOVE_TYPE_OF_WORK',
}

export type UserPayload = SuccessUserPayload
  | ErrorUserPayload
  | UserWithoutPayload
  | SetJuridicalAddressPayload
  | SetDeliveryAddressPayload
  | SetBankDetails
  | SetUserData
  | SetSocialProfile
  | SetCompanyData
  | SetUserAvatar
  | SetUserProfile
  | AddTypeOfWork
  | RemoveTypeOfWork

interface SuccessUserPayload {
  type: userReducerTypes.SET_USER_SUCCESS,
  payload: FullUserInterface
}

interface SetJuridicalAddressPayload {
  type: userReducerTypes.SET_JURIDICAL_ADDRESS,
  payload: {
    country: string,
    postalCode: string,
    state: string,
    city: string,
    street: string,
    houseBuilding: string,
    officeApartment: string
  }
}

interface SetDeliveryAddressPayload {
  type: userReducerTypes.SET_DELIVERY_ADDRESS,
  payload: {
    country: string,
    postalCode: string,
    state: string,
    city: string,
    street: string,
    houseBuilding: string,
    officeApartment: string
  }
}

interface ErrorUserPayload {
  type: userReducerTypes.SET_USER_ERROR,
  payload: string
}

interface UserWithoutPayload {
  type: userReducerTypes.CLEAR_USER,
}

interface SetBankDetails {
  type: userReducerTypes.SET_BANK_DETAILS,
  payload: {
    bankName: string,
    bankAccount: string,
    bankCorrAccount: string,
    bankBik: string
  }
}

interface SetUserData {
  type: userReducerTypes.SET_USER_DATA,
  payload: {
    fio: string,
    birthday: string,
    passportNumber: number,
    passportIssued: string,
  }
}

interface SetSocialProfile {
  type: userReducerTypes.SET_SOCIAL_PROFILE,
  payload: {
    phone: string,
    vkontakte: string,
    odnoklassniki: string,
    telegram: string,
    whatsapp: string,
    facebook: string,
    instagram: string,
    tiktok: string,
  }
}

interface SetCompanyData {
  type: userReducerTypes.SET_COMPANY_DATA,
  payload: {
    id: number,
    profile: string,
    name: string,
    description: string,
    inn: string,
    logo: string,
    typesOfWorks: {
      id: number,
      title: string,
      description: string,
    }[]
  }
}

interface SetUserAvatar {
  type: userReducerTypes.SET_USER_AVATAR,
  payload: string
}

interface AddTypeOfWork {
  type: userReducerTypes.ADD_TYPE_OF_WORK,
  payload: {
    id: number,
    title: string,
    description: string,
  }
}

interface RemoveTypeOfWork {
  type: userReducerTypes.REMOVE_TYPE_OF_WORK,
  payload: {
    id: number,
    title: string,
    description: string,
  }[]
}

interface SetUserProfile {
  type: userReducerTypes.SET_USER_PROFILE,
  payload: CompanyProfile
}

export enum isDeliveryOrJuridicalEnum {
  DELIVERY = 'delivery',
  JURIDICAL = 'juridical',
}

export interface FullUserInterface {
  id: number,
  email: string,
  fio: string,
  birthday: Date,
  profile: CompanyProfile,
  juridicalAddress: {
    country: string,
    postalCode: string,
    state: string,
    city: string,
    street: string,
    houseBuilding: string,
    officeApartment: string
  },
  deliveryAddress: {
    country: string,
      postalCode: string,
      state: string,
      city: string,
      street: string,
      houseBuilding: string,
      officeApartment: string
  },
  bankDetails: {
    bankName: string,
      bankAccount: string,
      bankCorrAccount: string,
      bankBik: string
  },
  nickname: string,
  avatar: string,
  phone: string,
  vkontakte: string,
  odnoklassniki: string,
  telegram: string,
  whatsapp: string,
  facebook: string,
  instagram: string,
  tiktok: string,
  passportNumber: number,
  passportIssued: Date,
  typesOfWorks: {
    id: number,
      title: string,
      description: string,
  } | [],
  ownCompany: {
    id: number,
      profile: string,
      name: string,
      description: string,
      inn: string,
      logo: string,
      typesOfWorks: {
        id: number,
        title: string,
        description: string,
      }[]
  },
  invitedFromCompanies: InvitedCompany[]
}

export interface UserRolesFull {
  value: UserRoles
}

export interface FullUserForAdminInterface extends FullUserInterface {
  status: UserStatuses,
  roles: UserRolesFull[],
}

export interface updateUserByAdminBodyInterface {
  status?: UserStatuses,
  addRole?: UserRoles,
  removeRole?: UserRoles,
}

export enum OrderRelated {
  DESC = 'desc',
  ASC = 'asc'
}

export interface UsersForAdminQueryDto {
  skip?: string
  take?: string
  order?: OrderRelated
}

export interface UserForAdminInterface {
  id: number,
  email: string,
  roles: UserRolesFull[],
  status: UserStatuses,
  nickname: string,
  _count: {
    customerToOrders: number,
    producerToOrders: number
  }
}