// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "../../../../../components/MDAvatar";
import noAvatar from "../../../../../assets/img/no-avatar.svg";
import MDButton from "../../../../../components/MDButton";
import React from "react";
import {useAppDispatch} from "../../../../../hooks/useActions";

interface InviteInterface {
  id: number
  company: {
    logo: string,
    name: string
  }
}

interface Props {
  list?: InviteInterface[];
  [key: string]: any;
}

function InvitesToBeManager({list}: Props): JSX.Element {
  const {acceptInvitation, declineInvitation} = useAppDispatch();

  const handleAccept = async (id: number) => {
    const res = await acceptInvitation(id);
    console.log(`ACCEPTED - RESULT ${JSON.stringify(res)}`);
  }

  const handleDecline = async (id: number) => {

  }

  const renderInvites = list.map(({id, company: {name, logo}}) => (
    <MDBox key={id} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
        <MDAvatar src={
          logo ? `http://localhost:5045/images/logos/300x300/${logo}` : noAvatar
        }
                  alt="something here"
                  shadow="md"
        />
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
      <MDBox ml="auto">
        <MDButton variant="text" color="success" onClick={() => handleAccept(id)}>
          Принять
        </MDButton>
        <MDButton variant="text" color="error" onClick={() => handleDecline(id)}>
          Отклонить
        </MDButton>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card id="notifications">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Приглашения на роль менеджера магазина</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Вы можете отказаться от приглашения без объяснения причин. Если вы примите приглашение, то полчите доступ к управлению заказами магазина.
        </MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <MDBox minWidth="auto" sx={{ overflow: "scroll" }}>
          {renderInvites}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default InvitesToBeManager;
