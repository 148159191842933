import React, {ReactNode, useEffect, useState} from 'react';
import MDBox from "../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../../dashboard/DashboardLayout";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {Box, Card, Modal} from "@mui/material";
import OrdersTableBody from "./Table/OrdersTableBody";
import OrdersTable from "./Table";
import OrdersSearchHeader from "./SearchHeader/OrdersSearchHeader";

const scrollableModalBlockStyle = {
  height: '100%',
  margin: '0em',
  overflowY: 'auto',
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90%',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "visible"
};

interface Props {
  children?: ReactNode;
}

const AdminAllOrders = ({ children }: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const {preOrders: {preOrders}} = useAppSelector(state => state);
  const {getPreOrders} = useAppDispatch();

  useEffect(() => {

  }, [])

  const handleClose = () => {
    setModalOpen(false);
  }

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card sx={modalStyle}>
            <Box sx={scrollableModalBlockStyle}>
              MODAL
            </Box>
          </Card>
        </Modal>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} md={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <OrdersSearchHeader />
                </Grid>
                <Grid item xs={12}>
                  <OrdersTable />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default AdminAllOrders;