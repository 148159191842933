// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import {useStringInput} from "../../../../../hooks/useInput";
import MDButton from "../../../../../components/MDButton";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {ChangeEvent, useEffect, useState} from "react";
import {UserStateInterface} from "../../../../../store/reducers/user.reducer";

interface Props {
  user: UserStateInterface;
}

function SocialProfile({user}: Props): JSX.Element {
  const {changeSocialProfile} = useAppDispatch();

  const [values, setValues] = useState({
    phone: '',
    vkontakte: '',
    odnoklassniki: '',
    telegram: '',
    whatsapp: '',
    facebook: '',
    instagram: '',
    tiktok: '',
  });

  const [prevData, setPrevData] = useState({
    phone: '',
    vkontakte: '',
    odnoklassniki: '',
    telegram: '',
    whatsapp: '',
    facebook: '',
    instagram: '',
    tiktok: '',
  });

  const {phone, vkontakte, odnoklassniki, telegram, whatsapp, facebook, instagram, tiktok} = values;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [e.target.name]: e.target.value});
  }

  useEffect(() => {
    if (user) {
      setValues({
        phone: user.phone,
        vkontakte: user.vkontakte,
        odnoklassniki: user.odnoklassniki,
        telegram: user.telegram,
        whatsapp: user.whatsapp,
        facebook: user.facebook,
        instagram: user.instagram,
        tiktok: user.tiktok,
      })
      setPrevData({
        phone: user.phone,
        vkontakte: user.vkontakte,
        odnoklassniki: user.odnoklassniki,
        telegram: user.telegram,
        whatsapp: user.whatsapp,
        facebook: user.facebook,
        instagram: user.instagram,
        tiktok: user.tiktok,
      })
    }
  }, [user]);

  const handleSubmit = async () => {
    const res = await changeSocialProfile({prevData, newData: values});
    if (res) {
      return console.log('SUCCESS')
    }
    return console.log('ERROR')
  }

  return (
    <Card id="social-profile" sx={{ overflow: "visible" }} style={{scrollMarginTop: 90}}>
      <MDBox p={3}>
        <MDTypography variant="h5">Профиль</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Телефон"
              placeholder="+7 935 631 6201"
              name={'phone'}
              value={phone || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Страница ВКонтакте"
              placeholder="..."
              name={'vkontakte'}
              value={vkontakte || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Страница Одноклассники"
              placeholder="..."
              name={'odnoklassniki'}
              value={odnoklassniki || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Ник в Телеграм"
              placeholder="..."
              name={'telegram'}
              value={telegram || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Телефон для Whatsapp"
              placeholder="..."
              name={'whatsapp'}
              value={whatsapp || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Страница Facebook"
              placeholder="..."
              name={'facebook'}
              value={facebook || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Страница Instagram"
              placeholder="..."
              name={'instagram'}
              value={instagram || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Страница TikTok"
              placeholder="..."
              name={'tiktok'}
              value={tiktok || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
          </Grid>
          <MDBox mt={3} ml={3} display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
              <MDButton variant="gradient" color="dark" size="small" onClick={() => handleSubmit()}>
                Сохранить изменения
              </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SocialProfile;
