import React from 'react';
import SidenavCollapse from "./SidenavCollapse";
import {NavLink} from "react-router-dom";
import Icon from "@mui/material/Icon";
import SidenavItem from "./SidenavItem";
import {useAppSelector} from "../../../hooks/useActions";

interface CollapseNamesInterface {
  account: boolean,
  dashboard: boolean,
  admin: boolean
}

const collapseNames: CollapseNamesInterface = {
  account: true,
  dashboard: true,
  admin: true
}

interface Props {
  collapseName: string;
  itemName: string;
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
}

function SidenavRoutes({collapseName, itemName, color = "info"}: Props) {

  const {roles} = useAppSelector(state => state.auth.user)

  const [open, setOpen] = React.useState<CollapseNamesInterface>(collapseNames);

  const handleClick = (name: "account" | "dashboard" | "admin") => {
    setOpen({...collapseNames, [name]: !open[name]});
  }

  return (
    <div style={{paddingLeft: 4, paddingRight: 4}}>


        <SidenavCollapse
          name="Личный кабинет"
          icon={<Icon fontSize="medium">person</Icon>}
          active={"account" === collapseName}
          open={open.account}
          onClick={() => handleClick("account")}
        >
          <NavLink to={"/account/settings"} key={"settings"} style={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={"Настройки"} active={"settings" === itemName} />
          </NavLink>
          <NavLink to={"/account/notifications"} key={"notifications"} style={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={"Уведомления"} active={"notifications" === itemName} />
          </NavLink>
        </SidenavCollapse>


      <SidenavCollapse
        name="Панель управления"
        icon={<Icon fontSize="medium">dashboard</Icon>}
        active={"dashboard" === collapseName}
        open={open.dashboard}
        onClick={() => handleClick("dashboard")}
      >
        <NavLink to={"/dashboard/analytics"} key={"analytics"} style={{ textDecoration: "none" }}>
          <SidenavItem color={color} name={"Статистика"} active={"analytics" === itemName} />
        </NavLink>
        <NavLink to={"/dashboard/preorders"} key={"preorders"} style={{ textDecoration: "none" }}>
          <SidenavItem color={color} name={"Мои заявки"} active={"preorders" === itemName} />
        </NavLink>
        <NavLink to={"/dashboard/offers"} key={"offers"} style={{ textDecoration: "none" }}>
          <SidenavItem color={color} name={"Мои заказы"} active={"offers" === itemName} />
        </NavLink>
        <NavLink to={"/dashboard/invoices"} key={"invoices"} style={{ textDecoration: "none" }}>
          <SidenavItem color={color} name={"Оплаты"} active={"invoices" === itemName} />
        </NavLink>
      </SidenavCollapse>


      {
        (roles.includes("ADMIN") || roles.includes("MANAGER")) &&
          <SidenavCollapse
              name="Админка"
              icon={<Icon fontSize="medium">settings</Icon>}
              active={"admin" === collapseName}
              open={open.admin}
              onClick={() => handleClick("admin")}
          >
              <NavLink to={"/admin/stages"} key={"stages"} style={{ textDecoration: "none" }}>
                  <SidenavItem color={color} name={"Этапы"} active={"stages" === itemName} />
              </NavLink>
              <NavLink to={"/admin/routes"} key={"routes"} style={{ textDecoration: "none" }}>
                  <SidenavItem color={color} name={"Маршруты"} active={"routes" === itemName} />
              </NavLink>
              <NavLink to={"/admin/pre-orders"} key={"pre-orders"} style={{ textDecoration: "none" }}>
                  <SidenavItem color={color} name={"Заявки"} active={"pre-orders" === itemName} />
              </NavLink>
              <NavLink to={"/admin/orders"} key={"orders"} style={{ textDecoration: "none" }}>
                  <SidenavItem color={color} name={"Заказы"} active={"orders" === itemName} />
              </NavLink>
              <NavLink to={"/admin/users"} key={"users"} style={{ textDecoration: "none" }}>
                  <SidenavItem color={color} name={"Пользователи"} active={"users" === itemName} />
              </NavLink>
              <NavLink to={"/admin/models"} key={"models"} style={{ textDecoration: "none" }}>
                  <SidenavItem color={color} name={"Описания"} active={"models" === itemName} />
              </NavLink>

          </SidenavCollapse>
      }

    </div>
  );
}

export default SidenavRoutes;