import {useEffect, useState} from "react";

import {Link, useNavigate} from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/img/auth-bg.jpg";
import {store, } from "../../store";
import {login} from "../../store/actions/auth.actions";
import {useAppDispatch, useAppSelector} from "../../hooks/useActions"
import {useStringInput} from "../../hooks/useInput";
import MySnackbar from "../../components/Snackbar";
import {AlertColorStatuses, AlertContent, ErrorTitleCreator} from "../../store/types/common.types";
import BasicPageLayout from "../layout/BasicPageLayout";

const Login = (): JSX.Element => {
  let navigate = useNavigate();
  //const [rememberMe, setRememberMe] = useState<boolean>(false);
  //const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const {auth: {isAuth, error}, alert} = useAppSelector(state => state)
  const { login, setAlertClose } = useAppDispatch()

  useEffect(() => {
    console.log(`IN LOGIN IF AUTH ${isAuth}`)
    if (isAuth) navigate('/profile/profile-overview', { replace: true })
  }, []);


  const email = useStringInput('');
  const password = useStringInput('');

  // const [isAlert, setIsAlert] = useState<boolean>(false);
  // const [alertContent, setAlertContent] = useState<AlertContent | null>(null);
  //
  // useEffect(() => {
  //   setIsAlert(!!error);
  //   if (error) {
  //     setAlertContent({status: AlertColorStatuses.ERROR, message: error});
  //     setTimeout(() => {
  //       setIsAlert(false);
  //       setAlertContent(null);
  //     }, 4000);
  //   }
  //   !error && setAlertContent(null);
  // }, [error]);

  const handleLogin = async () => {
    const result = await login(email.value, password.value);
    if (result) {
      navigate("/account/settings", { replace: true });
    }
  }


  return (
    <BasicPageLayout image={bgImage}>
      {/*{isAlert ?*/}
      {/*  <MySnackbar*/}
      {/*    // @ts-ignore*/}
      {/*    color={alert.status}*/}
      {/*    icon="warning"*/}
      {/*    // @ts-ignore*/}
      {/*    title={alert.status}*/}
      {/*    // @ts-ignore*/}
      {/*    content={alert.message ?? ''}*/}
      {/*    // @ts-ignore*/}
      {/*    open={alert.isOpen}*/}
      {/*    onClose={() => setAlertClose()}*/}
      {/*    close={() => setAlertClose()}*/}
      {/*  /> : null*/}
      {/*}*/}
      <Card >
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Вход
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            для зарегистрированных
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput {...email} type="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput {...password} type="password" label="Пароль" fullWidth />
            </MDBox>
            {/*<MDBox display="flex" alignItems="center" ml={-1}>*/}
            {/*  <Switch checked={rememberMe} onChange={handleSetRememberMe} />*/}
            {/*  <MDTypography*/}
            {/*    variant="button"*/}
            {/*    fontWeight="regular"*/}
            {/*    color="text"*/}
            {/*    onClick={handleSetRememberMe}*/}
            {/*    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}*/}
            {/*  >*/}
            {/*    &nbsp;&nbsp;Запомнить меня*/}
            {/*  </MDTypography>*/}
            {/*</MDBox>*/}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={() => handleLogin()}>
                ВОЙТИ
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Еще нет аккаунта?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/registration"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Зарегистрироваться
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/authentication/forgot-password"
                  variant="button"
                  color="info"
                  fontWeight="small"
                  textGradient
                >
                  Забыли пароль?
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicPageLayout>
  );
}

export default Login;
