import React from 'react';
import {useParams} from "react-router-dom";

function Post() {
  const {slug} = useParams<{ slug: string }>();

  return (
    <div>
      {slug}
    </div>
  );
}

export default Post;