import {
  FullOrderInterface,
  OrderActionTypes,
  OrdersOrderBy,
  OrdersSortBy,
  ShortOrderInterface
} from "../types/order.types";

export interface OrderReducerInterface {
  orders: ShortOrderInterface[],
  oneOrder: FullOrderInterface | null,
  take: number,
  skip: number,
  query: string | null,
  sort_by: OrdersSortBy,
  order_by: OrdersOrderBy,
}

const initialState: OrderReducerInterface = {
  orders: [],
  oneOrder: null,
  take: 10,
  skip: 0,
  query: null,
  sort_by: OrdersSortBy.ID,
  order_by: OrdersOrderBy.DESCENDING,
}

const orderReducer = (state: OrderReducerInterface = initialState, action: any) => {
  switch (action.type) {
    case (OrderActionTypes.GET_ALL_ORDERS):
      return {...state, orders: action.payload}
    case (OrderActionTypes.GET_ONE_ORDER):
      return {...state, oneOrder: action.payload}
    case (OrderActionTypes.UPDATE_STATUS):
      return {...state, oneOrder: action.payload}
    case (OrderActionTypes.SET_ORDER_QUERY):
      return {...state, query: action.payload}
    case (OrderActionTypes.SET_ORDER_BY):
      return {...state, order_by: action.payload}
    case (OrderActionTypes.SET_SORT_BY):
      return {...state, sort_by: action.payload}
    case (OrderActionTypes.SET_ORDER_SKIP):
      return {...state, skip: action.payload}
    case (OrderActionTypes.SET_ORDER_TAKE):
      return {...state, take: action.payload}
    case (OrderActionTypes.CLEAR_QUERY):
      return {...state, query: null}
    case (OrderActionTypes.SET_ORDER_PAYMENT):
      return {...state, oneOrder: {...state.oneOrder, payments: [...state.oneOrder.payments, action.payload]}}
    default:
      return state;
  }
}

export default orderReducer;