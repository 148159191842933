import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, TableHead, Tooltip} from "@mui/material";
import DehazeIcon from '@mui/icons-material/Dehaze';
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {convertFromStringToBeautyDateString, getPreOrderStatus, getRouteStatus} from "../../../../utilities/converters";
import PaginationBlock from "../../../../components/Pagination/PaginationBlock";
import {ShortRoutesInterface} from "../../../../store/reducers/route.reducer";
import {useEffect} from "react";


interface TablePropsInterface {
  routes: ShortRoutesInterface[],
  setModalOpen(o: boolean): void,
}

export default function AdminRoutesTable({routes, setModalOpen}: TablePropsInterface) {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const {getOneRouteAction} = useAppDispatch();
  const {route: {oneRoute, count, take, skip, sort_by, order_by, query}} = useAppSelector(state => state);

  useEffect(() => {
    //@ts-ignore
    oneRoute?.id && pickIdAndOpenModal(oneRoute?.id);
  }, [])
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - routes.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const pickIdAndOpenModal = (id: number) => {
    setModalOpen(true);
    getOneRouteAction(id);
  }

  const smallTextStyle = {
    fontSize: 14,
    fontWeight: 'bold'
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">

        <TableBody>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="center" style={smallTextStyle}>Дата</TableCell>
            <TableCell align="center" style={smallTextStyle}>Название</TableCell>
            <TableCell align="center" style={smallTextStyle}>Статус</TableCell>
            <TableCell align="center" style={smallTextStyle}>Кол-во этапов</TableCell>
            <TableCell align="center" style={smallTextStyle}>Заказчик</TableCell>
            <TableCell align="center" style={smallTextStyle}>Сбор предложений до</TableCell>
            <TableCell align="center" style={smallTextStyle}>Статус предзаказа</TableCell>
            <TableCell align="center" style={smallTextStyle}>Изменить</TableCell>
          </TableRow>
          {
            // @ts-ignore
            routes?.map(row =>
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell style={{ width: 140 }} align="center">
                  {convertFromStringToBeautyDateString(row.createdAt)}
                </TableCell>
                <TableCell style={{ width: 240 }} align="center">
                  {row.title.slice(0, 40)}
                </TableCell>
                <TableCell style={{ width: 120 }} align="center">
                  {getRouteStatus(row.status)}
                </TableCell>
                <TableCell style={{ width: 80 }} align="center">
                  {row._count.stages}
                </TableCell>
                <Tooltip title={row.preOrder.ownerUser?.email ?? ''} placement="top-start">
                  <TableCell style={{ width: 160 }} align="center">
                      { row.preOrder.ownerCompany?.name?.length > 0
                        ? row.preOrder.ownerCompany?.name
                        : row.preOrder.ownerUser?.nickname
                      }
                  </TableCell>
                </Tooltip>
                <TableCell style={{ width: 140 }} align="center">
                  {convertFromStringToBeautyDateString(row.preOrder.getOffersBeforeDate)}
                </TableCell>
                <TableCell style={{ width: 120 }} align="center">
                  {getPreOrderStatus(row.preOrder.status)}
                </TableCell>
                <TableCell style={{ width: 80 }} align="center">
                  <Button variant="text" onClick={() => pickIdAndOpenModal(row.id)}>
                    <DehazeIcon color="primary" />
                  </Button>
                </TableCell>
              </TableRow>
            )
          }
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
              colSpan={3}
              count={routes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'Кол-во записей на странице',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={PaginationBlock}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}