import {Dispatch} from "redux";
import {MaterialPayload, MaterialReducerTypes} from "../types/material.types";
import $api from "../../utilities/http.axios";

export const getAllMaterials = () => {
  return async (dispatch: Dispatch<MaterialPayload>) => {
    try {
      const res = await $api.get(`/material/get-all`);
      return dispatch({type: MaterialReducerTypes.GET_MATERIALS, payload: res.data});
    } catch (e) {
      console.log('Ошибка ', e)
    }
  }
}