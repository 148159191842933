import React from 'react';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { Table } from '@mui/material';
import OrdersTableFooter from "./OrdersTableFooter";
import OrdersTableHeader from "./OrdersTableHeader";
import OrdersTableBody from "./OrdersTableBody";

function OrdersTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>

          <OrdersTableHeader />

          <OrdersTableBody />

        </TableBody>

        <OrdersTableFooter />

      </Table>
    </TableContainer>
  );
}

export default OrdersTable;