import React, {Suspense, useEffect} from 'react';
import {getCompanyRating, getUserRating} from "../../../../utilities/http.requests";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';

interface PropsInterface {
  id: number,
  isCompany: boolean
}

export interface RatingInterface {
  _avg: {
    value: null | number
  }
}

function Rating({id, isCompany}: PropsInterface) {
  const [rating, setRating] = React.useState<RatingInterface>({_avg: {value: null}});
  async function getData() {
    let res: any;
    if (!isCompany) {
      res = await getUserRating(id);
    } else {
      res = await getCompanyRating(id);
    }
    setRating(res);
  }

  useEffect(() => {
    getData();
  }, []);

  const createStars = () => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating?._avg?.value) {
        stars.push(<StarIcon color="warning" />);
      } else if (i < rating?._avg?.value && i + 1 > rating?._avg?.value) {
        stars.push(<StarHalfIcon color="warning" />);
      } else {
        stars.push(<StarBorderIcon color="warning" />);
      }
    }
    return stars;
  }

  return (
    <div>
      <Suspense fallback={<div>Загружаем...</div>}>
        <div style={{padding: 10}}>
          <span style={{marginRight: 14, fontSize: 12, fontWeight: 'bold', verticalAlign: 'middle'}}>РЕЙТИНГ:</span>
          {
            rating?._avg?.value ? createStars() : <span style={{fontSize: 12, fontWeight: 'bold', verticalAlign: 'middle'}}> Пока нет отзывов</span>
          }
        </div>
      </Suspense>
    </div>
  );
}

export default Rating;