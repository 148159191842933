import {Dispatch} from "redux";
import {TextPayload, TextsReducerTypes} from "../types/text.types";

export const clearDialogText = () => {
  return async (dispatch: Dispatch<TextPayload>) => {
    dispatch({type: TextsReducerTypes.CLEAR_DIALOG_TEXT});
  }
}

export const setDialogText = (text: string) => {
  return async (dispatch: Dispatch<TextPayload>) => {
    dispatch({type: TextsReducerTypes.SET_DIALOG_TEXT, payload: text});
  }
}