import {useEffect, useState} from "react";

// react-images-viewer components
import ImgsViewer from "react-images-viewer";

// @mui material components
import Stack from "@mui/material/Stack";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Images
import emptyImage from "assets/images/empty-images/one.jpg";
import {useAppSelector} from "../../../../../hooks/useActions";
import {Constants, FolderTypesEnum, ImageSizesEnum} from "../../../../../utilities/constants";
import {createImagePath} from "../../../../../utilities/converters";

interface PropsInterface {
    images: { id: number; fileName: string; description: string; }[]
}

function PreOrderImages({images}: PropsInterface): JSX.Element {
    const [currentImage, setCurrentImage] = useState<string>(emptyImage);
    const [imgsViewer, setImgsViewer] = useState<boolean | number>(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState<number>(0);

    useEffect(() => {
       if (images?.length > 0) {
            setCurrentImage(createImagePath(FolderTypesEnum.ORDER, userId, ImageSizesEnum.X800, images[0].fileName))
        }

    }, [images])

    const {auth: {user: {userId}}} = useAppSelector(state => state);

    const handleSetCurrentImage = ({ currentTarget }: any) => {
        setCurrentImage(currentTarget.src);
        setImgsViewerCurrent(Number(currentTarget.id));
    };

    const openImgsViewer = () => setImgsViewer(true);
    const closeImgsViewer = () => setImgsViewer(false);
    const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
    const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

    return (
        <MDBox>
            <ImgsViewer
              imgs={images?.length > 0 ? images.map(i => {
                return {src: createImagePath(FolderTypesEnum.ORDER, userId, ImageSizesEnum.X800, i.fileName)}
              }) : [{src: emptyImage}]}
              isOpen={imgsViewer}
              onClose={closeImgsViewer}
              currImg={imgsViewerCurrent}
              onClickPrev={imgsViewerPrev}
              onClickNext={imgsViewerNext}
              backdropCloseable
            />
            <MDBox mb={2} pb={1} width='500'>
              {
                images?.length > 1 &&
                <Stack direction="row" spacing={3} style={{overflow: 'scroll'}}>
                  {
                    images?.length > 0
                      ? images.map((i, k) => <MDBox
                        component="img"
                        id="0"
                        src={createImagePath(FolderTypesEnum.ORDER, userId, ImageSizesEnum.X800, i.fileName)}
                        alt="small image 1"
                        borderRadius="lg"
                        shadow="md"
                        width="auto"
                        height="5rem"
                        minHeight="5rem"
                        sx={{cursor: "pointer", objectFit: "cover"}}
                        onClick={handleSetCurrentImage}
                        key={k}
                      />)
                      : <MDBox
                        component="img"
                        id="0"
                        src={emptyImage}
                        alt="small image 1"
                        borderRadius="lg"
                        shadow="md"
                        width="100%"
                        height="5rem"
                        minHeight="5rem"
                        sx={{cursor: "pointer", objectFit: "cover"}}
                        onClick={handleSetCurrentImage}
                      />
                  }
                </Stack>
              }
            </MDBox>

            <MDBox
                component="img"
                src={currentImage}
                alt="Product Image"
                shadow="lg"
                borderRadius="lg"
                width="100%"
                onClick={openImgsViewer}
            />
        </MDBox>
    );
}

export default PreOrderImages;
