import React, {ReactNode, useEffect, useState} from 'react';
import AdminPreOrdersTable from "./Table";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../dashboard/DashboardLayout";
import TableHeader from "./Table/TableHeader";
import {useAppDispatch, useAppSelector} from "../../../hooks/useActions";
import {Box, Card, Modal} from "@mui/material";
import PreviewBlock from "./Modal/Preview";

const scrollableModalBlockStyle = {
  height: '100%',
  margin: '0em',
  overflowY: 'auto',
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90%',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "visible"
};

interface Props {
  children?: ReactNode;
}

const AdminPreOrders = ({ children }: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const {preOrders: {preOrders}} = useAppSelector(state => state);
  const {getPreOrders} = useAppDispatch();

  useEffect(() => {
    getPreOrders();
  }, [])

  const handleClose = () => {
    setModalOpen(false);
  }

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card sx={modalStyle}>
            <Box sx={scrollableModalBlockStyle}>
            <PreviewBlock />
            </Box>
          </Card>
        </Modal>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} md={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TableHeader />
                </Grid>
                <Grid item xs={12}>
                  <AdminPreOrdersTable preOrders={preOrders} setModalOpen={setModalOpen}/>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default AdminPreOrders;