import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Home page components
import Header from "./components/Header/index";
import Footer from "./components/Footer/index";
import PricingCards from "./components/PricingCards";
import TrustedBrands from "./components/TrustedBrands";
import Faq from "./components/Faq";
import HomePageLayout from "../layout/HomePageLayout";
import PreOrdersBlock from "./components/PreOrdersBlock";
import Description from "./components/Description";

function HomePage(): JSX.Element {

  return (
    <HomePageLayout background={'light'}>
      <Header>
        <Container>
          <PreOrdersBlock />
          <Description />
          {/*<PricingCards prices={['12', '14']} />*/}
          {/*<TrustedBrands />*/}
          <Faq />
        </Container>
      </Header>
      <Footer />
    </HomePageLayout>
  );
}

export default HomePage;
