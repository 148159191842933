import {PreOrderStatus} from "../types/pre-order.types";
import {RoutePayload, RoutesOrderBy, RoutesReducerTypes, RoutesSortBy, RouteStatus} from "../types/route.type";
import {emptyShortStage, ShortStageInterface} from "./stage.reducer";

export interface SmallRoutesInterface {
  id: number,
  title: string,
  status: RouteStatus,
  createdAt: string,
  updatedAt: string,
  preOrderId: number,
}

export interface ShortRoutesInterface {
  id: number,
  title: string,
  status: RouteStatus,
  createdAt: string,
  updatedAt: string,
  preOrder: {
    id: number,
    title: string,
    status: PreOrderStatus,
    getOffersBeforeDate: string,
    ownerUser: {
      id: number,
      nickname: string,
      email: string,
    },
    ownerCompany: {
      id: number,
      name: string,
    }
  },
  _count: {
    stages: number,
  }
}

export interface FullRouteInterface {
  id: number,
  title: string,
  status: RouteStatus,
  createdAt: string,
  updatedAt: string,
  preOrder: {
    id: number,
    title: string,
    status: PreOrderStatus,
    preOrderRelevantTill: string,
    getOffersBeforeDate: string,
    ownerUser: {
        id: number,
        nickname: string,
    },
    ownerCompany: {
        id: number,
        name: string,
    },
    files?: {id: number, fileName: string, checked?: boolean}[],
    images?: {id: number, fileName: string, checked?: boolean}[],
  },
  order: {
      id: number,
      title: string,
      status: string,
  }[],
  detail: {
    id: number,
    title: string,
    quantity: string,
    shape: {
      id: number,
      title: string,
      image: string,
    },
    material: {
      id: number,
      title: string,
      image: string,
    }
  },
  stages: ShortStageInterface[]
}

export const emptyFullRoute = {
    id: 0,
    title: '',
    status: RouteStatus.CREATED,
    createdAt: '',
    updatedAt: '',
    preOrder: {
      id: 0,
      title: '',
      status: PreOrderStatus.CREATED,
      preOrderRelevantTill: '',
      getOffersBeforeDate: '',
      ownerUser: {
        id: 0,
        nickname: '',
      },
      ownerCompany: {
        id: 0,
        name: '',
      },
      files: [{id: 0, fileName: ''}],
      images: [{id: 0, fileName: ''}],
    },
    order: [{
      id: 0,
      title: '',
      status: '',
    }],
    detail: {
      id: 0,
      title: '',
      quantity: '',
      shape: {
        id: 0,
        title: '',
        image: '',
      },
      material: {
        id: 0,
        title: '',
        image: '',
      }
    },
    stages: [emptyShortStage]
}

export interface initialStateInterface {
  routes: ShortRoutesInterface[],
  oneRoute: FullRouteInterface,
  count: 0,
  skip: 0,
  take: 20,
  sort_by: RoutesSortBy,
  order_by: RoutesOrderBy,
  query: string,
}

const initialState: initialStateInterface = {
  routes: [],
  oneRoute: emptyFullRoute,
  count: 0,
  skip: 0,
  take: 20,
  sort_by: RoutesSortBy.ID,
  order_by: RoutesOrderBy.DESCENDING,
  query: null,
}

const routesReducer = (state: initialStateInterface = initialState, action: RoutePayload) => {
  switch (action.type) {
    case RoutesReducerTypes.CLEAR_ROUTES:
      return {...state, routes: [] as ShortRoutesInterface[], oneRoute: emptyFullRoute};
    case RoutesReducerTypes.GET_ROUTES:
      return {...state, routes: action.payload[1], count: action.payload[0]._count};
    case RoutesReducerTypes.GET_ONE_ROUTE:
      return {...state, oneRoute: action.payload}
    case RoutesReducerTypes.CREATE_ROUTES:
      return {...state, oneRoute: action.payload}
    case RoutesReducerTypes.UPDATE_STAGES_IN_ROUTE:
      return {...state, oneRoute: {...state.oneRoute, stages: action.payload}}
    default:
      return state;
  }
}

export default routesReducer;