import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import Select from 'react-select'
import MDBox from "../../../../components/MDBox";

interface PropsInterface {
  isForCompany: boolean;
  isForNewCompany?: boolean;
  handleAddTypesOfWorksWhenNoCompanyYet?: (arg: {value: number, label: string}) => void;
}
function TypesOfWorks({isForCompany, isForNewCompany, handleAddTypesOfWorksWhenNoCompanyYet}: PropsInterface) {
  const {addUserTypeOfWork, addCompanyTypeOfWork} = useAppDispatch();
  const {user: {ownCompany}} = useAppSelector(state => state);
  const typesOfWorks = useAppSelector(state => state.typesWorks);

  const options = typesOfWorks.map((typeOfWork) => {
    return {value: typeOfWork.id, label: typeOfWork.title}
  });

  const handleUser = async (e: any) => {
    console.log(e);
    addUserTypeOfWork(e.value);
  }

  const handleCompany = async (e: any) => {
    console.log(e);
    if (isForNewCompany) return handleAddTypesOfWorksWhenNoCompanyYet(e);
    if (ownCompany["id"]) {
      addCompanyTypeOfWork({companyId: ownCompany["id"], typeOfWorkId: e.value});
    }
  }

  return (
    <div>
      <Select
        options={options}
        placeholder={'Добавьте тип работ из списка'}
        onChange={(e) => isForCompany ? handleCompany(e) : handleUser(e)}
      />
    </div>
  );
}

export default TypesOfWorks;