import {Dispatch} from "redux";
import {ShapePayload, ShapeReducerTypes} from "../types/shape.types";
import $api from "../../utilities/http.axios";

export const getAllShapes = () => {
  return async (dispatch: Dispatch<ShapePayload>) => {
    try {
      const res = await $api.get(`/shape/get-all`);
      return dispatch({type: ShapeReducerTypes.GET_SHAPES, payload: res.data});
    } catch (e) {
      console.log('Ошибка при получении списка форм: ', e)
    }
  }
}