import {Action, configureStore, Middleware} from '@reduxjs/toolkit'
import thunk from "redux-thunk"
import rootReducer from "./reducers";
import logger from "redux-logger";

export type RootState = ReturnType<typeof rootReducer>

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(
          // correctly typed middlewares can just be used
          // you can also type middlewares manually
          thunk,  // as Middleware<
          //   (action: Action<'specialAction'>) => number,
          //   RootState
          //   >
        )
        // prepend and concat calls can be chained
        .concat(logger),
})

export type AppDispatch = typeof store.dispatch
//export const useAppDispatch = () => useDispatch<AppDispatch>()