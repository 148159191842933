// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import DashboardLayout from "../../dashboard/DashboardLayout";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/useActions";
import NotificationsList from "./components/NotificationsList";
import {useParams} from "react-router-dom";

function Notifications(): JSX.Element {
  const {getAllNotifications} = useAppDispatch();

  useEffect(() => {
    getAllNotifications();
  }, []);

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} >

              <NotificationsList />

          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Notifications;
