import React, {useCallback, useEffect, useState} from "react";
import DropZoneSquare from "./dropzone-square";
import ImagePreview from "./image-preview";
import {FileSize} from "../../utilities/constants";
import {DocumentType, FileOrImage} from "../../utilities/constants";
import {useAppDispatch} from "../../hooks/useActions";
import {convertCyrillicToLatin} from "../../utilities/converters";

interface PropsInterface {
    isImage: boolean,
    files: any,
    setFiles: any,
    sizeLimit: FileSize,
    isEditMode: boolean,
    documentType?: DocumentType,
    documentId?: string
}

const DropZone = ({isImage, files, setFiles, sizeLimit, isEditMode, documentType, documentId}: PropsInterface): JSX.Element  => {

    const {deleteImageOrFileForPreOrder, uploadImageForPreOrder, uploadFileForPreOrder} = useAppDispatch();

    const [done, setDone] = useState(false);
    useEffect(() => {
        const limit = isImage ? 7 : 3;
        if (files.length >= limit ) {
            setDone(true);
        } else {
            setDone(false);
        }
    }, [files.length])

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.map((file: any) => {
            if (isEditMode && isImage) {
                let formData = new FormData();
                formData.append('image', file);
                const res = uploadImageForPreOrder(formData, documentId);
                console.log('UPLOADED IMAGE RES _ ', res.prototype.fulfilled)
            }
            if (isEditMode && !isImage) {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('fileName', convertCyrillicToLatin(file.path));
                const res = uploadFileForPreOrder(formData, documentId);
                console.log('UPLOADED FILE RES _ ', res.prototype.fulfilled)
            }

            const reader = new FileReader();
            reader.onload = function (e) {
                file.convertedName =  convertCyrillicToLatin(file.path);
                setFiles((prevState: any) => [
                    ...prevState,
                    { id: file.path, src: e.target.result, raw: file },
                ]);
            };
            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    const removeUploadedFile = async (id: string) => {
        if (isEditMode && documentType) {
            const pickedFile = files?.filter((f: any) => f.id === id)[0];
            let fileName;
            isImage && (fileName = pickedFile.src.split('/').slice(-1)[0]);
            !isImage && (fileName = pickedFile.fileName);
            const type = isImage ? FileOrImage.IMAGE : FileOrImage.FILE;
            const res = await deleteImageOrFileForPreOrder(type, documentType, fileName, Number(id), documentId);
            console.log('REMOVED RES _ ', res)
        }
        setFiles((prevState: any[]) => prevState.filter(p => p.id !== id));
    }

    return (
        <main className="App">
            <DropZoneSquare
                onDrop={onDrop}
                done={done}
                isImage={isImage}
                removeUploadedFile={removeUploadedFile}
                sizeLimit={sizeLimit}
            />
            <ImagePreview images={files} isImage={isImage} removeUploadedFile={removeUploadedFile}/>
        </main>
    );
}

export default DropZone;