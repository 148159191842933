import React, { useMemo, useEffect, useState } from "react";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import InfoIcon from '@mui/icons-material/Info';

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React TS examples components
import {ShortOfferInterface} from "../../../../store/types/offer.type";
import {useAppSelector} from "../../../../hooks/useActions";
import TableCell from "@mui/material/TableCell";
import {
  convertFromStringToBeautyDateString,
  convertFromStringToBeautyDateTimeString,
  getOfferStatus
} from "../../../../utilities/converters";
import Tooltip from "@mui/material/Tooltip";
import {Box, Modal} from "@mui/material";
import Card from "@mui/material/Card";
import {useAppDispatch} from "../../../../hooks/useActions";

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90%',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "visible"
};

const scrollableModalBlockStyle = {
  height: '100%',
  margin: '0em',
  overflowY: 'auto',
}

function createData({id, title, status, createdAt, updatedAt, stage}: ShortOfferInterface) {
  return { id, title, status: getOfferStatus(status), createdAt: convertFromStringToBeautyDateString(createdAt), updatedAt: convertFromStringToBeautyDateString(updatedAt), stageTitle: stage.title, stageId: stage.id };
}

interface Props {
}

function DataTable(): JSX.Element {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const {offers, oneOffer, skip, take, sort_by, order_by} = useAppSelector(state => state.offers);
  const {getMyOneOffer} = useAppDispatch();

  const rows = useMemo(() => offers?.map(offer => createData(offer)), [offers]);

  const handleOneOfferModal = (id: number) => {
    getMyOneOffer(id);
    setModalOpen(true);
  }
  const handleClose = () => {
    setModalOpen(false);
  }
  const previousPage = () => {
    console.log('previousPage')
  }

  const nextPage = () => {
    console.log('nextPage')
  }

  return (
    <TableContainer sx={{ boxShadow: "none", padding: 3 }} >

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={modalStyle}>
          <Box sx={scrollableModalBlockStyle}>
            {JSON.stringify(oneOffer)}
          </Box>
        </Card>
      </Modal>

      <Table sx={{ minWidth: 650 }} aria-label="Мои заявки">
        {/*<TableHead style={{width: 0}}>*/}
        <thead>
          <TableRow>
            <TableCell style={{fontWeight: 'bold'}}>ID</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="right">Название</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="right">Статус</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="right">Создано</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="right">Изменено</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="right">Название заявки</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="right">Номер заявки</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="right">
              Инфо
            </TableCell>
          </TableRow>
        </thead>
        {/*</TableHead>*/}
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <Tooltip title={row.title}>
                <TableCell align="right">
                    {row.title.slice(0, 30)}
                </TableCell>
              </Tooltip>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">{row.createdAt}</TableCell>
              <TableCell align="right">{row.updatedAt}</TableCell>
              <Tooltip title={row.stageTitle}>
                <TableCell align="right" sx={{ maxLines: 1}}>{row.stageTitle.slice(0, 20)}</TableCell>
              </Tooltip>
              <TableCell align="right">{row.stageId}</TableCell>
              <TableCell
                align="right"
                sx={{ cursor: "pointer" }}
                onClick={() => handleOneOfferModal(row.id)}
              >
                <InfoIcon />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        //p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >

        <MDPagination
          variant={"gradient"}
          color={"info"}
        >
          <MDPagination item onClick={() => previousPage()}>
            <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
          </MDPagination>
          <MDPagination item onClick={() => nextPage()}>
            <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
          </MDPagination>
        </MDPagination>

      </MDBox>

    </TableContainer>
  );
}

export default DataTable;
