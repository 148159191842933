import React, {useEffect, useMemo, useState} from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";
import {Tooltip} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useActions";
import {OrderStatus, ShortOrderForAdmin} from "../../../../../store/types/order.types";
import {convertFromStringToBeautyDateString, getOrderStatus} from "../../../../../utilities/converters";

function OrdersTableBody() {

  const {orders: {orders}} = useAppSelector(state => state);
  // const {getAllOrders} = useAppDispatch();
  //
  // useEffect(() => {
  //   getAllOrders({take, skip});
  // }, []);

  return (
    <>
      {orders?.map((order: ShortOrderForAdmin) =>
        <TableRow key={order?.id}>
          <TableCell style={{ width: 20 }} component="th" scope="row">
            {order?.id}
          </TableCell>
          <TableCell style={{ width: 240 }} align="center">
            <Typography fontSize={11}>
              {order?.title}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 120 }} align="center">
            <Typography fontSize={11}>
              {convertFromStringToBeautyDateString(order?.createdAt)}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 120 }} align="center">
            <Typography fontSize={11}>
              {getOrderStatus(order?.status as OrderStatus).title}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 120 }} align="center">
            <Typography fontSize={11}>
              {convertFromStringToBeautyDateString(order?.productionStart)}<br/>{convertFromStringToBeautyDateString(order?.productionEnd)}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Typography fontSize={11}>
              {order?.priceForCustomer}{" - "}{(order?.alreadyPaiedToCustomer/order?.priceForCustomer*100).toFixed(1)}%
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Typography fontSize={11}>
              {order?.priceToPayToProducer}{" - "}{(order?.alreadyPaiedToProducer/order?.priceToPayToProducer*100).toFixed(1)}%
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Typography fontSize={11}>
              {order?._count?.payments}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Typography fontSize={11}>
              {order?.stage?.id}
            </Typography>
          </TableCell>
          <TableCell style={{ width: 80 }} align="center">
            <Link to={`${order?.id}`}>
              <DehazeIcon color="primary" />
            </Link>
          </TableCell>
        </TableRow>
        )
      }
    </>
  );
}

export default OrdersTableBody;