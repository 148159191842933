import {AlertColorStatuses} from "./common.types";

export enum LoadingReducerTypes {
  SET_LOADING_TRUE = 'SET_LOADING_TRUE',
  SET_LOADING_FALSE = 'SET_LOADING_FALSE'
}

export type LoadingPayload = SetLoadingTruePayload | SetLoadingFalsePayload

interface SetLoadingTruePayload {
  type: LoadingReducerTypes.SET_LOADING_TRUE
}

interface SetLoadingFalsePayload {
  type: LoadingReducerTypes.SET_LOADING_FALSE
}


