import {Dispatch} from "redux";
import {AlertPayload, AlertReducerTypes} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {AlertColorStatuses} from "../types/common.types";
import {
  ChangeTypesOfWorksInterface,
  TypesOfWorksPayload,
  TypesOfWorksReducerTypes
} from "../types/types-of-works.types";

export const getTypesOfWorks = () => {
  return async (dispatch: Dispatch<TypesOfWorksPayload | AlertPayload>) => {
    try {
      dispatch({type: TypesOfWorksReducerTypes.CLEAR_TYPES_OF_WORKS});
      const res = await $api.get('/types-of-works/get-all-types-of-works', );
      const readyArrayOfTypes = res.data.map((type: { id: number; title: string; description: string; }) => {
        return {id: type.id, title: type.title, description: type.description};
      })
      dispatch({type: TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_SUCCESS, payload: readyArrayOfTypes});
      return res.data;
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      dispatch({type: TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const getTypesOfWorksForAdmin = () => {
  return async (dispatch: Dispatch<TypesOfWorksPayload | AlertPayload>) => {
    try {
      dispatch({type: TypesOfWorksReducerTypes.CLEAR_TYPES_OF_WORKS});
      const res = await $api.get('/types-of-works/get-all-types-of-works-for-admin', );
      // const readyArrayOfTypes = res.data.map((type: { id: number; title: string; description: string; }) => {
      //   return {id: type.id, title: type.title, description: type.description};
      // })
      dispatch({type: TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_SUCCESS, payload: res.data});
      return res.data;
    } catch (err: unknown) {
      console.log('getTypesOfWorksForAdmin ERR - ', err)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_ERROR, payload: res});
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}

export const changeTypesOfWorks = (dto: ChangeTypesOfWorksInterface) => {
  return async (dispatch: Dispatch<TypesOfWorksPayload | AlertPayload>) => {
    try {
      dispatch({type: TypesOfWorksReducerTypes.CLEAR_TYPES_OF_WORKS});
      const res = await $api.put('/company/change-type-of-works', dto);
      console.log(`RES DATA ---- ${JSON.stringify(res.data)}`)
      console.log(`RES DATA IS ARRAY ---- ${typeof res.data}`)
      const readyArrayOfTypes = res.data.typesOfWorks.map((type: { id: number; title: string; description: string; }) => {
        return {id: type.id, title: type.title, description: type.description};
      })
      return dispatch({type: TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_SUCCESS, payload: readyArrayOfTypes});
      //return res.data;
    } catch (err: unknown) {
      console.log(`ERROR ======== ${err}`)
      const res = axiosErrorMessageHandler(err);
      dispatch({type: TypesOfWorksReducerTypes.SET_TYPES_OF_WORKS_ERROR, payload: res});
      console.log(`ALERT -------- ${res}`)
      return dispatch({type: AlertReducerTypes.OPEN, payload: {status: AlertColorStatuses.ERROR, message: res} });
    }
  }
}