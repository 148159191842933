import {Dispatch} from "redux";
import {AlertDurations, AlertPayload} from "../types/alert.types";
import $api, {axiosErrorMessageHandler} from "../../utilities/http.axios";
import {
  AllOrdersQueryDto,
  CreateOrderInterface,
  OrderActionTypes,
  OrderPayload, OrdersOrderBy, OrdersSortBy,
  OrderStatus
} from "../types/order.types";
import {store} from '../index';
import {alertActions} from "./alert.actions";
import {AlertColorStatuses} from "../types/common.types";

export const createOrder = (newOrder: CreateOrderInterface) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      const res = await $api.post(`/order/create`, newOrder);
      return dispatch({type: OrderActionTypes.CREATE_ORDER, payload: res.data});
    } catch (err: any) {
      console.log('Ошибка при создании заказа: ', err.response)
      return store.dispatch(alertActions(AlertColorStatuses.ERROR, err.response?.data?.statusCode === 500 ? err.response?.data?.message.toString() : 'Неопределенная ошибка при создании', AlertDurations.FIVE_SECONDS));
    }
  }
}

export const getMyOrders = ({query, take, skip}: {query?: string, take?: number, skip?: number}) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      const res = await $api.get(`/order/get-my-orders`);
      return dispatch({type: OrderActionTypes.GET_MY_ORDERS, payload: res.data});
    } catch (err: unknown) {
      return axiosErrorMessageHandler(err);
    }
  }
}

export const getOneOrder = (id: string) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      dispatch({type: OrderActionTypes.CLEAR_ONE_ORDER});
      const res = await $api.get(`/order/get-one-order/${id}`);
      return dispatch({type: OrderActionTypes.GET_ONE_ORDER, payload: res.data});
    } catch (err: unknown) {
      const res = axiosErrorMessageHandler(err);
      return console.log('res = ', res)
    }
  }
}

export const updateOrderStatus = (orderId: string, status: OrderStatus) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      const res = await $api.put(`/order/update-status/${orderId}`, {status});
      return dispatch({type: OrderActionTypes.UPDATE_STATUS, payload: res.data});
    } catch (err: unknown) {
      return axiosErrorMessageHandler(err);
    }
  }
}

export const setOrderTake = (take: number) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      return dispatch({type: OrderActionTypes.SET_ORDER_TAKE, payload: take});
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
    }
  }
}

export const setOrderQuery = (query: string) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      return dispatch({type: OrderActionTypes.SET_ORDER_QUERY, payload: query});
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
    }
  }
}

export const setOrderSkip = (skip: number) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      return dispatch({type: OrderActionTypes.SET_ORDER_SKIP, payload: skip});
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
    }
  }
}

export const setOrderOrderBy = (order_by: OrdersOrderBy) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      return dispatch({type: OrderActionTypes.SET_ORDER_BY, payload: order_by});
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
    }
  }
}

export const setSortOrderBy = (sort_by: OrdersSortBy) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      return dispatch({type: OrderActionTypes.SET_SORT_BY, payload: sort_by});
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
    }
  }
}

export const clearOrderQuery = () => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      return dispatch({type: OrderActionTypes.CLEAR_QUERY});
    } catch (err: unknown) {
      console.log('errrr = ', JSON.stringify(err))
    }
  }
}

export const getAllOrders = (query: string) => {
  return async (dispatch: Dispatch<OrderPayload | AlertPayload>) => {
    try {
      const res = await $api.get(`/order/get-all-orders${query}`) //&order_by=${query.order_by}&sort_by=${query.sort_by}&search=${query.search}&status=${query.status}&id=${query.id}&producer=${query.producer}&customer=${query.customer}`);
      return dispatch({type: OrderActionTypes.GET_ALL_ORDERS, payload: res.data});
    } catch (err: unknown) {
      return axiosErrorMessageHandler(err);
    }
  }
}