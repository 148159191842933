import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import $api from "../../../../utilities/http.axios";
import {FullUserForAdminInterface, FullUserInterface, UserForAdminInterface} from "../../../../store/types/user.types";
import DashboardLayout from "../../../dashboard/DashboardLayout";
import MDBox from "../../../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Footer from "../../../../examples/Footer";
import {Grid, Modal} from "@mui/material";
import MDButton from "../../../../components/MDButton";
import AdminUserModal from "./Modal";

export enum AdminUsersModalActionTypesInterface {
  CHANGE_ROLES = 'CHANGE_ROLES',
  CHANGE_STATUS = 'CHANGE_STATUS'
}

function Index() {
  const {id} = useParams<{ id: string }>();
  const [user, setUser] = React.useState<FullUserForAdminInterface | null>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [modalActionType, setModalActionType] = React.useState<AdminUsersModalActionTypesInterface | null>(null);

  const getOneUser = async (id: number) => {
    try {
      const res = await $api.get(`/users/one/${id}`);
      setUser(res.data as FullUserForAdminInterface);
    } catch (err: unknown) {
      console.log('getOneUser ERROR - ', JSON.stringify(err))
    }
  }

  useEffect(() => {
    getOneUser(Number(id))
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }, []);

  const handleModal = (type: AdminUsersModalActionTypesInterface) => {
    setModalActionType(type);
    setOpenModal(true);
  }

  const handleClose = () => {
    setModalActionType(null);
    setOpenModal(false);
  }

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          {
            openModal && modalActionType && (
              <AdminUserModal
                openModal={openModal}
                modalActionType={modalActionType}
                handleClose={handleClose}
                id={Number(id)}
                roles={user?.roles}
                status={user?.status}
                setUser={setUser}
              />
            )
          }
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="button" color="text">
                Управление доступом пользователя <strong>{user?.nickname}{" - "}{user?.email}</strong>
              </MDTypography>
            </MDBox>

            <MDBox p={3} lineHeight={1}>
              <Grid container spacing={2} justifySelf={"center"} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
                <Grid item xs={12} sm={6} md={4}>
                  <MDButton variant={"outlined"} color={"warning"} onClick={() => handleModal(AdminUsersModalActionTypesInterface.CHANGE_STATUS)}>
                    Изменить статус
                  </MDButton>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MDButton variant={"outlined"}  color={"info"} onClick={() => handleModal(AdminUsersModalActionTypesInterface.CHANGE_ROLES)}>
                    Изменить роли
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>

            <MDBox p={3} lineHeight={1}>
            {

              user && Object.entries(user).map(([key, value]) => {
                return (
                  <div style={{marginBottom: 8}} key={key}>
                    <span><strong>{key}</strong></span>{" - "}<span>{JSON.stringify(value)}</span>
                  </div>
                )
              })
            }

            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Index;