import React, {useEffect, Suspense} from 'react';
import HomePageLayout from "../layout/HomePageLayout";
import Header from "../home/components/Header";
import Container from "@mui/material/Container";
import PreOrdersBlock from "../home/components/PreOrdersBlock";
import Faq from "../home/components/Faq";
import Footer from "../home/components/Footer";
import {useParams} from "react-router-dom";
import MDBox from "../../components/MDBox";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import {Card, Grid} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/useActions";
import OpenOrderData from "./OpenOrderData";

function Index(props: any) {
  const { id } = useParams();
  const {getOneOpenStage} = useAppDispatch();
  const {stages: {oneOpenStage}} = useAppSelector(state => state);

  useEffect(() => {
    getOneOpenStage(id);
  }, [])


  return (
    <HomePageLayout background={'default'} menuTransparent={false}>
        <Container style={{paddingTop: 90}}>

          <Grid item xs={12} >
            <Card style={{padding: 20, minHeight: '70vh'}}>
              <Suspense fallback={<div>Loading...</div>}>
                <OpenOrderData stage={oneOpenStage} />
              </Suspense>
            </Card>
          </Grid>

        </Container>
      <Footer />
    </HomePageLayout>
  );
}

export default Index;