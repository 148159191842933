import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import MDButton from "../MDButton";
import MDInput from "../MDInput";
import {useAppDispatch, useAppSelector} from "../../hooks/useActions";
import {alertActions} from "../../store/actions/alert.actions";
import {AlertColorStatuses} from "../../store/types/common.types";
import {AlertDurations} from "../../store/types/alert.types";
import {CommentSenderRoles} from "../../store/types/comment.types.";

const CommentInput = () => {
  const [comment, setComment] = useState<string>('');
  const {createCommentForPreOrder, alertActions} = useAppDispatch();
  const {auth: {user}, preOrders: {onePreOrder}} = useAppSelector(state => state);

  const handlePostComment = async (e: any) => {
    e.preventDefault();
    if (comment.length === 0) return ;
    if (comment.length > 300) return alertActions(AlertColorStatuses.ERROR, 'Комментарий не должен превышать 200 мсимволов', AlertDurations.THREE_SECONDS);
    const role = (user.roles.some((r: any) => r === 'ADMIN') || user.roles.some((r: any) => r === 'MANAGER')) ? CommentSenderRoles.MANAGER : CommentSenderRoles.CUSTOMER;
    //@ts-ignore
    await createCommentForPreOrder({ senderRole: role, text: comment, preOrderId: onePreOrder.id})
    setComment('');
  }

  return(
    <Grid container >
      <Grid item md={9} lg={10} xs={12} p={2}>
        <MDInput
          style={{width: '100%', backgroundColor: 'white'}}
          variant="outlined"
          label="Новый комментарий..."
          value={comment}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
        />
      </Grid>
      <Grid item md={3} lg={2} xs={12} p={2}>
        <MDButton variant="gradient" color="info" fullWidth onClick={(e) => handlePostComment(e)}>
          Отправить
        </MDButton>
      </Grid>
    </Grid>
  )
}

export default CommentInput;