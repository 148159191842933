import React, {useEffect, useState} from 'react';
import {ShortStageInterface} from "../../../../store/reducers/stage.reducer";
import ArrowBox, {StageBoxColors} from "../ArrowBox";
import {Button, Grid, IconButton} from "@mui/material";
import {AddCircleOutline} from "@mui/icons-material";
import MDButton from "../../../../components/MDButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface PropsInterface {
  stages?: ShortStageInterface[],
  handleOpenDialog: any,
}

const StagesLineBlock = ({stages, handleOpenDialog}: PropsInterface) => {
  const [sortedStages, setSortedStages] = useState([]);

  useEffect(() => {
    if (stages.length > 0) {
      const sorted = [...stages].sort(function(a, b) {
        if (a.number < b.number) return -1;
        if (a.number > b.number) return 1;
        return 0;
      });
      setSortedStages(sorted);
    }
  }, [stages])

  return(
    <Grid container spacing={2}>
      {
        sortedStages?.length > 0 && sortedStages?.map((stage, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
            <Grid container>
              <Grid item xs={12} sm={2} style={{paddingTop: '35%'}}>
                <IconButton aria-label="delete" color="info" size="large" onClick={() => handleOpenDialog(stage.number)}>
                  <AddCircleOutline />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={10}>
                <ArrowBox stage={stage} isActive={false} boxColor={StageBoxColors.GREY} />
              </Grid>
            </Grid>
          </Grid>
          ))
      }
      <Grid item xs={12} sm={12} md={6} style={{paddingTop: '18%'}} >
        <MDButton variant="gradient" color="info" onClick={() => handleOpenDialog(sortedStages.length + 1)} style={{marginLeft: 55}}>
          <AddCircleOutlineIcon style={{marginRight: 8}}/> новый этап
        </MDButton>
      </Grid>
  </Grid>
  )
}

export default StagesLineBlock;