import {bool} from "yup";

export enum authReducerTypes {
  SET_AUTH_SUCCESS = 'SET_AUTH_SUCCESS',
  CLEAR_AUTH = 'CLEAR_AUTH',
  SET_AUTH_ERROR = 'SET_AUTH_ERROR',
  WAS_FORCIBLY_REFRESHED_TOKEN = 'WAS_FORCIBLY_REFRESHED_TOKEN',
  WAS_NOT_FORCIBLY_REFRESHED_TOKEN = 'WAS_NOT_FORCIBLY_REFRESHED_TOKEN',
}

export type AuthPayload = SuccessAuthPayload
  | ErrorAuthPayload
  | AuthWithoutPayload
  | setWasForciblyRefreshedPayload
  | setWasNotForciblyRefreshedPayload

interface SuccessAuthPayload {
  type: authReducerTypes.SET_AUTH_SUCCESS,
  payload: {
    accessToken: string,
    user: any,
  }
}

interface ErrorAuthPayload {
  type: authReducerTypes.SET_AUTH_ERROR,
  payload: string
}

interface AuthWithoutPayload {
  type: authReducerTypes.CLEAR_AUTH,
}

interface setWasForciblyRefreshedPayload {
  type: authReducerTypes.WAS_FORCIBLY_REFRESHED_TOKEN,
}

interface setWasNotForciblyRefreshedPayload {
  type: authReducerTypes.WAS_NOT_FORCIBLY_REFRESHED_TOKEN,
}