import { useEffect, ReactNode } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";
import Navbar from "./Navbar/DefaultNavbar";
import pageRoutes from "../../page.routes";
import MySnackbar from "../../components/Snackbar";
import {useAppDispatch, useAppSelector} from "../../hooks/useActions";

// Declaring props types for PageLayout
interface Props {
  background?: "white" | "light" | "default";
  menuTransparent?: boolean;
  children: ReactNode;
}

function HomePageLayout({ background, children, menuTransparent = true }: Props): JSX.Element {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  const {alert: { isOpen, status, message}} = useAppSelector(state => state)
  const { setAlertClose } = useAppDispatch()

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      {isOpen ?
        <MySnackbar
          // @ts-ignore
          color={status}
          icon="warning"
          // @ts-ignore
          title={status}
          // @ts-ignore
          content={message ?? ''}
          // @ts-ignore
          open={isOpen}
          onClose={() => setAlertClose()}
          close={() => setAlertClose()}
        /> : null
      }
      <Navbar
        routes={pageRoutes}
        // action={{
        //   type: "external",
        //   route: "/",
        //   label: "buy 6 now",
        //   color: "light",
        // }}
        transparent={menuTransparent}
        light={background === "white" || background === "light"}
      />

      {children}
    </MDBox>
  );
}

// Declaring default props for PageLayout
HomePageLayout.defaultProps = {
  background: "default",
};

export default HomePageLayout;
