export interface ShapeInterface {
  id: number
  title: string
  image: string
}

export enum ShapeReducerTypes {
  GET_SHAPES = 'GET_SHAPES',
  CLEAR_SHAPES = 'CLEAR_SHAPES',
}

export type ShapePayload = GetShapes | ClearShapes

export interface GetShapes {
  type: ShapeReducerTypes.GET_SHAPES,
  payload: ShapeInterface[]
}

export interface ClearShapes {
  type: ShapeReducerTypes.CLEAR_SHAPES
}