import axios from 'axios'
import {AxiosErrorServerResponse} from "../store/types/common.types";
import {authReducerTypes} from "../store/types/auth.types";
import {store} from '../store';
import {logout, checkAuth, clearAuth, setWasForciblyRefreshed, setWasNotForciblyRefreshed} from "../store/actions/auth.actions";

export const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5045/api' : 'https://marketdetal.ru/api'
export const FRONT_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5045' : 'https://marketdetal.ru'

const $api = axios.create()
$api.defaults.baseURL = API_URL;
$api.defaults.withCredentials = true;

$api.interceptors.request.use((config) => {
  const localStorageToken = localStorage.getItem('token');
  if (localStorageToken) {
    config.headers.Authorization = `Bearer ${localStorageToken.slice(1, -1)}`
  } else {
    const state = store?.getState();
    const reduxToken = state?.auth?.accessToken;
    if (reduxToken) {
      config.headers.Authorization = `Bearer ${reduxToken}`;
    } else {
      store.dispatch(clearAuth());
    }
  }
  return config
}, (err) => {
  console.log('API ----- !!!! ------ REQ INTERCEPTOR - err - ', JSON.stringify(err))
  return Promise.reject(err);
})

$api.interceptors.response.use((config) => {
  const state = store?.getState();

  if ((config?.status === 403 || config?.status === 401) && !state?.auth?.wasForceTokenRefresh) {
    console.log('API INTERCEPTOR - STATUS 403 - wasForceTokenRefresh')
    store.dispatch(setWasForciblyRefreshed());
    store.dispatch(checkAuth());
  }
  if ((config?.status === 403 || config?.status === 401) && state?.auth?.wasForceTokenRefresh) {
    console.log('API INTERCEPTOR - STATUS 403 - wasNOTForceTokenRefresh')
    store.dispatch(setWasNotForciblyRefreshed());
    store.dispatch(logout());
  }
  if ((config.status === 200 || config.status === 201) && !state?.auth?.isAuth && !state?.auth?.wasForceTokenRefresh) {
    store.dispatch(setWasNotForciblyRefreshed());
    store.dispatch(checkAuth());
  }
  if ((config.status === 200 || config.status === 201) && state?.auth?.isAuth && state?.auth?.wasForceTokenRefresh) {
    store.dispatch(setWasNotForciblyRefreshed());
  }

  return config;
}, (err) => {
  console.log('API ----- !!!! ------ response INTERCEPTOR - err - ', JSON.stringify(err))
  }
)

export const axiosErrorMessageHandler = (err: unknown): string => {
  const error = err as AxiosErrorServerResponse

  console.log('AXIOS - axiosErrorMessageHandler - ', JSON.stringify(err))

  if (error?.response?.status === 500) {
    if (error?.response?.data?.message?.includes('Невалидный токен') ) {
      store.dispatch(clearAuth());
      return 'Невалидный токен';
    }
    return 'Неопределенная ошибка. Попробуйте перезагрузить страницу';
  }

  if (error?.response?.status === 413) {
    return 'Вы пытаетесь загрузить слишком большой файл';
  }

  if (error?.response?.data?.statusCode === 401 && error.response?.data?.message) {
    store.dispatch(clearAuth());
    return JSON.stringify(error?.response.data.message);
  }

  // if (error?.response?.data?.statusCode === 403 && error.response?.data?.message) {
  //   const state = store?.getState();
  //   if (!state.auth.wasForceTokenRefresh) {
  //     store.dispatch(setWasForciblyRefreshed());
  //     store.dispatch(checkAuth());
  //     console.log('API INTERCEPTOR - STATUS 200 - wasForceTokenRefresh')
  //   }
  //   if (state.auth.wasForceTokenRefresh) {
  //     store.dispatch(setWasNotForciblyRefreshed());
  //     store.dispatch(clearAuth());
  //     console.log('API INTERCEPTOR - STATUS 200 - wasNOTForceTokenRefresh')
  //   }
  //   return JSON.stringify(error.response.data.message);
  // }

  if (error.response?.data?.message) {
    const message = error.response?.data?.message
    let errorString = '';
    if (typeof message == "string") {
      return message;
    } else if (message instanceof Array) {
      for (let i=0; i<message.length; i++) {
        errorString = errorString + `ошибка ${i + 1}: ${message[i]}\n`
      }
    }
    return errorString;
  } else {
    return 'Неопределенная ошибка. Попробуйте перезагрузить страницу';
  }
}

export default $api