import React, {useEffect, useState} from 'react';
import MDBox from "../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../../dashboard/DashboardLayout";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {Box, Card, Modal} from "@mui/material";
import {useParams} from "react-router-dom";
import Divider, {HeightSize, MarginSize} from "../../../../components/Divider";
import {
  convertFromStringToBeautyDateString,
  convertFromStringToBeautyDateTimeString
} from "../../../../utilities/converters";
import OrderStatus from "./components/OrderStatuses";
import MDButton from "../../../../components/MDButton";
import CreatePaymentModal from "./components/CreatePaymentModal";
import PaymentsList from "./components/PaymentsList";

const scrollableModalBlockStyle = {
  height: '100%',
  margin: '0em',
  overflowY: 'auto',
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90%',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "visible"
};

interface PropsInterface {

}

const AdminOneOrder = ({}: PropsInterface): JSX.Element => {

  const {id} = useParams<{ id: string }>();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const {orders: {oneOrder}} = useAppSelector(state => state);
  const {getOneOrder} = useAppDispatch();

  useEffect(() => {
    getOneOrder(id);
  }, [id])

  const handleClose = () => {
    setModalOpen(false);
  }

  const handleCreateInvoice = () => {
    setModalOpen(true);
  }

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card sx={modalStyle}>
            <Box sx={scrollableModalBlockStyle}>
              <CreatePaymentModal />
            </Box>
          </Card>
        </Modal>
        <div>
          <Grid container spacing={3} style={{padding: 12}}>
            <Grid item xs={12} lg={12} md={12}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    Управление заказом
                  </Grid>
                  <Grid item xs={12}>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Card>
                          <Grid container spacing={3} p={3}>
                            <Grid item xs={12} md={6}>
                              <p>ID: {oneOrder?.id}{" - "}{oneOrder?.title}</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <OrderStatus/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <p><small>Создан:</small> {convertFromStringToBeautyDateTimeString(oneOrder?.createdAt)}
                              </p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <p><small>Последние
                                изменения:</small> {convertFromStringToBeautyDateTimeString(oneOrder?.updatedAt)}</p>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                      <Grid item xs={12}>
                        <Card>
                          <Grid container spacing={3} p={3}>
                            <Grid item xs={12} md={6}>
                              <p><small>Запуск в
                                производство:</small> {convertFromStringToBeautyDateString(oneOrder?.productionStart)}
                              </p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <p><small>Окончание
                                проиозводства:</small> {convertFromStringToBeautyDateString(oneOrder?.productionEnd)}
                              </p>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={3}>

                          <Grid item xs={12} md={4}>
                            <Card>
                              <Grid item xs={12} p={3}>
                                <p><small>Цена заказчика:</small> {oneOrder?.priceForCustomer}{" ₽"}</p>
                              </Grid>
                              <Grid item xs={12} p={3}>
                                <p>
                                  <small>Оплачено:</small> {(oneOrder?.alreadyPaiedToCustomer / oneOrder?.priceForCustomer * 100).toFixed(1)}%
                                </p>
                              </Grid>
                            </Card>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <Card>
                              <Grid item xs={12} p={3}>
                                <p><small>Цена подрядчика:</small> {oneOrder?.priceToPayToProducer}{" ₽"}</p>
                              </Grid>
                              <Grid item xs={12} p={3}>
                                <p>
                                  <small>Оплачено:</small> {(oneOrder?.alreadyPaiedToProducer / oneOrder?.priceToPayToProducer * 100).toFixed(1)}%
                                </p>
                              </Grid>
                            </Card>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <Card>
                              <Grid item xs={12} p={3}>
                                <p style={{color: oneOrder?.priceForCustomer - oneOrder?.priceToPayToProducer <=0 ? 'red' : "inherit"}}><small>Прибыль:</small> {oneOrder?.priceForCustomer - oneOrder?.priceToPayToProducer}{" ₽"}</p>
                              </Grid>
                            </Card>
                          </Grid>

                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Card>
                              <Grid item xs={12} p={3}>
                                <p>
                                  <small>Заказчик</small>: {oneOrder?.customerUser?.nickname} | {oneOrder?.customerUser?.email}
                                </p>
                              </Grid>
                              <Grid item xs={12} p={3}>
                                {oneOrder?.customerCompany &&
                                    <p>Компания
                                        заказчика: {oneOrder?.customerCompany?.name} | {oneOrder?.customerCompany?.logo}</p>
                                }
                                {
                                  oneOrder?.customerCompany?.managers && oneOrder?.customerCompany?.managers?.map((manager: {
                                    id: number,
                                    nickname: string,
                                    email: string
                                  }, index: number) => (
                                    <p key={index}>Менеджеры компании
                                      заказчика: {manager?.nickname} | {manager?.email}</p>
                                  ))
                                }
                              </Grid>
                            </Card>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Card>
                              <Grid item xs={12} p={3}>
                                <p>
                                  <small>Производитель</small>: {oneOrder?.producerUser?.nickname} | {oneOrder?.producerUser?.email}
                                </p>
                              </Grid>
                              <Grid item xs={12} p={3}>
                                {
                                  oneOrder?.producerCompany &&

                                    <p>Компания
                                        производителя: {oneOrder?.producerCompany?.name} | {oneOrder?.producerCompany?.logo}</p>
                                }
                                {
                                  oneOrder?.producerCompany?.managers && oneOrder?.producerCompany?.managers?.map((manager: {
                                    id: number,
                                    nickname: string,
                                    email: string
                                  }, index: number) => (
                                    <p key={index}>Менеджеры компании
                                      производителя: {manager?.nickname} | {manager?.email}</p>
                                  ))
                                }
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Card>
                              <Grid item xs={12} p={3}>
                                <p><small>Создан как Этап: </small>{oneOrder?.stage?.id}{" - "}{oneOrder?.stage?.title}
                                </p>
                                <p><small>По
                                  маршруту: </small>{oneOrder?.stage?.route?.id}{" - "}{oneOrder?.stage?.route?.title}{" - "}{convertFromStringToBeautyDateString(oneOrder?.stage?.route?.createdAt)}
                                </p>
                                <p><small>К
                                  заявке: </small>{oneOrder?.stage?.route?.preOrder?.id}{" - "}{oneOrder?.stage?.route?.preOrder?.title}{" - "}{convertFromStringToBeautyDateString(oneOrder?.stage?.route?.preOrder?.createdAt)}
                                </p>
                              </Grid>
                            </Card>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Card>
                              <Grid item xs={12} p={3}>
                                <p><small>Тип работ: </small>{oneOrder?.stage?.typeOfWork?.title}<br/><small>c
                                  комиссией: </small>{oneOrder?.stage?.typeOfWork?.commission}%</p>

                                <p>
                                  <small>Деталь: </small>{oneOrder?.stage?.detail?.title}{" - "}{oneOrder?.stage?.detail?.quantity} шт.
                                </p>
                                <p><small>Материал: </small>{oneOrder?.stage?.detail?.material?.title}</p>
                                <p><small>Форма: </small>{oneOrder?.stage?.detail?.shape?.title}</p>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Card>
                          <Grid item xs={12} p={3}>
                            <Grid container>
                              <Grid item xs={12} md={6}>
                                <p>Платежи:</p>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <MDButton variant="gradient" color="info" onClick={() => handleCreateInvoice()}>
                                  Добавить платеж
                                </MDButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} p={3}>
                            <PaymentsList />
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </div>
      </MDBox>
    </DashboardLayout>
  )
}

export default AdminOneOrder;