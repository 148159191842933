import React from 'react';
import {AdminUsersModalActionTypesInterface} from "../index";
import {Box, Grid, Modal} from "@mui/material";
import ChangeRole from "./changeRole";
import ChangeStatus from "./changeStatus";
import {
  FullUserForAdminInterface,
  updateUserByAdminBodyInterface,
  UserRolesFull
} from "../../../../../store/types/user.types";
import $api from "../../../../../utilities/http.axios";
import {useAppDispatch} from "../../../../../hooks/useActions";
import {AlertColorStatuses} from "../../../../../store/types/common.types";
import {AlertDurations} from "../../../../../store/types/alert.types";
import {UserRoles, UserStatuses} from "../../../../../utilities/constants";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};

interface PropsInterface {
  id: number;
  openModal: boolean;
  handleClose: () => void;
  modalActionType: AdminUsersModalActionTypesInterface;
  roles: UserRolesFull[];
  status: UserStatuses;
  setUser: (user: FullUserForAdminInterface) => void;
}

function AdminUserModal({id, openModal, handleClose, modalActionType, roles, status, setUser}: PropsInterface) {

  const {alertActions} = useAppDispatch();
  const updateByAdmin = async (id: number, body: updateUserByAdminBodyInterface) => {
    try {
      const res = await $api.put(
          `users/update-by-admin/${id}`,
          body,
          {headers: {'Content-Type': 'application/json'}
          });
      setUser(res.data as FullUserForAdminInterface);
      handleClose();
      alertActions(  AlertColorStatuses.SUCCESS, 'Успешно изменено', AlertDurations.FOUR_SECONDS);
    } catch (err: unknown) {
      console.log('updateByAdmin ERROR - ', JSON.stringify(err))
      handleClose();
      alertActions(  AlertColorStatuses.ERROR, `Ошибка: ${err}`, AlertDurations.FOUR_SECONDS);
    }
  }

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container >
          {
            modalActionType === AdminUsersModalActionTypesInterface.CHANGE_ROLES && (
              <ChangeRole updateByAdmin={updateByAdmin} id={id} roles={roles}/>
            )
          }
          {
            modalActionType === AdminUsersModalActionTypesInterface.CHANGE_STATUS && (
              <ChangeStatus updateByAdmin={updateByAdmin} id={id} status={status}/>
            )
          }
        </Grid>
      </Box>
    </Modal>
  );
}

export default AdminUserModal;