import React, {ReactNode, Suspense, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import DashboardLayout from "../../../dashboard/DashboardLayout";
import MDBox from "../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import StagesSearchHeader from "./SearchHeader/StagesSearchHeader";
import StagesTable from "./Table/StagesTable";

// const scrollableModalBlockStyle = {
//   height: '100%',
//   margin: '0em',
//   overflowY: 'auto',
// }
//
// const modalStyle = {
//   position: 'absolute' as 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   height: '90%',
//   width: '80%',
//   bgcolor: 'background.paper',
//   border: '1px solid #000',
//   boxShadow: 24,
//   p: 4,
//   overflow: "visible"
// };

interface Props {
  children?: ReactNode;
}

const AdminStagesPage = ({ children }: Props): JSX.Element=> {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  function handleClose() {
    setModalOpen(false);
  }

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        {/*<Modal*/}
        {/*  open={modalOpen}*/}
        {/*  onClose={handleClose}*/}
        {/*  aria-labelledby="modal-modal-title"*/}
        {/*  aria-describedby="modal-modal-description"*/}
        {/*>*/}
        {/*  <Card sx={modalStyle}>*/}
        {/*    <Box sx={scrollableModalBlockStyle}>*/}
        {/*      <Suspense fallback={<div>Loading...</div>}>*/}
        {/*        <OneStageModal handleClose={handleClose} />*/}
        {/*      </Suspense>*/}
        {/*    </Box>*/}
        {/*  </Card>*/}
        {/*</Modal>*/}
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} md={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <StagesSearchHeader />
                </Grid>
                <Grid item xs={12}>
                  <StagesTable setModalOpen={setModalOpen} />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default AdminStagesPage;