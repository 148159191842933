import React, {ReactNode, useState} from "react";
import {Box, Modal, Typography} from "@mui/material";

interface Props {
  title: string,
  children: any,
  isOpen: boolean,
  setModalIsOpen: any,
}

const ModalWindow = ({title, isOpen, setModalIsOpen, children}: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
    open={isOpen}
    onClose={() => setModalIsOpen(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={{
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      borderRadius: 3,
      boxShadow: 24,
      p: 4,
    }}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {title}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {children}
      </Typography>
    </Box>
  </Modal>
  )
}

export default ModalWindow;