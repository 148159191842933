
export enum CommentSenderRoles {
  CUSTOMER = 'CUSTOMER',
  MANAGER = 'MANAGER',
  PRODUCER = 'PRODUCER',
}

export interface CommentInterface {
  id: number,
  createdAt: string,
  sender: {id: number, nickname: string},
  senderRole: CommentSenderRoles,
  text: string,
  preOrder: {id: number},
  received: boolean,
  viewed: boolean,
}

export interface CreatingCommentInterface {
  senderRole: CommentSenderRoles,
  text: string,
  preOrderId: number,
}