import {AlertPayload, AlertReducerTypes, AlertStatuses} from "../types/alert.types";

interface AlertState {
  status: AlertStatuses | null,
  message: string | null,
  isOpen: boolean,
}

const initialState: AlertState = {
  status: null,
  message: null,
  isOpen: false,
}

const alertReducer = (state: AlertState = initialState, action: AlertPayload) => {
  switch (action.type) {
    case AlertReducerTypes.OPEN:
      return {isOpen: true, status: action.payload.status, message: action.payload.message}
    case AlertReducerTypes.CLOSE:
      return {isOpen: false, status: null, message: null}
    default:
      return state
  }
}

export default alertReducer;