import React from 'react';
import {CircularProgress} from "@mui/material";

interface PropsInterface {
  color?: 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
  size?: number,
}
function FullWindowLoading({color = 'primary', size = 40}: PropsInterface) {
  return (
    <div style={
      {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }>
      <CircularProgress color={'info'} size={size} />
    </div>
  );
}

export default FullWindowLoading;