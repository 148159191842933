// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import Grid from "@mui/material/Grid";
import {
  convertFromStringToBeautyDateString,
  getPreOrderStatus,
  getPreOrderStatusColor
} from "../../../../utilities/converters";
import Divider, {MarginSize} from "../../../../components/Divider";
import {FullPreOrderStateInterface} from "../../../../store/reducers/pre-order.reducer";
import {PreOrderStatus} from "../../../../store/types/pre-order.types";
import MDButton from "../../../../components/MDButton";
import DownloadableFiles from "../../../../components/DownloadableFiles";
import ImagesForAdmin from "../../../../components/ImagesAdminBlock/Images";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {CommentSenderRoles} from "../../../../store/types/comment.types.";
import DialogWindow from "../../../../components/DialogWindow";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import PopUpDialogWindow from "../../../../components/PopUpDialogWindow";
import {alertActions} from "../../../../store/actions/alert.actions";
import {AlertColorStatuses} from "../../../../store/types/common.types";
import OneDetailCreateForm from "./OneDetailCreateForm";

enum buttonChoice {
  DECLINED = 'DECLINED',
  RECLARIFICATION = 'RECLARIFICATION',
  EMPTY = ''
}

interface PropsInterface {
  po: FullPreOrderStateInterface,
}

const helpTextStyle = {fontSize: 13, fontWeight: "lighter"}

function InfoBlock({po, }: PropsInterface): JSX.Element {
  let navigate = useNavigate();

  const [openPopDialog, setOpenPopDialog] = useState<boolean>(false);
  const [popDialogTitle, setPopDialogTitle] = useState<string>('');
  const [popDialogText, setPopDialogText] = useState<string>('');

  const [isDetailComplete, setIsDetailComplete] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogWindowText, setDialogWindowText] = useState<string>('');
  const [dialogWindowTitle, setDialogWindowTitle] = useState<string>('');
  const [dialogWindowDescription, setDialogWindowDescription] = useState<string>('');
  const [buttonChoiceState, setButtonChoiceState] = useState<buttonChoice>(buttonChoice.EMPTY);

  const {setNewStatus, createCommentForPreOrder, createRouteAction, alertActions} = useAppDispatch();
  const {details: {detailToCreateWithRoute}} = useAppSelector(state => state);

  const handleOpenDialog = (status: PreOrderStatus) => {
    if (status === PreOrderStatus.DECLINED) {
      setDialogWindowTitle('Укажите причину отклонения заказа');
      setButtonChoiceState(buttonChoice.DECLINED);
    }
    if (status === PreOrderStatus.RECLARIFICATION) {
      setDialogWindowTitle('Укажите какую дополнительную информацию, которую необходимо получить от заказчика');
      setButtonChoiceState(buttonChoice.RECLARIFICATION);
    }
    setOpenDialog(true);
  }

  const handleChangeStatus = async () => {
    setOpenDialog(false);
    if (dialogWindowText.length > 3 && buttonChoiceState !== buttonChoice.EMPTY) {
      await setNewStatus({
        newStatus: buttonChoiceState === buttonChoice.RECLARIFICATION ? PreOrderStatus.RECLARIFICATION : PreOrderStatus.DECLINED,
        id: po.id
      });
      createCommentForPreOrder({
        senderRole: CommentSenderRoles.MANAGER,
        text: `${buttonChoiceState === buttonChoice.RECLARIFICATION ? 'ТРЕБУЕТСЯ ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ' : 'ПРЕДЗАКАЗ ОТМЕНЕН'}: ${dialogWindowText}`,
        preOrderId: po.id})
    }
  }

  const handleOpenPopDialog = () => {
    setOpenPopDialog(true);
    setPopDialogTitle('Укажите название создаваемого Маршрута и необходимые параметры детали');
  }

  const handleAccept = async () => {
    if (popDialogText.length < 2 || !isDetailComplete || !detailToCreateWithRoute) {
      return alertActions(AlertColorStatuses.WARNING, 'У маршрута должны быть указаны Название и все параметры Детали');
    }
    const id = await createRouteAction(popDialogText, po.id, detailToCreateWithRoute);
    (po.status !== PreOrderStatus.ACCEPTED) && await setNewStatus({newStatus: PreOrderStatus.ACCEPTED, id: po.id});
    setTimeout(() => navigate(`/admin/routes`), 1000);
  }

  return (
    <MDBox>
      <DialogWindow
        open={openDialog}
        setOpen={setOpenDialog}
        setDialogWindowText={setDialogWindowText}
        dialogWindowTitle={dialogWindowTitle}
        dialogWindowDescription={dialogWindowDescription}
        handleAction={handleChangeStatus}
      />
      <PopUpDialogWindow
        open={openPopDialog}
        setOpen={setOpenPopDialog}
        title={popDialogTitle}
        answer={popDialogText}
        setAnswer={setPopDialogText}
        onAction={handleAccept}
        children={<OneDetailCreateForm setIsDetailComplete={setIsDetailComplete} />}
        description={'Создаваемая деталь будет привязана к данному маршруту.'}
      />
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          Предзаказ №{po?.id}
        </MDTypography>
      </MDBox>

      <MDBox mt={1} mb={1}>
        <MDBadge variant="contained" color={getPreOrderStatusColor(po?.status)} badgeContent={getPreOrderStatus(po?.status)} container/>
      </MDBox>

      {
        po?.project?.id &&
          <MDBox mt={1} mb={1}>
              <MDBadge variant="contained" color='dark' badgeContent={po?.project?.title} container/>
          </MDBox>
      }
      <MDBox mt={1} mb={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {po?.title}
        </MDTypography>
      </MDBox>

      <MDBox mt={1} mb={1}>
        <MDTypography variant="h6" fontWeight="medium">
          <span style={helpTextStyle}>Создан:</span> {convertFromStringToBeautyDateString(po?.createdAt)}
          {' - '}
          <span style={helpTextStyle}>Последние изменения:</span> {convertFromStringToBeautyDateString(po?.updatedAt)}
        </MDTypography>
      </MDBox>

      <Divider marginSize={MarginSize.big} />

      <MDBox mt={1} mb={1}>
        <MDTypography variant="h6" fontWeight="medium">
          <span style={helpTextStyle}>Минимальный бюджет:</span> {po?.minBudget}р.
          {' - '}
          <span style={helpTextStyle}>Максимальный бюджет:</span> {po?.maxBudget}р.
        </MDTypography>
      </MDBox>
      <MDBox mt={1} mb={1}>
        <MDBadge variant="contained" color='info' badgeContent={po?.budgetWithTax ? 'Цена с учетом налогов' : 'Цена без НДС'} container/>
      </MDBox>

      <Divider marginSize={MarginSize.big} />

      <MDBox mt={1} mb={1}>
        <MDTypography variant="h6" fontWeight="medium">
          <span style={helpTextStyle}>Желательная дата начала производства:</span> {convertFromStringToBeautyDateString(po?.productionStart)}
          {' - '}
          <span style={helpTextStyle}>Желательная дата окончания производства:</span> {convertFromStringToBeautyDateString(po?.productionEnd)}
        </MDTypography>
      </MDBox>

      <MDBox mt={1} mb={1}>
        <MDTypography variant="h6" fontWeight="medium">
          <span style={helpTextStyle}>Актуален до:</span> {convertFromStringToBeautyDateString(po?.preOrderRelevantTill) ?? 'Не указано'}
          {' - '}
          <span style={helpTextStyle}>Собирать предложения подрядчиков до:</span> {convertFromStringToBeautyDateString(po?.getOffersBeforeDate) ?? 'Не указано'}
        </MDTypography>
      </MDBox>

      <Divider marginSize={MarginSize.big} />

      <MDBox mt={1} mb={1}>
        <MDTypography variant="h6" fontWeight="medium">
          {
            (po?.useDeliveryCompany && po?.deliveryCompany?.title) ?
              <>
                <span style={helpTextStyle}>Использовать транспортную компанию - </span> {po?.deliveryCompany?.title}
              </>
              :
              <>
                Не использовать транспортную компанию
              </>
          }
        </MDTypography>
      </MDBox>

      <Divider marginSize={MarginSize.big} />

      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Описание
        </MDTypography>
      </MDBox>

      <MDBox component="h5" m={0} pl={4} mb={2} color="text" fontSize="1.25rem" lineHeight={1}>
        <div dangerouslySetInnerHTML={{ __html: po?.description}} />

        {/*<MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle" dangerouslySetInnerHTML={{ __html: po?.description}}>*/}
        {/*    {po?.description}*/}
        {/*</MDTypography>*/}
      </MDBox>

      <Divider marginSize={MarginSize.big} />

      <Grid container>
        <Grid item lg={3} md={4} xs={12}>
          <MDBox mt={1} mb={1} >
            <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle" mb={2}>
              {po?.files?.length > 0 ? 'Прикрепленные файлы:' : 'Нет прикрепленных файлов'}
            </MDTypography>
            <DownloadableFiles files={po?.files} gridSize={6} />
          </MDBox>
        </Grid>
        <Grid item lg={9} md={8} xs={12}>
          <ImagesForAdmin />
        </Grid>
      </Grid>
      {
        <MDBox mt={5} mb={5}>
          <Grid container justifyContent={'space-between'}>
            {
              (po.status === 'CREATED' || po.status === 'RECLARIFICATION' ) &&
                <>
                    <Grid item xs={6} md={3} lg={3} m={2}>
                        <MDButton variant="gradient" color="success" fullWidth onClick={() => handleOpenPopDialog()}>
                            Принять в работу
                        </MDButton>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3} m={2}>
                        <MDButton variant="gradient" color="error" fullWidth onClick={() => handleOpenDialog(PreOrderStatus.DECLINED)}>
                            Отклонить
                        </MDButton>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3} m={2}>
                        <MDButton variant="gradient" color="info" fullWidth onClick={() => handleOpenDialog(PreOrderStatus.RECLARIFICATION)}>
                            Запросить уточнения
                        </MDButton>
                    </Grid>
                </>
            }
            {
              po.status === PreOrderStatus.ACCEPTED &&
              <>
                  <Grid item xs={12} md={6} lg={4} m={2} justifyContent={'center'}>
                      <MDButton variant="gradient" color="success" fullWidth onClick={() => handleOpenPopDialog()}>
                          Создать новый маршрут
                      </MDButton>
                  </Grid>
              </>
            }

          </Grid>
        </MDBox>
      }
    </MDBox>
  );
}

export default InfoBlock;
