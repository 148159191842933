import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ChangeEvent, useState} from "react";

interface PropsInterface {
  open: boolean,
  setOpen: any,
  title: string,
  answer: string,
  setAnswer(text: string): void,
  onAction(): void,
  description?: string,
  children?: JSX.Element,
  documentId?: number | null,
}

export default function PopUpDialogWindow({open, setOpen, title, description, onAction,
                                            setAnswer, answer, children, documentId}: PropsInterface): JSX.Element {
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (text: string) => {
    setAnswer(text);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{
            description?.length > 0 && description
          }</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            label="Введите название"
            type="text"
            fullWidth
            variant="standard"
            value={answer}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
          />
        </DialogContent>
        {children}
        <DialogActions>
          <Button onClick={handleClose}>Отменить</Button>
          <Button onClick={() => onAction()}>Сохранить</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}