// Images
import kal from "assets/images/kal-visuals-square.jpg";
import marie from "assets/images/marie.jpg";
import ivana from "assets/images/ivana-square.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

// types
type Types = any;

const managersMockData: Types = [
  {
    avatar: kal,
    fio: "Sophie B.",
    nickname: 'somenickname',
    id: 1
  },
  {
    avatar: marie,
    fio: "Anne Marie",
    nickname: 'somenickname',
    id: 2
  },
  {
    avatar: ivana,
    fio: "Ivanna",
    nickname: 'somenickname',
    id: 3
  },
];

export default managersMockData;
