import React, {useEffect} from 'react';
import {updateUserByAdminBodyInterface, UserRolesFull} from "../../../../../store/types/user.types";
import {UserRoles, UserStatuses} from "../../../../../utilities/constants";
import {Button, FormControl, Grid, MenuItem} from "@mui/material";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {convertEnumToArray} from "../../../../../utilities/converters";

interface PropsInterface {
  updateByAdmin: (id: number, body: { status: UserStatuses }) => void;
  id: number;
  status: UserStatuses;
}

function ChangeStatus({id, updateByAdmin, status}: PropsInterface) {

  const [allStatuses, setAllStatuses] = React.useState<UserStatuses[]>([]);
  const [newStatus, setNewStatus] = React.useState<UserStatuses | null>(null);

  useEffect(() => {
    const allStatusesArray = convertEnumToArray(UserStatuses);
    setAllStatuses(allStatusesArray);
  }, []);

  const handleChangeStatus = (statusToSet: UserStatuses) => {
    updateByAdmin(id, {status: statusToSet});

  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <p style={{fontSize: 14}}>Выберите роль для добавления пользователю:</p>
        <FormControl fullWidth>

          <Grid container>
            <Grid item xs={9} pr={2}>
              <Select
                sx={{height: '40px', width: '100%'}}
                value={newStatus ?? ''}
                onChange={(e: SelectChangeEvent) => setNewStatus(e.target.value as UserStatuses)}
              >
                {
                  allStatuses && allStatuses?.map((status: UserStatuses, index: number) => (

                    <MenuItem value={status} key={index}>{status}</MenuItem>
                  ))
                }
              </Select>
            </Grid>
            <Grid item xs={3} pr={2}>
              <Button
                variant="contained"
                sx={{color: 'white'}}
                color={'success'}
                onClick={() => handleChangeStatus(newStatus)}
                disabled={!newStatus || newStatus === status}
              >
                Изменить
              </Button>
            </Grid>
          </Grid>

        </FormControl>

      </Grid>
    </Grid>
  );
}

export default ChangeStatus;