import {UserPayload, userReducerTypes} from "../types/user.types";
import {InvitedCompany} from "../types/common.types";
import {CompanyProfile} from "../types/company.types";

export interface UserStateInterface {
  id: number,
  error: string,
  email: string,
  fio: string,
  birthday: string,
  profile: CompanyProfile,
  juridicalAddress: {
    country: string,
    postalCode: string,
    state: string,
    city: string,
    street: string,
    houseBuilding: string,
    officeApartment: string
  },
  deliveryAddress: {
    country: string,
    postalCode: string,
    state: string,
    city: string,
    street: string,
    houseBuilding: string,
    officeApartment: string
  },
  bankDetails: {
    bankName: string,
    bankAccount: string,
    bankCorrAccount: string,
    bankBik: string
  },
  nickname: string,
  avatar: string,
  phone: string,
  vkontakte: string,
  odnoklassniki: string,
  telegram: string,
  whatsapp: string,
  facebook: string,
  instagram: string,
  tiktok: string,
  passportNumber: number,
  passportIssued: string,
  ownCompany: {
    id: number,
    profile: string,
    name: string,
    description: string,
    inn: string,
    logo: string,
    typesOfWorks: {
      id: number,
      title: string,
      description: string,
    }[]
  },
  typesOfWorks: {
    id: number,
    title: string,
    description: string,
  }[],
  invitedFromCompanies: InvitedCompany[]
}

const initialState: UserStateInterface = {
  id: 0,
  error: '',
  email: '',
  fio: '',
  birthday: '',
  profile: CompanyProfile.BLANK,
  juridicalAddress: {
    country: '',
    postalCode: '',
    state: '',
    city: '',
    street: '',
    houseBuilding: '',
    officeApartment: ''
  },
  deliveryAddress: {
    country: '',
    postalCode: '',
    state: '',
    city: '',
    street: '',
    houseBuilding: '',
    officeApartment: ''
  },
  bankDetails: {
    bankName: '',
    bankAccount: '',
    bankCorrAccount: '',
    bankBik: ''
  },
  nickname: '',
  avatar: '',
  phone: '',
  vkontakte: '',
  odnoklassniki: '',
  telegram: '',
  whatsapp: '',
  facebook: '',
  instagram: '',
  tiktok: '',
  passportNumber: 0,
  passportIssued: '',
  ownCompany: {
    id: 0,
    profile: '',
    name: '',
    description: '',
    inn: '',
    logo: '',
    typesOfWorks: [],
  },
  typesOfWorks: [],
  invitedFromCompanies: []
}

const userReducer = (state: UserStateInterface = initialState, action: UserPayload) => {
  switch (action.type) {
    case userReducerTypes.CLEAR_USER:
      return {
        id: 0,
        error: '',
        email: '',
        fio: '',
        birthday: '',
        profile: CompanyProfile.BLANK,
        juridicalAddress: {
          country: '',
          postalCode: '',
          state: '',
          city: '',
          street: '',
          houseBuilding: '',
          officeApartment: ''
        },
        deliveryAddress: {
          country: '',
          postalCode: '',
          state: '',
          city: '',
          street: '',
          houseBuilding: '',
          officeApartment: ''
        },
        bankDetails: {
          bankName: '',
          bankAccount: '',
          bankCorrAccount: '',
          bankBik: ''
        },
        nickname: '',
        avatar: '',
        phone: '',
        vkontakte: '',
        odnoklassniki: '',
        telegram: '',
        whatsapp: '',
        facebook: '',
        instagram: '',
        tiktok: '',
        passportNumber: 0,
        passportIssued: '',
        ownCompany: {
          id: 0,
          profile: '',
          name: '',
          description: '',
          inn: '',
          logo: '',
          typesOfWorks: [],
        },
        typesOfWorks: [],
        invitedFromCompanies: []
      }
    case userReducerTypes.SET_USER_SUCCESS:
      return {
        id: action.payload.id,
        error: '',
        email: action.payload.email,
        fio: action.payload.fio || '',
        birthday: action.payload.birthday || '',
        profile: action.payload.profile || CompanyProfile.BLANK,
        juridicalAddress: action.payload.juridicalAddress ? {
          country: action.payload.juridicalAddress.country,
          postalCode: action.payload.juridicalAddress.postalCode,
          state: action.payload.juridicalAddress.state,
          city: action.payload.juridicalAddress.city,
          street: action.payload.juridicalAddress.street,
          houseBuilding: action.payload.juridicalAddress.houseBuilding,
          officeApartment: action.payload.juridicalAddress.officeApartment
        } : null,
        deliveryAddress: action.payload.deliveryAddress ? {
          country: action.payload.deliveryAddress.country,
          postalCode: action.payload.deliveryAddress.postalCode,
          state: action.payload.deliveryAddress.state,
          city: action.payload.deliveryAddress.city,
          street: action.payload.deliveryAddress.street,
          houseBuilding: action.payload.deliveryAddress.houseBuilding,
          officeApartment: action.payload.deliveryAddress.officeApartment
        } : null,
        bankDetails: action.payload.bankDetails ? {
          bankName: action.payload.bankDetails.bankName,
          bankAccount: action.payload.bankDetails.bankAccount,
          bankCorrAccount: action.payload.bankDetails.bankCorrAccount,
          bankBik: action.payload.bankDetails.bankBik
        } : null,
        nickname: action.payload.nickname,
        avatar: action.payload.avatar || '',
        phone: action.payload.phone || '',
        vkontakte: action.payload.vkontakte || '',
        odnoklassniki: action.payload.odnoklassniki || '',
        telegram: action.payload.telegram || '',
        whatsapp: action.payload.whatsapp || '',
        facebook: action.payload.facebook || '',
        instagram: action.payload.instagram || '',
        tiktok: action.payload.tiktok || '',
        passportNumber: action.payload.passportNumber || '',
        passportIssued: action.payload.passportIssued || '',
        ownCompany: {
          id: action.payload.ownCompany?.id || 0,
          profile: action.payload.ownCompany?.profile || '',
          name: action.payload.ownCompany?.name || '',
          description: action.payload.ownCompany?.description || '',
          inn: action.payload.ownCompany?.inn || '',
          logo: action.payload.ownCompany?.logo || '',
          typesOfWorks: action.payload.ownCompany?.typesOfWorks || [],
        },
        typesOfWorks: action.payload.typesOfWorks,
        invitedFromCompanies: action.payload.invitedFromCompanies,
      }
    case userReducerTypes.SET_USER_ERROR:
      return {...state, error: action.payload}
    case userReducerTypes.SET_JURIDICAL_ADDRESS:
      return {...state, juridicalAddress: action.payload}
    case userReducerTypes.SET_DELIVERY_ADDRESS:
      return {...state, deliveryAddress: action.payload}
    case userReducerTypes.SET_BANK_DETAILS:
      return {...state, bankDetails: action.payload}
    case userReducerTypes.SET_SOCIAL_PROFILE:
      return {
        ...state,
        phone: action.payload.phone,
        vkontakte: action.payload.vkontakte,
        odnoklassniki: action.payload.odnoklassniki,
        telegram: action.payload.telegram,
        whatsapp: action.payload.whatsapp,
        facebook: action.payload.facebook,
        instagram: action.payload.instagram,
        tiktok: action.payload.tiktok,
      }
    case userReducerTypes.SET_COMPANY_DATA:
      return {...state, ownCompany: {
          id: action.payload.id,
          profile: action.payload.profile,
          name: action.payload.name,
          description: action.payload.description,
          inn: action.payload.inn,
          logo: action.payload.logo,
          typesOfWorks: action.payload.typesOfWorks,
        }}
    case userReducerTypes.SET_USER_DATA:
      return {...state,
        fio: action.payload.fio,
        birthday: action.payload.birthday,
        passportNumber: action.payload.passportNumber,
        passportIssued: action.payload.passportIssued,
      }
    case userReducerTypes.SET_USER_AVATAR:
      return {...state, avatar: action.payload}
    case userReducerTypes.SET_USER_PROFILE:
      return {...state, profile: action.payload}
    case userReducerTypes.ADD_TYPE_OF_WORK:
      //@ts-ignore
      const isInArray = state.typesOfWorks?.find(item => item.id === action.payload.id)
      if (isInArray) return state;
      return {...state, typesOfWorks: [...state.typesOfWorks as {id: number, title: string, description: string}[], action.payload]}
    case userReducerTypes.REMOVE_TYPE_OF_WORK:
      return {...state, typesOfWorks: action.payload}
    default:
      return state
  }
}

export default userReducer;