// react-router components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {Grid} from "@mui/material";
import PreOrderCard from "./PreOrderCard";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useActions";
import {useEffect} from "react";
import {ShortOpenStage} from "../../../../store/reducers/stage.reducer";

function PreOrdersBlock(): JSX.Element {
  const {stages: {openStages}} = useAppSelector(state => state);
  const {getOpenStages} = useAppDispatch();

  useEffect(() => {
    getOpenStages();
  }, [])

  return (
    <Grid container mt={7} spacing={1}>
      {
        // @ts-ignore
        openStages?.length > 0 && openStages?.map((stage: ShortOpenStage, index: number) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index} mt={5}>
            < PreOrderCard stage={stage} />
          </Grid>
        ))
      }
    </Grid>
  );
}

export default PreOrdersBlock;
